<!--
 * @Autor: dingxiaolin
 * @Date: 2021-10-13 16:10:53
 * @LastEditors: dingxiaolin
 * @LastEditTime: 2021-12-20 17:13:26
-->
<template>
    <div style="width: 100%; height: 100%" class="box">
        <div class="title">实时告警通告 <img @click="$router.push('/shipAlarm')" :src="require('@/assets/index_more.png')"></div>
        <ul id="alarmRealTime">
            <template v-for="item in data">
                <li :key="item.id" @click="toDetail(item.id)">
                    <div class="pic">
                        <img :src="item.alarmPic ? item.alarmPic : require('@/assets/noAlarmPic.png')" />
                    </div>
                    <div class="content">
                        <p class="alarmname">
                            {{ item.alarmTypeName}}
                            
                        </p>
                        <p v-if="item.areaName" class="ellipsis">{{ item.areaName }}</p>
                        <!-- <p v-if="item.alarmPlaceWarf" class="ellipsis"><i class="iconfont icon-matou"></i>{{ item.alarmPlaceWarf }}</p> -->
                        <div class="flex rigtBtn">
                            <div>{{ item.alarmTime }}</div>
                            <div>
                            <span :class="'level' + item.alarmLevel">{{ item.alarmLevelName }}</span>
                            <span :class="`${get_status(item.confirmStatus, item.isDeal).color} `"  class="waitAlarm">
                                {{ get_status(item.confirmStatus, item.isDeal).text }}
                            </span>
                            </div>
                            
                        </div>
                    </div>
                </li>
            </template>
        </ul>
    </div>
</template>

<script>
import { alarmRealtimeNoticesStatistics } from '@/api/companybord/index'
import { get_status } from '@/utils/returnStatus'
import { Empty } from 'ant-design-vue'
export default {
    name: 'dataNotice',
    props: {},
    data() {
        return {
          data: [],
          timer: null
        }
    },
    computed: {
        empty() {
            return this.data.length > 0 ? false : true
        }
    },
    mounted() {
       this.getData()
    },
    beforeDestroy() {
        /** 页面销毁前清除定时器 */
        this.clearTimer()
    },
    methods: {
        get_status: get_status,
        /** 获取基础数据 */
        getData() {
            this.clearTimer()
            alarmRealtimeNoticesStatistics({
                size: '5', //每页数量
                current: '1', //当前页
                descs: 'alarm_time' //排序字段
            }).then(res => {
                this.data = res.records
                this.timer = setTimeout(() => {
                    this.getData()
                }, 5000)
            })
        },
        clearTimer() {
            if (this.timer) {
                clearTimeout(this.timer)
            }
        },
        toDetail(id) {
            this.$store.commit('updateList', this.data)
            this.$router.push('/shipAlarm/detail?id=' + id)
        }
    }
}
</script>

<style lang="less" scoped>
.box{
  color: #fff;
  .title{
    padding:0 10px 0 10px;
    height: 45px;
    line-height: 42px;
    border-bottom:#fff solid 1px;
    overflow: hidden;
    img{
      float: right;
      width: 13px;
      margin-top: 15px;
      cursor: pointer;
    }
  }
}
.waitDeal {
    color: #f26d4e;
}
.alreadyDeal {
    color: rgba(112, 182, 3, 1);
}
.noDeal {
    color: rgba(255, 255, 255, 0.3);
}
#alarmRealTime {
    padding-inline-start:0;
    width: 100%;
    height: calc(100% - 53px);
    overflow-y: auto;
    margin: 0 8px 0 0;
    li:hover {
        background: rgba(0,110,255,0.40) !important;
    }
    li {
        height: 20%;
        padding: 6px;
        display: flex;
        cursor: pointer;
        .pic {
            height: 100%;
            width: 30%;
            // border: 1px solid rgba(33, 184, 234, 0.23);
            // border-radius: 8px;
            overflow: hidden;
            display: flex;
            align-items: center;
            img {
                width: 100%;
                height: auto;
                align-items: center;
            }
        }
        .content {
            width: calc(100% - 90px);
            margin-left: 8px;
            color: rgba(255, 255, 255, 0.7);
            text-align: left;
            overflow: hidden;
            display: flex;
            flex-direction: column;
            justify-content: center;
            .alarmname{
              font-size: 14px;
              color: #00ACEF;
              margin-bottom: 5px;
            }
            .rigtBtn{
              font-size: 12px;
              justify-content: space-between;
              box-sizing: border-box;
              span{
                 padding: 0px 5px;
                  border-radius: 4px;
                  color: #1890FF; 
                  border:#054CB3 solid 1px;
                  margin-right: 2px;
                  &.waitAlarm{
                    background: #054CB3;
                  }
              }
              .level1 {
                    color: rgba(255, 21, 65, 1);
                    border: 1px solid rgba(255, 21, 65, 1);
                }
                .level2 {
                    color: rgba(220, 124, 56, 1);
                    border: 1px solid rgba(220, 124, 56, 1);
                }
                .level3 {
                    color: rgba(60, 184, 120, 1);
                    border: 1px solid rgba(60, 184, 120, 1);
                }
                .level4 {
                    color: rgb(60, 114, 184);
                    border: 1px solid rgba(60, 114, 184, 1);
                }
            }
            p {
                margin-bottom: 0px;
                position: relative;
                font-size: 12px;
                div{
                  span {
                      padding: 0px 2px;
                      border-radius: 4px;
                  }
                }
                
                
            }
            .icon-fanchuanzhuanhuan {
                font-size: 14px;
                margin-right: 2px;
                color: rgb(25, 100, 170);
            }
            .icon-matou {
                font-size: 12px;
                margin-right: 2px;
                color: rgb(54, 181, 181);
            }
        }
    }
    // li:nth-child(odd) {
    //     background: rgb(27, 58, 86);
    // }

}
</style>
