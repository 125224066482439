/*
 * @Autor: dingxiaolin
 * @Date: 2021-09-29 10:04:34
 * @LastEditors: dingxiaolin
 * @LastEditTime: 2021-12-07 15:32:08
 */
import config from '@/config/defaultSettings'
import { lStorage } from '@/utils/lStorage'
const app = {
    state: {
        mqtt: null,
        sidebar: true,
        device: 'desktop',
        layout: '',
        contentWidth: '',
        autoHideHeader: false,
        theme: lStorage.get('DEFAULT_THEME', config.navTheme),
        color: lStorage.get('DEFAULT_COLOR', config.primaryColor),
        weak: lStorage.get('DEFAULT_COLOR_WEAK', config.colorWeak),
        // multiTab: lStorage.get('DEFAULT_MULTI_TAB', config.multiTab),
        multiTab: true,
        collapsed: false,
        isFullScreen: false,
        deviceTagsList: []
    },
    mutations: {
        TOGGLE_SCREEN: (state, isFullScreen) => {
            state.isFullScreen = isFullScreen
        },
        TOGGLE_DEVICE: (state, device) => {
            state.device = device
        },
        TOGGLE_THEME: (state, theme) => {
            console.log('theme1', theme)
            lStorage.set('DEFAULT_THEME', theme)
            state.theme = theme
        },
        TOGGLE_COLOR: (state, color) => {
            lStorage.set('DEFAULT_COLOR', color)
            state.color = color
        },
        TOGGLE_WEAK: (state, weak) => {
            lStorage.set('DEFAULT_COLOR_WEAK', weak)
            state.weak = weak
        },
        TOGGLE_MULTI_TAB: (state, bool) => {
            lStorage.set('DEFAULT_MULTI_TAB', bool)
            state.multiTab = bool
        },
        SAVE_MQTT: (state, payload) => {
            state.mqtt = payload
        },
        SAVE_TAGS: (state, payload) => {
            state.deviceTagsList = payload
        }
    },
    actions: {}
}

export default app
