<template>
    <div class="container">
        <div class="title">
            客流管理平台 告警大屏
        </div>
        <div class="dashboard-header">
            <span class="screenfull" @click="toggleFull()">
                <a-icon :type="$store.state.app.isFullScreen ? 'fullscreen-exit' : 'fullscreen'" />
                &nbsp;{{ $store.state.app.isFullScreen ? '退出全屏' : '全屏' }}
            </span>
        </div>
        <div class="bg">
            <div class="left">
                <!-- 告警处理状态 -->
                <div class="deal-state">
                    <a-tabs default-active-key="1" @change="alarmDealStateChange">
                        <a-tab-pane key="1" tab="告警处理状态" forceRender>
                            <!-- <cricle-type-chart :data="chartsData.alarmDealState" /> -->
                        </a-tab-pane>
                        <a-tab-pane key="2" tab="告警场景分布" forceRender> </a-tab-pane>
                        <template #tabBarExtraContent>
                            <a-range-picker
                                v-model="timeValue.alarmDealState"
                                :allowClear="false"
                                class="rt"
                                @change="(dates, dateString) => onTimeChange(dates, dateString, 'alarmDealState')"
                            >
                                <a-icon slot="suffixIcon" type="calendar" />
                            </a-range-picker>
                        </template>
                    </a-tabs>
                    <div style="height:80%">
                        <cricle-type-chart :data="chartsData.alarmDealState" />
                    </div>
                </div>
                <!-- 排名 -->
                <div class="rank">
                    <a-tabs default-active-key="1" @change="alarmRankChartChange">
                        <a-tab-pane key="1" tab="告警类型排行" forceRender>
                            <!-- <rank-gradient-chart :data="chartsData.alarmRankChart.data" :category="chartsData.alarmRankChart.category" /> -->
                        </a-tab-pane>
                        <a-tab-pane key="2" tab="告警次数Top10" forceRender>
                            <!-- <rank-gradient-chart :data="chartsData.alarmTop.data" :category="chartsData.alarmTop.category" /> -->
                        </a-tab-pane>
                        <template #tabBarExtraContent>
                            <a-range-picker
                                v-model="timeValue.alarmRankChart"
                                :allowClear="false"
                                class="rt"
                                @change="(dates, dateString) => onTimeChange(dates, dateString, 'alarmRankChart')"
                            >
                                <a-icon slot="suffixIcon" type="calendar" />
                            </a-range-picker>
                        </template>
                    </a-tabs>
                    <div style="height:80%">
                        <rank-gradient-chart :data="chartsData.alarmRankChart.data" :category="chartsData.alarmRankChart.category" />
                    </div>
                </div>
                <!-- 告警时段 -->
                <div class="time">
                    <div class="time_header">
                        <div class="time_header-title">告警时段分布</div>
                        <div class="calendar">
                            <a-range-picker
                                v-model="timeValue.alarmTime"
                                :allowClear="false"
                                class="rt"
                                @change="(dates, dateString) => onTimeChange(dates, dateString, 'alarmTime')"
                            >
                                <a-icon slot="suffixIcon" type="calendar" />
                            </a-range-picker>
                        </div>
                    </div>
                    <div class="time-container">
                        <gradient-bar-chart :data="chartsData.alarmTime.data" :category="chartsData.alarmTime.category" />
                    </div>
                </div>
            </div>
            <div class="mid">
                <div class="map">
                    <Map></Map>
                </div>
                <div class="counter">
                    <a-tabs default-active-key="1" @change="alarmCountChange">
                        <a-tab-pane key="1" tab="告警次数统计(日统计)"> </a-tab-pane>
                        <a-tab-pane key="2" tab="告警次数统计(月统计)"> </a-tab-pane>
                        <template #tabBarExtraContent>
                            <a-range-picker
                                v-model="timeValue.alarmCount"
                                :allowClear="false"
                                class="rt"
                                :format="timeFmt"
                                @change="(dates, dateString) => onTimeChange(dates, dateString, 'alarmCount')"
                            >
                                <a-icon slot="suffixIcon" type="calendar" />
                            </a-range-picker>
                        </template>
                    </a-tabs>
                    <div style="height:80%">
                        <line-chart :data="chartsData.alarmCount.data" :category="chartsData.alarmCount.category" />
                    </div>
                </div>
            </div>
            <div class="right">
                <div class="rank">
                    <dataDes></dataDes>
                </div>
                <div class="notice">
                    <dataNotice></dataNotice>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import screenfull from 'screenfull'
import { debounce } from '@/utils/util'
import CricleTypeChart from './components/CricleTypeChart'
import Map from './components/map.vue'
import RankGradientChart from './components/RankGradientChart'
import GradientBarChart from './components/GradientBarChart'
import LineChart from './components/LineChart'
import {
    alarmHandleStatusStatistics,
    alarmDistributionScenarioStatistics,
    alarmTypRankingStatistics,
    alarmTimesStatistics,
    alarmHourStatistics
} from '@/api/companybord/index'
import { spArr } from '@/utils/util'
import DataDes from './components/DataDes'
import DataNotice from './components/DataNotice'
export default {
    components: {
        CricleTypeChart,
        Map,
        DataDes,
        DataNotice,
        RankGradientChart,
        GradientBarChart,
        LineChart
    },
    data() {
        return {
            /** 子组件时间数组 */
            timeValue: {
                /** 告警处理 告警场景 时间 */
                alarmDealState: [this.$moment(this.$moment().format('YYYY-01-01')), this.$moment()],
                /** 告警类型排行/告警次数Top10 */
                alarmRankChart: [this.$moment(this.$moment().format('YYYY-01-01')), this.$moment()],
                /** 告警时段分布 */
                alarmTime: [this.$moment(this.$moment().format('YYYY-01-01')), this.$moment()],
                alarmCount: [this.$moment().subtract(1, 'months'), this.$moment()]
            },
            chartsData: {
                /** 告警处理状态  告警场景分布*/
                alarmDealState: [],

                alarmScreen: [],
                /** 告警类型排行榜 */
                alarmRankChart: {
                    data: [],
                    category: []
                },
                /** 告警次数Top10 */
                // alarmTop: {
                //     data: [],
                //     category: []
                // },
                /** 告警时段分布 */
                alarmTime: {
                    data: [],
                    category: []
                },
                alarmCount: {
                    data: [],
                    category: []
                }
            },
            /** 排行定时器 */
            rankTimer: null,
            /** 时间格式 */
            timeFmt: 'YYYY-MM-DD',
            isFullScreen: false,
            debounce: debounce
        }
    },

    mounted() {
        this.initChartsData()
        screenfull.on('change', this.screenChange)
        this.$once('hook:beforeDestroy', function() {
            screenfull.off('change', this.screenChange)
        })
    },
    methods: {
        screenChange() {
            this.isFullScreen = !this.isFullScreen
            this.$store.commit('TOGGLE_SCREEN', this.isFullScreen)
        },
        initChartsData() {
            this.getAlarmDealState()
            this.alarmTypRankingStatistics()
            this.alarmHourStatistics()
            this.getAlarmCounter(1)
        },
        alarmDealStateChange: debounce(function(e) {
            if (e == 1) {
                this.getAlarmDealState()
            } else {
                this.getAlarmSceen()
            }
        }, 500),

        alarmRankChartChange: debounce(function(e) {
            if (e == 1) {
                this.alarmTypRankingStatistics()
            } else {
                this.rankTimer && clearInterval(this.rankTimer)
                this.rankTimer = null
                console.log(this.rankTimer)
                this.alarmTimesStatistics()
            }
        }, 500),
        alarmCountChange: debounce(function(e) {
            if (e == 1) {
                this.timeFmt = 'YYYY-MM-DD'
                this.timeValue.alarmCount = [this.$moment().subtract(1, 'months'), this.$moment()]
            } else {
                this.timeFmt = 'YYYY-MM'
                this.timeValue.alarmCount = [
                    this.$moment()
                        .subtract(1, 'years')
                        .add(1, 'M'),
                    this.$moment()
                ]
            }
            this.getAlarmCounter(e)
        }, 500),
        /** 获取告警场景分布 */
        getAlarmSceen() {
            alarmDistributionScenarioStatistics({
                alarmYearMonthStart: this.timeValue.alarmDealState[0].format('YYYY-MM-DD'),
                alarmYearMonthEnd: this.timeValue.alarmDealState[1].format('YYYY-MM-DD')
            }).then(res => {
                const data = []
                if (res && res.length > 0) {
                    res.map(item => {
                        data.push({
                            name: item.alarmSceneName || '未知',
                            value: item.statisticsCount
                        })
                    })
                    this.chartsData.alarmDealState = data
                }
            })
        },
        /** 获取告警处理状态 */
        getAlarmDealState() {
            alarmHandleStatusStatistics({
                alarmYearMonthStart: this.timeValue.alarmDealState[0].format('YYYY-MM-DD'),
                alarmYearMonthEnd: this.timeValue.alarmDealState[1].format('YYYY-MM-DD')
            }).then(res => {
                const data = []
                if (res && res.length > 0) {
                    for (let i in res[0]) {
                        data.push({
                            name: i,
                            value: res[0][i]
                        })
                    }
                    this.chartsData.alarmDealState = data
                }
            })
        },
        /** 获取告警类型排行 */
        alarmTypRankingStatistics() {
            alarmTypRankingStatistics({
                alarmYearMonthStart: this.timeValue.alarmRankChart[0].format('YYYY-MM-DD'),
                alarmYearMonthEnd: this.timeValue.alarmRankChart[1].format('YYYY-MM-DD')
            }).then(res => {
                if (res && res.length > 0) {
                    var i = 0
                    const array = spArr(res, 10)
                    let data = []
                    let category = []
                    array[0].map(item => {
                        category.push(item.alarmTypeName)
                        data.push(item.statisticsCount)
                    })
                    this.chartsData.alarmRankChart = {
                        data,
                        category
                    }
                    this.rankTimer = setInterval(() => {
                        let category = []
                        let data = []
                        i = i == array.length - 1 ? 0 : i + 1
                        array[i].map(item => {
                            category.push(item.alarmTypeName)
                            data.push(item.statisticsCount)
                        })
                        this.chartsData.alarmRankChart = {
                            data,
                            category
                        }
                    }, 3000)
                } else {
                    clearInterval(this.rankTimer)
                    this.chartsData.alarmRankChart = {
                        data: [],
                        category: []
                    }
                }
            })
        },

        /** 获取告警次数Top10 */
        alarmTimesStatistics() {
            Promise.all([
                alarmTimesStatistics({
                    alarmYearMonthStart: this.timeValue.alarmRankChart[0].format('YYYY-MM-DD'),
                    alarmYearMonthEnd: this.timeValue.alarmRankChart[1].format('YYYY-MM-DD'),
                    alarmTimesStatisticsType: '3' // 按船计算
                }),
                alarmTimesStatistics({
                    alarmYearMonthStart: this.timeValue.alarmRankChart[0].format('YYYY-MM-DD'),
                    alarmYearMonthEnd: this.timeValue.alarmRankChart[1].format('YYYY-MM-DD'),
                    alarmTimesStatisticsType: '4' // 按码头计算
                })
            ]).then(res => {
                const value = res[0].length > 0 ? res[0] : res[1] // 判断按船计算是否有数据，如果有用船舶的预警数据
                const data = []
                const category = []
                value.map(item => {
                    category.push(item.shipName)
                    data.push(item.statisticsCount)
                })
                console.log(value)
                if (value && value.length > 0) {
                    this.chartsData.alarmRankChart = {
                        data,
                        category
                    }
                } else {
                    this.chartsData.alarmRankChart = {
                        data: [],
                        category: []
                    }
                }
            })
        },
        /** 获取告警时段分布 */
        alarmHourStatistics() {
            alarmHourStatistics({
                alarmYearMonthStart: this.timeValue.alarmTime[0].format('YYYY-MM-DD'),
                alarmYearMonthEnd: this.timeValue.alarmTime[1].format('YYYY-MM-DD')
            }).then(res => {
                const data = []
                const category = []
                if (res && res.length > 0) {
                    res.map(item => {
                        category.push(item.alarmHour)
                        data.push(item.statisticsCount)
                    })
                    this.chartsData.alarmTime = {
                        data,
                        category
                    }
                } else {
                    this.chartsData.alarmTime = {
                        data: [],
                        category: []
                    }
                }
            })
        },
        /** 获取告警次数 */
        getAlarmCounter(timeMode) {
            const format = timeMode == 2 ? 'YYYY-MM' : 'YYYY-MM-DD' // 判断计算类型
            alarmTimesStatistics({
                alarmYearMonthStart: this.timeValue.alarmCount[0].format(format),
                alarmYearMonthEnd: this.timeValue.alarmCount[1].format(format),
                alarmTimesStatisticsType: String(timeMode)
            }).then(res => {
                const data = []
                const category = []
                res.map(item => {
                    category.push(item[timeMode == 2 ? 'alarmYearMonth' : 'alarmYearMonthDay']) // 判断计算类型，时间取值不同
                    data.push(item.statisticsCount)
                })
                this.chartsData.alarmCount = {
                    data,
                    category
                }
            })
        },

        /** 更改时间，按照对应的type触发子组件内获取新数据 */
        onTimeChange(dates, dateString, type) {
            this.timeValue[type] = dates
            switch (type) {
                case 'alarmDealState':
                    this.getAlarmDealState()
                    this.getAlarmSceen()
                    break
                case 'alarmRankChart':
                    this.alarmTypRankingStatistics()
                    this.alarmTimesStatistics()
                    break
                case 'alarmTime':
                    this.alarmHourStatistics()
                    break
                case 'alarmCount':
                    this.getAlarmCounter(1)
                    break
                default:
                    break
            }
            // this.$refs[type].getData(dates, this.timesStatistics)
        },
        toggleFull() {
            // 全屏切换
            if (!screenfull.isEnabled) {
                this.$message({
                    message: '浏览器不支持全屏，请切换浏览器！',
                    type: 'warning'
                })
                return false
            }
            screenfull.toggle()
        }
    }
}
</script>

<style lang="less" scoped>
.container {
    width: 100%;
    height: 100%;
    position: relative;
    .title {
        color: #fff;
        font-size: 32px;
        position: absolute;
        left: 50%;
        transform: translateX(-48%);
        z-index: 1000;
        top: 1%;
    }
    .bg {
        background: url('../../../assets/alarmbg.jpg') no-repeat 100% 100%;
        height: 100%;
        width: 100%;
        position: absolute;
        z-index: 999;
        background-size: 100% 100%;
        position: relative;

        .left {
            width: 29%;
            height: 91%;
            position: absolute;
            left: 2%;
            top: 9%;
            .deal-state {
                height: 30%;
                background: rgba(0, 37, 239, 0.2);
                border-radius: 6px;
            }
            .rank {
                height: 36%;
                background: rgba(0, 37, 239, 0.2);
                border-radius: 6px;
                margin: 2% 0;
            }
            .time {
                height: 30%;
                background: rgba(0, 37, 239, 0.2);
                border-radius: 6px;
                .time_header {
                    position: relative;
                    display: flex;
                    justify-content: space-between;
                    padding: 0 16px;
                    margin: 0 10px;
                    align-items: center;
                    .time_header-title {
                        color: #fff;
                    }

                    &::after {
                        content: '';
                        width: 100%;
                        border-bottom: 1px solid #e8e8e8;
                        position: absolute;
                        bottom: 0;
                        left: 0;
                    }
                }
                .time-container {
                    width: 100%;
                    height: 80%;
                }
            }
        }
        .mid {
            width: 36%;
            height: 91%;
            position: absolute;
            left: 32%;
            top: 9%;
            .map {
                width: 100%;
                height: 67%;
                margin-bottom: 2%;
            }
            .counter {
                height: 30%;
                width: 100%;
                border-radius: 6px;
                background: rgba(0, 37, 239, 0.2);
            }
        }
        .right {
            width: 29%;
            height: 91%;
            position: absolute;
            right: 2%;
            top: 9%;
            .rank {
                height: 30%;
                background: rgba(0, 37, 239, 0.2);
                border-radius: 6px;
                margin: 0 0 2%;
            }
            .notice {
                height: 67%;
                background: rgba(0, 37, 239, 0.2);
                border-radius: 6px;
                margin: 0 0 2%;
            }
        }
    }
}
/deep/ .ant-tabs-nav .ant-tabs-tab {
    color: #fff;
}
/deep/.ant-tabs-nav .ant-tabs-tab-active {
    color: #00acef;
}
/deep/.ant-tabs-ink-bar {
    color: #00acef;
}
/deep/ .ant-calendar-picker-input.ant-input {
    width: 200px;
    height: 100%;
    font-size: 11px;
    background: rgba(24, 144, 255, 0.1);
    border: none;
    border-radius: 3px;
    color: #fff;
    margin: 5% 0;
    margin-left: auto;
}

/deep/.ant-tabs-bar {
    margin: 0 10px 0 10px;
}

/deep/ .ant-calendar-range-picker-separator {
    color: #fff;
}
.screenfull {
    line-height: 100%;
    font-size: 18px;
    color: #fff;
    display: flex;
    align-items: center;
    margin-left: 30px;
    cursor: pointer;
    position: absolute;
    top: 2%;
    right: 3%;
    z-index: 1000;
}
</style>
