<template>
    <a-modal
        :title="title"
        centered
        :width="700"
        :visible="visible"
        :confirmLoading="confirmLoading"
        @ok="handleOk"
        @cancel="handleCancel"
        destroyOnClose
    >
        导入失败数据
    </a-modal>
</template>

<script>
import { shipInfoDetail, updateShipInfo, saveShipInfo, hcwInfoList } from '@/api/alarm/index'
import { formRules } from '@/utils/validator'
import { DeptTree } from '@/components'
import debounce from 'lodash/debounce'
export default {
    data() {
        this.fetchGoods = debounce(this.fetchGoods, 500)
        return {
            shipBrandList:[],
            title: '导入失败',
            formRules: formRules,
            labelCol: {
                xs: { span: 24 },
                sm: { span: 5 }
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 15 }
            },
            fetching: false,
            visible: false,
            confirmLoading: false,
            detailData: {},
            hcwInfo: '',
            hcwInfo_list: [],
            form: {
                id: '',
                /**车辆名称 */
                shipName: '',
                /**MMSI */
                shipMmsi: '',
                /**车辆识别号 */
                shipLocatorCode: '',
                /**船型 */
                shipType: '',
                /**车辆品牌 */
                shipBrand: '',
                /**船载货吨位 */
                shipTon: '',
                /**所属机构 */
                tenantId: '',
                /**所属机构名称 */
                tenantName: '',
                /**联系人 */
                linkPeople: '',
                /**联系电话 */
                linkPhone: '',
                /**备注 */
                memo: '',
                /**货物类型 */
                goodsType: 0,
                /**货物类型为1时，名称逗号分隔  */
                goodsNames: '',
                /**货物类型为0时，id逗号分隔  */
                goodsIds: '',
                goodsIdsArray: []
            }
        }
    },
    props: {},
    components: {
        DeptTree
    },
    methods: {
        // 初始化方法
        show(record) {
            this.visible = true

            if (record) {
                this.getDetail(record)
                this.title = '编辑车辆'
                this.hcwInfo_list = []
            } else {
                this.title = '添加车辆'
                this.hcwInfo_list = []
                this.form = {
                    id: '',
                    shipName: '',
                    shipMmsi: '',
                    shipLocatorCode: '',
                    shipType: '',
                    shipBrand: '',
                    shipTon: '',
                    tenantId: '',
                    tenantName: '',
                    linkPeople: '',
                    linkPhone: '',
                    memo: '',
                    goodsType: 0,
                    goodsNames: '',
                    goodsIds: '',
                    goodsIdsArray: []
                }
            }
        },
        async getDetail(record) {
            this.detailData = await shipInfoDetail({ id: record.id })
            Object.assign(this.form, this.detailData)
            // this.form.goodsIdsArray = this.form.goodsIds.split(',')
            // console.log(this.form.goodsIdsArray)
            // if (this.form.goodsIdsArray.length > 0) {
            //     //获取全部危化品名录
            //     hcwInfoList({
            //         keyWords_like: '',
            //         size: -1,
            //         descs: 'create_time'
            //     }).then(res => {
            //         this.hcwInfo_list = res.records
            //     })
            // }
        },
        handleOk() {
            this.confirmLoading = true
            this.$refs.form.validate(valid => {
                if (valid) {
                    // this.form.goodsIds = this.form.goodsIdsArray.join(',')
                    if (this.title == '添加车辆') {
                        saveShipInfo(this.form)
                            .then(res => {
                                this.$message.success('新增车辆成功')
                                this.visible = false
                                this.confirmLoading = false
                                this.$emit('ok')
                            })
                            .catch(() => {
                                this.confirmLoading = false
                            })
                    } else {
                        updateShipInfo(this.form)
                            .then(res => {
                                this.$message.success('编辑车辆成功')
                                this.visible = false
                                this.confirmLoading = false
                                this.$emit('ok')
                            })
                            .catch(() => {
                                this.confirmLoading = false
                            })
                    }
                } else {
                    this.confirmLoading = false
                }
            })
        },
        handleCancel() {
            this.visible = false
        },
        /**切换货物类型 */
        handleGoodsChange(v) {
            this.form.goodsIdsArray = []
            this.form.goodsNames = ''
            this.form.goodsIds = ''
        },
        deptChange(v) {
            this.form.tenantId = v.value
            this.form.tenantName = v.title
        },
        /**远程检索货物 */
        fetchGoods(value) {
            console.log('fetching goods', value)
            if (!value) {
                return
            }
            this.hcwInfo_list = []
            this.fetching = true
            hcwInfoList({
                keyWords_like: value,
                size: -1,
                descs: 'create_time'
            })
                .then(res => {
                    this.hcwInfo_list = res.records
                    this.fetching = false
                })
                .catch(() => {
                    this.fetching = false
                })
        },
        handleChange(value) {
            console.log('test', value)
            // Object.assign(this, {
            //     value,
            //     data: [],
            //     fetching: false
            // })
        }
    }
}
</script>
<style scoped lang="less">
.swiper-container {
    height: 280px;
}
.handle .ant-form-item {
    margin-bottom: 10px;
}
.swiper-pagination-fraction {
    top: 10px;
    bottom: unset;
    color: #007aff;
}
.swiper-container-horizontal > .swiper-pagination-bullets {
    top: 10px;
    bottom: unset;
}
</style>
