<!--
 * @Author: gsy 1443717855@qq.com
 * @LastEditors: gsy 1443717855@qq.com
 * @Date: 2024-02-06 10:34:42
 * @LastEditTime: 2024-04-08 10:12:30
 * @Description: 
-->
<template>
    <a-modal :title="title" :visible="visible" :confirm-loading="confirmLoading" @ok="handleOk" @cancel="handleCancel" destroyOnClose>
        <a-form-model ref="form" :model="form" :rules="formRules" :label-col="labelCol" :wrapper-col="wrapperCol">
            <a-form-model-item label="所属机构" prop="tenantId" :rules="formRules.change">
                <DeptTree :all="false" v-model="form.tenantId" :disabled="title == '编辑站点'"></DeptTree>
            </a-form-model-item>
            <a-form-model-item label="站点编号" prop="stationCode" :rules="formRules.blur">
                <a-input v-model="form.stationCode" placeholder="请输入站点编号" :maxLength="20" />
            </a-form-model-item>
            <a-form-model-item label="站点名称" prop="stationName" :rules="formRules.blur">
                <a-input v-model="form.stationName" placeholder="请输入站点名称" :maxLength="20" />
            </a-form-model-item>
            <a-form-model-item label="经度" prop="stationLng" :rules="formRules.blur">
                <a-input v-model="form.stationLng" placeholder="请输入经度" :maxLength="20" />
            </a-form-model-item>
            <a-form-model-item label="纬度" prop="stationLat" :rules="formRules.blur">
                <a-input v-model="form.stationLat" placeholder="请输入纬度" :maxLength="20" />
            </a-form-model-item>
            <a-form-model-item label="站点半径" prop="radius" :rules="formRules.blur">
                <a-input-number v-model="form.radius" :min="0" :max="9999" />
                <span>米</span>
            </a-form-model-item>
            <a-form-model-item label="备注">
                <a-textarea v-model="form.memo" placeholder="请输入备注" :auto-size="{ minRows: 3, maxRows: 5 }" :maxLength="100" />
            </a-form-model-item>
        </a-form-model>
    </a-modal>
</template>
<script>
import { formRules } from '@/utils/validator'
import { DeptTree } from '@/components'
import { stationsAdd, stationsUpdate } from '@/api/alarm'
export default {
    components: {
        DeptTree
    },
    data() {
        return {
            formRules: formRules,
            labelCol: {
                xs: { span: 24 },
                sm: { span: 5 }
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 15 }
            },
            title: '添加站点',
            isAdd: false,
            visible: false,
            confirmLoading: false,
            form: {
                id: '',
                stationName: '', // 站点名称
                stationCode: '', // 站点编号
                stationLng: '', // 站点GPS经度
                stationLat: '', // 站点GPS纬度
                memo: '', // 备注
                radius: 0,
                tenantId: '' // 组织机构id
            }
        }
    },
    methods: {
        show(record) {
            this.visible = true
            if (record) {
                this.isAdd = false
                this.title = '编辑站点'
                // 详情
                Object.keys(this.form).forEach(key => (this.form[key] = record[key]))
            } else {
                this.form = this.$options.data.call(this).form
                this.isAdd = true
                this.title = '添加站点'
            }
        },
        handleOk() {
            this.$refs.form.validate(valid => {
                if (valid) {
                    let api = this.isAdd ? stationsAdd : stationsUpdate
                    this.confirmLoading = true
                    api(this.form)
                        .then(res => {
                            this.$message.success(this.isAdd ? '添加成功' : '编辑成功')
                            this.confirmLoading = false
                            this.visible = false
                            this.$emit('ok')
                        })
                        .catch(err => {
                            this.confirmLoading = false
                        })
                }
            })
        },
        handleCancel() {
            this.visible = false
        }
    }
}
</script>
<style lang="less" scoped></style>
