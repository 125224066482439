<!--
 * @Autor: dingxiaolin
 * @Date: 2021-10-13 16:10:53
 * @LastEditors: dingxiaolin
 * @LastEditTime: 2021-12-22 13:52:47
-->
<template>
    <div id="lineChart" ref="lineChart" style="height: 100%; width: 100%">
        <a-empty v-if="empty" :image="simpleImage" style="color: #fff" />
    </div>
</template>

<script>
import * as echarts from 'echarts'
import { Empty } from 'ant-design-vue'
export default {
    name: 'lineChart',
    /** 图表数据 */
    props: {
        data: {
            type: Array,
            default: () => {
                return []
            }
        },
        category: {
            type: Array,
            default: () => {
                return []
            }
        }
    },
    computed: {
        empty() {
            return this.data.length > 0 ? false : true
        }
    },
    data() {
        return {}
    },
    watch: {
        data: {
            handler() {
                this.drawChart(this.data, this.category)
            },
            deep: true
        }
    },
    created() {
        this.simpleImage = Empty.PRESENTED_IMAGE_SIMPLE
    },
    mounted() {
    },
    methods: {
        /** 绘制图表 */
        drawChart(data, category) {
            var lineChart = echarts.init(this.$refs.lineChart)
            window.addEventListener('resize', () => {
                setTimeout(() => {
                    lineChart.resize()
                }, 200)
            })
            var option = {
                visualMap: [
                    {
                        show: false,
                        type: 'continuous',
                        seriesIndex: 0,
                        min: 0,
                        max: 33
                    }
                ],
                grid: {
                    top: '12%',
                    left: '3%',
                    right: '3%',
                    bottom: '3%',
                    containLabel: true
                },
                tooltip: {
                    trigger: 'axis'
                },
                xAxis: {
                    type: 'category',
                    data: category, // 接口获取的类型
                    axisLabel: {
                        show: true
                    },
                    axisTick: {
                        alignWithLabel: true
                    }
                },
                yAxis: {
                    splitLine: { show: false },
                    axisLabel: {
                        show: true
                    }
                },
                series: [
                    {
                        type: 'line',
                        showSymbol: false,
                        data: data, // 接口获取的数据
                        itemStyle: {
                            normal: {
                                lineStyle: {
                                    color: '#3CB878'
                                }
                            }
                        }
                    }
                ]
            }
            lineChart.setOption(option)
            setTimeout(() => {
                lineChart.resize()
            }, 0)
        }
    }
}
</script>

<style lang="less" scoped>
#lineChart {
    width: 100%;
    height: 70%;
}
</style>
