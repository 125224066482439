<!--
 * @Autor: dingxiaolin
 * @Date: 2021-10-13 16:10:53
 * @LastEditors: dingxiaolin
 * @LastEditTime: 2022-11-03 14:39:28
-->
<template>
    <div :class="['wrap']">
        <div class="leftTree" v-if="!$store.state.app.isFullScreen">
            <ExpandTree ref="eTree" :channel="true" @channels="getChannels" @unselectedKey="unselectedKey" />
        </div>
        <div class="rightContent">
            <div class="videoBox">
                <div :class="['boxclass' + num, 'video']">
                    <div v-for="n in num" :key="n" :class="['box', 'box' + n]">
                        <div class="title" v-if="n <= channelList.length">
                            <div class="label" :title="channelList[n - 1].nodeDescription">
                                {{ channelList[n - 1].nodeDescription }} <span v-if="channelList[n - 1].deviceType == '1'" style="color:red">4G</span>
                            </div>
                            <div @click="delVideo(n - 1, channelList[n - 1].nodeId)" title="删除">
                                <a-icon type="delete" style="font-size:16px;cursor: pointer;" />
                            </div>
                        </div>
                        <!-- v-if="channelList[n - 1].showModal" -->
                        <div class="modal" v-if="n <= channelList.length && channelList[n - 1].showModal">
                            <a-icon type="reload" style="font-size:30px" />
                            <div style="margin:10px ">1分钟播放结束</div>
                            <a-button type="primary" @click="resumePlay(channelList[n - 1])"> 继续播放 </a-button>
                        </div>
                        <live-player
                            :video-url="n <= channelList.length ? channelList[n - 1].url : ''"
                            fluent
                            autoplay
                            live
                            stretch
                            aspect="fullscreen"
                            :controls="false"
                        />
                    </div>
                </div>
            </div>
            <div class="boxSizing">
                <div v-for="(item, i) in boxList" :key="i" :class="['boxclass', 'boxclass' + item]" @click="checkBox(item)">
                    <div v-for="n in item" :key="n" :class="['box', num == item ? 'active' : 'other', 'box' + n]"></div>
                </div>
                <a-icon type="fullscreen" class="icon" @click.prevent="fullScreen" />
            </div>
        </div>
    </div>
</template>

<script>
import LivePlayer from '@liveqing/liveplayer'
import ExpandTree from '@/components/ExpandTree'
import { getGBSToken } from '@/api/alarm'
import qs from 'qs'
import axios from 'axios'
export default {
    name: 'importantAlarm',
    components: { LivePlayer, ExpandTree },
    data() {
        return {
            data: [],
            timer: null,
            num: 9,
            boxList: [1, 4, 6, 9, 16, 25],
            channelList: []
        }
    },
    computed: {},
    mounted() {
        // this.startStream()
    },
    beforeDestroy() {
        /** 页面销毁前清除定时器 */
    },
    methods: {
        /**开始播放流 */
        async startStream(deviceCode, channelNumber) {
            let token = await getGBSToken()
            let param = {
                serial: deviceCode,
                code: channelNumber,
                token,
                timeout: 60
            }
            return new Promise(async (res, rej) => {
                try {
                    let data = await axios.get(process.env.VUE_APP_GBS_URL + '/api/v1/stream/start?' + qs.stringify(param))
                    // res(data.data.HLS)
                    res(data.data.FLV)
                } catch (error) {
                    rej(error)
                }
            })
        },
        /**获取树选中通道 */
        getChannels(item) {
            console.log('item', item)
            this.startStream(item.deviceCode, item.channelNumber)
                .then(url => {
                    let index = this.$refs.eTree.selectedKeys.findIndex(v => v == item.nodeId)
                    if (index == -1) return
                    this.channelList.push({
                        ...item,
                        url: url,
                        showModal: false
                    })
                    console.log('channelList1', url, this.channelList, this.num)
                    // 4G设备播放一分钟   1:4G 2:以太网
                    if (item.deviceType == '1') {
                        setTimeout(() => {
                            this.remove4GURL(url)
                        }, 60000)
                    }
                    if (this.channelList.length > this.num) {
                        this.num = this.boxList[this.boxList.indexOf(this.num) + 1]
                    }
                    if (this.channelList.length > 25) {
                        this.$message.warning('已超过播放通道个数限制')
                    }
                })
                .catch(err => {
                    this.$message.error(item.nodeDescription + '取流失败')
                    let i = this.$refs.eTree.selectedKeys.findIndex(v => v == item.nodeId)
                    this.$refs.eTree.selectedKeys.splice(i, 1)
                })
        },
        /**一分钟自动移除4G地址 */
        remove4GURL(url) {
            const index = this.channelList.findIndex(v => v.url == url)
            if (index > -1) {
                this.channelList[index].showModal = true
                this.channelList[index].url = ''
            }
        },
        /**继续播放 */
        resumePlay(item) {
            this.startStream(item.deviceCode, item.channelNumber)
                .then(url => {
                    item.url = url
                    item.showModal = false
                    setTimeout(() => {
                        this.remove4GURL(url)
                    }, 60000)
                })
                .catch(err => {
                    this.$message.error(item.nodeDescription + '取流失败')
                })
        },
        /**删除直播 */
        unselectedKey(key) {
            const index = this.channelList.findIndex(v => v.nodeId == key)
            if (index > -1) {
                this.channelList.splice(index, 1)
            } else {
                // setTimeout(() => {
                //     const index = this.channelList.findIndex(v => v.nodeId == key)
                //     if (index > -1) {
                //         this.channelList.splice(index, 1)
                //     }
                // }, 2000)
            }
            console.log('channelList', this.channelList)
        },
        /**删除当前视频 */
        delVideo(index, key) {
            let i = this.$refs.eTree.selectedKeys.findIndex(v => v == key)
            this.$refs.eTree.selectedKeys.splice(i, 1)
            this.channelList.splice(index, 1)
        },
        /**选中布局 */
        checkBox(num) {
            this.num = num
        },
        fullScreen() {
            this.$fullscreen.enter(this.$el.querySelector(`.videoBox`), {
                wrap: false,
                callback: f => {
                    this.isFullScreen = f
                }
            })
        }
    }
}
</script>

<style lang="less" scoped>
.wrap {
    padding: 5px;
    height: calc(100% - 45px);
    display: flex;
    &.full {
        height: 100%;
    }
    .leftTree {
        background: #fff;
        width: 25%;
        padding: 10px;
        height: 100%;
        margin-right: 0.5%;
    }
    .rightContent {
        flex: auto;
        height: 100%;
        padding: 10px;
        background: #fff;
        .title {
            padding: 0 10px;
            width: 100%;
            background: #ddd;
            display: flex;
            justify-content: space-between;
        }

        .videoBox {
            width: 100%;
            height: calc(100% - 50px);
            margin-right: 10px;
            display: flex;
            .title {
                width: 100%;
                background: rgba(43, 51, 63, 0.7);
                position: absolute;
                z-index: 99;
                color: #fff;
                padding-left: 5px;
                .label {
                    width: 100%;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    word-break: break-all;
                    margin-right: 5px;
                }
            }
            .modal {
                position: absolute;
                left: 0;
                top: 0;
                background: #192533;
                width: 100%;
                height: 100%;
                z-index: 88;
                color: #fff;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
            }
            .video {
                width: 100%;
            }
        }

        .boxSizing {
            margin-top: 10px;
            width: 100%;
            height: 40px;
            display: flex;
            .icon {
                font-size: 36px;
                color: #7f7f7f;
                margin-top: 2px;
                cursor: pointer;
            }
        }
        .boxclass {
            width: 40px;
            margin-right: 10px;
            background: #fff;
            cursor: pointer;
            .active {
                background: #1890ff;
            }
            .other {
                background: #7f7f7f;
            }
        }
        .boxclass1 {
            display: grid;
            grid-gap: 1px;
            grid-template-columns: 1fr;
            grid-row: 1;
        }
        .box {
            position: relative;
        }
        .boxclass4 {
            display: grid;
            grid-template-columns: 1fr 1fr;
            .box {
                margin: 1px;
            }
        }
        .boxclass6 {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            .box {
                margin: 1px;
            }
            .box1 {
                grid-area: 1/1 / span 2 / span 2;
            }
        }
        .boxclass9 {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            .box {
                margin: 1px;
            }
        }
        .boxclass16 {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr;
            .box {
                margin: 1px;
            }
        }
        .boxclass25 {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
            .box {
                margin: 1px;
            }
        }
    }
}
</style>
