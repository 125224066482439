/*
 * @Autor: dingxiaolin
 * @Date: 2022-10-22 14:13:48
 * @LastEditors: dingxiaolin
 * @LastEditTime: 2022-10-24 15:34:42
 */
/**
 * 船舶预警
 */
import { Post, Get, Patch, Put, Import, Download, ax } from '@/utils/newRequest'
import config from '@/config/config.js'
import qs from 'qs'

/**
 * 车辆车辆管理列表
 */
export function busInfoList(body) {

    return Post(config.namespace + '/busInfo/list', body)
}
/**
 * 车辆品牌列表
 */
export function busTypeInfoList(body) {

    return Post(config.namespace + '/busTypeInfo/list', body)
}
/**
 * 车辆车辆列表导出
 */
export function exportBus(query) {
    return Download(config.namespace + `/busInfo/exportBusInfo?${qs.stringify(query)}`, {})
}
/**
 * 删除车辆
 */
export function removeBus(body) {
    return Post(config.namespace + '/busInfo/remove', body)
}
/**
 * 车辆新增
 */
export function saveBus(body) {
    return Post(config.namespace + '/busInfo/save', body)
}
/**
 * 车辆修改
 */
export function updateBus(body) {
    return Post(config.namespace + '/busInfo/update', body)
}
/**
 * 门型信息
 */
export function busModelDoorLink(body) {
    return Post(config.namespace + '/busModelDoorLink/list', body)
}
/**
 * 车辆信息导入
 */
export function importExcel(body) {
    return Import(config.namespace + '/busInfo/importExcel', body)
}
