<template>
    <a-card :bordered="false">
        <div class="table-page-search-wrapper">
            <a-form-model :label-col="labelCol" :wrapper-col="wrapperCol">
                <a-row :gutter="48">
                    <a-col :md="6" :sm="24">
                        <a-form-model-item label="选择时间">
                            <a-range-picker v-model="queryDate" format="YYYY-MM-DD" @change="dateChange" />
                        </a-form-model-item>
                    </a-col>
                    <a-col :md="6" :sm="24">
                        <a-form-model-item label="所属机构">
                            <dept-tree v-model="queryParam.tenantId" :all="false" @change="handleDeptChange" />
                        </a-form-model-item>
                    </a-col>
                    <a-col :md="6" :sm="24">
                        <a-form-model-item label="所属线路">
                            <line-select placeholder="请选择所属线路" v-model="lineSubId" :lines="lines" @change="handleLine" />
                        </a-form-model-item>
                    </a-col>

                    <a-col :md="6" :sm="24" align="right">
                        <span class="table-page-search-submitButtons">
                            <a-button type="primary" @click="search()">查询</a-button>
                            <a-button style="margin-left: 8px" @click="handleExport">导出</a-button>
                        </span>
                    </a-col>
                </a-row>
            </a-form-model>
        </div>
        <a-table
            ref="table"
            bordered
            size="small"
            :pagination="false"
            :columns="columns"
            :dataSource="dataSource"
            :rowKey="record => record.id"
            :scrollToFirstRowOnChange="true"
            :scroll="{ y: 650 }"
        >
        </a-table>
    </a-card>
</template>

<script>
import { DeptTree, LineSelect } from '@/components'
import { linePeakPeriodAnalysis, getLineList, linePeakAnalysisInformationExport } from '@/api/alarm/index'
export default {
    components: {
        DeptTree,
        LineSelect
    },

    data() {
        return {
            labelCol: {
                sm: { span: 6 },
                xs: { span: 24 }
            },
            wrapperCol: {
                sm: { span: 18 },
                xs: { span: 24 }
            },
            queryDate: [this.$moment().subtract(1, 'days'), this.$moment().subtract(1, 'days')], //告警时间范围
            // 查询参数
            queryParam: {
                beginTime: this.$moment().subtract(1, 'days').format('YYYY-MM-DD'), //查询开始时间yyyy-MM-dd HH:mm:ss
                endTime: this.$moment().subtract(1, 'days').format('YYYY-MM-DD'), //查询开始时间yyyy-MM-dd HH:mm:ss
                tenantId: '', //部门名称
                lineSubId: undefined
            },
            lineSubId: undefined,
            tenantName: '',
            dataSource: [],
            // 表头
            columns: [
                {
                    title: '序号',
                    dataIndex: 'id',
                    key: 'id',
                    align: 'center',
                    width: '80px'
                },
                {
                    title: '时间段',
                    dataIndex: 'uploadHour',
                    key: 'uploadHour'
                },
                {
                    title: '上客人数',
                    dataIndex: 'inNum',
                    key: 'inNum'
                },
                {
                    title: '下客人数',
                    dataIndex: 'leaveNum',
                    key: 'leaveNum'
                },
                {
                    title: '上客比例',
                    dataIndex: 'proportionOnBoard',
                    key: 'proportionOnBoard'
                },
                {
                    title: '下客比例',
                    dataIndex: 'proportionOffloading',
                    key: 'proportionOffloading'
                },
                {
                    title: '客流增量/h',
                    dataIndex: 'increase',
                    key: 'increase'
                }
            ],
            /** 线路列表 */
            lines: []
        }
    },

    activated() {
        this.getLines()
    },
    methods: {
        handleLine(v, lineSubId) {
            this.queryParam.lineSubId = lineSubId
        },
        search() {
            if (!this.queryParam.beginTime) {
                this.$message.warning('请选择时间')
                return
            }
            if (!this.queryParam.lineSubId) {
                this.$message.warning('请选择所属线路')
                return
            }
            linePeakPeriodAnalysis(this.queryParam).then(res => {
                res.forEach((item, index) => (item.id = index + 1))
                this.dataSource = res
                console.log(this.dataSource)
            })
        },
        handleDeptChange({ value, title }) {
            this.getLines(value)
            this.queryParam.lineSubId = undefined
            this.tenantName = title
            this.lineSubId = undefined
        },
        filterOption(input, option) {
            return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
        },
        getLines(tenantId = this.$store.state.user.tenant.tenantId) {
            getLineList({
                size: '-1',
                descs: 'create_time', //排序字段
                tenantId_equal: tenantId //所属机构
            }).then(res => {
                this.lines = res.records
            })
        },
        dateChange(date, dateString) {
            this.queryParam.beginTime = dateString[0] ? this.$moment(dateString[0]).format('YYYY-MM-DD') : ''
            this.queryParam.endTime = dateString[1] ? this.$moment(dateString[1]).format('YYYY-MM-DD') : ''
        },

        /** 导出 */
        handleExport() {
            if (!this.queryParam.beginTime) {
                this.$message.warning('请选择时间')
                return
            }
            if (!this.queryParam.lineSubId) {
                this.$message.warning('请选择所属线路')
                return
            }
            linePeakAnalysisInformationExport({
                ...this.queryParam,
                tenantName: this.tenantName
            })
        }
    }
}
</script>

<style lang="less" scoped>
.alarmTypeName {
    border-radius: 20px;
    padding: 2px 8px;
    width: fit-content;
    background: rgba(250, 152, 164, 0.996078431372549);
    color: #fff;
    font-size: 12px;
}
/deep/ .ant-card {
    height: 100%;
}
/deep/ .ant-card-body {
    height: 100%;
}
</style>
