<template>
    <div class="container">
        <div class="flex">
            <a-input-search style="margin-bottom: 8px;" placeholder="关键字查询" v-model="serKey" @change="onChange" />
            <a-button type="primary" icon="reload" style="width:40px" @click="refresh" :loading="loading" />
        </div>
        <p class="tips" v-if="channel"><a-icon type="info-circle" /> 查看4G版设备监控，请注意流量消耗！</p>

        <a-tree
            ref="Tree"
            :multiple="channel"
            default-expand-all
            :auto-expand-parent="autoExpandParent"
            :expanded-keys="expandedKeys"
            :selected-keys="selectedKeys"
            :tree-data="newTreeData"
            @select="onSelect"
            @expand="onExpand"
            :replace-fields="replaceFields"
            show-icon
        >
            <template slot="nodeName" slot-scope="{ nodeName, nodeId, nodeType, onlineNum, channelOnlineNum, totalNum, deviceType, channelNumber }">
                <span :id="nodeId">
                    <!-- 机构 -->
                    <span v-if="nodeType == '0'" @click="expandKey(nodeId)">
                        <span v-if="serKey && nodeName.indexOf(serKey) > -1" :id="nodeId">
                            <span>{{ nodeName.substr(0, nodeName.indexOf(serKey)) }}</span>
                            <span style="color: #f50">{{ serKey }}</span>
                            <span>{{ nodeName.substr(nodeName.indexOf(serKey) + serKey.length) }}</span>
                        </span>
                        <span v-else>{{ nodeName }}</span>
                        {{ ' (' + (channel ? onlineNum : channelOnlineNum) + '/' + totalNum + ')' }}</span
                    >
                    <!-- 设备 -->
                    <span
                        v-else-if="nodeType == '1'"
                        :style="{
                            color: channelNumber ? (channelNumber.split(',')[1] != '1' ? 'rgb(145,136,136)' : 'rgb(122,192,7)') : 'rgb(145,136,136)'
                        }"
                        @click="expandKey(nodeId)"
                    >
                        <span v-if="serKey && nodeName.indexOf(serKey) > -1" :id="nodeId">
                            <span class="img"></span>
                            <span>{{ nodeName.substr(0, nodeName.indexOf(serKey)) }}</span>
                            <span style="color: #f50">{{ serKey }}</span>
                            <span>{{ nodeName.substr(nodeName.indexOf(serKey) + serKey.length) }}</span>
                        </span>
                        <span v-else><span class="img"></span>{{ nodeName }}</span>
                        <a-tag color="#F2F2F2" style="margin-left:3px;color:#666;border:1px solid #ddd" v-if="deviceType == '1'">4G版</a-tag></span
                    >
                    <!--通道 -->
                    <span
                        v-if="channel && nodeType == '2'"
                        @click.prevent="getChannelKey(nodeId, isOnline)"
                        :style="{ color: isOnline == 0 ? 'rgb(145,136,136)' : 'rgb(122,192,7)' }"
                    >
                        <span v-if="serKey && nodeName.indexOf(serKey) > -1" :id="nodeId">
                            <span>{{ nodeName.substr(0, nodeName.indexOf(serKey)) }}</span>
                            <span style="color: #f50">{{ serKey }}</span>
                            <span>{{ nodeName.substr(nodeName.indexOf(serKey) + serKey.length) }}</span>
                        </span>
                        <span v-else>{{ nodeName }}</span>
                    </span>
                </span>
            </template>
        </a-tree>
    </div>
</template>

<script>
import { getRJDeviceTree } from '@/api/alarm'

export default {
    data() {
        return {
            loading: false,
            serKey: '',
            /** 自定义key-title */
            replaceFields: {
                key: 'nodeId'
            },
            /** 平面组织架构数组*/
            dataList: [],
            /** 定位关键词位置 */
            serKeyList: [],
            /** nodename关键词高亮 */
            newTreeData: [],
            /**原始树 */
            treeData: [], //节点类型 0-组织机构，机构ID1-终端设备 终端ID 2-设备媒体通道
            expandedKeys: [], //（受控）展开指定的树节点
            selectedKeys: [], //（受控）设置选中的树节点
            /**查询条件 */
            searchValue: '',
            /**是否自动展开父级 */
            autoExpandParent: true,
            /**当前选中的key */
            selectedKey: []
        }
    },
    props: {
        channel: {
            type: Boolean,
            default: true
        }
    },
    watch: {
        serKey(val) {
            if (val) {
                console.log('aaaaaaaaaaaa')
                this.onChange(val)
            }
        }
    },
    mounted() {
        this.getDeptTree()
    },
    methods: {
        /**扩展收缩节点 */
        expandKey(key) {
            const index = this.expandedKeys.findIndex(v => v == key)
            if (index > -1) {
                this.expandedKeys.splice(index, 1)
            } else {
                this.expandedKeys.push(key)
            }
        },
        /**选择节点 */
        onSelect(keys, { selected, selectedNodes, node, event }) {
            this.selectedKeys = keys
            if (!this.channel) {
                //单选
                if (selected) {
                    const [
                        {
                            data: { props: props }
                        }
                    ] = selectedNodes
                    this.$emit('Board', props)
                } else {
                    this.$emit('unselected')
                }
            } else {
                //多选
                if (selected) {
                    this.$emit('channels', node._props.dataRef)
                } else {
                    this.$emit('unselectedKey', this.selectedKey)
                }
            }
        },
        /**点击通道key */
        getChannelKey(key, online) {
            if (online == false) {
                this.$message.warning({ content: '此通道号不在线', key: 'channel' })
            }
            this.selectedKey = key
        },
        /**刷新 */
        refresh() {
            this.loading = true
            this.getDeptTree()
        },
        /**遍历树重组 */
        mapTree(list) {
            this.newTreeData = list.map(item => {
                item.scopedSlots = {
                    title: 'nodeName'
                }
                if (!this.channel) {
                    if (item.nodeType == '1' && item.isOnline == 1 && item.channelNumber && item.channelNumber.split(',')[1] == '1') {
                        item.selectable = true
                    } else {
                        item.selectable = false
                    }
                } else {
                    if (item.nodeType == '2' && item.isOnline == 1) {
                        item.selectable = true
                    } else {
                        item.selectable = false
                    }
                }
                // 不展示通道号节点
                if (!this.channel && item.nodeType == '1') {
                    item.children = []
                }
                if (item.children && item.children.length > 0) {
                    this.mapTree(item.children)
                }

                return item
            })
        },
        /**初始化树 */
        getDeptTree() {
            getRJDeviceTree().then(res => {
                this.treeData = res
                this.mapTree(res)
                // this.expandedKeys = res.map(item => {
                //     return item.nodeId
                // })
                this.generateList(res)
                this.loading = false
            })
        },
        /**展开组织树为一维数组,为了后续查询*/
        generateList(data) {
            for (let i = 0; i < data.length; i++) {
                const node = data[i]
                const nodeName = node.nodeName
                const nodeId = node.nodeId
                this.dataList.push({ nodeName, nodeId })
                if (node.children) {
                    this.generateList(node.children)
                }
            }
        },
        /**获取所有展开key */
        getParentKey(key, tree) {
            let parentKey
            for (let i = 0; i < tree.length; i++) {
                const node = tree[i]
                if (node.children) {
                    if (node.children.some(item => item.nodeId === key)) {
                        parentKey = node.nodeId
                    } else if (this.getParentKey(key, node.children)) {
                        parentKey = this.getParentKey(key, node.children)
                    }
                }
            }
            return parentKey
        },
        onExpand(expandedKeys) {
            this.expandedKeys = expandedKeys
            this.autoExpandParent = false
        },
        /**查询定位位置 */
        onChange(v) {
            if (!this.serKey) return
            this.serKeyList = []
            this.expandedKeys = this.dataList
                .map(item => {
                    if (item.nodeName.indexOf(this.serKey) > -1) {
                        this.serKeyList.push(item.nodeId)
                        return this.getParentKey(item.nodeId, this.treeData)
                    }
                    return null
                })
                .filter((item, i, self) => item && self.indexOf(item) === i)
            this.autoExpandParent = true
            // console.log('expandedKeys', this.expandedKeys)
            // console.log('serKeyList', this.serKeyList)
            if (this.serKeyList.length > 0) {
                this.$nextTick(() => {
                    setTimeout(() => {
                        // document.getElementById(this.serKeyList[0]).scrollIntoView({ behavior: 'smooth' })
                        let activeCard = document.getElementById(this.serKeyList[0])
                        let scrollY = activeCard.offsetTop - 60
                        document.querySelector('.ant-tree').scrollTo(0, scrollY)
                    }, 200)
                })
            }
        }
    }
}
</script>

<style lang="less" scoped>
.container {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
}
.ant-tree {
    flex: auto;
    overflow: auto;
}
.tips {
    color: #999;
    margin-bottom: 0;
    user-select: none;
}
/deep/ .ant-tree-title {
    user-select: none;
}
</style>
