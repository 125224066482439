<template>
    <a-select style="width: 100%" showSearch v-bind="$attrs" :filter-option="filterOption" :value="_value" allowClear @change="handleChange">
        <a-select-option value="">{{ $attrs.placeholder }}</a-select-option>
        <a-select-option v-for="(item, index) in lines" :key="index" :value="item.groupLineId">
            {{ item.lineSubName }}
        </a-select-option>
    </a-select>
</template>

<script>
export default {
    model: {
        prop: 'value',
        event: 'change'
    },
    props: ['value', 'lines'],
    data() {
        return {}
    },
    computed: {
        _value: {
            get() {
                return this.value
            }
        }
    },
    mounted() {},
    methods: {
        handleChange(groupLineId) {
            if (!Array.isArray(groupLineId)) {
                if (groupLineId === undefined || groupLineId === '') {
                    this.$emit('change', undefined)
                }
                const item = this.lines.find(item => item.groupLineId === groupLineId)
                if (item) {
                    this.$emit('change', groupLineId, item.lineSubId)
                }
            } else {
                let temp_arr = []
                groupLineId.forEach(item => {
                    const val = this.lines.find(f => f.groupLineId == item)
                    temp_arr.push(val.lineSubId)
                })
                this.$emit('change', groupLineId, temp_arr)
            }
        },
        filterOption(input, option) {
            return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
    }
}
</script>
