<template>
    <div>
        <div class="flex sb ac">
            <a-button class="btn-add" type="primary" @click="$refs.editForm.init(null)">添加参数</a-button>
            <div class="btn-tb">
                最新同步时间：{{ configTime }}
                <a-button class="btn-add" type="default" @click="sync_config"> {{ syncBtnText }} </a-button>
            </div>
        </div>
        <div class="search flex"></div>
        <div class="log_list">
            <a-table
                bordered
                :loading="isLoading"
                :columns="configColumns"
                :data-source="configData"
                size="small"
                :rowKey="record => record.id"
                :scrollToFirstRowOnChange="true"
                :pagination="pagination"
                :scroll="{ x: 1500, y: tableheight }"
            >
                <span slot="paramType" slot-scope="text">
                    <span v-if="text == '0'">系统参数</span>
                    <span v-if="text == '1'">用户参数</span>
                </span>
                <span slot="action" slot-scope="text, record">
                    <a-button @click="$refs.editForm.init(record)" type="link">编辑</a-button>
                </span>
            </a-table>
        </div>
        <edit ref="editForm" />
    </div>
</template>
<script>
import Edit from './editForm.vue'
import { aiDeviceSetConfigSynInfo, aiDeviceSetConfigList, aiDeviceTasklist } from '@/api/alarm'
export default {
    components: {
        Edit
    },
    props: ['deviceTag'],
    data() {
        return {
            // tabH:;
            configColumns: [
                {
                    title: '参数标识',
                    dataIndex: 'paramKey',
                    width: '100px'
                },
                {
                    title: '类型',
                    dataIndex: 'paramType',
                    scopedSlots: { customRender: 'paramType' },
                    width: '80px'
                },
                {
                    title: '参数值',
                    dataIndex: 'paramValue',
                    width: '120px'
                },
                {
                    title: '参数描述',
                    dataIndex: 'paramDesc',
                    width: '300px'
                },
                {
                    title: '操作',
                    dataIndex: 'action',
                    width: '80px',
                    scopedSlots: { customRender: 'action' }
                }
            ],
            configData: [],
            configTime: '',
            queryParam: {
                deviceId_equal: '',
                descs: 'create_time,id'
            },
            /** 定时器实例 */
            timer: null,
            /** 弹窗提示key */
            key: 'configKey',
            pagination: {
                current: 1,
                pageSize: 10,
                total: 0,
                showSizeChanger: true,
                showTotal: (total, range) => {
                    return range[0] + '-' + range[1] + '共' + total + '条'
                },
                onChange: this.onChange,
                onShowSizeChange: this.onPageSizeChnage
            },
            isLoading: false,
            syncBtnText: '同步',
            tableheight: `calc(100vh - 550px) !important`
        }
    },
    mounted() {
        this.getConfigList(true)
    },

    methods: {
        getRealTaskStatus(fn) {
            return aiDeviceTasklist({
                current: -1,
                size: -1,
                deviceTag_like: this.deviceTag,
                taskStatus_equal: 0
            }).then(({ records }) => {
                if (records.length > 0) {
                    this.$message.error('当前有任务在执行')
                    return
                }
                fn && fn()
            })
        },
        /** 同步 */
        sync_config() {
            this.getRealTaskStatus(() => {
                aiDeviceSetConfigSynInfo({
                    deviceId: this.$route.query.id
                })
                this.syncBtnText = '同步中...'

                this.configTime = this.$moment().format('YYYY-MM-DD HH:mm:ss')
                this.cancelTimer()
                this.setError()
            })
        },
        setError() {
            // 自定义错误处理
            this.timer = setTimeout(() => {
                // this.$message.error({
                //     key: this.key,
                //     content: '操作超时'
                // })
                this.syncBtnText = '同步'
                this.$refs.editForm.confirmLoading = false
                this.getConfigList()
                this.isLoading = false
            }, 1000 * 60)
        },
        cancelTimer() {
            clearTimeout(this.timer)
            this.isLoading = false
            this.$refs.editForm.confirmLoading = false
        },
        /** 获取媒体列表 */
        getConfigList(isFirst) {
            this.isLoading = true
            aiDeviceSetConfigList({
                ...this.queryParam,
                current: this.pagination.current,
                size: this.pagination.pageSize,
                deviceId_equal: this.$route.query.id
            }).then(re => {
                this.isLoading = false
                this.syncBtnText = '同步'
                this.configData = re.records
                this.pagination.total = Number(re.total)
                if (isFirst) {
                    this.configTime = re.records[0].updateTime
                }
            })
        },
        onChange(page, pageSize) {
            this.pagination.current = page
            this.pagination.pageSize = pageSize
            this.getConfigList(true)
        },
        onPageSizeChnage(current, size) {
            this.pagination.current = current
            this.pagination.pageSize = size
            this.getConfigList(true)
        }
    }
}
</script>

<style lang="less" scoped>
.deepGreen {
    color: #67c23a;
}
.lightGreen {
    color: rgb(225, 243, 216);
}
.deepRed {
    color: #f56c6c;
}
.lightRed {
    color: rgb(253, 226, 226);
}
// /deep/ .ant-table-wrapper{
//   max-height: calc(((100vh - 560px) - 0px) - 0px) !important;
// }
/deep/ .ant-table-body {
    
    max-height: calc(((100vh - 500px) - 0px) - 0px) !important;
}
</style>
