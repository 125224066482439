import { axios } from '@/utils/request'
import config from '@/config/config.js'

/**
 * 分页查询定时任务
 */
export function sysTimersPage(parameter) {
    return axios({
        url: config.namespace+'/timer/page',
        method: 'get',
        params: parameter
    })
}

/**
 * 获取全部定时任务
 */
export function sysTimersList(parameter) {
    return axios({
        url: config.namespace+'/timers/list',
        method: 'get',
        params: parameter
    })
}

/**
 * 查看详情定时任务
 */
export function sysTimersDetail(parameter) {
    return axios({
        url: config.namespace+'/timer/detail',
        method: 'get',
        params: parameter
    })
}

/**
 * 添加定时任务
 */
export function sysTimersAdd(parameter) {
    return axios({
        url: config.namespace+'/timer/add',
        method: 'post',
        data: parameter
    })
}

/**
 * 删除定时任务
 */
export function sysTimersDelete(parameter) {
    return axios({
        url: config.namespace+'/timer/delete',
        method: 'post',
        data: parameter
    })
}

/**
 * 编辑定时任务
 */
export function sysTimersEdit(parameter) {
    return axios({
        url: config.namespace+'/timer/edit',
        method: 'post',
        data: parameter
    })
}

/**
 * 获取系统的所有任务列表
 */
export function sysTimersGetActionClasses(parameter) {
    return axios({
        url: config.namespace+'/timer/getActionClasses',
        method: 'post',
        data: parameter
    })
}

/**
 * 启动定时任务
 */
export function sysTimersStart(parameter) {
    return axios({
        url: config.namespace+'/timer/start',
        method: 'post',
        data: parameter
    })
}

/**
 * 停止定时任务
 */
export function sysTimersStop(parameter) {
    return axios({
        url: config.namespace+'/timer/stop',
        method: 'post',
        data: parameter
    })
}
