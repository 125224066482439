<template>
    <div>
        <div class="main">
            <div>
                <div id="map"></div>
                <div class="operation">
                    <div style="margin-top: 6px">
                        <a-button
                            type="primary"
                            style="margin-right: 6px"
                            @click="
                                isLine = true
                                zone_type = 2
                                setMode('LINE')
                            "
                            >线段
                        </a-button>

                        <a-button
                            type="primary"
                            style="margin-right: 6px"
                            @click="
                                isLine = false
                                zone_type = 0
                                setMode('POLYGON')
                            "
                        >
                            区域
                        </a-button>

                        <a-button type="danger" style="float:right" @click="Revoke()">撤销</a-button>
                    </div>
                </div>
            </div>
            <a-table
                style="width: calc(100% - 652px); margin-left: 12px"
                :columns="columns"
                :dataSource="allFeatures"
                :scroll="{ y: 360 }"
                :pagination="false"
                :rowKey="record => record.areaTag"
                bordered
                :rowClassName="(record, index) => (index % 2 === 1 ? 'odd' : 'even')"
            >
                <span slot="type" slot-scope="text, record">
                    <a-tag>{{ name_map[record.props.type] }}</a-tag>
                </span>
                <span slot="algo" slot-scope="text, record">
                    <a-tag>{{ record.props.algo && record.props.algo.name ? record.props.algo.name : '全部' }}</a-tag>
                </span>
                <span slot="action" slot-scope="text, record">
                    <a-button type="link" @click="show_zone(record)" style="margin-right: 6px">高亮 </a-button>
                    <a-popconfirm placement="topRight" title="确认删除？" @confirm="() => delete_zone(record.id)">
                        <a style="color:red">删除</a>
                    </a-popconfirm>
                </span>
            </a-table>
            <a-modal title="设置" :visible.sync="dialogFormVisible" width="45%" destroyOnClose>
                <a-form style="margin-left: 20px;margin-right: 20px" label-position="left" label-width="80">
                    <a-form-item label="标识">
                        <a-input v-model="zone.RuleId" autocomplete="off" />
                    </a-form-item>
                    <a-form-item label="区域类型">
                        <a-select v-model="zone_type" placeholder="区域类型" style="float: right;width: 100%">
                            <a-select-option v-for="item in isLine ? line_type : polygon_type" :value="item.id" v-bind:key="item.id">{{
                                item.name
                            }}</a-select-option>
                        </a-select>
                    </a-form-item>
                    <a-form-item label="关联算法">
                        <a-select v-model="select_algo" placeholder="关联算法" style="float: right;width: 100%">
                            <a-select-option v-for="(item, id) in algoArray" :value="id" v-bind:key="id">{{ item.name }}</a-select-option>
                        </a-select>
                    </a-form-item>
                </a-form>
                <div slot="footer" class="dialog-footer">
                    <a-button @click="dialogFormVisible = false" style="margin-right: 6px">取 消</a-button>
                    <a-button type="primary" @click="submit_polygon">确 定</a-button>
                </div>
            </a-modal>
        </div>
        <div style="float:right; margin-right: 50px;">
            <a-button type="primary" @click="goBack" style="margin-right:20px;">取消</a-button>
            <a-button type="primary" @click="set_rule_property">保存</a-button>
        </div>
    </div>
</template>

<script>
import AILabel from 'ailabel'
// import { dismiss_loading, show_loading, submitAlgTask } from '@/api/api'
import { aiDeviceAlgorithmDetail, aiDeviceAlgorithmUpdate } from '@/api/alarm'
import { mapState } from 'vuex'

export default {
    data() {
        return {
            drawingStyle: {},
            mode: '',
            itemName: '',
            editId: '', //待填充图形id
            gMap: null, //AILabel实例
            gFirstFeatureLayer: null, //矢量图层实例(矩形，多边形等矢量)
            allFeatures: null, //所有features
            bImage: '',
            /** 区域详情 */
            taskDetail: null,
            dialogFormVisible: false,
            zone: {
                RuleId: '',
                RuleType: 0,
                RuleTypeName: '',
                Points: [],
                RulePoints: '[]',
                LabelData: null,
                LabelType: ''
            },

            columns: [
                {
                    title: '标识',
                    dataIndex: 'id',
                    align: 'center',
                    width: '60px'
                },
                {
                    title: '区域类型',
                    dataIndex: 'type',
                    align: 'center',
                    width: '100px',
                    scopedSlots: { customRender: 'type' }
                },
                {
                    title: '算法',
                    dataIndex: 'algo',
                    align: 'center',
                    width: '100px',
                    scopedSlots: { customRender: 'algo' }
                },
                {
                    title: '操作',
                    dataIndex: 'action',
                    align: 'center',
                    width: '100px',
                    scopedSlots: { customRender: 'action' }
                }
            ],
            name_map: {
                4: '专用算法区域',
                1: '禁入区域',
                0: '兴趣区域',
                2: '辅助线'
            },
            // polygon_type: [
            //     {
            //         name: '兴趣区域',
            //         id: 0
            //     },
            //     {
            //         name: '禁入区域',
            //         id: 1
            //     },
            //     {
            //         name: '岗位区域',
            //         id: 4
            //     }
            // ],
            polygon_type: [
                {
                    name: '兴趣区域',
                    id: 0
                },
                {
                    name: '专用算法区域',
                    id: 4
                }
            ],
            line_type: [
                {
                    name: '辅助线',
                    id: 2
                }
            ],
            zone_type: 0,
            algoArray: [],
            select_algo: undefined,
            isLine: false,
            LineStyle: {
                strokeStyle: '#BA55D3',
                lineJoin: 'round',
                lineCap: 'round',
                lineWidth: 5,
                arrow: false
            },
            PolygonStyle: {
                strokeStyle: '#0099CC', //边框颜色
                fill: true, //是否填充
                fillStyle: '#FF6666', //填充色
                globalAlpha: 0.3,
                lineWidth: 3,
                stroke: true
            }
        }
    },
    watch: {
        mode(mode) {
            this.gMap.setMode(mode)
            this.setDrawingStyle(mode)
        }
    },
    methods: {
        async goBack() {
            this.$router.push({
                path: `/aiDevices/detail?id=${this.$route.query.backId}&activeKey=2&areaId=${this.$route.query.areaId}`
            })
        },
        /** 提交 */
        set_rule_property: function() {
            let that = this
            let detail = this.taskDetail
            detail.RuleProperty = []
            if (this.allFeatures) {
                for (let rule of this.allFeatures) {
                    console.log(rule)
                    let points = []
                    if (rule.type === 'LINE') {
                        let start = rule.shape.start
                        let end = rule.shape.end
                        points.push({ X: start.x / 960, Y: start.y / 540 })
                        points.push({ X: end.x / 960, Y: end.y / 540 })
                    } else {
                        for (let pt of rule.shape.points) {
                            points.push({ X: pt.x / 960, Y: pt.y / 540 })
                        }
                    }
                    let type = rule.props.type

                    let example = {
                        RuleId: rule.id,
                        RuleType: type,
                        RuleTypeName: this.name_map[type],
                        Points: points,
                        RulePoints: points,
                        Algo: rule.props.algo
                    }
                    detail.RuleProperty.push(example)
                }
            }
            detail.aiDeviceAlgorithmAreaList = []
            detail.RuleProperty.forEach((item, index) => {
                detail.aiDeviceAlgorithmAreaList.push({
                    areaTag: item.RuleId,
                    areaType: item.RuleType,
                    areaAlgo: JSON.stringify(item.Algo),
                    areaData: JSON.stringify(item.Points)
                })
            })
            aiDeviceAlgorithmUpdate(detail)
        },
        /** 高亮 */
        show_zone: function(feature) {
            this.gMap.setActiveFeature(feature)
        },
        /** 删除 */
        delete_zone: function(id) {
            console.log(id)
            this.gFirstFeatureLayer.removeFeatureById(id)
            this.getFeatures()
        },
        /** 保存形状 */
        submit_polygon() {
            let that = this
            if (this.zone.RuleId.length === 0) {
                this.$message.warning('请填写区域标识')
                return
            }
            let repeat = false
            if (this.allFeatures) {
                for (let x of this.allFeatures) {
                    if (x.id === that.zone.RuleId) {
                        repeat = true
                        break
                    }
                }
            }

            if (repeat) {
                this.$message.warning('区域标识已存在')
                return
            }

            that.zone.RuleType = this.zone_type
            console.log('New', that.zone.LabelData, that.zone.LabelType, that.zone.RuleId, that.zone.RuleType)
            if (this.select_algo >= 0 && this.select_algo < this.algoArray.length) {
                that.zone.Algo = this.algoArray[this.select_algo]
            }
            console.log('select:', this.select_algo, that.zone.Algo, this.allFeatures)

            that.addFeature(that.zone.LabelData, that.zone.LabelType, that.zone.RuleId, that.zone.RuleType, that.zone.Algo)
            this.dialogFormVisible = false
        },
        setMode(mode) {
            this.mode = mode
        },
        // 获取所有features
        getFeatures() {
            this.allFeatures = this.gFirstFeatureLayer.getAllFeatures()
            console.log('--allFeatures--', this.allFeatures)
            for (let it of this.allFeatures) {
                console.log(it.props)
            }
        },
        // 初始样式
        setDrawingStyle(mode) {
            switch (mode) {
                //线段
                case 'LINE': {
                    this.gMap.setDrawingStyle(this.LineStyle)
                    break
                }
                //多边形
                case 'POLYGON': {
                    this.gMap.setDrawingStyle(this.PolygonStyle)
                    break
                }
                default:
                    break
            }
        },

        // 添加图形
        addFeature(data, type, id, desc, algo) {
            console.log('111111111111111111111', algo)
            let that = this

            //线
            if (type === 'LINE') {
                const scale = that.gMap.getScale()
                const width = that.LineStyle.lineWidth / scale
                const lineFeature = new AILabel.Feature.Line(
                    id, // id
                    { ...data, width }, // shape
                    { type: desc, algo: algo }, // props
                    that.LineStyle // style
                )
                that.gFirstFeatureLayer.addFeature(lineFeature)
            }
            //多边形
            else if (type === 'POLYGON') {
                const polygonFeature = new AILabel.Feature.Polygon(
                    id, // id
                    { points: data }, // shape
                    { type: desc, algo: algo }, // props
                    that.PolygonStyle // style
                )
                that.gFirstFeatureLayer.addFeature(polygonFeature)
            }
            this.getFeatures()
        },
        // 画完取名
        getName(mode) {
            return this.$prompt('请输入填写名字', {
                confirmButtonText: '确定',
                showCancelButton: false
            })
                .then(({ value }) => {
                    this.itemName = value
                    return value
                })
                .catch(() => {
                    return null
                })
        },
        // 删除 删除按钮
        deIcon() {
            this.gMap.markerLayer.removeAllMarkers()
        },
        // 增加事件
        addEvent() {
            let that = this
            let gMap = this.gMap

            gMap.events.on('drawDone', (type, data) => {
                console.log('--type, data--111', type, data)
                // that.addFeature(data, type);
                {
                    that.zone.RuleId = ''
                    that.select_algo = undefined
                    that.dialogFormVisible = true
                    that.zone.LabelData = data
                    that.zone.LabelType = type
                }
            })
            gMap.events.on('boundsChanged', data => {
                console.log('--map boundsChanged--', data)
                return ''
            })
            // 双击编辑 在绘制模式下双击feature触发选中
            gMap.events.on('featureSelected', feature => {
                this.editId = feature.id
                console.log('--map featureSelected--', feature, '双击编辑')
                //设置编辑feature
                gMap.setActiveFeature(feature)
            })
            //右键 目前只针对点双击选中右键触发
            gMap.events.on('featureDeleted', feature => {
                if (feature.type === 'POINT') {
                    // 根据id删除对应feature
                    that.gFirstFeatureLayer.removeFeatureById(feature.id)
                }
            })
            // 单机空白取消编辑
            gMap.events.on('featureUnselected', () => {
                // 取消featureSelected
                that.editId = ''
                that.deIcon()
                gMap.setActiveFeature(null)
            })
            // 更新完
            gMap.events.on('featureUpdated', (feature, shape) => {
                console.log(feature)
            })

            // 删除
            gMap.events.on('FeatureDeleted', () => {
                // that.gFirstFeatureLayer.removeFeatureById(that.editId);
            })
        },
        // 获取坐标 需要自行添加
        getPoints(feature) {
            switch (feature.type) {
                case 'LINE':
                    return [feature.shape.start, feature.shape.end]
                case 'POLYGON':
                    return feature.shape.points
            }
        },
        //撤销
        Revoke() {
            console.log('撤销')
            this.getFeatures()
            this.allFeatures.pop()
            //刷新map
            this.gMap.refresh()
            console.log(this.allFeatures, '--所有操作--')
        },
        getDetail() {
            return aiDeviceAlgorithmDetail({ id: this.$route.query.id }).then(res => {
                this.taskDetail = res
                return res
            })
        }
    },
    computed: {
        ...mapState({
            mqtt: state => state.app.mqtt
        })
    },
    async mounted() {
        await this.getDetail()
        let that = this
        this.taskDetail.RuleProperty = []
        this.taskDetail.aiDeviceAlgorithmAreaList.forEach((item, index) => {
            this.taskDetail.RuleProperty.push({
                Points: JSON.parse(item.areaData),
                RuleId: item.areaTag,
                Algo: JSON.parse(item.areaAlgo),
                RulePoints: JSON.parse(item.areaData),
                RuleType: item.areaType,
                RuleTypeName: this.name_map[item.areaType]
            })
        })
        let detail = this.taskDetail
        this.algoArray = JSON.parse(detail.disposeInfo)
        const gMap = new AILabel.Map('map', {
            center: { x: 480, y: 270 }, // 为了让图片居中
            zoom: 960,
            mode: 'BAN', // 绘制线段
            refreshDelayWhenZooming: true, // 缩放时是否允许刷新延时，性能更优
            zoomWhenDrawing: false,
            panWhenDrawing: false,
            zoomWheelRatio: 5, // 控制滑轮缩放缩率[0, 10), 值越小，则缩放越快，反之越慢
            withHotKeys: true // 关闭快捷键
        })
        that.gMap = gMap
        this.addEvent()
        let params = {
            BoardId: this.$route.query.deviceTag,
            Event: '/alg_task_snap',
            AlgTaskSession: this.taskDetail.taskName
        }
        // 订阅截图
        this.mqtt.publish('/edge_app_controller', JSON.stringify(params))
        this.$root.$on('task', res => {
            //获取区域信息列表
            if (res.Event == '/alg_task_config' && res.Result.Code == 0) {
                this.$message.success(res.Result.Desc)
                this.goBack()
                // this.$router.back()
            }
            //获取截图
            if (res.Event == '/alg_task_snap' && res.Result.Code == 0) {
                this.bImage = 'data:image/jpeg;base64,' + res.Base64
                const gFirstImageLayer = new AILabel.Layer.Image(
                    'first-layer-image', // id
                    {
                        src: this.bImage,
                        width: 960,
                        height: 540,
                        crossOrigin: false, // 如果跨域图片，需要设置为true
                        position: {
                            // 左上角相对中心点偏移量
                            x: 0,
                            y: 0
                        }
                    }, // imageInfo
                    { name: '第一个图片图层' }, // props
                    { zIndex: 5 } // style
                )
                // 添加到gMap对象
                gMap.addLayer(gFirstImageLayer)
                // 图片层添加
            }
        })
        // 添加矢量图层
        const gFirstFeatureLayer = new AILabel.Layer.Feature(
            'first-layer-feature', // id
            { name: '第一个矢量图层' }, // props
            { zIndex: 10 } // style
        )
        this.gFirstFeatureLayer = gFirstFeatureLayer
        gMap.addLayer(gFirstFeatureLayer)

        if (detail && detail.RuleProperty) {
            for (let rule of detail.RuleProperty) {
                let data = null
                if (rule.RuleType === 2) {
                    if (rule.Points.length === 2) {
                        data = {
                            start: { x: rule.Points[0]['X'] * 960, y: rule.Points[0]['Y'] * 540 },
                            end: { x: rule.Points[1]['X'] * 960, y: rule.Points[1]['Y'] * 540 }
                        }
                    }
                } else {
                    data = []
                    for (let pt of rule.Points) {
                        console.log(pt)
                        data.push({ x: pt.X * 960, y: pt.Y * 540 })
                    }
                }
                if (data) {
                    console.log('Reload', data, rule.RuleType === 2 ? 'LINE' : 'POLYGON', rule.RuleId, rule.RuleType, rule.Algo)
                    that.addFeature(data, rule.RuleType === 2 ? 'LINE' : 'POLYGON', rule.RuleId, rule.RuleType, rule.Algo)
                }
            }
        }

        window.onresize = function() {
            this.gMap && this.gMap.resize()
        }
    },
    destroyed() {
        this.$root.$off('task')
    },
    beforeDestroy() {
        this.gMap.destroy()
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.main {
    display: flex;
    flex-direction: row;
    margin: 50px;
    justify-content: center;
}

#map {
    /* margin: 0 auto; */
    overflow: hidden;
    position: relative;
    height: 540px;
    width: 960px;
    border: 1px dashed #ccc;
}

.a-button + .a-button {
    margin-left: 0px;
}
</style>
