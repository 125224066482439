import { axios } from '@/utils/request'
import config from '@/config/config.js'

/**
 * 权限管理模块
 */

/**
 * 登录 -- 废弃
 */
export function login(parameter) {
    return axios({
        url: config.namespace+'/auth/login',
        method: 'post',
        data: parameter
    })
}

/**
 * 登录
 */
export function tokenLogin(parameter) {
    return axios({
        url: config.namespace+'/dnl/auth/token',
        method: 'post',
        data: parameter
    })
}

/**
 * 注销
 */
export function logout(parameter) {
    return axios({
        url: config.namespace+'/dnl/auth/outLine',
        method: 'post',
        data: parameter
    })
}

/**
 * 登出--废弃
 */
export function logouta(parameter) {
    return axios({
        url: config.namespace+'/auth/logout',
        method: 'get',
        params: parameter
    })
}

/**
 * 获取登录用户信息
 */
export function getLoginUser(parameter) {
    return axios({
        url: config.namespace+'/user/getLoginUser',
        method: 'get',
        params: parameter
    })
}

/**
 * 获取登录用户信息--废弃
 */
export function getLoginUsers(parameter) {
    return axios({
        url: config.namespace+'/auth/getLoginUser',
        method: 'get',
        params: parameter
    })
}

/**
 * 获取租户开关
 */
export function getTenantOpen(parameter) {
    return axios({
        url: config.namespace+'/auth/getTenantOpen',
        method: 'get',
        params: parameter
    })
}

/**
 * 获取短信验证码
 */
export function getSmsCaptcha(parameter) {
    return axios({
        url: config.namespace+'/auth/getSmsCaptcha',
        method: 'get',
        params: parameter
    })
}

/**
 * 获取验证码图片
 */
export function getImgCaptcha(parameter) {
    return axios({
        url: config.namespace+'/dnl/auth/captcha',
        method: 'post',
        data: parameter
    })
}
