<template>
    <a-card :bordered="false">
        <div class="table-page-search-wrapper">
            <a-form layout="inline">
                <a-row :gutter="48">
                    <a-col :md="6" :sm="24">
                        <a-form-item label="所属机构">
                            <DeptTree v-model="queryParam.tenantId_equal" />
                        </a-form-item>
                    </a-col>
                    <a-col :md="6" :sm="24">
                        <a-form-item label="站点名称">
                            <a-input v-model="queryParam.stationName" placeholder="请输入站点名称"></a-input>
                        </a-form-item>
                    </a-col>
                    <a-col :md="6" :sm="24">
                        <a-form-item label="站点编号">
                            <a-input v-model="queryParam.stationCode" placeholder="请输入站点编号"></a-input>
                        </a-form-item>
                    </a-col>
                    <a-col :md="18" :sm="24" align="right" :offset="6">
                        <span class="table-page-search-submitButtons">
                            <a-button type="primary" @click="search">查询</a-button>
                            <a-button style="margin-left: 8px" @click="resetForm()">重置</a-button>
                        </span>
                    </a-col>
                </a-row>
            </a-form>
        </div>
        <div class="table-operator">
            <a-button @click="add" type="primary" style="margin-left:auto">新增</a-button>
        </div>
        <n-table ref="table" :columns="columns" :scroll="{ x: 1200 }" :data="loadData" :rowKey="record => record.id" :scrollToFirstRowOnChange="true">
            <span slot="action" slot-scope="text, record">
                <a-button type="link" size="small" @click="$refs.edit.show(record)">编辑</a-button>
                <a-divider type="vertical" />
                <a-popconfirm placement="topRight" title="你确定要删除该站点吗？" @confirm="() => del(record)">
                    <a-button type="link" size="small" style="color:red">删除</a-button>
                </a-popconfirm>
            </span>
        </n-table>
        <edit ref="edit" @ok="editOk" />
    </a-card>
</template>

<script>
import { NTable, DeptTree } from '@/components'
import { stationsRemove, getStationsList } from '@/api/alarm/index'
import Edit from './components/edit'
export default {
    components: {
        NTable,
        DeptTree,
        Edit
    },

    data() {
        return {
            loading: false,
            /**结果列表模态框 */
            tableVisible: false,
            labelCol: {
                sm: { span: 8 },
                xs: { span: 24 }
            },
            wrapperCol: {
                sm: { span: 16 },
                xs: { span: 24 }
            },
            // 查询参数
            queryParam: {
                tenantId_equal: '', //所属机构
                stationName: '',
                stationCode: ''
            },

            // 表头
            columns: [
                {
                    title: '所属机构',
                    dataIndex: 'tenantName',
                    ellipsis: true
                },
                {
                    title: '站点名称',
                    dataIndex: 'stationName',
                    ellipsis: true
                },
                {
                    title: '站点编号',
                    dataIndex: 'stationCode',
                    ellipsis: true
                },
                {
                    title: '站点GPS经度',
                    dataIndex: 'stationLng',
                    ellipsis: true
                },
                {
                    title: '站点GPS纬度',
                    dataIndex: 'stationLat',
                    ellipsis: true
                },

                {
                    title: '创建时间',
                    dataIndex: 'createTime',
                    ellipsis: true
                },
                {
                    title: '操作',
                    dataIndex: 'action',
                    scopedSlots: { customRender: 'action' }
                }
            ],
            // 加载数据方法 必须为 Promise 对象
            loadData: async parameter => {
                return await getStationsList(Object.assign(parameter, this.queryParam))
            },
            queryDate: null
        }
    },
    mounted() {},
    methods: {
        del(record) {
            stationsRemove({
                ids: record.id
            }).then(res => {
                this.$message.success('删除成功')
                this.$refs.table.refresh()
            })
        },
        add() {
            this.$refs.edit.show()
        },
        filterOption(input, option) {
            return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
        },
        editOk() {
            this.$refs.table.refresh()
        },

        resetForm() {
            this.queryParam = this.$options.data.call(this).queryParam
            this.queryDate = null
        },

        /**查询 */
        search() {
            this.$refs.table.refresh(true)
        }
    }
}
</script>

<style lang="less" scoped>
.align .ant-form-item-children {
    display: flex;
    .ant-input-affix-wrapper {
        width: 45%;
    }
    .line {
        width: 10%;
        display: inline-block;
        text-align: center;
    }
}
.step1 {
    p span {
        color: red;
        cursor: pointer;
        text-decoration: underline;
    }
}
.resultContent {
    display: flex;
    flex-direction: column;
    align-items: center;
}
</style>
