<!--
 * @Autor: dingxiaolin
 * @Date: 2021-10-13 16:10:53
 * @LastEditors: dingxiaolin
 * @LastEditTime: 2021-12-20 17:13:26
-->
<template>
    <div style="width: 100%; height: 100%" class="box">
        <p class="title">数据概况</p>
        <!-- <a-empty v-show="empty" :image="simpleImage" style="color: #fff" /> -->
        <div class="data">
            <div class="item cursor" @click="$router.push('/shipAlarm')">
              <span class="left">
                <img src="../../../../../assets/index_alarm.png">
              </span>
              <span class="right">
                <p>{{ overviewData ? overviewData['告警总数'] : '' }}</p> 告警总数
              </span>
            </div>
            <div class="item cursor" @click="$router.push('/shipAlarm?today=1')">
              <span class="left left1">
                <img src="../../../../../assets/index_today.png">
              </span>
              <span class="right right1">
                <p>{{ overviewData ? overviewData['今日告警数'] : '' }}</p> 今日告警数
              </span>
            </div>
            <div class="item cursor" @click="$router.push('/shipAlarm?isDeal_equal=1')">
              <span class="left left2">
                <img src="../../../../../assets/index_deal.png">
              </span>
              <span class="right right2">
                <p>{{ overviewData ? overviewData['已处理告警数'] : '' }}</p> 已处理告警数
              </span>
            </div>
        </div>
        <div class="Iconshow mt10">
          <div class="item cursor"  @click="$router.push('/aiDevices')">
              <div class="imgbox"><img src="../../../../../assets/index_bDevice.png"></div>
              <p class="num">{{ overviewData ? overviewData['AI终端总数'] : '' }}</p>
              <p>AI终端管理</p>
          </div>
          <div class="item">
              <div class="imgbox"><img src="../../../../../assets/index_bShip.png"></div>
              <p class="num">{{ overviewData ? overviewData['监控船舶数'] : '' }}</p>
              <p>监控船舶数</p>
          </div>
          <div class="item">
              <div class="imgbox"><img src="../../../../../assets/index_bWhist.png"></div>
              <p class="num">{{ overviewData ? overviewData['监控码头数'] : '' }}</p>
              <p>监控码头数</p>
          </div>
          <div class="item cursor" @click="$router.push('/shipAlarm')">
              <div class="imgbox"><img src="../../../../../assets/index_balarm.png"></div>
              <p class="num">{{ overviewData ? overviewData['待处理告警数'] : '' }}</p>
              <p>待处理告警</p>
          </div>
        </div>
    </div>
</template>

<script>
import { alarmDataOverviewStatistics } from '@/api/companybord/index'
export default {
    name: 'overviewData2',
    props: {
        data: {
            type: Array,
            default: () => {
                return []
            }
        }
    },

    data() {
        return {
            overviewData: null
        }
    },
    mounted() {
        this.getOverviewData()
    },
    methods: {
       /** 获取基础数据 */
        getOverviewData() {
            alarmDataOverviewStatistics({}).then((res) => {
                this.overviewData = res[0]
            })
        }
    }
        
}
</script>

<style lang="less" scoped>
#pieRadius {
    width: 400px;
    height: 190px;
    // width: 300px;
    // height: 300px;
}
.cursor{
  cursor: pointer;
}
.box{
  padding:0 10px 10px 10px;
  color: #fff;
  .title{
    height: 45px;
    line-height: 42px;
    border-bottom:#fff solid 1px;
  }
  .data{
    display: flex;
    .item{
      width:33%;
      margin: 0.5%;
      display: flex;
      align-items: center;
      justify-items: center;
      box-sizing: border-box;
      .left{
        height: 60px;
        border-radius: 6px 0 0 6px;
        width: 35%;
        text-align: center;
        background: linear-gradient(to right,#EB727E,#EC8379);
        img{
          margin-top: 15px;
        }
      }
      .right{
        height: 60px;
        border-radius: 0 6px 6px 0;
        width: 65%;
        padding-left:5px;
        background: linear-gradient(to right,#EB776B,#EC9262);
        font-size: 12px;
        p{
          margin:6px 0  0 0;
          font-size: 20px;
        }
      }

      .left1{
        background: linear-gradient(to right,#717EFC,#8F81FC);
      }
      .left2{
        background: linear-gradient(to right,#50AAF7,#639CF8);
      }
      .right1{
        background: linear-gradient(to right,#8474FC,#B77DFB);
      }
      .right2{
        background: linear-gradient(to right,#5492F7,#7586FB);
      }
    }
  }
  .Iconshow{
    display: flex;
    .item{
      width: 25%;
      text-align: center;
      .imgbox{
        height: 36px;
      }
      .num{
        margin:5px 0;
      }
      
    }
  }
}
</style>
