<!--
 * @Autor: dingxiaolin
 * @Date: 2021-12-01 08:58:39
 * @LastEditors: dingxiaolin
 * @LastEditTime: 2021-12-10 16:50:21
-->
<template>
    <a-breadcrumb class="breadcrumb">
        <a-breadcrumb-item v-for="(item, index) in breadList" :key="item.name">
          <!-- { path: item.path === '' ? '/welcome' : item.path } -->
            <router-link v-if="item.name != name && index != 1 && item.path !== ''" :to="item.path">{{
                item.meta.title
            }}</router-link>
            <span v-else>{{ item.meta.title }}</span>
        </a-breadcrumb-item>
    </a-breadcrumb>
</template>

<script>
export default {
    data() {
        return {
            name: '',
            breadList: []
        }
    },
    created() {
        this.getBreadcrumb()
    },
    methods: {
        getBreadcrumb() {
            this.breadList = []
            this.name = this.$route.name
            this.$route.matched.forEach((item) => {
                this.breadList.push(item)
            })
        }
    },
    watch: {
        $route() {
            this.getBreadcrumb()
        }
    }
}
</script>

<style scoped>
</style>
