<!--
 * @Autor: dingxiaolin
 * @Date: 2021-12-01 08:58:39
 * @LastEditors: dingxiaolin
 * @LastEditTime: 2022-09-05 14:02:36
-->
<template>
    <a-popover
        v-model="visible"
        trigger="click"
        placement="bottomRight"
        overlayClassName="header-notice-wrapper msyPopover"
        :getPopupContainer="() => $refs.noticeRef.parentElement"
        :autoAdjustOverflow="true"
        :arrowPointAtCenter="true"
    >
        <template slot="content">
            <a-spin :spinning="loading">
                <a-tabs>
                    <a-tab-pane tab="最新通知" key="1">
                        <div class="message" v-for="(item, i) in msgList" :key="i" @click="toDetail(item)">
                            <div class="title">
                                <span class="type">{{ item.noticeTitle }}</span>
                                <span>{{ item.noticeTime }}</span>
                            </div>
                            <div class="content">
                                <span>{{ item.noticeDesc }}</span>
                            </div>
                        </div>
                    </a-tab-pane>
                </a-tabs>
                <a-divider style="margin: 3px"></a-divider>
                <div class="flex ct">
                    <a-button
                        type="default"
                        @click="
                            $router.push('/notice/index')
                            visible = false
                        "
                        >查看更多</a-button
                    >
                </div>
            </a-spin>
        </template>
        <span @click="fetchNotice" class="header-notice" ref="noticeRef" style="padding: 0 18px">
            <a-badge :count="count">
                <a-icon style="font-size: 16px; padding: 4px" type="bell" />
            </a-badge>
        </span>
    </a-popover>
</template>

<script>
import { noticeOpList, unReadCount } from '@/api/alarm/index'

export default {
    name: 'HeaderNotice',
    data() {
        return {
            visible: false,
            loading: false,
            msgList: [],
            count: 0,
            form: {
                size: 3,
                current: 1,
                descs: 'notice_time',
                isDeleted_equal: 0
            }
        }
    },
    mounted() {
        /**消息数 */
        unReadCount({}).then(res => {
            this.count = res
        })
        /**消息列表 */
        noticeOpList(this.form).then(res => {
            this.current = res.current
            this.msgList = res.records
            this.pages = res.pages
        })
    },
    methods: {
        fetchNotice() {
            this.visible = !this.visible
        },
        toDetail(item) {
            if (item.noticeType == '1') {
                this.$router.push('/notice/index')
            } else {
                this.$router.push('/shipAlarm/detail?id=' + item.objectId + '&hide=1')
            }
            this.visible = false
        }
    }
}
</script>
<style lang="less" scoped>
.message {
    margin: 5px 0;
    cursor: pointer;
    &:hover {
        background: #eee;
    }
    .title {
        display: flex;
        justify-content: space-between;
        margin-bottom: 5px;
        .type {
            font-weight: bold;
        }
    }
    .content {
        max-width: 400px;
    }
}

.header-notice {
    display: inline-block;
    transition: all 0.3s;
    span {
        vertical-align: initial;
    }
}
</style>
