import { axios } from '@/utils/request'
import config from '@/config/config.js'

/**
 * 获取用户列表
 */
export function getUserPage(parameter) {
    return axios({
        url: config.namespace+'/user/page',
        method: 'get',
        params: parameter
    })
}

/**
 * 增加用户
 */
export function sysUserAdd(parameter) {
    return axios({
        url: config.namespace+'/user/add',
        method: 'post',
        data: parameter
    })
}

/**
 * 编辑用户
 */
export function sysUserEdit(parameter) {
    return axios({
        url: config.namespace+'/user/edit',
        method: 'post',
        data: parameter
    })
}


/**
 * 获取用户详情
 */
export function sysUserDetail(parameter) {
    return axios({
        url: config.namespace+'/user/detail',
        method: 'get',
        params: parameter
    })
}

/**
 * 删除用户
 */
export function sysUserDelete(parameter) {
    return axios({
        url: config.namespace+'/user/delete',
        method: 'post',
        data: parameter
    })
}

/**
 * 拥有角色
 */
export function sysUserOwnRole(parameter) {
    return axios({
        url: config.namespace+'/user/ownRole',
        method: 'get',
        params: parameter
    })
}

/**
 * 授权角色
 */
export function sysUserGrantRole(parameter) {
    return axios({
        url: config.namespace+'/user/grantRole',
        method: 'post',
        data: parameter
    })
}

/**
 * 拥有数据
 */
export function sysUserOwnData(parameter) {
    return axios({
        url: config.namespace+'/user/ownData',
        method: 'get',
        params: parameter
    })
}

/**
 * 授权数据
 */
export function sysUserGrantData(parameter) {
    return axios({
        url: config.namespace+'/user/grantData',
        method: 'post',
        data: parameter
    })
}

/**
 * 修改状态
 */
export function sysUserChangeStatus(parameter) {
    return axios({
        url: config.namespace+'/user/changeStatus',
        method: 'post',
        data: parameter
    })
}

/**
 * 重置密码
 */
export function sysUserResetPwd(parameter) {
    return axios({
        url: config.namespace+'/user/resetPwd',
        method: 'post',
        data: parameter
    })
}

/**
 * 修改密码
 */
export function sysUserUpdatePwd(parameter) {
    return axios({
        url: config.namespace+'/user/updatePwd',
        method: 'post',
        data: parameter
    })
}

/**
 * 用户选择器
 */
export function sysUserSelector(parameter) {
    return axios({
        url: config.namespace+'/user/selector',
        method: 'get',
        params: parameter
    })
}

/**
 * 修改头像
 */
export function sysUserUpdateAvatar(parameter) {
    return axios({
        url: config.namespace+'/user/updateAvatar',
        method: 'post',
        data: parameter
    })
}

/**
 * 更新基本信息
 */
export function sysUserUpdateInfo(parameter) {
    return axios({
        url: config.namespace+'/user/updateInfo',
        method: 'post',
        data: parameter
    })
}
