<template>
    <div>detail</div>
</template>

<script>
export default {
    name: 'detail',
    components: {},
    computed: {},
    data() {
        return {}
    },
    mounted() {},
    methods: {}
}
</script>

<style lang="less" scoped></style>
