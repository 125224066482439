<!--
 * @Autor: dingxiaolin
 * @Date: 2022-08-04 08:53:22
 * @LastEditors: dingxiaolin
 * @LastEditTime: 2023-03-03 11:27:05
-->
<template>
    <div class="wrap">
        <div class="leftTree">
            <div class="treeCom">
                <GBSTree ref="eTree" :channel="false" @Board="getBoard" @unselected="unselected" />
            </div>
            <!-- <div class="box2">
                <div class="titlebox">
                    <div class="device" :title="nodeName ? nodeName : ''"><span>告警记录</span> | {{ nodeName || '全部' }}</div>
                    <div class="more" @click="toListPage">查看更多</div>
                </div>
                <div class="alarmList" v-scrollBar>
                    <div class="container" v-if="alarmList.records && alarmList.records.length > 0">
                        <div class="box" v-for="(v, i) in alarmList.records" :key="i" @click="toDetailPage(v.id)">
                            <div class="flex line2">
                                <div class="imgBox">
                                    <img :src="v.alarmPic" alt="" />
                                    <div
                                        v-if="v['relativeBox'] && v['relativeType'] == 0"
                                        :style="{
                                            position: 'absolute',
                                            top: `${Math.abs(JSON.parse(v['relativeBox'])[1] * 100)}%`,
                                            left: `${Math.abs(JSON.parse(v['relativeBox'])[0] * 100)}%`,
                                            width: `${Math.abs(JSON.parse(v['relativeBox'])[2] * 100)}%`,
                                            height: `${Math.abs(JSON.parse(v['relativeBox'])[3] * 100)}%`,
                                            border: '1px solid #e4393c',
                                            zIndex: 1499
                                        }"
                                    ></div>
                                </div>
                                <div class="info">
                                    <div class="label1">{{ v.alarmTypeName }}</div>
                                    <div class="label1">{{ v.alarmTime }}</div>
                                    <div class="label2">
                                        摄像头:<span>{{ v.deviceName }}</span>
                                    </div>
                                </div>
                                <a-tag :color="get_status(v.confirmStatus, v.isDeal).color">
                                    {{ get_status(v.confirmStatus, v.isDeal).text }}
                                </a-tag>
                            </div>
                        </div>
                    </div>
                    <div class="container1" v-else>
                        <a-empty :image="simpleImage" />
                    </div>
                </div>
            </div> -->
        </div>
        <div class="rightContent">
            <div v-if="!hasStream" class="noREC">
                <img :src="bImage" class="noRECImg" />
                <p>试试选择监控对象~</p>
            </div>
            <live-player :video-url="url" fluent autoplay live stretch aspect="fullscreen" :controls="false" v-else />
            <div class="modal" v-if="showModal">
                <a-icon type="reload" style="font-size:30px" />
                <div style="margin:10px ">1分钟播放结束</div>
                <a-button type="primary" @click="resumePlay()"> 继续播放 </a-button>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import DeptTree from '@/components/DeptTree'
import GBSTree from '@/components/GBSTree'
import { alarmList } from '@/api/alarm/index'
import { Empty } from 'ant-design-vue'
import { get_status } from '@/utils/returnStatus'
import LivePlayer from '@liveqing/liveplayer'
import { getGBSToken } from '@/api/alarm'
import qs from 'qs'
import axios from 'axios'
export default {
    data() {
        return {
            bImage: require('@/assets/noRec.png'),
            intFun: null,
            timer: null,
            alarmList: [],
            BoardId: '',
            url: '',
            playTime: 60,
            deviceName: '',
            nodeName: '',
            deviceType: '',
            channelNumber: '',
            hasStream: false,
            showModal: false
        }
    },
    components: {
        DeptTree,
        LivePlayer,
        GBSTree
    },
    computed: {
        ...mapState({
            mqtt: state => state.app.mqtt
        })
    },
    beforeCreate() {
        this.simpleImage = Empty.PRESENTED_IMAGE_SIMPLE
    },
    mounted() {
        // this.loadData()
    },
    destroyed() {},
    methods: {
        get_status: get_status,
        /**开始播放流 */
        async startStream(deviceCode, channelNumber) {
            let token = await getGBSToken()
            let param = {
                serial: deviceCode,
                code: channelNumber,
                token,
                timeout: 60
            }
            return new Promise(async (res, rej) => {
                try {
                    let data = await axios.get(process.env.VUE_APP_GBS_URL + '/api/v1/stream/start?' + qs.stringify(param))
                    // res(data.data.HLS)
                    res(data.data.FLV)
                } catch (error) {
                    rej(error)
                }
            })
        },
        /**获取树选中设备 */
        getBoard(item) {
            if (!item.channelNumber) return
            this.BoardId = item.deviceCode
            this.channelNumber = item.channelNumber.split(',')[0]
            this.nodeName = item.nodeName
            this.deviceName = item.nodeDescription
            this.deviceType = item.deviceType
            console.log('ddd', item)
            clearTimeout(this.timer)
            this.showModal = false
            this.url = ''
            this.startStream(item.deviceCode, item.channelNumber.split(',')[0])
                .then(url => {
                    let index = this.$refs.eTree.selectedKeys.findIndex(v => v == item.nodeId)
                    if (index == -1) return
                    this.hasStream = true
                    this.url = url
                    // 4G设备播放一分钟   1:4G 2:以太网
                    if (item.deviceType == '1') {
                        this.timer = setTimeout(() => {
                            this.showModal = true
                            this.url = ''
                        }, this.playTime * 1000)
                    }
                })
                .catch(err => {
                    console.log('err', err)
                    this.$message.error(item.nodeDescription + '取流失败')
                    let i = this.$refs.eTree.selectedKeys.findIndex(v => v == item.nodeId)
                    this.$refs.eTree.selectedKeys.splice(i, 1)
                })
            // this.loadData()
        },
        /**继续播放 */
        resumePlay() {
            this.startStream(this.BoardId, this.channelNumber)
                .then(url => {
                    this.url = url
                    this.showModal = false
                    this.timer = setTimeout(() => {
                        this.showModal = true
                        this.url = ''
                    }, this.playTime * 1000)
                })
                .catch(err => {
                    this.$message.error(item.nodeDescription + '取流失败')
                })
        },
        unselected() {
            this.url = ''
            this.BoardId = ''
            this.deviceName = ''
            this.nodeName = ''
            this.deviceType = ''
            this.showModal = false
            clearTimeout(this.timer)
            // this.loadData()
        },
        toListPage() {
            this.$router.push('/shipAlarm?deviceName=' + this.deviceName.replaceAll('#', '@'))
        },
        toDetailPage(id) {
            this.$router.push('/shipAlarm/detail?id=' + id + '&hide=1')
        },
        async loadData() {
            let q = {
                confirmStatus_equal: 0,
                alarmDeviceName_like: this.deviceName || '',
                confirmStatus_equal: '',
                confirmStatus_notequal: '2',
                current: 1,
                descs: 'create_time',
                isDeal_equal: '',
                isDeal_notequal: '',
                isIgnore_equal: 0,
                size: 4
            }
            this.alarmList = await alarmList(q)
        },
        fullScreen() {
            this.$fullscreen.enter(this.$el.querySelector(`.videoBox`), {
                wrap: false,
                callback: f => {
                    this.isFullScreen = f
                }
            })
        }
    }
}
</script>

<style lang="less" scoped>
.wrap {
    padding: 5px;
    height: calc(100% - 45px);
    display: flex;
    .leftTree {
        width: 25%;
        padding: 10px;
        height: 100%;
        margin-right: 0.5%;
        display: flex;
        flex-direction: column;
        flex-shrink: 0;
        .treeCom {
            height: calc(100%);
            // height: calc(53% - 45px);
            flex-shrink: 0;
            background: #fff;
            padding: 10px;
        }
        .box2 {
            background: #fff;
            margin-top: 10px;
        }
        .titlebox {
            width: 100%;
            display: flex;
            justify-content: space-between;
            padding-right: 10px;
            color: #666;
            // background: #ddd;
            height: 30px;
            line-height: 30px;
            padding-left: 10px;
            margin-top: 5px;
            .more {
                cursor: pointer;
            }
        }
        .device {
            width: 80%;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            word-break: break-all;
            color: rgba(0, 0, 0, 0.65);
            span {
                font-size: 16px;
                color: #000000;
            }
        }
        .alarmList {
            flex: auto;
            color: #999;
            position: relative;
            overflow: hidden;
            .container {
                padding: 5px;
                height: 100%;
                .box {
                    cursor: pointer;
                    &:hover {
                        background: #ddd;
                    }
                }
                .line1 {
                    padding: 5px 15px;
                }
                .line2 {
                    border-bottom: 1px solid #ddd;
                    padding: 1vh;
                    .imgBox {
                        width: 100px;
                        height: auto;
                        position: relative;
                        img {
                            width: 100%;
                            height: 100%;
                        }
                        .targetDiv {
                            position: absolute;
                        }
                    }
                    .info {
                        margin-left: 10px;
                        .tag {
                            background-color: #e87774;
                            border-radius: 20px;
                            color: #fff;
                            width: auto;
                            margin-top: 5px;
                            margin-bottom: 10px;
                            display: inline-block;
                            padding: 0 10px;
                        }
                    }
                }
            }

            .container1 {
                padding: 10px;
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }
    .rightContent {
        flex: auto;
        width: 100%;
        height: 100%;
        padding: 10px;
        background: #fff;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;
        img {
            width: 100%;
        }

        p {
            margin-top: 10px;
            color: rgba(0, 0, 0, 0.65);
            font-size: 16px;
        }
        .player-wrapper {
            width: 100%;
            height: 100%;
        }
        .modal {
            position: absolute;
            display: flex;
            flex-direction: column;
            width: 300px;
            height: 200px;
            justify-items: center;
            align-items: center;
            padding-top: 50px;
            background: #fff;
        }
    }
    .icon {
        font-size: 36px;
        color: #7f7f7f;
        margin-top: 7px;
        cursor: pointer;
        margin-right: auto;
    }
    .ant-tag {
        height: fit-content;
        border: transparent;
        padding: 5px 10px;
        margin-left: auto;
    }
    .label1 {
        font-size: 14px;
        color: #000000;
        letter-spacing: 0;
        margin: 3px 0;
    }
    .label2 {
        font-size: 12px;
        color: #918c8c;
        letter-spacing: 0;
        margin: 3px 0;
    }
    .noREC {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .noRECImg {
            width: 25%;
            height: auto;
        }
    }
    /deep/ .ant-tree-node-content-wrapper-normal {
        vertical-align: revert;
        .img {
            width: 14px;
            height: 14px;
            background: url('~@/assets/rus.png');
            display: inline-block;
            background-size: 100% 100%;
            margin-right: 5px;
        }
        &.ant-tree-node-selected .img {
            background: url('~@/assets/rs.png');
            background-size: 100% 100%;
        }
    }
    /deep/ .ant-tree-switcher_open {
        width: 24px;
        height: 24px;
        background: url('~@/assets/down.png') no-repeat !important;
        background-size: 10% 10%;
        i {
            display: none !important;
        }
    }
    // 关闭时的图标
    ::v-deep .ant-tree-switcher_close {
        width: 24px;
        height: 24px;
        background: url('~@/assets/right.png') no-repeat !important;
        background-size: 10% 10%;
        i {
            display: none !important;
        }
    }
    // // 把之前的默认图标隐藏
    // ::v-deep .ant-tree-switcher-noop {
    //     width: 0 !important;
    //     i {
    //         display: none !important;
    //     }
    // }
}
</style>
