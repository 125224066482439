/*
 * @Autor: dingxiaolin
 * @Date: 2022-10-05 09:22:06
 * @LastEditors: dingxiaolin
 * @LastEditTime: 2022-10-22 09:12:46
 */
import { axios } from '@/utils/request'
import config from '@/config/config.js'

/**
 * 发送邮件
 */
export function emailSendEmail(parameter) {
    return axios({
        url: config.namespace + '/email/sendEmail',
        method: 'post',
        data: parameter
    })
}

/**
 * 发送html邮件
 */
export function emailSendEmailHtml(parameter) {
    return axios({
        url: config.namespace + '/email/sendEmailHtml',
        method: 'post',
        data: parameter
    })
}
