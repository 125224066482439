import { axios } from '@/utils/request'
import config from '@/config/config.js'

/**
 * 对于关系配置列表查询
 * poi_type POI点类型 1：景点；2：住宿；3：餐饮；4：充电；5：嗨游服务区；6：图片视频；7：取车点；8：还车点'
 * data_source 数据来源 1：高德
 * data_type 数据类型 1：分类；2：标签
 * "current": 1,
  "size": 10
 */
export function dictLinkPage(parameter) {
    return axios({
        url: '/app-ota/dictLink/list',
        method: 'post',
        data: parameter
    })
}

/**
 * 对于关系配置编辑
 * "version": 1,
    "id": "1",
    "poiType": "1",
    "dataSource": "1",
    "dataType": "1",
    "dataCode": "12345678",
    "dataName": "亲子游",
    "dictId": "1341885395117370372",
    "dictName": "新奇体验"
 */
export function dictLinkEdit(parameter) {
    return axios({
        url: '/app-ota/dictLink/submit',
        method: 'post',
        data: parameter
    })
}

/**
 * 分页查询配置列表
 */
export function sysConfigPage(parameter) {
    return axios({
        url: config.namespace + '/config/page',
        method: 'get',
        params: parameter
    })
}

/**
 * 添加系统参数配置
 */
export function sysConfigAdd(parameter) {
    return axios({
        url: config.namespace + '/config/add',
        method: 'post',
        data: parameter
    })
}

/**
 * 编辑系统参数配置
 */
export function sysConfigEdit(parameter) {
    return axios({
        url: config.namespace + '/config/edit',
        method: 'post',
        data: parameter
    })
}

/**
 * 删除系统参数配置
 */
export function sysConfigDelete(parameter) {
    return axios({
        url: config.namespace + '/config/delete',
        method: 'post',
        data: parameter
    })
}

/**
 * 获取字典类型下所有字典，举例，返回格式为：[{code:"M",value:"男"},{code:"F",value:"女"}]
 */
export function sysDictTypeDropDown(parameter) {
    return axios({
        url: config.namespace + '/dict/dropDown',
        method: 'get',
        params: parameter
    })
}

/**
 * 获取系统的所有任务列表
 */
export function sysTimersGetActionClasses(parameter) {
    return axios({
        url: config.namespace + '/timer/getActionClasses',
        method: 'get',
        params: parameter
    })
}
