import T from 'ant-design-vue/es/table/Table'
import { goTop } from '@/utils/util'
import { get, times } from 'lodash'
import './index.less'
export default {
    data() {
        return {
            needTotalList: [],
            selectedRows: [],
            selectedRowKeys: [],
            localLoading: false,
            localDataSource: [], //列表数据
            localPagination: Object.assign({}, this.pagination),
            scrollHeight: "",
            hasLoad: false,
            total: 0,
            pageNum: 1,
            pageSize: 10,
        }
    },
    props: Object.assign({}, T.props, {
        rowKey: {
            type: [String, Function],
            default: 'key'
        },
        data: {
            type: Function,
            required: true
        },

        showSizeChanger: {
            type: Boolean,
            default: true
        },
        size: {
            type: String,
            default: 'small'
        },
        rowSelection: {
            type: Object,
            default: null
        },
        showPagination: {
            type: String | Boolean,
            default: 'auto'
        },
        pageURI: {
            type: Boolean,
            default: false
        },
        scrollX: {
            type: Number | Boolean | String,
            default: 0
        },
        scrollY: {
            type: Number | Boolean,
            default: 0
        },

    }),
    watch: {
        'localPagination.current'(val) {
            this.pageURI &&
                this.$router.push({
                    ...this.$route,
                    name: this.$route.name,
                    params: Object.assign({}, this.$route.params, {
                        pageNo: val
                    })
                })
        },
        pageNum(val) {
            Object.assign(this.localPagination, {
                current: val
            })
        },
        pageSize(val) {
            Object.assign(this.localPagination, {
                pageSize: val
            })
        },
        showSizeChanger(val) {
            Object.assign(this.localPagination, {
                showSizeChanger: val
            })
        }
    },
    created() {
        const { pageNo } = this.$route.params
        const localPageNum = (this.pageURI && pageNo && parseInt(pageNo)) || this.pageNum
        this.localPagination =
            (['auto', true].includes(this.showPagination) &&
                Object.assign({}, this.localPagination, {
                    current: localPageNum,
                    pageSize: this.pageSize,
                    showSizeChanger: this.showSizeChanger,
                    showTotal: (total, range) => {
                        return range[0] + '-' + range[1] + '共' + total + '条'
                    }
                })) ||
            false
        // console.log('this.localPagination', this.localPagination)
        this.needTotalList = this.initTotalList(this.columns)
        if (this.$route.meta.cache === 0) {
            this.refresh(true)
        }
    },
    mounted() {
        const searchHeight = document.getElementsByClassName('table-page-search-wrapper')[0] ? document.getElementsByClassName('table-page-search-wrapper')[0].clientHeight : 0
        const btnHeight = document.getElementsByClassName('table-operator')[0] ? document.getElementsByClassName('table-operator')[0].clientHeight + 18 : 0
        if (this.$store.state.app.multiTab) {
            this.scrollHeight = `calc(100vh - 311px - ${searchHeight}px - ${btnHeight}px)`
        } else {
            this.scrollHeight = `calc(100vh - 274px - ${searchHeight}px - ${btnHeight}px) `
        }
    },
    activated() {
        this.refresh(false)
    },
    methods: {
        /**
         * 表格重新加载方法
         * 如果参数为 true, 则强制刷新到第一页
         * @param Boolean bool
         */
        refresh(bool = false) {
            ['auto', true].includes(this.showPagination) && bool &&
                (this.localPagination = Object.assign(
                    this.localPagination,
                    {
                        current: 1,
                        pageSize: this.pageSize
                    }
                ))
            bool && (this.pageNum = 1, this.pageSize = 10);
            this.loadData()
        },
        /**
         * 加载数据方法
         * @param {Object} pagination 分页选项器
         * @param {Object} filters 过滤条件
         * @param {Object} sorter 排序条件
         */
        loadData(pagination, filters, sorter) {
            this.localLoading = true
            const parameter = Object.assign(
                {
                    current:
                        (pagination && pagination.current) || (this.showPagination && this.localPagination.current) || this.pageNum,
                    size:
                        (pagination && pagination.pageSize) ||
                        (this.showPagination && this.localPagination.pageSize) ||
                        this.pageSize
                },
                (sorter &&
                    sorter.field && {
                    sortField: sorter.field
                }) ||
                {},
                (sorter &&
                    sorter.order && {
                    sortOrder: sorter.order
                }) ||
                {},
                {
                    ...filters
                }
            )
            console.log('parameter', parameter)
            const result = this.data(parameter)

            // 对接自己的通用数据接口需要修改下方代码中的 r.pageNo, r.totalCount, r.data
            // eslint-disable-next-line
            if ((typeof result === 'object' || typeof result === 'function') && typeof result.then === 'function') {
                result
                    .then(r => {
                        if (r == null) {
                            this.localLoading = false
                            return
                        }
                        this.localPagination =
                            (this.showPagination &&
                                Object.assign({}, this.localPagination, {
                                    current: parameter.current, // pageNo, // 返回结果中的当前分页数
                                    total: Number(r.total), // totalCount, // 返回结果中的总记录数
                                    showSizeChanger: this.showSizeChanger,
                                    pageSize: parameter.size
                                })) ||
                            false
                        this.total = Number(r.total)
                        // 后端数据rows为null保存修复
                        if (r.records == null) {
                            r.records = []
                        }

                        if (r.records.length == 0 && this.total != 0) {
                            this.localPagination.current = Math.ceil(this.total / this.pageSize)
                            this.pageNum = Math.ceil(this.total / this.pageSize)
                            this.loadData()
                        }
                        // 为防止删除数据后导致页面当前页面数据长度为 0 ,自动翻页到上一页
                        // if (r.records.length === 0 && this.showPagination && this.localPagination.current > 1) {
                        //     this.localPagination.current--
                        //     this.loadData()
                        //     return
                        // }

                        // 这里用于判断接口是否有返回 r.totalCount 且 this.showPagination = true 且 pageNo 和 pageSize 存在 且 totalCount 小于等于 pageNo * pageSize 的大小
                        // 当情况满足时，表示数据不满足分页大小，关闭 table 分页功能
                        // try {
                        //     if (
                        //         ['auto', true].includes(this.showPagination) &&
                        //         r.totalCount <= r.totalPage * this.localPagination.pageSize
                        //     ) {
                        //         this.localPagination.hideOnSinglePage = true
                        //     }
                        // } catch (e) {
                        //     this.localPagination = false
                        // }
                        this.localDataSource = r.records // 返回结果中的数组数据
                        this.localLoading = false
                    })
                    .catch(err => {
                        this.localLoading = false
                        // console.log(err)
                    })
            }
            goTop()
        },
        initTotalList(columns) {
            const totalList = []
            columns &&
                columns instanceof Array &&
                columns.forEach(column => {
                    if (column.needTotal) {
                        totalList.push({
                            ...column,
                            total: 0
                        })
                    }
                })
            return totalList
        },
        /**
         * 用于更新已选中的列表数据 total 统计
         * @param selectedRowKeys
         * @param selectedRows
         */
        updateSelect(selectedRowKeys, selectedRows) {
            this.selectedRows = selectedRows
            this.selectedRowKeys = selectedRowKeys
            const list = this.needTotalList
            this.needTotalList = list.map(item => {
                return {
                    ...item,
                    total: selectedRows.reduce((sum, val) => {
                        const total = sum + parseInt(get(val, item.dataIndex))
                        return isNaN(total) ? 0 : total
                    }, 0)
                }
            })
        },
        /**
         * 清空 table 已选中项
         */
        clearSelected() {
            if (this.rowSelection) {
                this.rowSelection.onChange([], [])
                this.updateSelect([], [])
            }
        },
        /**
         * 处理交给 table 使用者去处理 clear 事件时，内部选中统计同时调用
         * @param callback
         * @returns {*}
         */
        renderClear(callback) {
            if (this.selectedRowKeys.length <= 0) return null
            return (
                <a
                    style="margin-left: 24px"
                    onClick={() => {
                        callback()
                        this.clearSelected()
                    }}
                >
                    清空
                </a>
            )
        },
        pageChange(pageNum, pageSize) {
            console.log('1', pageNum, pageSize)
            this.pageNum = pageNum
            this.pageSize = pageSize
            this.loadData({ current: this.pageNum, pageSize: this.pageSize })
            document.getElementsByClassName('ant-table-body')[0].scrollTop = 0;
        },
        pageSizeChange(pageNum, pageSize) {
            console.log('2', pageNum, pageSize)
            this.pageNum = pageNum
            this.pageSize = pageSize
            this.loadData({ current: this.pageNum, pageSize: this.pageSize })
            document.getElementsByClassName('ant-table-body')[0].scrollTop = 0;
        },
    },

    render() {
        const props = {}
        const localKeys = Object.keys(this.$data)

        Object.keys(T.props).forEach(k => {
            const localKey = `local${k.substring(0, 1).toUpperCase()}${k.substring(1)}`
            if (localKeys.includes(localKey)) {
                props[k] = this[localKey]
                return props[k]
            }
            if (k === 'rowSelection') {
                if (this.rowSelection) {
                    props[k] = {
                        ...this.rowSelection,
                        selectedRows: this.selectedRows,
                        selectedRowKeys: this.selectedRowKeys,
                        onChange: (selectedRowKeys, selectedRows) => {
                            this.updateSelect(selectedRowKeys, selectedRows)
                            typeof this[k].onChange !== 'undefined' && this[k].onChange(selectedRowKeys, selectedRows)
                        }
                    }
                    return props[k]
                } else {
                    // 如果没打算开启 rowSelection 则清空默认的选择项
                    props[k] = null
                    return props[k]
                }
            }
            this[k] && (props[k] = this[k])
            return props[k]
        })
        const table = (
            <div>
                <a-table
                    bordered
                    size={props.size}
                    scroll={{ x: this.scrollX, y: this.scrollY || this.scrollHeight }}
                    rowClassName={(record, index) => index % 2 === 1 ? 'odd' : 'even'}
                    {...{ props, scopedSlots: { ...this.$scopedSlots } }}
                    onChange={this.loadData}
                    onExpand={(expanded, record) => {
                        this.$emit('expand', expanded, record)
                    }}
                >
                    {Object.keys(this.$slots).map(name => (
                        <template slot={name}>{this.$slots[name]}</template>
                    ))}
                </a-table>
                {
                    !this.showPagination && <div class="table-operator1">
                        <span slot="btns">{this.$slots.btns}</span>
                        <div style="float:right">
                            <a-pagination
                                total={this.total}
                                current={this.pageNum}
                                pageSize={this.pageSize}
                                show-total={total => `共${total}条`}
                                show-size-changer
                                show-quick-jumper
                                onChange={this.pageChange}
                                onShowSizeChange={this.pageSizeChange}
                            />
                        </div>
                    </div>
                }

            </div>

        )

        return (
            <div class="table-wrapper">
                {table}
            </div>
        )
    }
}
