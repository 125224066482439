<template>
    <a-card :bordered="false">
        <div class="table-page-search-wrapper">
            <a-form layout="inline">
                <a-row :gutter="74">
                    <a-col :md="6" :sm="24">
                        <a-form-model-item label="摄像头编号">
                            <a-input :maxLength="35" v-model="queryParam.deviceCode" allow-clear placeholder="请输入摄像头编号" />
                        </a-form-model-item>
                    </a-col>

                    <a-col :md="6" :sm="24">
                        <a-form-model-item label="摄像头名称">
                            <a-input :maxLength="35" v-model="queryParam.deviceName" allow-clear placeholder="请输入摄像头名称" />
                        </a-form-model-item>
                    </a-col>

                    <a-col :md="6" :sm="24">
                        <a-form-model-item label="监控场景">
                            <a-select style="width: 100%" placeholder="请选择监控场景" v-model="queryParam.alarmSceneType">
                                <a-select-option key="-1" value="">全部</a-select-option>
                                <a-select-option v-for="(item, index) in sceneTypeList" :key="index" :value="item.code">{{
                                    item.value
                                }}</a-select-option>
                            </a-select>
                        </a-form-model-item>
                    </a-col>

                    <a-col :md="6" :sm="24">
                        <a-form-model-item label="所属机构">
                            <dept-tree v-model="queryParam.tenantId_equal" />
                        </a-form-model-item>
                    </a-col>

                    <a-col :md="6" :sm="24">
                        <a-form-model-item label="状态">
                            <a-select style="width: 100%" placeholder="请选择状态" v-model="queryParam.deviceStatus">
                                <a-select-option v-for="(item, index) in deviceStatusList" :key="index" :value="item.code">{{
                                    item.value
                                }}</a-select-option>
                            </a-select>
                        </a-form-model-item>
                    </a-col>

                    <a-col :md="6" :sm="24">
                        <a-form-model-item label="AI终端ID">
                            <a-input :maxLength="35" v-model="queryParam.aiDeviceCode" allow-clear placeholder="请输入AI终端ID" />
                        </a-form-model-item>
                    </a-col>

                    <a-col :md="6" :sm="24">
                        <a-form-model-item label="AI终端名称">
                            <a-input :maxLength="35" v-model="queryParam.aiDeviceName" allow-clear placeholder="请输入AI终端名称" />
                        </a-form-model-item>
                    </a-col>

                    <a-col :md="6" :sm="24" align="right">
                        <span class="table-page-search-submitButtons" :style="(advanced && { float: 'right', overflow: 'hidden' }) || {}">
                            <a-button type="primary" @click="$refs.table.refresh(true)">查询</a-button>
                            <a-button style="margin-left: 8px" @click="() => (queryParam = {})">重置</a-button>
                        </span>
                    </a-col>
                </a-row>
            </a-form>
        </div>

        <div class="table-operator" v-if="hasPerm('alarmDevice:add')">
            <a-button type="primary" v-if="hasPerm('alarmDevice:add')" icon="plus" @click="$refs.addForm.add()">新增</a-button>
        </div>

        <n-table ref="table" :columns="columns" :data="loadData" :rowKey="(record) => record.id" :scrollToFirstRowOnChange="true">
            <span slot="deviceStatus" slot-scope="text">
                <span v-if="text == 0" class="grey grey_point">未激活</span>
                <span v-if="text == 1" class="green green_point">在线</span>
                <span v-if="text == 2" class="red red_point">离线</span>
            </span>

            <span slot="action" slot-scope="text, record">
                <a @click="$refs.addForm.edit(record)">编辑</a>
                <a-divider type="vertical" />
                <a-popconfirm
                    placement="topRight"
                    :title="record.aiDeviceName ? '删除后，将自动与终端' + record.aiDeviceName + '解绑，确定删除吗？' : '你确定要删除该摄像头吗？'"
                    @confirm="() => del(record)"
                >
                    <a class="red">删除</a>
                </a-popconfirm>
            </span>
        </n-table>

        <add-form ref="addForm" @ok="handleOk" />
    </a-card>
</template>

<script>
import { NTable } from '@/components'
import { alarmDeviceList, removeCamera } from '@/api/alarm/alarmDevice'
import { sysDictTypeDropDown } from '@/api/comm/index'
import addForm from './addForm'
import DeptTree from '@/components/DeptTree'

export default {
    components: {
        NTable,
        addForm,
        DeptTree
    },

    data() {
        return {
            labelCol: {
                sm: { span: 6 },
                xs: { span: 24 }
            },
            wrapperCol: {
                sm: { span: 18 },
                xs: { span: 24 }
            },
            // 高级搜索 展开/关闭
            advanced: false,
            // 查询参数
            queryParam: {
                deviceCode: '',
                deviceName: '',
                alarmSceneType: '',
                deviceStatus: '',
                aiDeviceCode: '',
                aiDeviceName: '',
                tenantId_equal: '',
                descs: 'create_time'
            },
            labelCol: {
                sm: { span: 6 },
                xs: { span: 24 }
            },
            wrapperCol: {
                sm: { span: 18 },
                xs: { span: 24 }
            },
            // 表头
            columns: [
                {
                    title: '摄像头编号',
                    dataIndex: 'deviceCode',
                    ellipsis: true
                },
                {
                    title: '摄像头名称',
                    dataIndex: 'deviceName',
                    ellipsis: true
                },
                {
                    title: '监控场景',
                    dataIndex: 'alarmSceneName',
                    ellipsis: true
                },
                {
                    title: '所属机构',
                    dataIndex: 'tenantName',
                    ellipsis: true
                },
                {
                    title: 'AI终端ID',
                    dataIndex: 'aiDeviceCode',
                    ellipsis: true
                },
                {
                    title: 'AI终端名称',
                    dataIndex: 'aiDeviceName',
                    ellipsis: true
                },
                {
                    title: '状态',
                    dataIndex: 'deviceStatus',
                    scopedSlots: { customRender: 'deviceStatus' }
                },
                {
                    title: '操作',
                    dataIndex: 'action',
                    width: '150px',
                    scopedSlots: { customRender: 'action' }
                }
            ],
            // 加载数据方法 必须为 Promise 对象
            loadData: async (parameter) => {
                return await alarmDeviceList(Object.assign(parameter, this.queryParam))
            },
            selectedRowKeys: [],
            selectedRows: [],
            //监控场景
            sceneTypeList: [],
            //状态
            deviceStatusList: [
                { code: '', value: '全部' },
                { code: '0', value: '未激活' },
                { code: '1', value: '在线' },
                { code: '2', value: '离线' }
            ]
        }
    },
    activated() {
        this.sysDictTypeDropDown()
    },
    methods: {
        toggleAdvanced() {
            this.advanced = !this.advanced
        },
        handleOk() {
            this.$refs.table.refresh()
        },
        onSelectChange(selectedRowKeys, selectedRows) {
            this.selectedRowKeys = selectedRowKeys
            this.selectedRows = selectedRows
        },
        /**
         * 获取所属分类
         */
        sysDictTypeDropDown() {
            sysDictTypeDropDown({ code: 'scene' }).then((res) => {
                this.sceneTypeList = res
            })
        },
        /**删除 */
        del(record) {
            removeCamera({ ids: record.id }).then((res) => {
                this.$message.success('删除摄像头成功')
                this.$refs.table.refresh()
            })
        }
    }
}
</script>

<style lang="less" scoped></style>
