import { axios } from '@/utils/request'
import config from '@/config/config.js'


/**
 * 系统属性监控
 *
 * @author Antonio
 * @date 2020/6/8 19:47
 */
export function sysMachineQuery(parameter) {
    return axios({
        url: config.namespace+'/machine/query',
        method: 'get',
        params: parameter
    })
}
