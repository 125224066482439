<template>
    <a-modal
        :title="title"
        centered
        :width="700"
        :visible="visible"
        :confirmLoading="confirmLoading"
        @ok="handleOk"
        @cancel="handleCancel"
        destroyOnClose
    >
        <a-form-model ref="form" :model="form" :rules="formRules" :label-col="labelCol" :wrapper-col="wrapperCol">
            <a-form-model-item label="盒子唯一标识">
                <a-input v-model="form.deviceTag" placeholder="若不填写，默认等于AI终端号" :disabled="title == '编辑AI终端'" />
            </a-form-model-item>
            <a-form-model-item label="AI终端名称" prop="deviceName" :rules="formRules.blur">
                <a-tooltip placement="right" title="">
                    <a-input v-model="form.deviceName" placeholder="请输入AI终端名称" />
                </a-tooltip>
            </a-form-model-item>
            <a-form-model-item label="设备型号" prop="deviceModel" :rules="formRules.blur">
                <a-select placeholder="请选择设备型号" v-model="form.deviceModel" allowClear @change="deviceModelChange">
                    <a-select-option v-for="(item, index) in deviceModelist" :key="index" :value="item.code">{{ item.value }}</a-select-option>
                </a-select>
            </a-form-model-item>
            <a-form-model-item label="所属机构" prop="tenantId" :rules="formRules.blur">
                <DeptTree
                    v-model="form.tenantId"
                    :all="false"
                    @change="handleTenantChange"
                    :disabled="Boolean(form.tenantId) && !hasPerm() && Boolean(id)"
                />
            </a-form-model-item>
            <a-form-model-item label="车牌号">
                <a-select placeholder="请选择车牌号" show-search v-model="form.areaName" @change="busInfoChange1" allowClear>
                    <a-select-option v-for="(item, index) in busInfoList1" :key="index" :value="item.busNum">{{ item.busNum }}</a-select-option>
                </a-select>
            </a-form-model-item>
            <a-form-model-item label="车架号" prop="areaTag" :rules="formRules.change">
                <a-select placeholder="请选择车架号" show-search v-model="form.areaTag" @change="busInfoChange2" allowClear>
                    <a-select-option v-for="(item, index) in busInfoList" :key="index" :value="item.busTag">{{ item.busTag }}</a-select-option>
                </a-select>
            </a-form-model-item>
            <a-form-model-item label="门型" v-if="busDoorInfo">
                <span>{{ busDoorInfo }}</span>
            </a-form-model-item>
            <a-form-model-item label="SIM卡号" prop="simNumber" :rules="formRules.change">
                <a-input v-model="form.simNumber" :maxLength="50" placeholder="请输入SIM卡号" />
            </a-form-model-item>
            <a-form-model-item label="备注">
                <a-textarea v-model="form.memo" type="textarea" placeholder="描述其他信息" :max-length="100" />
            </a-form-model-item>
        </a-form-model>
    </a-modal>
</template>

<script>
import {
    saveAiDevice,
    aiDeviceDetail,
    editAiDevice,
    getUnbindShipInfoList,
    warfInfoList,
    getAreaInfoList,
    getUnbindBusInfoList
} from '@/api/alarm/index'
import { formRules } from '@/utils/validator'
import { DeptTree } from '@/components'
import { mapState, mapActions } from 'vuex'
import { enumDeviceType, enumAreaType } from '@/utils/enum'
import { sysDictTypeDropDown } from '@/api/comm/index'
export default {
    props: {},
    components: {
        DeptTree
    },
    data() {
        return {
            title: '新增AI终端',
            id: '',
            formRules: formRules,
            index: 0,
            totalURL: 0,
            labelCol: {
                xs: { span: 24 },
                sm: { span: 5 }
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 15 }
            },
            visible: false,
            popShow: false,
            confirmLoading: false,
            form: {
                /**机构ID */
                tenantId: this.$store.state.user.tenant.tenantId || '',
                /**机构名称 */
                tenantName: this.$store.state.user.tenant.tenantName || '',
                /**AI终端名称 */
                deviceName: '',
                /** 监控类型 */
                areaType: 0,
                /**车辆ID */
                areaId: undefined,
                /** 车牌号 */
                areaName: '',
                /** 车架号 */
                areaTag: '',
                /**盒子唯一标识 */
                deviceTag: '',
                /**备注 */
                memo: '',
                /**SIM卡号 */
                simNumber: '',
                /**设备型号：设备型号 0-JHX-B2L、 1-JHX-CB360 */
                deviceModel: '',
                /**设备类型名称 */
                deviceModelName: ''
            },
            detail: {},
            /**船舶列表 */
            shipList: [],
            /**码头列表 */
            warfList: [],
            /** 区域列表 */
            areaList: [],
            /**设备类型列表 */
            deviceModelist: [],
            /** 区域类型列表 */
            areaTypeList: [],
            /** 区域详情列表 */
            areaDetailList: [],

            /** 动态变化的区域名称值 */
            areaAttrName: '',
            /** 获取未绑定的车辆信息列表 */
            busInfoList: [],
            /** 获取未绑定的车辆信息列表,过滤了没有车牌号的 */
            busInfoList1: [],
            /** 门型信息 */
            busDoorInfo: ''
        }
    },
    watch: {
        'form.areaType': {
            handler(val) {
                if (this.$refs.form) {
                    this.$refs.form.clearValidate('areaType')
                }
                console.log(this.formRules.change, 'this.rules')
                this.formRules.change[0].required = true
            },
            immediate: true
        }
        // 'form.areaTag': {
        //     handler(val) {
        //         if (this.$refs.form) {
        //             this.$refs.form.clearValidate('areaTag')
        //         }
        //         this.formRules.change[0].required = true
        //         if(val){
        //           this.$refs.form.resetFields();
        //         }
        //         // this.formRules.change[0].required = !val;
        //     },
        //     immediate: true
        // }
    },
    mounted() {},
    created() {
        console.log('object', this.$store.state.user.tenant.tenantId)
    },
    methods: {
        ...mapActions(['getUserTenant']),
        getDict() {
            sysDictTypeDropDown({ code: 'areaType' }).then(res => {
                this.areaTypeList = res
            })
            sysDictTypeDropDown({ code: 'deviceModelist' }).then(res => {
                this.deviceModelist = res
            })
        },
        // 初始化方法
        show(id) {
            this.id = id
            this.getUserTenant()
            this.form = {
                tenantId: this.$store.state.user.tenant.tenantId || '',
                tenantName: this.$store.state.user.tenant.tenantName || '',
                deviceName: '',
                areaId: undefined,
                memo: '',
                deviceType: undefined,
                deviceTag: ''
            }
            this.visible = true
            if (this.id) {
                this.title = '编辑AI终端'
                this.getDetail()
            } else {
                this.title = '新增AI终端'
                this.form.areaId = undefined
                this.shipList = []
                this.warfList = []
                this.areaList = []
                console.log('object', this.$store.state.user.tenant.tenantId)
                if (this.form.tenantId) {
                    this.initRelation(this.form.tenantId)
                }
            }
            this.getDict()
            this.$store.state.user.tenant.tenantId && this.getUnbindBusInfoList()
        },
        getUnbindBusInfoList() {
            getUnbindBusInfoList({
                tenantId: this.form.tenantId,
                deviceId: this.id
            }).then(res => {
                this.busInfoList = res
                this.busInfoList1 = this.busInfoList.filter(item => {
                    return item.busNum
                })
                console.log(this.busInfoList, this.busInfoList1)
            })
        },
        deviceModelChange(val) {
            let arr = this.deviceModelist.find(item => {
                return (item.code = val)
            })
            console.log(arr)
            this.form.deviceModelName = arr.value
            console.log(this.form.deviceModelName, 'this.form.deviceModelName')
        },
        busInfoChange1(val) {
            // 选择了车牌
            let item = null
            if (val) {
                item = this.busInfoList.find(i => {
                    return i.busNum == val
                })
                this.form.areaTag = item.busTag
                this.form.areaName = item.busNum
                this.busDoorInfo = item.busDoorInfo
                this.form.areaId = item.id
            } else {
                this.form.areaTag = ''
                this.form.areaName = ''
                this.busDoorInfo = ''
                this.form.areaId = ''
            }
        },
        busInfoChange2(val) {
            // 选择了车牌
            let item = null
            if (val) {
                item = this.busInfoList.find(i => {
                    return i.busTag == val
                })
                this.form.areaTag = item.busTag
                this.form.areaName = item.busNum
                this.busDoorInfo = item.busDoorInfo
                this.form.areaId = item.id
            } else {
                this.form.areaTag = ''
                this.form.areaName = ''
                this.busDoorInfo = ''
                this.form.areaId = ''
            }
            console.log(this.form, 'this.form')
        },
        /**获取详情 */
        getDetail() {
            aiDeviceDetail({ id: this.id }).then(async res => {
                this.form = res
                this.form = {
                    id: res.id, //多媒体通道ID
                    tenantId: res.tenantId, //所属机构ID
                    tenantName: res.tenantName, //所属机构名称
                    deviceName: res.deviceName, //AI终端名称
                    areaType: res.areaType,
                    areaId: res.areaId,
                    areaName: res.areaName,
                    areaTag: res.areaTag,
                    deviceTag: res.deviceTag, //终端设备标识
                    memo: res.memo,
                    simNumber: res.simNumber,
                    deviceModel: res.deviceModel,
                    deviceModelName: res.deviceModelName
                }
                await this.initRelation(res.tenantId)
                // this.setAreaList()
            })
        },

        handleOk() {
            console.log(this.form, 'this.form')
            this.$refs.form.validate(valid => {
                if (valid) {
                    this.confirmLoading = true
                    if (this.id) {
                        editAiDevice(this.form)
                            .then(res => {
                                this.$message.success('编辑AI终端成功')
                                this.visible = false
                                this.confirmLoading = false
                                this.$emit('ok')
                                this.$refs.form.resetFields()
                            })
                            .catch(() => {
                                this.confirmLoading = false
                            })
                    } else {
                        saveAiDevice(this.form)
                            .then(res => {
                                this.$message.success('新增AI终端成功')
                                this.visible = false
                                this.confirmLoading = false
                                this.$emit('ok')
                                this.$refs.form.resetFields()
                            })
                            .catch(() => {
                                this.confirmLoading = false
                            })
                    }
                } else {
                    this.confirmLoading = false
                }
            })
        },
        handleCancel() {
            this.visible = false
        },
        async handleTenantChange(options) {
            this.form.tenantName = options.title
            this.form.tenantId = options.value
            this.form.areaId = undefined
            await this.initRelation(options.value)
            // this.setAreaList() // 切换机构时重新加载监控对象类型和名称
        },
        /**级联下拉数据及初始化默认数据 */
        initRelation(tenantId) {
            this.form.tenantId = tenantId
            this.getUnbindBusInfoList()
        }
    }
}
</script>
<style scoped lang="less">
.swiper-container {
    height: 280px;
}
.handle .ant-form-item {
    margin-bottom: 10px;
}
.swiper-pagination-fraction {
    top: 10px;
    bottom: unset;
    color: #007aff;
}
.swiper-container-horizontal > .swiper-pagination-bullets {
    top: 10px;
    bottom: unset;
}
.devicesContainer {
    border: 1px solid #ddd;
    min-height: 100px;
    padding: 0 10px;
}
</style>
