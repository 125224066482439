<template>
  <a-modal
    title="新增字典值"
    centered
    :width="980"
    :maskClosable="false"
    :destroyOnClose="true"
    :visible="visible"
    :confirmLoading="confirmLoading"
    @ok="handleSubmit"
    @cancel="handleCancel"
  >
    <a-spin :spinning="confirmLoading">
      <a-form :form="form">
        <a-form-item style="display: none;" :labelCol="labelCol" :wrapperCol="wrapperCol" has-feedback>
          <a-input v-decorator="['typeId']" />
        </a-form-item>

        <a-form-item label="字典值" :labelCol="labelCol" :wrapperCol="wrapperCol" has-feedback>
          <a-input
            :maxLength="45"
            placeholder="请输入字典值"
            v-decorator="['value', { rules: [{ required: true, message: '请输入字典值！' }] }]"
          />
        </a-form-item>

        <a-form-item label="唯一编码" :labelCol="labelCol" :wrapperCol="wrapperCol" has-feedback>
          <a-input
            :maxLength="45"
            placeholder="请输入唯一编码"
            v-decorator="['code', { rules: [{ required: true, message: '请输入唯一编码！' }] }]"
          />
        </a-form-item>
        <a-form-item label="扩展字段一" :labelCol="labelCol" :wrapperCol="wrapperCol" has-feedback>
          <a-input
            :maxLength="255"
            placeholder="请输入扩展字段一"
            v-decorator="['exta', { rules: [{ required: false, message: '请输入扩展字段一！' }] }]"
          />
        </a-form-item>
        <a-form-item label="扩展字段二" :labelCol="labelCol" :wrapperCol="wrapperCol" has-feedback>
          <a-input
            :maxLength="255"
            placeholder="请输入扩展字段二"
            v-decorator="['extb', { rules: [{ required: false, message: '请输入扩展字段二！' }] }]"
          />
        </a-form-item>
        <a-form-item label="扩展字段三" :labelCol="labelCol" :wrapperCol="wrapperCol" has-feedback>
          <a-input
            :maxLength="255"
            placeholder="请输入扩展字段三"
            v-decorator="['extc', { rules: [{ required: false, message: '请输入扩展字段三！' }] }]"
          />
        </a-form-item>
        <a-form-item :labelCol="labelCol" :wrapperCol="wrapperCol" label="排序">
          <a-input-number
            placeholder="请输入排序"
            style="width: 100%"
            v-decorator="['sort', { initialValue: 100 }]"
            :min="0"
            :max="1000"
          />
        </a-form-item>

        <a-form-item label="备注" :labelCol="labelCol" :wrapperCol="wrapperCol" has-feedback>
          <a-textarea :maxLength="254" :rows="4" placeholder="请输入备注" v-decorator="['remark']"></a-textarea>
        </a-form-item>
      </a-form>
    </a-spin>
  </a-modal>
</template>

<script>
import { sysDictDataAdd } from '@/api/system/dictData'
export default {
  data() {
    return {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 15 }
      },
      visible: false,
      confirmLoading: false,
      form: this.$form.createForm(this)
    }
  },
  methods: {
    // 初始化方法
    add(record) {
      this.visible = true
      // 增加上级类型ID
      setTimeout(() => {
        this.form.setFieldsValue({
          typeId: record
        })
      }, 100)
    },

    handleSubmit() {
      const {
        form: { validateFields }
      } = this
      this.confirmLoading = true
      validateFields((errors, values) => {
        if (!errors) {
          sysDictDataAdd(values)
            .then(res => {
              if (res.success) {
                this.$message.success('新增成功')
                this.visible = false
                this.confirmLoading = false
                this.$emit('ok', values)
                this.form.resetFields()
              } else {
                this.$message.error('新增失败：' + res.msg)
              }
            })
            .finally(res => {
              this.confirmLoading = false
            })
        } else {
          this.confirmLoading = false
        }
      })
    },
    handleCancel() {
      this.form.resetFields()
      this.visible = false
    }
  }
}
</script>
