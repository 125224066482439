import { message } from 'ant-design-vue' /// es/notification
import RequestForm from '@/utils/RequestForm'
import config from '@/config/defaultSettings'
import moment from 'moment'
var OSS = require('ali-oss');
var COS = require('cos-js-sdk-v5')
const { SecretId, SecretKey, Bucket, Region, oss } = config
var cos = new COS({
    SecretId: SecretId,
    SecretKey: SecretKey
})
var client = new OSS.Wrapper(oss);
export function timeFix() {
    const time = new Date()
    const hour = time.getHours()
    return hour < 9 ? '早上好' : hour <= 11 ? '上午好' : hour <= 13 ? '中午好' : hour < 17 ? '下午好' : '晚上好'
}

export function hello() {
    const arr = ['休息一会儿吧', '准备吃什么呢?', '要不要打一把 LOL', '我猜你可能累了']
    const index = Math.floor(Math.random() * arr.length)
    return arr[index]
}
/**
 * 
 * @param {*} arr 数组
 * @param {*} num 个数
 * @returns 
 */
export function spArr(arr, num) { //arr是你要分割的数组，num是以几个为一组
    let newArr = [] //首先创建一个新的空数组。用来存放分割好的数组
    for (let i = 0; i < arr.length;) { //注意：这里与for循环不太一样的是，没有i++
        newArr.push(arr.slice(i, i += num));
    }
    return newArr
}

/**
 * @description 过滤枚举
 * @param list 
 * @param key 
 */
export const filterEnum = (list, key) => {
    if (key === null || key == undefined || key === '' || key.toString() == 'NaN') return '-'
    const data = list.filter(v => Number(v?.code) == key)
    return data.length ? data[0].value : '枚举不匹配'
    
}
/** 密码强度计算 <3 低  3< >5 中 >5 高 */
export function getPasswordStrength(password) {
    var strength = 0;
    [/.{6,}/, /[0-9]+/, /[a-z]+/, /[A-Z]+/, /[^0-9a-zA-Z]+/].forEach((v, i) => {
        if (v.test(password)) strength++;
    });
    return strength;
};

/**
 * @param {Function} fn 防抖函数
 * @param {Number} delay 延迟时间
 */
export function debounce(fn, delay) {
    var timer;
    return function () {
        var context = this;
        var args = arguments;
        clearTimeout(timer);
        timer = setTimeout(function () {
            fn.apply(context, args);
        }, delay);
    };
}

// 递归筛选出来所有的子节点，存放在childArr数组中
export function requestList(data) {
    var childArr = []
    data &&
        data.map(item => {
            if (item.children && item.children.length > 0) {
                requestList(item.children)
            } else {
                childArr.push(item.id)
            }
            return null
        })
    // console.log('子节点', childArr)
    return childArr
}

/**
 * 数组进行比对去除父节点
 * @memberof Role
 */
export function uniqueTree(uniqueArr, Arr) {
    let uniqueChild = []
    for (var i in Arr) {
        for (var k in uniqueArr) {
            if (uniqueArr[k] === Arr[i]) {
                uniqueChild.push(uniqueArr[k])
            }
        }
    }
    // console.log('uniqueChild', uniqueChild)
    return uniqueChild
}

/** 返回顶部 */
export function goTop() {
    const container = document.querySelector('#containerScor')
    // console.log(container);
    if (container && container !== null && container.scrollTop) {
        container.scrollTop = 0
    }
}
/** 去掉line-height */
export function replaceSpecialChar(detail) {
    var content = detail.replace(
        /<p[^>]*>|<span[^>]*>|<h1[^>]*>|<h2[^>]*>|<h3[^>]*>|<h4[^>]*>|<h5[^>]*>|<li[^>]*>|<div[^>|<ol[^>]*>/gi,
        function (match, capture) {
            var matchFont = match.replace(/(style="(.*?)")|(line-height="(.*?)")/gi, '')
            return matchFont
        }
    )
    return content.replace(/<p><\/p>|<\/?font[^>]*>/gi, '')
}
//转意符换成普通字符
export function escape2Html(str) {
    var arrEntities = { lt: '<', gt: '>', nbsp: '&nbsp;', amp: '&', quot: '"' }
    var reg = /&(lt|gt|nbsp|amp|quot);/gi
    // console.log(reg.test(str));
    if (reg.test(str) === false) {
        return false
    }
    return str.replace(/&(lt|gt|nbsp|amp|quot);/gi, function (all, t) {
        return arrEntities[t]
    })
}
export function getTimes(file) {
    //获取音视频时长
    return new Promise((resolve, reject) => {
        var content = file
        var url = URL.createObjectURL(content)
        //经测试，发现audio也可获取视频的时长
        var audioElement = new Audio(url)
        audioElement.addEventListener('loadedmetadata', _event => {
            var audioDuration = parseInt(audioElement.duration)
            var durations = formatDuraton(audioDuration)
            console.log('audioDuration', durations)
            resolve(durations)
            reject(durations)
        })
    })
}
/** 格式化音视频时长 时分秒 00:00:01 */
export function formatDuraton(time) {
    if (time > -1) {
        var hour = Math.floor(time / 3600)
        var min = Math.floor(time / 60) % 60
        var sec = time % 60
        if (hour < 10) {
            // time = '0'+ hour + ":";
            time = ''
        } else {
            // time = hour + ":";
            time = ''
        }

        if (min < 10) {
            time += '0'
        }
        time += min + ':'

        if (sec < 10) {
            time += '0'
        }
        time += sec
    }
    return time
}

/**
 * 上传前的附件格式验证 .avi,.wmv,.mpeg,.mov,.rm,.ram,.swf,.mp4,.mp3,.wma,.rmvb,.flv,.mpg,.mkv
 */
export function filesBeforeUpload(file, onlyImg, fileArr) {
    // console.log(fileArr, file, file.name.replace(/\s/g, ''))
    if (file.status === 'removed' || (file.status === 'done' && file.url !== undefined)) {
        return false
    }
    const isImg = /^image\//g.test(file.type)
    const isVideo = /^video\//g.test(file.type)
    const isAudio = /^audio\//g.test(file.type)
    const isNameValid = /^[\w\u4e00-\u9fa5\-\()\/.]+$/g.test(file.name)
    const isFormatValid = onlyImg
        ? ['image/png', 'image/jpeg', 'image/gif', 'image/bmp'].includes(file.type)
        : [
            'image/png',
            'image/jpeg',
            'image/gif',
            'image/bmp',
            'video/mp4',
            'audio/mp3',
            'audio/mpeg',
            'application/vnd.ms-excel',
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            'application/acad',
            'text/plain',
            'application/x-zip-compressed',
            'application/pdf',
            'application/msword',
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
        ].includes(file.type)
    const ext = file.name.slice(file.name.lastIndexOf('.') + 1).toLowerCase()
    const isApps =
        file.type !== ''
            ? !/\.(mp3|mpeg|mp4|gif|jpg|jpeg|png|txt|dwg|doc|docx|xls|xlsx|pdf|svg|zip|rar|7z)$/.test(ext)
            : /^application\//g.test(file.type)
    console.log(isApps)
    if (fileArr.length > 0) {
        const isRename = true
        fileArr.filter((item, index) => {
            if (item.name === file.name.replace(/\s/g, '')) {
                message.warning('提示：已存在同名同类型文件！')
                isRename = false
                return isRename
            }
        })
        return isRename
    }
    if (isFormatValid) {
        if (isImg) {
            const isImgLt2M = file.size / 1024 / 1024 < 10
            if (isNameValid) {
                if (!isImgLt2M) message.error('请确认上传图片' + file.name + '不超过10MB')
            } else {
                message.error('请确认图片名称仅包含以下字符：字母 数字 汉字 _ - .')
            }
            return isFormatValid && isImgLt2M && isNameValid
        }
        if (isVideo) {
            const isVideoLt10M = file.size / 1024 / 1024 < 200
            if (isNameValid) {
                if (!isVideoLt10M) message.error('请确认上传的视频' + file.name + '不超过200MB')
            } else {
                message.error('请确认视频名称仅包含以下字符：字母 数字 汉字 _ - ./')
            }
            return isFormatValid && isVideoLt10M && isNameValid
        }
        if (isAudio) {
            const isAudioLt10M = file.size / 1024 / 1024 < 200
            if (isNameValid) {
                if (!isAudioLt10M) message.error('请确认上传的音频' + file.name + '不超过200MB')
            } else {
                message.error('请确认音频名称仅包含以下字符：字母 数字 汉字 _ - ./')
            }
            return isFormatValid && isAudioLt10M && isNameValid
        }
    } else {
        if (onlyImg) {
            message.error('请上传图片，格式为：.png, .jpg')
        } else {
            if (isVideo) message.error('请上传视频，格式为：.mp4')
            if (isAudio) message.error('请上传音频，格式为：.mp3')
            if (isApps) message.error('请上传文件，格式为：.txt, .dwg, .doc, .docx, .xls, .xlsx, .pdf, .zip, .rar, .7z')
        }
    }
    return true
}

/**
 * 初始化cos对象存储
 * @export
 * @returns
 */
export function instanceCos() {
    return new COS({
        // 必选参数
        getAuthorization: async (options, callback) => {
            // 服务端获取签名，请参考对应语言的 COS SDK：https://cloud.tencent.com/document/product/436/6474
            // 注意：这种有安全风险，后端需要通过 method、pathname 严格控制好权限，例如不允许 put / 等
            // const response = await getCosAuth({ id: '1' })
            const response = {}
            // pathname: '/' + options.Key,
            if (response.success) {
                const data = response.data
                if (!data || !data.authorization) return console.error('authorization invalid')
                callback(
                    new Error({
                        Authorization: data.authorization
                        // XCosSecurityToken: data.sessionToken, // 如果使用临时密钥，需要把 sessionToken 传给 XCosSecurityToken
                    })
                )
                message.success(response.msg)
            } else {
                message.error(response.msg || '获取失败')
            }
        },
        // 可选参数
        FileParallelLimit: 3, // 控制文件上传并发数
        ChunkParallelLimit: 3, // 控制单个文件下分片上传并发数
        ProgressInterval: 1000 // 控制上传的 onProgress 回调的间隔
    })
}

/**
 * cos对象存储--上传
 * @export
 * @returns
 */
// export function uploadFile(key, fileObject) {
//   const dates = moment().format('YYYYMMDD')
//   return new Promise((resolve, reject) => {
//     cos.putObject(
//       {
//         Bucket: Bucket /* 必须 */,
//         Region: Region /* 存储桶所在地域，必须字段 */,
//         Key: 'background/' + dates + '/' + uuid() /* 必须 */,
//         StorageClass: 'STANDARD',
//         allowPrefix: '/background',
//         Body: fileObject, // 上传文件对象
//         onProgress: progressData => {
//           console.log(JSON.stringify(progressData))
//           // resolve(JSON.stringify(progressData));
//         }
//       },
//       (err, data) => {
//         resolve(data)
//         reject(err)
//         // console.log(err || data)
//       }
//     )
//   })
// }

/**
 * cos对象存储--批量上传
 * @export
 * @returns
 */
// export function uploadFileMultiple(fileArras) {
//   // [{
//   //   Bucket: Bucket, // Bucket 格式：BucketName-APPID
//   //   Region: Region,     /* 存储桶所在地域，必须字段 */
//   //   Key: '1',
//   //   Body: 'fileArras',
//   // }]
//   // const dates = moment().format('YYYYMMDD')
//   fileArras.forEach(item => {
//     item.Bucket = Bucket
//     item.Region = Region
//     // item.Key = '/background/' + dates + '/' + item.Key
//   })
//   console.log('fileArras', fileArras)
//   return new Promise((resolve, reject) => {
//     cos.uploadFiles(
//       {
//         files: fileArras,
//         SliceSize: 1024 * 1024,
//         onProgress: info => {
//           var percent = parseInt(info.percent * 10000) / 100
//           var speed = parseInt((info.speed / 1024 / 1024) * 100) / 100
//           console.log('进度：' + percent + '%; 速度：' + speed + 'Mb/s;')
//         },
//         onFileFinish: (err, data, options) => {
//           console.log(options.Key + '上传' + (err ? '失败' : '完成'), data)
//           resolve(data)
//           reject(err)
//         }
//       },
//       (err, data) => {
//         console.log('err', err || data)
//       }
//     )
//   })
// }

/**
 * OSS对象存储--上传
 * @export
 * @returns
 */
export async function uploadFile(name, fileObject, path = '/web/') {
    const dates = moment().format('YYYYMMDDHHmmss')
    console.log(name, fileObject)
    const result = await client.multipartUpload(path + dates + '_' + name, fileObject)
    console.log('result', result)
    if (result.res.statusCode == 200) {
        return result.res.requestUrls[0].split('?')[0];
    }
}

/**
 * OSS对象存储--批量上传
 * @export
 * @returns
 */
export async function uploadFileMultiple(fileArras) {
    console.log('fileArrasfileArras', fileArras)
    var success = true;
    return new Promise((resolve, reject) => {
        try {
            fileArras.forEach(async (v) => {
                const result = await client.multipartUpload(v.name, v.Body);
                console.log('result', result)
                if (result.res.statusCode != 200) {
                    success = false;
                    reject('上传失败')
                    throw new Error('上传失败')
                }
            })
        } catch (e) { }
    })
}
/**重命名 */
var getUuid = () => {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'
        .replace(/[xy]/g, function (c) {
            var r = (Math.random() * 16) | 0,
                v = c == 'x' ? r : (r & 0x3) | 0x8;
            return v.toString(16);
        })
        .toUpperCase();
};
/**
 * cos对象存储--上传字符串作为文件
 * @export
 * @returns
 */
export function uploadString(key, string) {
    cos.putObject(
        {
            Bucket: Bucket /* 必须 */,
            Region: Region /* 存储桶所在地域，必须字段 */,
            Key: key /* 必须 */,
            Body: string // 'hello!',
        },
        (err, data) => {
            console.log(err || data)
        }
    )
}

/**
 * cos对象存储--创建文件夹
 * @export
 * @returns
 */
export function createFloder(floderName) {
    cos.putObject(
        {
            Bucket: Bucket /* 必须 */,
            Region: Region /* 存储桶所在地域，必须字段 */,
            Key: floderName /* 必须 */,
            Body: ''
        },
        (err, data) => {
            console.log(err || data)
        }
    )
}

/**
 * cos对象存储--删除文件
 * [{Key: 'exampleobject'},
 * {Key: 'exampleobject2'},]
 * @export
 * @returns
 */
export function deleteFile(filesArr) {
    return new Promise((resolve, reject) => {
        cos.deleteMultipleObject(
            {
                Bucket: Bucket /* 必须 */,
                Region: Region /* 存储桶所在地域，必须字段 */,
                Objects: filesArr
            },
            (err, data) => {
                resolve(data)
                reject(err)
                console.log(err || data)
            }
        )
    })
}

/**
 * cos对象存储--下载文件
 * @export
 * @returns
 */
export function downloadFileCos(key) {
    return new Promise((resolve, reject) => {
        cos.getObjectUrl(
            {
                Bucket: Bucket /* 必须 */,
                Region: Region /* 存储桶所在地域，必须字段 */,
                Key: key /* 必须 */,
                Sign: false
            },
            (err, data) => {
                resolve(data.Url)
                // reject(err);
                console.log(err || data)
            }
        )
    })
}

/**
 * cos对象存储--获取文件
 * @export
 * @returns
 */
export function getFile(key) {
    return new Promise((resolve, reject) => {
        cos.headObject(
            {
                Bucket: Bucket /* 必须 */,
                Region: Region /* 存储桶所在地域，必须字段 */,
                Key: key /* 必须 */
            },
            (err, data) => {
                resolve(data.body)
                // reject(err);
                console.log(err || data)
            }
        )
    })
}

/**
 * 生成uuid
 */
export function uuid() {
    var s = []
    var hexDigits = '0123456789abcdef'
    for (var i = 0; i < 36; i++) {
        s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1)
    }
    s[14] = '4' // bits 12-15 of the time_hi_and_version field to 0010
    s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1) // bits 6-7 of the clock_seq_hi_and_reserved to 01
    // s[8] = s[13] = s[18] = s[23] = "";
    // s[10] = Math.random();
    var uuid = s.join('')
    return uuid
}

/**
 * 图片转换base64
 */
export function getBase64(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => resolve(reader.result)
        reader.onerror = error => reject(error)
    })
}

/**
 * 合并数据去重
 * @param arr1
 * @param arr2
 * @returns {array}
 */
export function unique(arr1, arr2) {
    if (arguments.length <= 1) {
        return false
    }
    var concat_ = function (arr1, arr2) {
        var arr = arr1.concat()
        for (var i = 0; i < arr2.length; i++) {
            arr.indexOf(arr2[i]) === -1 ? arr.push(arr2[i]) : 0
        }
        return arr
    }
    var result = concat_(arr1, arr2)
    for (var i = 2; i < arguments.length; i++) {
        result = concat_(result, arguments[i])
    }
    return result
}

/**
 * 递归 遍历tree
 * @param arrData
 * @returns {array}
 */
export function recursion(arrData) {
    const arr1 = []
    for (const i in arrData.children) {
        if (arrData.children && typeof arrData.children === 'object') {
            // arr1.push(arrData.children[i].key)
            if (arrData.children[i].children && typeof arrData.children[i].children === 'object') {
                console.log(arr1, arrData.children[i])
                // 递归往下找
                recursion(arrData.children[i])
                return arrData.children[i]
            } else {
                // 跳出当前递归，返回上层递归
                continue
            }
        }
    }
}

/**
 * Json强转为Form类型
 */
export function toFormData(obj) {
    return new RequestForm(obj)
}

/** base64转blob */
export function dataURLtoBlob(dataurl) {
    var arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n)
    while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
    }
    return new Blob([u8arr], { type: mime })
}

/** base64转文件 dataURLtoFile('data:text/plain;base64,YWFhYWFhYQ==', 'test.txt') */
export function dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n)
    while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
    }
    return new File([u8arr], filename, { type: mime })
}

/** base64转成文件url*/
export function fileToUrl(base64){
  let file = dataURLtoFile(base64); 
  return window.webkitURL.createObjectURL(file) || window.URL.createObjectURL(file)
}

/**
 * 视频截帧--返回文件对象fileObj
 * @param {*} file
 */
export function extractFramesFromVideo(videoBlob, fps = 25) {
    return new Promise(async resolve => {
        //首先完全下载（无缓冲）
        let videoObjectUrl = URL.createObjectURL(videoBlob)
        let videoElement = document.createElement('video')
        videoElement.src = videoObjectUrl
        videoElement.autoplay = true
        videoElement.addEventListener('loadeddata', async () => {
            let canvas = document.createElement('canvas')
            let context = canvas.getContext('2d')
            let [w, h] = [videoElement.videoWidth * 0.9, videoElement.videoHeight * 0.9]
            canvas.width = w
            canvas.height = h
            let frames = []
            // let interval = 1 / fps
            // let currentTime = 0
            // let duration = videoElement.duration
            context.drawImage(videoElement, 0, 0, w, h)
            let base64ImageData = canvas.toDataURL('image/png')
            let blobImg = dataURLtoFile(base64ImageData, uuid() + '_pc_cover.png')
            frames.push(blobImg)
            videoElement.pause()
            videoElement.autoplay = false
            // console.log('video', frames, videoElement)
            resolve(frames)
        })
    })
}

/**
 * 视频截帧--返回base64
 * @param {*} file
 */
export function extractBaseFromVideo(videoBlob, fps = 25) {
    return new Promise(async resolve => {
        //首先完全下载（无缓冲）
        let videoObjectUrl = URL.createObjectURL(videoBlob)
        let videoElement = document.createElement('video')
        videoElement.src = videoObjectUrl
        videoElement.autoplay = true
        videoElement.addEventListener('loadeddata', async () => {
            let canvas = document.createElement('canvas')
            let context = canvas.getContext('2d')
            let [w, h] = [videoElement.videoWidth * 0.9, videoElement.videoHeight * 0.9]
            canvas.width = w
            canvas.height = h
            let frames = []
            context.drawImage(videoElement, 0, 0, w, h)
            let base64ImageData = canvas.toDataURL('image/png')
            // let blobImg = dataURLtoBlob(base64ImageData)
            frames.push(base64ImageData)
            videoElement.pause()
            videoElement.autoplay = false
            // console.log('video', videoElement, frames[0])
            resolve(frames[0])
        })
    })
}

/**
 * 视频截帧--返回fileBlob
 * @param {*} file
 */
export function extractBlobFromVideo(videoBlob, fps = 25) {
    return new Promise(async resolve => {
        //首先完全下载（无缓冲）
        let videoObjectUrl = URL.createObjectURL(videoBlob)
        let videoElement = document.createElement('video')
        videoElement.src = videoObjectUrl
        videoElement.autoplay = true
        videoElement.addEventListener('loadeddata', async () => {
            let canvas = document.createElement('canvas')
            let context = canvas.getContext('2d')
            let [w, h] = [videoElement.videoWidth * 0.9, videoElement.videoHeight * 0.9]
            canvas.width = w
            canvas.height = h
            let frames = []
            context.drawImage(videoElement, 0, 0, w, h)
            let base64ImageData = canvas.toDataURL('image/png')
            let blobImg = dataURLtoBlob(base64ImageData)
            frames.push(blobImg)
            videoElement.pause()
            videoElement.autoplay = false
            // console.log('videoBlob', videoElement, frames[0])
            resolve(frames[0])
        })
    })
}

/**
 * 获取图片尺寸--长高
 * @param file blobObj
 */
export function checkImageWH(file) {
    // 参数分别是上传的file
    return new Promise(function (resolve, reject) {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = e => {
            const image = new Image()
            image.src = reader.result
            image.onload = () => resolve([{ width: image.width }, { height: image.height }])
            image.onerror = error => reject(error)
        }
    })
}
/**
 * 金额格式化--千位符,逗号
 * @param value
 * @returns {String}
 */
export function limitDecimalsY(value) {
    const reg = /^(-)*(\d+)\.(\d\d).*$/
    return `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',').replace(reg, '$1$2.$3')
}
/**
 * 金额格式化--千位符,逗号
 * @param value
 * @returns {String}
 */
export function limitDecimalsF(value) {
    const reg = /^(-)*(\d+)\.(\d\d).*$/
    return `￥ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',').replace(reg, '$1$2.$3')
}
/**
 * 金额格式化--保留两位小数
 * @param value
 * @returns {String}
 */
export function limitDecimalsP(value) {
    const reg = /^(-)*(\d+)\.(\d\d{1,2}).*$/
    return value.replace(/￥\s?|(,*)/g, '').replace(reg, '$1$2.$3')
}
/**
 * 经纬度验证
 * @param longitude
 * @param latitude longLat
 * @returns {String}
 */
export function verifyLongLat(rule, value, callback) {
    console.log(value)
    if (value === undefined) {
        callback(new Error('经纬度不能为空！'))
        return false
    }
    const longStr = value.split(',')
    if (longStr.length !== 2) {
        // message.error('经纬度格式不正确，请以逗号隔开重新输入!');
        callback(new Error('经纬度格式不正确，请以逗号隔开重新输入！'))
        return false
    }
    const longitude = longStr[0]
    const latitude = longStr[1]
    // 经度，整数部分为0-180小数部分为0到6位
    var longreg = /^(-|\+)?(((\d|[1-9]\d|1[0-7]\d|0{1,3})\.\d{0,6})|(\d|[1-9]\d|1[0-7]\d|0{1,3})|180\.0{0,6}|180)$/
    if (!longreg.test(longitude)) {
        callback(new Error('经度整数部分为0-180,小数部分为0到6位！'))
        // message.error('经度整数部分为0-180,小数部分为0到6位!');
        return false
    }
    // 纬度,整数部分为0-90小数部分为0到6位
    var latreg = /^(-|\+)?([0-8]?\d{1}\.\d{0,6}|90\.0{0,6}|[0-8]?\d{1}|90)$/
    if (!latreg.test(latitude)) {
        callback(new Error('纬度整数部分为0-90,小数部分为0到6位！'))
        // message.error('纬度整数部分为0-90,小数部分为0到6位!');
        return false
    }
    callback()
    return true
}
/**
 * 触发 window.resize
 */
export function triggerWindowResizeEvent() {
    const event = document.createEvent('HTMLEvents')
    event.initEvent('resize', true, true)
    event.eventType = 'message'
    window.dispatchEvent(event)
}

export function handleScrollHeader(callback) {
    let timer = 0

    let beforeScrollTop = window.pageYOffset
    callback = callback || function () { }
    window.addEventListener(
        'scroll',
        event => {
            clearTimeout(timer)
            timer = setTimeout(() => {
                let direction = 'up'
                const afterScrollTop = window.pageYOffset
                const delta = afterScrollTop - beforeScrollTop
                if (delta === 0) {
                    return false
                }
                direction = delta > 0 ? 'down' : 'up'
                callback(direction)
                beforeScrollTop = afterScrollTop
            }, 50)
        },
        false
    )
}

export function isIE() {
    const bw = window.navigator.userAgent
    const compare = s => bw.indexOf(s) >= 0
    const ie11 = (() => 'ActiveXObject' in window)()
    return compare('MSIE') || ie11
}

/**
 * Remove loading animate
 * @param id parent element id or class
 * @param timeout
 */
export function removeLoadingAnimate(id = '', timeout = 1500) {
    if (id === '') {
        return
    }
    setTimeout(() => {
        document.body.removeChild(document.getElementById(id))
    }, timeout)
}
/**
 * 下载文件
 */
export function downloadFile(res) {
    if (!res.data) {
        return;
    }
    if (res.headers['content-disposition']) {
        var fileName = res.headers['content-disposition'].split(';')[1].split('filename=')[1];
    } else {
        fileName = time.getFullDate(new Date());
    }
    let url = window.URL.createObjectURL(new Blob([res.data]));
    let link = document.createElement('a');
    link.style.display = 'none';
    link.href = url;
    link.setAttribute('download', decodeURI(fileName));
    document.body.appendChild(link);
    link.click();
};
/**
 * 文件大小转换
 * @param {*} size 
 * @returns 
 */
export function readFileSize(size) {
    if((size / 1024) < 1){
      fileSizeString = `${size}KB`
    }else{
      var size = size / 1024
      var aMultiples = ['KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
      var fileSizeString = ''
      for (var i = 0; size > 1; size /= 1024, i++) {
          fileSizeString = size.toFixed(2) + ' ' + aMultiples[i]
      }
    }
    
    return fileSizeString
}