<template>
    <a-card :bordered="false">
        <div class="table-page-search-wrapper">
            <a-form-model :label-col="labelCol" :wrapper-col="wrapperCol">
                <a-row :gutter="48">
                    <a-col :md="6" :sm="24">
                        <a-form-model-item label="AI终端号">
                            <a-input :maxLength="35" v-model="queryParam.deviceCode_equal" allow-clear placeholder="请输入AI终端号" />
                        </a-form-model-item>
                    </a-col>
                    <a-col :md="6" :sm="24">
                        <a-form-model-item label="AI终端名称">
                            <a-input :maxLength="35" v-model="queryParam.deviceName_like" allow-clear placeholder="请输入AI终端名称" />
                        </a-form-model-item>
                    </a-col>
                    <a-col :md="6" :sm="24">
                        <a-form-model-item label="盒子唯一标识">
                            <a-input :maxLength="35" v-model="queryParam.deviceTag_like" allow-clear placeholder="请输入盒子唯一标识" />
                        </a-form-model-item>
                    </a-col>
                    <a-col :md="6" :sm="24">
                        <a-form-model-item label="固件版本">
                            <a-input :maxLength="35" v-model="queryParam.firmwareVersion_equal" allow-clear placeholder="请输入固件版本" />
                        </a-form-model-item>
                    </a-col>
                    <a-col :md="6" :sm="24">
                        <a-form-model-item label="所属机构">
                            <DeptTree v-model="queryParam.tenantId_equal" />
                        </a-form-model-item>
                    </a-col>
                    <a-col :md="6" :sm="24">
                        <a-form-model-item label="车牌号">
                            <a-input :maxLength="35" v-model="queryParam.areaName" allow-clear placeholder="请输入车牌号" />
                        </a-form-model-item>
                    </a-col>
                    <a-col :md="6" :sm="24">
                        <a-form-model-item label="设备型号">
                            <a-select style="width: 100%" placeholder="请选择设备型号" v-model="queryParam.deviceModel_equal">
                                <a-select-option value="">全部</a-select-option>
                                <a-select-option v-for="(item, index) in deviceModelist" :key="index" :value="item.code">{{
                                    item.value
                                }}</a-select-option>
                            </a-select>
                        </a-form-model-item>
                    </a-col>
                    <a-col :md="6" :sm="24">
                        <a-form-model-item label="在线状态">
                            <a-select style="width: 100%" placeholder="请选择在线状态" v-model="queryParam.deviceStatus_equal">
                                <a-select-option value="">全部</a-select-option>
                                <a-select-option v-for="(item, index) in isOnlineList" :key="index" :value="item.code">{{
                                    item.value
                                }}</a-select-option>
                            </a-select>
                        </a-form-model-item>
                    </a-col>
                    <a-col :md="12" :sm="24" :offset="12" align="right">
                        <span class="table-page-search-submitButtons">
                            <a-button
                                type="primary"
                                @click="
                                    queryParam.areaId_equal = ''
                                    $refs.table.refresh(true)
                                "
                                >查询</a-button
                            >
                            <a-button style="margin-left: 8px" @click="() => (queryParam = {})">重置</a-button>
                        </span>
                    </a-col>
                </a-row>
            </a-form-model>
        </div>
        <div class="table-operator">
            <a-button type="primary" @click="$refs.addForm.show()">新增</a-button>
            <a-button type="default" @click="exportList()" style="float:right">导出</a-button>
            <!-- <a-button type="default" @click="updateModel()" :disabled="disabeledUp">升级算法模型</a-button> -->
        </div>
        <n-table
            ref="table"
            :columns="columns"
            :data="loadData"
            :scrollX="1650"
            :rowKey="record => record.id"
            :scrollToFirstRowOnChange="true"
            :rowSelection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
        >
            <span slot="areaType" slot-scope="text">
                {{ text ? $filterEnum(areaTypeList, text) : '' }}
            </span>
            <span slot="deviceModel" slot-scope="text">
                <span v-if="text === '0'">JHX-B2L</span>
                <span v-if="text === '1'">JHX-CB360</span>
                <!-- <span>{{text ? $filterEnum(deviceModelist, text) : '' }}</span> -->
            </span>
            <span slot="registerType" slot-scope="text">
                <span v-if="text == 0">自动注册</span>
                <span v-else>手动添加</span>
            </span>
            <span slot="deviceStatus" slot-scope="text">
                <span v-if="text == 0" class="grey grey_point">未激活</span>
                <span v-if="text == 1" class="green green_point">在线</span>
                <span v-if="text == 2" class="red red_point">离线</span>
            </span>
            <span slot="deviceOnlineTime" slot-scope="text, record">
                <span v-if="record.deviceStatus == 1">{{ $moment(new Date()).format('YYYY-MM-DD HH:mm:ss') }}</span>
                <span v-else>{{ record.deviceOnlineTime }}</span>
            </span>
            <span slot="action" slot-scope="text, record">
                <a @click="toDetail(record)" v-if="hasPerm('aiDevices:detail')">查看</a>
                <a-divider type="vertical" v-if="hasPerm('aiDevices:edit')" />
                <a @click="$refs.addForm.show(record.id)" v-if="hasPerm('aiDevices:edit')">编辑</a>
                <a-divider type="vertical" v-if="hasPerm('aiDevices:del')" />
                <a-popconfirm
                    placement="topRight"
                    :title="record.deviceStatus != '0' ? '已经激活的设备无法删除！' : '你确定要删除该AI终端吗？'"
                    @confirm="() => del(record)"
                    :disabled="record.deviceStatus != '0'"
                    v-if="hasPerm('aiDevices:del')"
                >
                    <a class="red" @click="record.deviceStatus != '0' && $message.warning('已经激活的设备无法删除！')">删除</a>
                </a-popconfirm>
            </span>
        </n-table>
        <add-form ref="addForm" @ok="handleOk" />
        <upgrade ref="upgrade" @ok="handleOk(detail)" @showDrawer="showDrawer" />
        <a-drawer title="批量升级结果" placement="right" :closable="false" :visible="upvisible" @close="upvisible = false" width="380">
            <p v-for="(item, index) in uplist" :key="index">
                盒子唯一编号:<br />{{ item.boardId }}<br />固件版本：{{ item.version }}<br />升级结果:
                <a-tag :color="item.success ? 'green' : 'red'">
                    {{ item.desc }}
                </a-tag>
            </p>
        </a-drawer>
    </a-card>
</template>

<script>
import { NTable } from '@/components'
import { aiDeviceList, removeDevice, exportAiDeviceInfo } from '@/api/alarm/index'
import addForm from './addForm'
import { sysDictTypeDropDown } from '@/api/comm/index'
import { DeptTree } from '@/components'
import { enumDeviceType, enumAreaType } from '@/utils/enum'
import Upgrade from './upgrade'

export default {
    components: {
        Upgrade,
        NTable,
        addForm,
        DeptTree
    },
    data() {
        return {
            registerType: [
                { label: '自动注册', code: '0' },
                { label: '手动添加', code: '1' }
            ],
            uplist: [],
            enumDeviceType: enumDeviceType,
            /**升级算法模型按钮 是否禁用 */
            disabeledUp: true,
            /**批量升级抽屉 */
            upvisible: false,
            labelCol: {
                sm: { span: 6 },
                xs: { span: 24 }
            },
            wrapperCol: {
                sm: { span: 18 },
                xs: { span: 24 }
            },
            // 查询参数
            queryParam: {
                /**设备编码=匹配 */
                deviceCode_equal: '',
                /**设备名称模糊匹配 */
                deviceName_like: '',
                /**设备状态 */
                deviceStatus_equal: '',
                /** 固件版本 */
                firmwareVersion_equal: '',
                /**所属机构 */
                tenantId_equal: '',
                /**终端设备标识 */
                deviceTag_like: '',
                descs: 'create_time'
            },
            // 表头
            columns: [
                {
                    title: 'AI终端编号',
                    dataIndex: 'deviceCode',
                    width: '100px',
                    ellipsis: true
                },
                {
                    title: '盒子唯一标识',
                    dataIndex: 'deviceTag',
                    scopedSlots: { customRender: 'deviceTag' },
                    ellipsis: true
                },
                {
                    title: '设备型号',
                    dataIndex: 'deviceModel',
                    scopedSlots: { customRender: 'deviceModel' },
                    ellipsis: true
                },
                {
                    title: 'AI终端名称',
                    dataIndex: 'deviceName',
                    ellipsis: true
                },
                {
                    title: 'SIM卡号',
                    dataIndex: 'simNumber',
                    ellipsis: true
                },
                {
                    title: '所属机构',
                    dataIndex: 'tenantName',
                    ellipsis: true
                },
                {
                    title: '车牌号',
                    dataIndex: 'areaName',
                    ellipsis: true
                },
                {
                    title: '固件版本',
                    dataIndex: 'firmwareVersion',
                    ellipsis: true
                },
                {
                    title: '在线状态',
                    width: '100px',
                    dataIndex: 'deviceStatus',
                    scopedSlots: { customRender: 'deviceStatus' }
                },
                {
                    title: '最后在线时间',
                    scopedSlots: { customRender: 'deviceOnlineTime' },
                    ellipsis: true
                },
                {
                    title: '操作',
                    dataIndex: 'action',
                    align: 'center',
                    scopedSlots: { customRender: 'action' }
                }
            ],
            // 加载数据方法 必须为 Promise 对象
            loadData: async parameter => {
                return await aiDeviceList(Object.assign(parameter, this.queryParam))
            },
            warningList: [
                { code: '', value: '全部' },
                { code: '1', value: '未戴安全帽' }
            ],
            isDealList: [
                { code: '', value: '全部' },
                { code: '0', value: '未处理' },
                { code: '1', value: '已处理' }
            ],
            isOnlineList: [],
            selectedRowKeys: [],
            selectedRows: [],
            deviceModelist: [] // 设备型号 0-JHX-B2L、 1-JHX-CB360,
        }
    },

    mounted() {
        this.getDict()
    },

    methods: {
        exportList() {
            // exportAiDeviceInfo
            exportAiDeviceInfo({
                ...this.queryParam,
                size: -1
            })
        },
        getDict() {
            sysDictTypeDropDown({ code: 'online_status' }).then(res => {
                this.isOnlineList = res
            })
            sysDictTypeDropDown({ code: 'deviceModelist' }).then(res => {
                this.deviceModelist = res
            })
        },
        handleOk() {
            this.$refs.table.refresh()
        },
        onSelectChange(selectedRowKeys, selectedRows) {
            this.selectedRowKeys = selectedRowKeys
            this.selectedRows = selectedRows
            if (this.selectedRows.length > 0) {
                const offline = this.selectedRows.findIndex(v => v.deviceStatus != 1)
                if (offline == -1) {
                    this.disabeledUp = false
                } else {
                    this.disabeledUp = true
                }
            } else {
                this.disabeledUp = true
            }
        },
        toDetail(record) {
            console.log(record)
            this.$router.push('/aiDevices/detail?id=' + record.id + '&areaId=' + record.areaId)
        },
        /**显示抽屉 */
        showDrawer(uplist) {
            this.uplist = uplist
            this.upvisible = true
            this.handleOk()
        },
        updateModel() {
            if (this.selectedRows.length == 1) {
                this.$refs.upgrade.show(this.selectedRowKeys, this.selectedRows[0])
            } else {
                this.$refs.upgrade.show(this.selectedRowKeys)
            }
        },
        /**删除 */
        del(record) {
            console.log('re', JSON.stringify(record))
            removeDevice({ ids: record.id }).then(res => {
                this.$message.success('删除AI终端成功')
                this.$refs.table.refresh()
            })
        }
    }
}
</script>

<style lang="less" scoped></style>
