<template>
  <a-modal
    title="授权角色"
    centered
    :width="980"
    :maskClosable="false"
    :destroyOnClose="true"
    :visible="visible"
    :confirmLoading="confirmLoading"
    @ok="handleSubmit"
    @cancel="handleCancel"
  >
    <a-card :bordered="false">
      <div>
        <a-table
          :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
          :columns="columns"
          :dataSource="loadData"
          :pagination="true"
          :loading="loading"
          :rowKey="record => record.id"
        />
      </div>
    </a-card>
  </a-modal>
</template>

<script>
import { getRolePage } from '@/api/system/role'
import { sysUserOwnRole, sysUserGrantRole } from '@/api/system/user'

const columns = [
  {
    title: '角色名称',
    dataIndex: 'name'
  },
  {
    title: '唯一编码',
    dataIndex: 'code'
  }
]

export default {
  name: 'UserRoleIndex',

  data() {
    return {
      columns,
      loadData: [],
      selectedRowKeys: [], // Check here to configure the default column
      loading: true,
      visible: false,
      confirmLoading: false,
      recordEntity: []
    }
  },
  computed: {
    hasSelected() {
      return this.selectedRowKeys.length > 0
    }
  },
  methods: {
    // 初始化方法
    userRole(record) {
      this.recordEntity = record
      if (record.userId) {
        this.recordEntity.id = record.userId
      }
      console.log('70', this.recordEntity)
      this.visible = true
      // 加载已有数据
      this.sysUserOwnRole()
      // 获取全部列表,无需分页
      getRolePage().then(res => {
        this.loadData = res.data.rows
        var _arrs = []
        if (res.data.rows.length > 0) {
          res.data.rows.map(item => {
            _arrs.push(item.id)
          })
        }
        if (this.selectedRowKeys.length > 0 && _arrs.length > 0) {
          this.loading = true
          // console.log('intersection', _arrs, this.selectedRowKeys);
          let _arr = new Set(_arrs)
          // 差集
          let intersection = this.selectedRowKeys.filter(item => !_arr.has(item))
          if (intersection.length > 0) {
            let ids = intersection.join(',')
            console.log('intersection', intersection, ids, _arrs, this.selectedRowKeys)
            getRolePage({ idList: ids }).then(res => {
              // 选中多选框
              this.loadData = this.loadData.concat(res.data.rows)
            })
          }
          this.loading = false
        } else {
          this.loading = false
        }
      })
    },

    /**
     * 获取用户已有角色
     */
    sysUserOwnRole() {
      this.loading = true
      sysUserOwnRole({ id: this.recordEntity.id }).then(res => {
        // 选中多选框
        this.selectedRowKeys = res.data
        this.loading = false
      })
    },

    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys
    },

    handleSubmit() {
      // eslint-disable-next-line no-unused-expressions
      this.confirmLoading = false
      this.visible = false
      sysUserGrantRole({ id: this.recordEntity.id, grantRoleIdList: this.selectedRowKeys })
        .then(res => {
          if (res.success) {
            this.$message.success('授权成功')
            this.confirmLoading = false
            this.$emit('ok', this.recordEntity)
            this.handleCancel()
          } else {
            this.$message.error('授权失败：' + res.msg)
          }
        })
        .finally(res => {
          this.confirmLoading = false
        })
    },
    handleCancel() {
      this.recordEntity = []
      this.selectedRowKeys = []
      this.visible = false
    }
  }
}
</script>
