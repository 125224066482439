<template>
    <a-card :bordered="false">
        <div class="table-page-search-wrapper">
            <a-form-model :label-col="labelCol" :wrapper-col="wrapperCol">
                <a-row :gutter="48">
                    <a-col :md="6" :sm="24">
                        <a-form-model-item label="车牌号">
                            <a-input v-model="queryParam.carNumber" allow-clear placeholder="请输入车牌号" />
                        </a-form-model-item>
                    </a-col>

                    <a-col :md="6" :sm="24">
                        <a-form-model-item label="时间">
                            <a-range-picker
                                :show-time="{ defaultValue: [$moment('00:00:00', 'HH:mm:ss'), $moment('23:59:59', 'HH:mm:ss')] }"
                                v-model="queryDate"
                                format="YYYY-MM-DD"
                                @change="dateChange"
                            />
                        </a-form-model-item>
                    </a-col>
                    <a-col :md="6" :sm="24">
                        <a-form-model-item label="车门">
                            <a-select style="width: 100%" placeholder="请选择企业类型" v-model="queryParam.doorNumber">
                                <a-select-option value="">全部</a-select-option>
                                <a-select-option v-for="(item, index) in doorList" :key="index" :value="item.value">{{ item.value }}</a-select-option>
                            </a-select>
                        </a-form-model-item>
                    </a-col>
                    <a-col :md="6" :sm="24" align="right">
                        <span class="table-page-search-submitButtons">
                            <a-button type="primary" @click="init">查询</a-button>
                            <a-button style="margin-left: 8px" @click="resetForm">重置</a-button>
                        </span>
                    </a-col>
                </a-row>
            </a-form-model>
        </div>
        <a-table
            ref="table"
            :columns="columns"
            size="small"
            bordered
            :data-source="datalist"
            :rowKey="record => record.id"
            :rowClassName="(record, index) => (index % 2 === 1 ? 'odd' : 'even')"
            :scroll="{ y: scrollHeight }"
        >
            <span slot="action" slot-scope="text, record">
                <a-popconfirm placement="topRight" title="确定移除吗？" @confirm="() => del(record)">
                    <a class="red">移除</a>
                </a-popconfirm>
            </span>
        </a-table>
    </a-card>
</template>

<script>
import { NTable, DeptTree } from '@/components'
import { statisticsPassengerFlowInfo } from '@/api/alarm/index'
import { sysDictTypeDropDown } from '@/api/comm/index'
import { formRules } from '@/utils/validator'
export default {
    components: {
        NTable,
        DeptTree
    },

    data() {
        return {
            scrollHeight: 0,
            formRules: formRules,
            datalist: [],
            visible: false,
            ignore_visible: false,
            layout: {
                labelCol: { span: 5 },
                wrapperCol: { span: 17 }
            },
            paginationProps: {
                hideOnSinglePage: true,
                showSizeChanger: true
            },
            labelCol: {
                sm: { span: 6 },
                xs: { span: 24 }
            },
            wrapperCol: {
                sm: { span: 18 },
                xs: { span: 24 }
            },
            // 高级搜索 展开/关闭
            advanced: true,
            queryDate: [this.$moment().format('YYYY-MM-DD'), this.$moment().format('YYYY-MM-DD')], //告警时间范围
            createTime: null, //接收时间范围
            //中间状态临时存储后期判断
            status_temp: 'null',
            // 查询参数
            queryParam: {
                searchStartTime: this.$moment().format('YYYY-MM-DD'), //查询开始时间yyyy-MM-dd HH:mm:ss
                searchEndTime: this.$moment().format('YYYY-MM-DD'), //查询开始时间yyyy-MM-dd HH:mm:ss
                carNumber: '', //车牌号
                doorNumber: '' //车门号
            },
            // 弹窗处理方式
            dealType: 0, // 0 忽略 1 处理完成
            // 表头
            columns: [
                {
                    title: '车牌号',
                    dataIndex: 'carNumber',
                    key: 'carNumber'
                },
                {
                    title: '1号门',
                    children: [
                        {
                            title: '上',
                            dataIndex: 'inNum1',
                            key: 'inNum1',
                            width: 100
                        },
                        {
                            title: '下',
                            dataIndex: 'leaveNum1',
                            key: 'leaveNum1',
                            width: 100
                        }
                    ]
                },
                {
                    title: '2号门',
                    children: [
                        {
                            title: '上',
                            dataIndex: 'inNum2',
                            key: 'inNum2',
                            width: 100
                        },
                        {
                            title: '下',
                            dataIndex: 'leaveNum2',
                            key: 'leaveNum2',
                            width: 100
                        }
                    ]
                },
                {
                    title: '3号门',
                    children: [
                        {
                            title: '上',
                            dataIndex: 'inNum3',
                            key: 'inNum3',
                            width: 100
                        },
                        {
                            title: '下',
                            dataIndex: 'leaveNum3',
                            key: 'leaveNum3',
                            width: 100
                        }
                    ]
                },
                {
                    title: '4号门',
                    children: [
                        {
                            title: '上',
                            dataIndex: 'inNum4',
                            key: 'inNum4',
                            width: 100
                        },
                        {
                            title: '下',
                            dataIndex: 'leaveNum4',
                            key: 'leaveNum4',
                            width: 100
                        }
                    ]
                },
                {
                    title: '合计上',
                    dataIndex: 'carTotalInNum',
                    key: 'carTotalInNum'
                },
                {
                    title: '合计下',
                    dataIndex: 'carTotalLeaveNum',
                    key: 'carTotalLeaveNum'
                }
            ],
            doorList: [
                { code: '0', value: '1号门' },
                { code: '1', value: '2号门' },
                { code: '2', value: '3号门' },
                { code: '3', value: '4号门' }
            ],
            levelList: [],
            sceneList: [],
            selectedRowKeys: [],
            selectedRows: [],
            form: {
                dealType: '',
                dealTypeName: '',
                dealDesc: ''
            },
            /** 区域类型 */
            areaTypeList: [
                {
                    code: '0',
                    value: '船舶'
                },
                {
                    code: '1',
                    value: '码头'
                }
            ],
            // areaTypeList: [],
            industryList: []
        }
    },
    activated() {
        if (this.$store.state.app.multiTab) {
            this.scrollHeight = `calc(100vh - 520px)`
        } else {
            this.scrollHeight = `calc(100vh - 370px) `
        }
    },
    mounted() {
        this.init()
    },
    methods: {
        init() {
            statisticsPassengerFlowInfo(this.queryParam).then(res => {
                this.datalist = res
                this.datalist.forEach((v, i) => {
                    v.carPassengerFlowInfo.forEach((item, index) => {
                        v['inNum' + parseInt(item.doorNumber)] = item.inNum
                        v['leaveNum' + parseInt(item.doorNumber)] = item.leaveNum
                    })
                })
            })
            // this.datalist = [
            //     {
            //         carNumber: '苏J06601D',
            //         carPassengerFlowInfo: [
            //             {
            //                 doorNumber: '1号门',
            //                 leaveNum: 1,
            //                 inNum: 1
            //             }
            //         ],
            //         carTotalInNum: 1,
            //         carTotalLeaveNum: 1
            //     },
            //     {
            //         carNumber: '苏J06601D',
            //         carPassengerFlowInfo: [
            //             {
            //                 doorNumber: '1号门',
            //                 leaveNum: 1,
            //                 inNum: 1
            //             }
            //         ],
            //         carTotalInNum: 1,
            //         carTotalLeaveNum: 1
            //     },
            //     {
            //         carNumber: '苏J06601D',
            //         carPassengerFlowInfo: [
            //             {
            //                 doorNumber: '1号门',
            //                 leaveNum: 1,
            //                 inNum: 1
            //             }
            //         ],
            //         carTotalInNum: 1,
            //         carTotalLeaveNum: 1
            //     },
            //     {
            //         carNumber: '苏J06601D',
            //         carPassengerFlowInfo: [
            //             {
            //                 doorNumber: '1号门',
            //                 leaveNum: 1,
            //                 inNum: 1
            //             }
            //         ],
            //         carTotalInNum: 1,
            //         carTotalLeaveNum: 1
            //     },
            //     {
            //         carNumber: '苏J06601D',
            //         carPassengerFlowInfo: [
            //             {
            //                 doorNumber: '1号门',
            //                 leaveNum: 1,
            //                 inNum: 1
            //             }
            //         ],
            //         carTotalInNum: 1,
            //         carTotalLeaveNum: 1
            //     },
            //     {
            //         carNumber: '苏J06601D',
            //         carPassengerFlowInfo: [
            //             {
            //                 doorNumber: '1号门',
            //                 leaveNum: 1,
            //                 inNum: 1
            //             }
            //         ],
            //         carTotalInNum: 1,
            //         carTotalLeaveNum: 1
            //     },
            //     {
            //         carNumber: '苏J06601D',
            //         carPassengerFlowInfo: [
            //             {
            //                 doorNumber: '1号门',
            //                 leaveNum: 1,
            //                 inNum: 1
            //             }
            //         ],
            //         carTotalInNum: 1,
            //         carTotalLeaveNum: 1
            //     },
            //     {
            //         carNumber: '苏J06601D',
            //         carPassengerFlowInfo: [
            //             {
            //                 doorNumber: '1号门',
            //                 leaveNum: 1,
            //                 inNum: 1
            //             }
            //         ],
            //         carTotalInNum: 1,
            //         carTotalLeaveNum: 1
            //     },
            //     {
            //         carNumber: '苏J06601D',
            //         carPassengerFlowInfo: [
            //             {
            //                 doorNumber: '1号门',
            //                 leaveNum: 1,
            //                 inNum: 1
            //             }
            //         ],
            //         carTotalInNum: 1,
            //         carTotalLeaveNum: 1
            //     },
            //     {
            //         carNumber: '苏J06601D',
            //         carPassengerFlowInfo: [
            //             {
            //                 doorNumber: '1号门',
            //                 leaveNum: 1,
            //                 inNum: 1
            //             }
            //         ],
            //         carTotalInNum: 1,
            //         carTotalLeaveNum: 1
            //     },
            //     {
            //         carNumber: '苏J06601D',
            //         carPassengerFlowInfo: [
            //             {
            //                 doorNumber: '1号门',
            //                 leaveNum: 1,
            //                 inNum: 1
            //             }
            //         ],
            //         carTotalInNum: 1,
            //         carTotalLeaveNum: 1
            //     },
            //     {
            //         carNumber: '苏J06601D',
            //         carPassengerFlowInfo: [
            //             {
            //                 doorNumber: '1号门',
            //                 leaveNum: 1,
            //                 inNum: 1
            //             }
            //         ],
            //         carTotalInNum: 1,
            //         carTotalLeaveNum: 1
            //     },
            //     {
            //         carNumber: '苏J06601D',
            //         carPassengerFlowInfo: [
            //             {
            //                 doorNumber: '1号门',
            //                 leaveNum: 1,
            //                 inNum: 1
            //             }
            //         ],
            //         carTotalInNum: 1,
            //         carTotalLeaveNum: 1
            //     },
            //     {
            //         carNumber: '苏J06601D',
            //         carPassengerFlowInfo: [
            //             {
            //                 doorNumber: '1号门',
            //                 leaveNum: 1,
            //                 inNum: 1
            //             }
            //         ],
            //         carTotalInNum: 1,
            //         carTotalLeaveNum: 1
            //     },
            //     {
            //         carNumber: '苏J06601D',
            //         carPassengerFlowInfo: [
            //             {
            //                 doorNumber: '1号门',
            //                 leaveNum: 1,
            //                 inNum: 1
            //             }
            //         ],
            //         carTotalInNum: 1,
            //         carTotalLeaveNum: 1
            //     },
            //     {
            //         carNumber: '苏J06601D',
            //         carPassengerFlowInfo: [
            //             {
            //                 doorNumber: '1号门',
            //                 leaveNum: 1,
            //                 inNum: 1
            //             }
            //         ],
            //         carTotalInNum: 1,
            //         carTotalLeaveNum: 1
            //     },
            //     {
            //         carNumber: '苏J06601D',
            //         carPassengerFlowInfo: [
            //             {
            //                 doorNumber: '1号门',
            //                 leaveNum: 1,
            //                 inNum: 1
            //             }
            //         ],
            //         carTotalInNum: 1,
            //         carTotalLeaveNum: 1
            //     },
            //     {
            //         carNumber: '苏J06601D',
            //         carPassengerFlowInfo: [
            //             {
            //                 doorNumber: '1号门',
            //                 leaveNum: 1,
            //                 inNum: 1
            //             }
            //         ],
            //         carTotalInNum: 1,
            //         carTotalLeaveNum: 1
            //     },
            //     {
            //         carNumber: '苏J06601D',
            //         carPassengerFlowInfo: [
            //             {
            //                 doorNumber: '1号门',
            //                 leaveNum: 1,
            //                 inNum: 1
            //             }
            //         ],
            //         carTotalInNum: 1,
            //         carTotalLeaveNum: 1
            //     },
            //     {
            //         carNumber: '苏J06601D',
            //         carPassengerFlowInfo: [
            //             {
            //                 doorNumber: '1号门',
            //                 leaveNum: 1,
            //                 inNum: 1
            //             }
            //         ],
            //         carTotalInNum: 1,
            //         carTotalLeaveNum: 1
            //     },
            //     {
            //         carNumber: '苏J06601D',
            //         carPassengerFlowInfo: [
            //             {
            //                 doorNumber: '1号门',
            //                 leaveNum: 1,
            //                 inNum: 1
            //             }
            //         ],
            //         carTotalInNum: 1,
            //         carTotalLeaveNum: 1
            //     },
            //     {
            //         carNumber: '苏J06601D',
            //         carPassengerFlowInfo: [
            //             {
            //                 doorNumber: '1号门',
            //                 leaveNum: 1,
            //                 inNum: 1
            //             }
            //         ],
            //         carTotalInNum: 1,
            //         carTotalLeaveNum: 1
            //     },
            //     {
            //         carNumber: '苏J06601D',
            //         carPassengerFlowInfo: [
            //             {
            //                 doorNumber: '1号门',
            //                 leaveNum: 1,
            //                 inNum: 1
            //             }
            //         ],
            //         carTotalInNum: 1,
            //         carTotalLeaveNum: 1
            //     },
            //     {
            //         carNumber: '苏J06601D',
            //         carPassengerFlowInfo: [
            //             {
            //                 doorNumber: '1号门',
            //                 leaveNum: 1,
            //                 inNum: 1
            //             }
            //         ],
            //         carTotalInNum: 1,
            //         carTotalLeaveNum: 1
            //     },
            //     {
            //         carNumber: '苏J06602D',
            //         carPassengerFlowInfo: [
            //             {
            //                 doorNumber: '1号门',
            //                 leaveNum: 1,
            //                 inNum: 0
            //             },
            //             {
            //                 doorNumber: '2号门',
            //                 leaveNum: 1,
            //                 inNum: 1
            //             }
            //         ],
            //         carTotalInNum: 1,
            //         carTotalLeaveNum: 2
            //     }
            // ]
            // this.datalist.forEach((v, i) => {
            //     v.carPassengerFlowInfo.forEach((item, index) => {
            //         v['inNum' + index] = item.inNum
            //         v['leaveNum' + index] = item.leaveNum
            //     })
            // })
        },
        handleOk() {},
        resetForm() {
            this.queryDate = null
            console.log(this.$options.data())
            this.queryParam = this.$options.data().queryParam
            this.status_temp = this.$options.data().status_temp
        },
        onSelectChange(selectedRowKeys, selectedRows) {
            this.selectedRowKeys = selectedRowKeys
        },
        getCheckboxProps(record) {
            return {
                props: { disabled: record.isDeal == 2 || record.confirmStatus == 2 } // Column configuration not to be checked
            }
        },
        dateChange(date, dateString) {
            this.queryParam.searchStartTime = dateString[0]
            this.queryParam.searchEndTime = dateString[1]
        },
        /**跳转详情 */
        toDetail(id) {
            this.$store.commit('updateList', this.$refs.table.localDataSource)
            this.$router.push('/shipAlarm/detail?id=' + id)
        },
        /** 删除 */
        toDelete(id) {
            let ids
            if (id) {
                ids = id
            } else {
                // 批量删除id拼接
                ids = this.selectedRowKeys.join(',')
            }
            alarmRemove({
                ids: ids
            })
                .then(res => {
                    this.$message.success('删除成功')
                    this.$refs.table.refresh()
                })
                .catch(error => {
                    this.$refs.table.refresh()
                })
        },
        /** 导出 */
        handleAlarmExport() {
            alarmExport({
                ...this.queryParam,
                size: -1
            })
        }
    }
}
</script>

<style lang="less" scoped>
.alarmTypeName {
    border-radius: 20px;
    padding: 2px 8px;
    width: fit-content;
    background: rgba(250, 152, 164, 0.996078431372549);
    color: #fff;
    font-size: 12px;
}
/deep/ .ant-card {
    height: 100%;
}
/deep/ .ant-card-body {
    height: 100%;
}
</style>
