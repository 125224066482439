<!--
 * @Autor: dingxiaolin
 * @Date: 2022-09-19 16:57:31
 * @LastEditors: dingxiaolin
 * @LastEditTime: 2022-09-20 10:00:17
-->
<template>
    <div id="map"></div>
</template>

<script>
import Vue from 'vue'
import { alarmUndisposedImportanceStatistics } from '@/api/companybord/index'
import alertInfo from './AlertInfo/index.vue'

export default {
    name: 'map',
    components: { alertInfo },
    computed: {},
    data() {
        return {
            data: [],
            timer: null,
            /**信息窗体 */
            infoWindow: new AMap.InfoWindow({
                isCustom: false,
                offset: new AMap.Pixel(10, -30)
            }),
            /**覆盖物群组 */
            overlayGroup: new AMap.OverlayGroup()
        }
    },
    mounted() {
        this.initMap()
    },
    beforeDestroy() {
        /** 页面销毁前清除定时器 */
        this.clearTimer()
    },
    methods: {
        /** 获取基础数据 */
        getData(init = true) {
            this.clearTimer()
            var shipIcon = new AMap.Icon({
                size: new AMap.Size(40, 40),
                image: require('@/assets/gj.png'),
                imageSize: new AMap.Size(40, 40)
            })
            alarmUndisposedImportanceStatistics({
                size: '5', //每页数量
                current: '1', //当前页
                descs: 'alarm_time' //排序字段
            }).then(res => {
                this.data = res.records
                if (this.data[0].relativeType === '0' && this.data[0].relativeBox) {
                    this.data[0].relativeBox = JSON.parse(this.data[0].relativeBox)
                }

                res.records.map(v => {
                    if (v['alarmAutoNaviLng']) {
                        var marker = new AMap.Marker({
                            position: [Number(v['alarmAutoNaviLng']), Number(v['alarmAutoNaviLat'])],
                            icon: shipIcon,
                            extData: v
                        })
                        this.overlayGroup.addOverlay(marker)
                        marker.on('mouseover', this.showInfoOver)
                        marker.on('click', this.toDetail)
                    }
                })
                this.overlayGroup.setMap(this.map)
                console.log('init', init)
                if (init) {
                    var MyComponent = Vue.extend(alertInfo)
                    this.infoWindow.setContent(new MyComponent({ propsData: { info: this.data[0], router: this.$router, that: this } }).$mount().$el)
                    this.infoWindow.open(this.map, [Number(this.data[0]['alarmAutoNaviLng']), Number(this.data[0]['alarmAutoNaviLat'])])
                    this.map.setFitView(this.overlayGroup.getOverlays())
                }
                this.timer = setTimeout(() => {
                    this.getData(false)
                }, 5000)
            })
        },
        /**信息窗体 */
        showInfoOver(e) {
            let data = e.target.getExtData()
            if (data.relativeType === '0' && !Array.isArray(data.relativeBox)) {
                data.relativeBox = JSON.parse(data.relativeBox)
            }
            var MyComponent = Vue.extend(alertInfo)
            this.infoWindow.setContent(new MyComponent({ propsData: { info: data, router: this.$router, that: this } }).$mount().$el)
            this.infoWindow.open(this.map, [Number(data['alarmAutoNaviLng']), Number(data['alarmAutoNaviLat'])])
        },
        clearTimer() {
            if (this.timer) {
                clearTimeout(this.timer)
            }
        },
        toDetail(e) {
            // this.$store.commit('updateList', this.data)
            this.$router.push('/shipAlarm/detail?id=' + e.target.getExtData().id)
        },
        initMap() {
            this.$nextTick(() => {
                let options = {
                    resizeEnable: true,
                    zooms: [3, 15],
                    mapStyle: 'amap://styles/2863bbf9ce83636345bebf8ecd1515d8' //设置地图的显示样式
                }
                this.map = new AMap.Map('map', options)
                this.getData()
            })
        }
    }
}
</script>

<style lang="less" scoped>
#map {
    width: 100%;
    height: 100%;
    /deep/ .infoTitle {
        width: 100%;
        font-size: 13px;
        color: #fff;
        background: #1890ff;
        text-align: center;
        padding: 5px;
    }
    /deep/ .infoContent {
        width: 300px;
        padding: 5px 10px;
    }
    /deep/ .amap-info-content {
        background: rgba(0, 37, 239, 0.3);
        border: 1px solid #0025ef;
        box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.2);
    }
    /deep/ .info {
        padding: 20px;
        display: flex;
    }
    /deep/ .bottom-center .amap-info-sharp {
        border-top: 8px solid #0025ef;
    }
}
</style>
