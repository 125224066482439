<template>
    <div>
        <a-card :bordered="false">
            <div class="table-page-search-wrapper">
                <a-form-model :label-col="labelCol" :wrapper-col="wrapperCol">
                    <a-row :gutter="48">
                        <a-col :md="6" :sm="24">
                            <a-form-model-item label="机构">
                                <DeptTree v-model="queryParam.tenantId" :all="false" @change="onDeptChange" />
                            </a-form-model-item>
                        </a-col>
                        <a-col :md="6" :sm="24">
                            <a-form-model-item label="监控对象类型">
                                <a-select placeholder="请选择监控对象类型" v-model="queryParam.areaType" @change="areaTypeChange" allowClear>
                                    <a-select-option v-for="(item, index) in areaTypeList" :key="index" :value="item.code">{{
                                        item.value
                                    }}</a-select-option>
                                </a-select>
                            </a-form-model-item>
                        </a-col>
                        <a-col :md="6" :sm="24">
                            <a-form-model-item label="监控对象名称">
                                <a-select placeholder="请选择监控对象名称" v-model="queryParam.areaId" allowClear @change="areaDetailChange">
                                    <a-select-option v-for="(item, index) in areaDetailList" :key="index" :value="item.id">{{
                                        item[areaAttrName]
                                    }}</a-select-option>
                                </a-select>
                            </a-form-model-item>
                        </a-col>
                        <a-col :md="6" :sm="24">
                            <a-form-model-item label="时间">
                                <a-range-picker
                                    :show-time="{ defaultValue: [$moment('00:00:00', 'HH:mm:ss'), $moment('23:59:59', 'HH:mm:ss')] }"
                                    v-model="queryDate"
                                    format="YYYY-MM-DD"
                                    @change="dateChange"
                                />
                            </a-form-model-item>
                        </a-col>
                        <a-col :md="6" :sm="24" :offset="18" align="right">
                            <span class="table-page-search-submitButtons">
                                <a-button type="primary" @click="onQuery">查询</a-button>
                                <a-button style="margin-left: 8px" @click="() => (queryParam = $options.data().queryParam)">重置</a-button>
                            </span>
                        </a-col>
                    </a-row>
                </a-form-model>
            </div>
        </a-card>
        <div class="chart-container">
            <a-card>
                <div class="flex sb ac">
                    <div class="flex ac">
                        <div class="chart-title">告警统计</div>
                        <a-radio-group v-model="type" :options="typeOptions" @change="handleRadioChange" />
                        <a-select
                            v-if="type == 1"
                            mode="multiple"
                            style="width: 300px"
                            :max-tag-count="2"
                            placeholder="请选择类型"
                            :value="totalCountType"
                            @change="handleTypeChange"
                            :options="
                                alarmTypeLinkList.map(item => {
                                    return {
                                        value: item.alarmTypeCode,
                                        label: item.alarmTypeName,
                                        key: item.alarmTypeCode,
                                        title: item.alarmTypeName
                                    }
                                })
                            "
                        >
                        </a-select>
                    </div>
                    <div class="flex ac">
                        <a-radio-group
                            v-model="totalTimeType"
                            button-style="solid"
                            v-for="item in timeOptions"
                            :key="item.value"
                            @change="totalTimeTypeChange"
                        >
                            <a-radio-button :value="item.value">{{ item.label }}</a-radio-button>
                        </a-radio-group>
                        <div class="chart-download">
                            <a-icon type="download" style="color: #1890ff"></a-icon>
                            <span class="chart-link" @click="exportAlarmDataStatistic"> 导出到excel </span>
                        </div>
                    </div>
                </div>
                <chart :data="alarmTotal" ref="alarmTotal" :legend="alarmTotalLegend" />
            </a-card>
            <a-card>
                <div class="flex sb ac">
                    <div class="flex ac">
                        <div class="chart-title">告警处理统计</div>
                    </div>
                    <div class="flex ac">
                        <a-radio-group
                            v-model="dealTimeType"
                            button-style="solid"
                            v-for="item in timeOptions"
                            :key="item.value"
                            @change="totalTimeTypeChange"
                        >
                            <a-radio-button :value="item.value">{{ item.label }}</a-radio-button>
                        </a-radio-group>
                        <div class="chart-download">
                            <a-icon type="download" style="color: #1890ff"></a-icon>
                            <span class="chart-link" @click="exportAlarmStatisticsInfo"> 导出到excel </span>
                        </div>
                    </div>
                </div>
                <chart
                    :data="feedBackTotal"
                    title="处理反馈统计"
                    ref="feedBackTotal"
                    :legend="['不安全行为处理的次数', '结果反馈次数', '误报警次数']"
                />
                <chart :data="closeLoop" ref="closeLoop" title="处理闭环率及误报警率（%）" :legend="['处理闭环率', '误报警率']" :percent="true" />
            </a-card>
            <a-card>
                <div class="flex sb ac">
                    <div class="flex ac">
                        <div class="chart-title">监控统计</div>
                    </div>
                    <div class="flex ac">
                        <a-radio-group
                            v-model="watchTimeType"
                            button-style="solid"
                            v-for="item in timeOptions"
                            :key="item.value"
                            @change="totalTimeTypeChange"
                        >
                            <a-radio-button :value="item.value">{{ item.label }}</a-radio-button>
                        </a-radio-group>
                        <div class="chart-download">
                            <a-icon type="download" style="color: #1890ff"></a-icon>
                            <span class="chart-link" @click="exportMonitoringStatisticsInfo"> 导出到excel </span>
                        </div>
                    </div>
                </div>
                <chart :data="watchOnline" title="在线/关闭时长" ref="watchOnline" :legend="['在线时长', '关闭时长']" />
                <chart :data="watchCloseCountData" title="关闭次数" ref="watchCloseCountData" :legend="['关闭次数']" />
                <chart :data="watchOffline" title="设备掉线率" ref="watchOffline" :legend="['离线率']" :percent="true" />
            </a-card>
        </div>
    </div>
</template>

<script>
import {
    shipInfoList,
    warfInfoList,
    getAlarmDataStatistic,
    getProcessFeedbackStatistics,
    getClosedLoopRateAndFalseAlarmRate,
    getMonitoringStatistics,
    exportAlarmDataStatistic,
    exportAlarmStatisticsInfo,
    exportMonitoringStatisticsInfo,
    getAreaInfoList
} from '@/api/alarm/index'
import { DeptTree } from '@/components'
import Chart from './chart.vue'
import { alarmTypeLinkList } from '@/api/alarm/index'
import { sysDictTypeDropDown } from '@/api/comm/index'
export default {
    components: {
        DeptTree,
        Chart
    },
    data() {
        return {
            areaDetailList: [],
            areaTypeList: [],
            /** 动态变化的区域名称值 */
            areaAttrName: '',
            labelCol: {
                sm: { span: 6 },
                xs: { span: 24 }
            },
            wrapperCol: {
                sm: { span: 18 },
                xs: { span: 24 }
            },
            type: 2,
            typeOptions: [
                {
                    label: '分等级统计',
                    value: 2
                },
                {
                    label: '分类型统计',
                    value: 1
                }
            ],
            /** 告警统计时间类型 */
            totalTimeType: 1,
            totalCountType: [],
            /** 告警处理时间类型 */
            dealTimeType: 1,
            /** 监控统计时间类型 */
            watchTimeType: 1,
            timeOptions: [
                {
                    label: '天',
                    value: 1
                },
                {
                    label: '周',
                    value: 5
                },
                {
                    label: '月',
                    value: 2
                },

                {
                    label: '季度',
                    value: 6
                },
                {
                    label: '年度',
                    value: 7
                }
            ],
            shipList: [],
            warfList: [],
            // 查询参数
            queryParam: {
                /**告警地点船舶 */
                shipId: undefined,
                /**告警地点码头 */
                warfId: undefined,
                /**所属机构 */
                tenantId: '',
                timeStart: '',
                timeEnd: '',
                descs: 'create_time'
            },
            queryDate: null,
            dealTotal: [],
            alarmTypeLinkList: [],
            /**  告警统计*/
            alarmTotal: null,
            /** 告警统计图例 */
            alarmTotalLegend: ['全部', '一级预警', '二级预警', '三级预警', '四级预警'],
            /** 告警类型统计图例 */
            alarmTotalTypeLegend: [],
            /** 告警处理统计  -- 处理反馈统计 */
            feedBackTotal: null,
            /** 告警处理统计 --- 处理闭环率及误报警率*/
            closeLoop: null,
            /** 监控---在线/关闭时长 */
            watchOnline: null,
            /** 关闭次数 */
            watchCloseCountData: null,
            /** 掉线率 */
            watchOffline: null
        }
    },

    mounted() {
        this.getDict()
        this.onQuery()
    },

    methods: {
        /** 区域类型下拉 */
        areaTypeChange() {
            this.setAreaList()
            this.queryParam.areaId = ''
            this.queryParam.areaName = ''
        },
        /**码头下拉 */
        areaDetailChange() {
            if (this.queryParam.areaId) {
                let areaType = Number(this.queryParam.areaType)
                switch (areaType) {
                    case 0:
                        // 船舶
                        this.queryParam.areaName = this.shipList.filter(v => v.id == this.queryParam.areaId)[0].shipName
                        break
                    case 1:
                        // 码头
                        this.queryParam.areaName = this.warfList.filter(v => v.id == this.queryParam.areaId)[0].warfName
                        break
                    case 2:
                        // 区域
                        this.queryParam.areaName = this.areaList.filter(v => v.id == this.queryParam.areaId)[0].areaName
                        break
                    default:
                        break
                }
            } else {
                this.queryParam.areaName = ''
            }
            this.$forceUpdate() // fix #2271  a-select回显后，不能选择
        },
        /**级联下拉数据及初始化默认数据 */
        initRelation(tenantId) {
            let params = {
                descs: 'create_time',
                current: 0,
                size: -1,
                tenantId_equal: tenantId,
                tenantSearchType: 1
            }

            return Promise.all([shipInfoList(params), warfInfoList(params), getAreaInfoList(params)]).then(res => {
                this.shipList = tenantId ? res[0].records : []
                this.warfList = tenantId ? res[1].records : []
                this.areaList = tenantId ? res[2].records : []
            })
        },
        /** 设置监控对象名称 */
        setAreaList() {
            let areaType = Number(this.queryParam.areaType)
            switch (areaType) {
                case 0:
                    // 船舶
                    this.areaDetailList = this.shipList
                    this.areaAttrName = 'shipName'
                    break
                case 1:
                    // 码头
                    this.areaDetailList = this.warfList
                    this.areaAttrName = 'warfName'
                    break
                case 2:
                    // 区域
                    this.areaDetailList = this.areaList
                    this.areaAttrName = 'areaName'
                    break
                default:
                    break
            }
        },
        /** 告警统计radio切换 */
        handleRadioChange({ target: { value } }) {
            if (this.totalCountType.length == 0 && value == 1) {
                return
            }
            this.onQuery()
        },
        totalTimeTypeChange() {
            this.onQuery()
        },
        onQuery() {
            this.getAlarmTotal()
            this.getProcessFeedbackStatistics()
            this.getClosedLoopRateAndFalseAlarmRate()
            this.getMonitoringStatistics()
        },
        dateChange(date, dateString) {
            this.queryParam.timeStart = dateString[0]
            this.queryParam.timeEnd = dateString[1]
        },
        getDict() {
            sysDictTypeDropDown({ code: 'areaType' }).then(res => {
                this.areaTypeList = res
            })
            alarmTypeLinkList({ alarmThirdTag: '0' }).then(res => {
                this.alarmTypeLinkList = res.records
            })
        },
        /** 部门修改 */
        async onDeptChange(options) {
            this.shipList = []
            this.warfList = []
            this.queryParam.shipId = undefined
            this.queryParam.warfId = undefined
            if (!options.value) {
                return
            }
            await this.initRelation(options.value)
            this.setAreaList() // 切换机构时重新加载监控对象类型和名称
            // shipInfoList({
            //     descs: 'create_time',
            //     current: 0,
            //     size: -1,
            //     tenantId_equal: options.value,
            //     tenantSearchType: 1
            // }).then(res => {
            //     this.shipList = options.value ? res.records : []
            // })
            // warfInfoList({
            //     descs: 'create_time',
            //     current: 0,
            //     size: -1,
            //     tenantId_equal: options.value,
            //     tenantSearchType: 1
            // }).then(res => {
            //     this.warfList = options.value ? res.records : []
            // })
        },

        /** 分类型统计变化事件 */
        handleTypeChange(value, option) {
            if (option.length > 10) {
                this.$message.warning('告警类型不能超过10个')
                return
            }
            this.alarmTotalTypeLegend = []
            this.alarmTotalTypeLegend = option.map(item => item.data.props.title)
            this.alarmTotalTypeLegend.unshift('全部')
            this.totalCountType = option.map(item => item.data.props.value)
            this.onQuery()
        },
        /** 告警统计 */
        getAlarmTotal() {
            getAlarmDataStatistic({
                ...this.queryParam,
                statisticsType: this.totalTimeType,
                alarmStatisticsType: this.type,
                alarmTypeCodes: this.totalCountType.toString()
            }).then(res => {
                // // 在线关闭时长
                if (this.type == 1) {
                    this.alarmTotalLegend = this.alarmTotalTypeLegend
                } else {
                    this.alarmTotalLegend = ['全部', '一级预警', '二级预警', '三级预警', '四级预警']
                }
                this.alarmTotal = this.splitAlarmTotalData(res)
                console.log(this.alarmTotal, 'alarmTotal')
                setTimeout(() => {
                    this.$refs.alarmTotal.setOptions()
                }, 10)
            })
        },

        /** 获取处理反馈统计 */
        getProcessFeedbackStatistics() {
            getProcessFeedbackStatistics({
                ...this.queryParam,
                statisticsType: this.dealTimeType
            }).then(res => {
                this.feedBackTotal = this.splitWatchData(res, 'dateTime', ['unsafeCount', 'feedBackCount', 'misReportCount'])
                setTimeout(() => {
                    this.$refs.feedBackTotal.setOptions()
                }, 10)
            })
        },

        /** 获取处理闭环率和误报警率 */
        getClosedLoopRateAndFalseAlarmRate() {
            getClosedLoopRateAndFalseAlarmRate({
                ...this.queryParam,
                statisticsType: this.dealTimeType
            }).then(res => {
                this.closeLoop = this.splitWatchData(res, 'dateTime', ['closedLoopRate', 'misReportRate'])
                console.log(this.closeLoop, 'this.closeLoop')
                setTimeout(() => {
                    this.$refs.closeLoop.setOptions()
                }, 10)
            })
        },
        /** 统计监控  三合一*/
        getMonitoringStatistics() {
            getMonitoringStatistics({
                ...this.queryParam,
                statisticsType: this.watchTimeType
            }).then(res => {
                // 在线关闭时长
                this.watchOnline = this.splitWatchData(res, 'dateTime', ['onlineTime', 'leaveTime'])
                // 关闭次数
                this.watchCloseCountData = this.splitWatchData(res, 'dateTime', ['leaveCount'])
                // 离线率
                this.watchOffline = this.splitWatchData(res, 'dateTime', ['dropRate'])
                setTimeout(() => {
                    this.$refs.watchOnline.setOptions()
                    this.$refs.watchCloseCountData.setOptions()
                    this.$refs.watchOffline.setOptions()
                }, 10)
            })
        },
        /** 监控统计 数据分割 */
        splitWatchData(data, xAxisName, seriesKeys) {
            var categoryData = []
            var values = []
            for (let index = 0; index < data.length; index++) {
                categoryData.push(data[index][xAxisName])
                values.push(seriesKeys.map(item => data[index][item]))
            }
            return {
                categoryData: categoryData,
                values: values
            }
        },
        /** 监控统计 数据分割 */
        splitAlarmTotalData(data) {
            var categoryData = []
            var values = []
            for (let index = 0; index < data.length; index++) {
                categoryData.push(data[index].slice(1, 2)) // 0,1
                values.push(data[index].slice(2)) // 1
            }
            return {
                categoryData: categoryData,
                values: values
            }
        },

        /** 告警统计导出 */
        exportAlarmDataStatistic() {
            exportAlarmDataStatistic({
                ...this.queryParam,
                statisticsType: this.totalTimeType,
                alarmStatisticsType: this.type,
                alarmTypeCodes: this.totalCountType.toString()
            })
        },
        /** 告警处理统计导出 */
        exportAlarmStatisticsInfo() {
            exportAlarmStatisticsInfo({
                ...this.queryParam,
                statisticsType: this.dealTimeType
            })
        },
        /** 监控统计导出 */
        exportMonitoringStatisticsInfo() {
            exportMonitoringStatisticsInfo({
                ...this.queryParam,
                statisticsType: this.watchTimeType
            })
        }
    }
}
</script>

<style lang="less" scoped>
.chart-container {
    overflow: auto;
    height: calc(100vh - 254px);
}
.chart-link {
    cursor: pointer;
    text-decoration: underline;
    color: @primary-color;
}
.chart-title {
    font-size: 18px;
    font-weight: 600;
    padding-right: 10px;
}
.chart-download {
    padding-left: 10px;
}
</style>
