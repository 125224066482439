<template>
    <a-layout-header
        v-if="visible"
        :class="['ant-header-fixedHeader', sidebarOpened ? 'ant-header-side-opened' : 'ant-header-side-closed']"
        :style="{ padding: '0' }"
    >
        <div class="header">
            <a-col :md="24" :sm="24" :class="theme === 'dark' ? 'msyBgDark' : 'msyBgLight'">
                <a-icon
                    class="trigger"
                    :type="collapsed ? 'menu-unfold' : 'menu-fold'"
                    @click="toggle"
                    :class="theme === 'dark' ? 'light' : 'dark'"
                />
                <user-menu :theme="theme"></user-menu>
            </a-col>
        </div>
    </a-layout-header>
</template>

<script>
import UserMenu from '../tools/UserMenu'
import SMenu from '../Menu/'
import Logo from '../tools/Logo'
import { mixin } from '@/utils/mixin'
import { mapActions, mapState } from 'vuex'
import { ALL_APPS_MENU } from '@/store/mutation-types'
import Vue from 'vue'
import { message } from 'ant-design-vue/es'

export default {
    name: 'GlobalHeader',
    components: {
        UserMenu,
        SMenu,
        Logo
    },
    computed: {
        ...mapState({
            userInfo: (state) => state.user.info
        })
    },
    created() {
        this.defApp.push(Vue.ls.get(ALL_APPS_MENU) ? Vue.ls.get(ALL_APPS_MENU)[0].code : '')
    },
    mixins: [mixin],
    props: {
        menus: {
            type: Array,
            required: true
        },
        theme: {
            type: String,
            required: false,
            default: 'dark'
        },
        collapsed: {
            type: Boolean,
            required: false,
            default: false
        },
        device: {
            type: String,
            required: false,
            default: 'desktop'
        }
    },
    data() {
        return {
            visible: true,
            oldScrollTop: 0,
            defApp: [],
            isTrue: true
        }
    },
    watch: {
        appsmenu(val) {
            if (val.length < 1) {
                return false
            }
            let code = val[0].code
            this.$set(this.defApp, 0, code)
            console.log('GlobalHeader,defApp', this.defApp, val)
        }
    },
    mounted() {
        document.addEventListener('scroll', this.handleScroll, { passive: true })
    },
    methods: {
        ...mapActions(['MenuChange', 'getUserInfo']),

        /**
         * 应用切换
         */
        switchApp(appCode) {
            this.defApp = []
            const applicationData = this.userInfo.apps.filter((item) => item.code === appCode)
            // const hideMessage = message.loading('正在切换应用！', 0)
            this.MenuChange(applicationData[0])
                .then((res) => {
                    // hideMessage()
                    // eslint-disable-next-line handle-callback-err
                })
                .catch((err) => {
                    message.error('应用切换异常')
                })
        },
        handleScroll() {
            if (!this.autoHideHeader) {
                return
            }

            const scrollTop = document.body.scrollTop + document.documentElement.scrollTop
            if (!this.ticking) {
                this.ticking = true
                requestAnimationFrame(() => {
                    if (this.oldScrollTop > scrollTop) {
                        this.visible = true
                    } else if (scrollTop > 300 && this.visible) {
                        this.visible = false
                    } else if (scrollTop < 300 && !this.visible) {
                        this.visible = true
                    }
                    this.oldScrollTop = scrollTop
                    this.ticking = false
                })
            }
        },
        toggle() {
            this.$emit('toggle')
        }
    },
    beforeDestroy() {
        document.body.removeEventListener('scroll', this.handleScroll, true)
    }
}
</script>
<style lang="less" >
.msyMenuLight {
    .ant-menu-item-selected {
        color: #ffffff !important;
    }
}
.msyMenuDark {
    .ant-menu-item-selected {
        color: #1890ff !important;
    }
}
.msyBgLight {
    background: #ffffff;
}
.msyBgDark {
    background: #001529;
}
.layout.ant-layout .trigger.light {
    color: #ffffff;
}
.layout.ant-layout .trigger.dark {
    color: #001529;
}
.showHeader-enter-active {
    transition: all 0.25s ease;
}
.showHeader-leave-active {
    transition: all 0.5s ease;
}
.showHeader-enter,
.showHeader-leave-to {
    opacity: 0;
}
</style>
