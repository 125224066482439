<template>
    <a-card :bordered="false">
        <div class="table-page-search-wrapper">
            <a-form layout="inline">
                <a-row :gutter="48">
                    <a-col :md="6" :sm="24">
                        <a-form-item label="固件版本">
                            <a-input v-model="queryParam.firmwareVersion_like" allow-clear placeholder="请输入固件版本" />
                        </a-form-item>
                    </a-col>
                    <a-col :md="6" :sm="24">
                        <a-form-item label="上传时间">
                            <a-range-picker
                                :show-time="{ defaultValue: [$moment('00:00:00', 'HH:mm:ss'), $moment('23:59:59', 'HH:mm:ss')] }"
                                format="YYYY-MM-DD HH:mm:ss"
                                v-model="queryDate"
                                @change="dateChange"
                            />
                        </a-form-item>
                    </a-col>
                    <a-col :md="6" :sm="24" align="right" :offset="6">
                        <span class="table-page-search-submitButtons">
                            <a-button type="primary" @click="search">查询</a-button>
                            <a-button
                                style="margin-left: 8px"
                                @click="
                                    () => {
                                        queryParam = {}
                                        queryDate = null
                                    }
                                "
                                >重置</a-button
                            >
                        </span>
                    </a-col>
                </a-row>
            </a-form>
        </div>
        <div class="table-operator">
            <a-button type="primary" @click="$refs.editForm.show()">上传固件</a-button>
        </div>
        <n-table ref="table" :scrollX="1650" :columns="columns" :data="loadData" :rowKey="record => record.id" :scrollToFirstRowOnChange="true">
            <span slot="firmwareSize" slot-scope="text">
                {{ readFileSize(text) }}
            </span>
            <span slot="action" slot-scope="text, record">
                <a-popconfirm placement="topRight" title="你确定要删除该固件吗？" @confirm="() => del(record)">
                    <a class="red">删除</a>
                </a-popconfirm>
                <a-divider type="vertical" />
                <a @click="download(record.firmwareUrl)">下载</a>
            </span>
        </n-table>
        <edit-form ref="editForm" @ok="handleOk" :sexList="sexList" />
    </a-card>
</template>

<script>
import { NTable } from '@/components'
import { firmwareInfoList, removefirmwareInfo } from '@/api/alarm/index'
import editForm from './editForm'
import { readFileSize } from '@/utils/util'

export default {
    components: {
        NTable,
        editForm
    },

    data() {
        return {
            labelCol: {
                sm: { span: 8 },
                xs: { span: 24 }
            },
            wrapperCol: {
                sm: { span: 16 },
                xs: { span: 24 }
            },
            // 查询参数
            queryParam: {
                /**固件版本 */
                firmwareVersion_like: '',
                /**创建开始时间 yyyy-MM-dd HH:mm:ss */
                createTime_ge: '',
                /**创建结束时间 yyyy-MM-dd HH:mm:ss */
                createTime_le: '',
                descs: 'firmware_version' //以前是'create_time'
            },
            // 表头
            columns: [
                {
                    title: '固件版本',
                    dataIndex: 'firmwareVersion',
                    width: '150px',
                    ellipsis: true
                },
                {
                    title: '文件名',
                    dataIndex: 'firmwareName',
                    width: '150px',
                    ellipsis: true
                },
                {
                    title: '文件地址',
                    dataIndex: 'firmwareUrl',
                    width: '600px',
                    ellipsis: true
                },
                {
                    title: '固件大小',
                    dataIndex: 'firmwareSize',
                    scopedSlots: { customRender: 'firmwareSize' },
                    width: '150px',
                    ellipsis: true
                },
                {
                    title: '固件描述',
                    dataIndex: 'firmwareDesc',
                    ellipsis: true
                },
                {
                    title: '上传时间',
                    dataIndex: 'uploadTime',
                    width: '150px',
                    ellipsis: true
                },
                {
                    title: '操作',
                    dataIndex: 'action',
                    width: '150px',
                    scopedSlots: { customRender: 'action' }
                }
            ],
            // 加载数据方法 必须为 Promise 对象
            loadData: async parameter => {
                return await firmwareInfoList(Object.assign(parameter, this.queryParam))
            },
            /**日期数组 */
            queryDate: null,
            /**性别枚举 */
            sexList: [],
            /**适任证书状态枚举 */
            certStateList: []
        }
    },

    mounted() {},

    methods: {
        readFileSize: readFileSize,
        search() {
            this.$refs.table.refresh(true)
        },
        handleOk() {
            this.$refs.table.refresh()
        },
        dateChange(v, string) {
            this.queryParam.createTime_ge = string[0]
            this.queryParam.createTime_le = string[1]
        },
        download(url) {
            url = url.slice(0, 4) + 's' + url.slice(4)
            console.log(url, 'downloadurl')
            window.open(url, '_blank')
        },
        /**删除 */
        del(record) {
            removefirmwareInfo({ ids: record.id }).then(res => {
                this.$message.success('删除固件成功')
                this.$refs.table.refresh()
                record.show = false
            })
        }
    }
}
</script>

<style lang="less" scoped></style>
