<template>
  <div class="clothes">
    <div class="table-operator flex sb ac">
      <a-button type="primary"  @click="add()">添加类别</a-button>
      <div class="btn-tb">
          <!-- 最新同步时间：{{ configTime }} -->
          <a-button class="btn-add" type="default" @click="sync_suit"> {{ syncBtnText }} </a-button>
      </div>
    </div>
    <a-collapse :loading="isLoading">
      <a-collapse-panel  :header="item.suitGroupName" v-for="(item,index) in cloLists" :key="index">
        <div class="suitBox">
          <span v-for="(suit,index) in item.aiDeviceSuitInfoList" :key="index" >
            <a-popconfirm
              title="确定删除吗？"
              ok-text="确认"
              cancel-text="取消"
              @confirm="confirmDel(suit.id)"
            > 
            <!-- {{suit.pic}} -->
            <img :src="handlePic(suit.pic)" /> 
            </a-popconfirm>       
          </span>
        </div>
        <a-upload
            action=""
            list-type="text"
            :multiple="false"
            :customRequest="customRequest"
            :file-list="fileList"
            accept=".jpeg,.jpg"
            @preview="handlePreview"
            @change="uploadChange"
            v-model="fileUrl"
        >
            <a-button @click="openUpload(item)"> <a-icon type="upload" /> 上传底图 </a-button>
        </a-upload>
      </a-collapse-panel>
    </a-collapse>
    <!-- <img :src="testPic" /> -->
     <a-modal
        title="添加类别"
        centered
        :width="500"
        :visible="visible"
        :confirmLoading="confirmLoading"
        @ok="addType"
        @cancel="visible = false"
        destroyOnClose
    >
    <a-input v-model="suitName" :maxLength="200" allow-clear placeholder="请输入工服类别名称" ></a-input>
    </a-modal>
  </div>
</template>

<script>
import { getSuitGroupList, addSuitGroup, synSuit, aiDeviceTasklist, addSuitInfo, removeSuitInfo} from '@/api/alarm';
import { dataURLtoFile ,fileToUrl} from '@/utils/util';

export default {
  data() {
        return {
          testPic:'',
          isLoading:false,
          configTime:'',
          suitGroupId:'', // 类别id
          loading:false,
          syncBtnText:'同步',
          fileList: [],
          fileUrl:'',
          suitName:'',
          visible:false,
          confirmLoading:false,
          activeKey: [],
          cloLists:[],
        }
  },
  mounted(){
    this.suitList()
  },
  methods:{
    handlePic(data){
      let url = fileToUrl('data:image/png;base64,' + data)
      return url
      // this.testPic = url
      // this.file = dataURLtoFile(data); // 得到File对象
      // console.log("thisfile", this.file);
      // this.testPic = window.webkitURL.createObjectURL(this.file) || window.URL.createObjectURL(this.file)
     
    },
    getRealTaskStatus(fn) {
        return aiDeviceTasklist({
            current: -1,
            size: -1,
            deviceTag_like: this.deviceTag,
            taskStatus_equal: 0
        }).then(({ records }) => {
            if (records.length > 0) {
                this.$message.error('当前有任务在执行')
                return
            }
            fn && fn()
        })
    },
    /** 同步 */
    sync_suit() {
        this.getRealTaskStatus(() => {
            synSuit({
                deviceId: this.$route.query.id
            })
            this.syncBtnText = '同步中...'
            // this.configTime = this.$moment().format('YYYY-MM-DD HH:mm:ss')
            this.cancelTimer()
            this.setError()
        })
    },
    setSync(){
      this.syncBtnText = '同步'
      this.isLoading = false
    },
    setError() {
        // 自定义错误处理
        this.timer = setTimeout(() => {
            // this.$message.error({
            //     key: this.key,
            //     content: '操作超时'
            // })
            this.syncBtnText = '同步'
            // this.$refs.editForm.confirmLoading = false
            this.suitList()
            this.isLoading = false
        }, 1000 * 60)
    },
    cancelTimer() {
        clearTimeout(this.timer)
        this.isLoading = false
        // this.$refs.editForm.confirmLoading = false
    },
    openUpload(item){
      this.suitGroupId = item.id
    },
    addType(){
      if(this.suitName){
        this.confirmLoading = true
        addSuitGroup({
          "deviceId":this.$route.query.id, //设备ID
          "suitGroupName":this.suitName, //参数描述
        }).then( res =>{
          // this.suitList() //盒子推过来再调接口，eg: detail.vue line 462
        })
        this.visible = false
        this.confirmLoading = false
      }else{
        this.$message.error('请输入类别名称')
      }
      
    },
    suitList(){
      this.loading = true
      this.syncBtnText = '同步'
      getSuitGroupList({deviceId:this.$route.query.id}).then( res =>{
        this.loading = false
        this.cloLists = res
        // this.cloLists = this.cloLists.slice(0,this.cloLists.length-2)
      })
    },
    add(){
      this.visible = true
      this.suitName = ''
    },
    /**预览图片 */
    handlePreview(file) {
        this.previewImage = file.response
        this.previewVisible = true
    },
    uploadChange(info) {
      let fileList = [...info.fileList]
      console.log(fileList);
    },
    customRequest({ action, file, onSuccess, onError, onProgress }) {
      if(file.type == 'image/jpeg'){
        console.log(file);
        if(file.size / 1024 / 1024 <= 1){
          new Promise(resolve => {
            const fileReader = new FileReader()
            fileReader.readAsDataURL(file)
            fileReader.onload = async(e) => {
              // let index = {
              //   uid: this.genId(5),
              //   name: file.name,
              //   status: 'done',
              //   url: fileReader.result
              // }
              // this.imageList = [...this.imageList.filter(item => item.status === 'done'), index]
              // this.$message.success('文件上传成功！')
              const src = e.target.result
              const image = new Image()
              image.src = src
              new Promise(resolve => {
                image.onload = function() {
                  console.log(image.width,image.height);
                  resolve([image.width,image.height])
                }
              }).then((array)=>{
                let w = array[0]
                let h = array[1]
                if(w > 2560 || h> 1440){
                  this.$message.warning('图片像素控制在2560*1440以内')
                  return
                }else{
                  let params = {
                    "deviceId":this.$route.query.id, //设备ID
                    'suitGroupId':this.suitGroupId,
                    "pic":fileReader.result.slice(23) // 上传jpeg格式工服图片,返回截取掉前23位base64字符串前缀
                  }
                  addSuitInfo(params).then( res => {
                      this.suitList()
                  }).catch(e =>{
                    console.log(e);
                  })
                }
              })
              
              
            }
          })
        }else{
          this.$message.warning('图片过大，请上传1M以内的图片')
        }
      }else{
        this.$message.warning('请上传jpg/jpeg格式的图片')
      }
    },
    genId(length) {
      return Number(Math.random().toString().substr(3, length) + Date.now()).toString(36)
    },
    confirmDel(id){
      removeSuitInfo({
        "deviceId":this.$route.query.id, //设备ID
        "id":id, 
      }).then( res => {

      }).catch(e =>{
        console.log(e);
      })
    }
    // async customRequest(file) {
    //   debugger
    //   const a = new FileReader()
    //   a.readAsDataURL(file.originFileObj)
    //   a.onload = (e) => {
    //     let base64 = e.target.result
    //     console.log(base64)//base64数据
    //   }

    //   // const url = await uploadFile(options.file.name, options.file)
    //   // options.onSuccess(url, options.file) //url即为response的冗余字段
    //   // this.fileUrl = url
    // },

    // async uploadChange({ file, fileList, event }) {
    //     this.fileList = fileList
    // }
  }
}
</script>

<style lang="less" scoped>
 .clothes{
  font-size: 20px;
  .suitBox{
    margin-bottom: 50px;
  }
  img{
    width: 100px;
    height: 150px;
    display: inline-block;
    margin: 10px;
    border: #ddd solid 1px;
    cursor: pointer;
  }
   /deep/ .ant-collapse-content-box{
    position: relative;
    min-height: 100px;
    .ant-upload{
      position: absolute;
      bottom: 10px;
      right: 10px;
    }
  }
}
</style>