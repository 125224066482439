/*
 * @Autor: dingxiaolin
 * @Date: 2022-10-05 09:22:06
 * @LastEditors: dingxiaolin
 * @LastEditTime: 2022-10-22 09:12:31
 */
import { axios } from '@/utils/request'
import config from '@/config/config.js'

/**
 * 分页查询系统字典类型
 */
export function sysDictTypePage(parameter) {
    return axios({
        url: config.namespace + '/dict/page',
        method: 'get',
        params: parameter
    })
}

/**
 * 添加系统字典类型
 */
export function sysDictTypeAdd(parameter) {
    return axios({
        url: config.namespace + '/dict/add',
        method: 'post',
        data: parameter
    })
}

/**
 * 编辑系统字典类型
 */
export function sysDictTypeEdit(parameter) {
    return axios({
        url: config.namespace + '/dict/edit',
        method: 'post',
        data: parameter
    })
}

/**
 * 删除系统字典类型
 */
export function sysDictTypeDelete(parameter) {
    return axios({
        url: config.namespace + '/dict/delete',
        method: 'post',
        data: parameter
    })
}

/**
 * 获取字典类型下所有字典，举例，返回格式为：[{code:"M",value:"男"},{code:"F",value:"女"}]
 */
export function sysDictTypeDropDown(parameter) {
    return axios({
        url: config.namespace + '/dict/dropDown',
        method: 'get',
        params: parameter
    })
}

/**
 * 获取所有字典，启动时加入缓存使用
 */
export function sysDictTypeTree(parameter) {
    return axios({
        url: config.namespace + '/dict/tree',
        method: 'get',
        params: parameter
    })
}
