<template>
    <a-modal
        title="客流/人脸设置"
        centered
        :width="700"
        :visible="visible"
        :confirmLoading="confirmLoading"
        @ok="handleOk"
        @cancel="handleCancel"
        destroyOnClose
    >
        <a-form-model ref="form" :model="form" :rules="formRules" :label-col="labelCol" :wrapper-col="wrapperCol">
            <a-form-model-item label="客流统计" prop="countlist" :rules="formRules.change">
                <a-checkbox-group @change="klChange" v-model="form.countlist">
                    <a-checkbox value="0">上客</a-checkbox>
                    <a-checkbox value="1">下客</a-checkbox>
                </a-checkbox-group>
            </a-form-model-item>
            <a-form-model-item label="人脸抓拍">
                <a-radio-group v-model="form.faceType">
                    <a-radio value="0">上客</a-radio>
                    <a-radio value="1">下客</a-radio>
                </a-radio-group>
            </a-form-model-item>
        </a-form-model>
    </a-modal>
</template>
<script>
import { formRules } from '@/utils/validator'
import { aiDeviceMediaSave, aiDeviceMediaUpdate, aiDeviceMediaDetail,updateFaceAiDeviceMedia } from '@/api/alarm'
export default {
    data() {
        return {
            isAdd: true,
            sceneList: [],
            doorList: [], // 门型列表
            form: {
                id: '', //多媒体通道ID
                // doorType: '', //门型 1-右前，2-右中 3-右后 4-左前 5-左中 6-左后
                faceType: '', //人脸抓拍类型 0-上客 1-下客
                isCountBoarding: '1', //上客是否统计 0-否 1-是
                isCountDisembark: '1', //下客是否统计 0-否 1-是
                // rtspTransport: false //RTSP本地代理 0-否 1-是
                countlist:[]
            },
            formRules: formRules,
            labelCol: {
                xs: { span: 24 },
                sm: { span: 5 }
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 15 }
            },
            visible: false,
            confirmLoading: false,
            /** 表格行数据 */
            tableCell: null
        }
    },
    created() {},
    methods: {
        async init(record) {
            // this.form = record
            // this.form.id =record.deviceId
            
            this.form ={
              "id":record.id, //多媒体通道ID
              "faceType":record.faceType,//人脸抓拍类型 0-上客 1-下客
              "isCountBoarding":record.isCountBoarding,//上客是否统计 0-否 1-是
              "isCountDisembark":record.isCountDisembark,//下客是否统计 0-否 1-是
              "countlist":[]
            }
            if(record.isCountBoarding == '1'){ //  上客
              this.form.countlist.push('0')
            }
            if(record.isCountDisembark == '1'){ // 下客
              this.form.countlist.push('1')
            }
            this.visible = true
        },

        getDetail(id) {
            return aiDeviceMediaDetail({ id })
        },
        handleOk() {
            this.$refs.form.validate(res => {
                if (!res) {
                    return
                }
                this.confirmLoading = true
                updateFaceAiDeviceMedia({...this.form}).then(res=>{
                  if(res.success){
                    this.$message.success(res.msg)
                    this.visible = false
                    this.$emit('getMediaList')
                  }else{
                    this.$message.error(res.msg)
                  }
                })
                this.confirmLoading = false
                // this.$parent.setError()
            })
        },
        handleCancel() {
            this.confirmLoading = false
            this.visible = false // 点击确定关闭弹窗
        },
        /**客流选择 */
        klChange(e) {
            console.log('e', e)
            this.form.isCountBoarding = e.includes('0') ? '1' : '0'
            this.form.isCountDisembark = e.includes('1') ? '1' : '0'
        }
    }
}
</script>
