<!--
 * @Autor: dingxiaolin
 * @Date: 2021-10-13 16:10:53
 * @LastEditors: dingxiaolin
 * @LastEditTime: 2021-12-22 13:46:39
-->
<template>
    <div style="width: 100%; height: 100%" class="all_center">
        <a-empty v-show="empty" :image="simpleImage" style="color: #fff" />
        <div id="alarmRankChart" ref="alarmRankChart" v-show="!empty"></div>
    </div>
</template>

<script>
import * as echarts from 'echarts'
import { Empty } from 'ant-design-vue'
import { alarmTypRankingStatistics } from '@/api/companybord/index'
import { spArr } from '@/utils/util'
export default {
    name: 'alarmRankChart',
    props: {
        data: {
            type: Array,
            default: () => {
                return []
            }
        },
        category: {
            type: Array,
            default: () => {
                return []
            }
        }
    },
    data() {
        return {}
    },
    computed: {
        empty() {
            return this.data.length > 0 ? false : true
        }
    },
    watch: {
        data: {
            handler() {
                console.log('change', this.data, this.category)
                this.drawChart(this.data, this.category)
            },
            deep: true
        }
    },
    created() {
        this.simpleImage = Empty.PRESENTED_IMAGE_SIMPLE
    },

    methods: {
        /** 绘制图表 */
        drawChart(data, category) {
            var alarmRankChart = echarts.init(this.$refs.alarmRankChart)
            window.addEventListener('resize', () => {
                setTimeout(() => {
                    alarmRankChart.resize()
                }, 200)
            })
            var option = {
                color: new echarts.graphic.LinearGradient(1, 0, 0, 0, [
                    { offset: 1, color: '#0cb2b7' },
                    { offset: 0.5, color: '#0e79fe' },
                    { offset: 0, color: '#3f3c8b' }
                ]), // 渐变色
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'none'
                    },
                    position: function(point, params, dom, rect, size) {
                        if (params[0].name.length > 8) {
                            return point
                        }
                    },
                    formatter: function(params) {
                        return (
                            params[0].name +
                            '<br/>' +
                            "<span style='display:inline-block;margin-right:5px;border-radius:10px;width:9px;height:9px;background-color:#0F82DB'></span>" +
                            params[0].seriesName +
                            ' : ' +
                            params[0].value +
                            '次 <br/>'
                        )
                    }
                },
                grid: {
                    top: '2%',
                    left: '3%',
                    right: '5%',
                    bottom: 0,
                    containLabel: true,
                    borderColor: '#f00'
                },
                xAxis: [
                    {
                        type: 'value',
                        splitLine: {
                            show: true,
                            lineStyle: {
                                color: '#243e7a',
                                width: 1
                            }
                        },
                        axisLabel: {
                            color: '#fff'
                        }
                    }
                ],
                yAxis: [
                    {
                        type: 'category',
                        data: category, // 接口获取的类型
                        axisTick: {
                            alignWithLabel: true
                        },
                        inverse: true,
                        color: '#fff',
                        axisLabel: {
                            textStyle: {
                                color: '#fff'
                            },
                            formatter: function(value) {
                                // 判断文字长度是否超过6，如果超过沈略
                                value = value.toString()
                                var maxlength = 6
                                if (value.length > maxlength) {
                                    return value.substring(0, maxlength - 1) + '...'
                                } else {
                                    return value
                                }
                            }
                        }
                    }
                ],
                series: [
                    {
                        showBackground: true,
                        name: '告警次数',
                        type: 'bar',
                        itemStyle: {
                            normal: {
                                barBorderRadius: [0, 10, 10, 0]
                            }
                        },
                        barWidth: 10,
                        barGap: '50%',
                        label: {
                            show: true,
                            position: 'right',
                            valueAnimation: true,
                            color: '#1781eb'
                        },
                        data: data // 接口获取的数据
                    }
                ]
            }
            alarmRankChart.setOption(option, true)
            setTimeout(() => {
                alarmRankChart.resize()
            }, 0)
        }
    }
}
</script>

<style lang="less" scoped>
#alarmRankChart {
    width: 100%;
    height: 100%;
}
</style>
