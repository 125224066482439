<template>
    <a-modal centered :width="700" :maskClosable="false" :visible="visible" :confirmLoading="confirmLoading" @ok="handleOk" @cancel="handleCancel">
        <template #title>
            <span>远程升级</span>
            <span class="title">{{ deviceCode }} {{ deviceName }}</span>
        </template>
        <a-form-model ref="form" :model="form" :rules="formRules" :label-col="labelCol" :wrapper-col="wrapperCol">
            <a-form-model-item label="版本号" prop="firmwareId" :rules="formRules.blur">
                <a-select
                    show-search
                    allowClear
                    placeholder="输入固件版本号搜索"
                    option-filter-prop="children"
                    v-model="form.firmwareId"
                    @change="versionChange"
                >
                    <a-select-option v-for="item in firmwareList" :key="item.id" :value="item.id" :label="item.firmwareVersion">
                        {{ item.firmwareVersion }}
                    </a-select-option>
                </a-select>
            </a-form-model-item>
        </a-form-model>
    </a-modal>
</template>

<script>
import { formRules } from '@/utils/validator'
import { uploadFile } from '@/utils/util'
import { firmwareInfoList, aiDeviceLevelForRJ } from '@/api/alarm/index'

export default {
    props: {},
    data() {
        return {
            title: '远程升级',
            formRules: formRules,
            firmwareList: [],
            patchList: [],
            labelCol: {
                xs: { span: 24 },
                sm: { span: 5 }
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 15 }
            },
            form: {
                /**设备ID */
                ids: '',
                /**固件ID */
                firmwareId: ''
            },
            visible: false,
            confirmLoading: false,
            deviceName: '',
            deviceCode: '',
            version: '',
            uplist: []
        }
    },
    mounted() {
        this.uplist = []
        this.$root.$on('task', res => {
            console.log('aa')
            //获取区域信息列表
            if (res.Event == '/app_upgrade_cmd') {
                if (this.form.ids.includes(',')) {
                    this.uplist.push({
                        boardId: res.BoardId,
                        desc: res.Result.Desc,
                        version: this.version,
                        success: res.Result.Code == 0
                    })
                    this.$emit('showDrawer', this.uplist)
                } else {
                  console.log(res.Result.Desc,'res.Result.Desc')
                  let key = 'update'
                    if (res.Result.Code == 0) {
                        this.$message.success({ content: res.Result.Desc, key })
                    } else {
                        this.$message.error({ content: res.Result.Desc, key })
                    }
                }
            }
        })
    },
    destroyed() {
        this.$root.$off('task')
    },
    methods: {
        versionChange() {
            this.version = this.firmwareList.filter(v => v.id == this.form.firmwareId)[0].firmwareVersion
        },
        show(ids, detail) {
            this.title = '远程升级'
            this.visible = true
            this.form.firmwareId = undefined  // fix <a-select>默认placeholder不显示的问题
            this.uplist = []
            firmwareInfoList({ descs: 'firmware_version', current: 1, size: -1 }).then(res => {
                this.firmwareList = res.records
            })
            this.$nextTick(() => {
                if (this.$route.query.id) {
                    this.$refs.form.resetFields()
                    this.form.ids = this.$route.query.id
                    this.deviceCode = detail.deviceCode
                    this.deviceName = detail.deviceName
                } else {
                    this.$refs.form.resetFields()
                    this.form.ids = ids.join(',')
                    this.deviceCode = ids.length == 1 ? detail.deviceCode : ''
                    this.deviceName = ids.length == 1 ? detail.deviceName : ''
                }
            })
        },
        handleOk() {
            this.confirmLoading = true
            this.$refs.form.validate(valid => {
                if (valid) {
                    aiDeviceLevelForRJ(this.form)
                        .then(res => {
                            this.confirmLoading = false
                            this.firmwareList = []
                            this.handleCancel()
                        })
                        .catch(() => {})
                } else {
                    this.confirmLoading = false
                }
            })
        },
        handleCancel() {
            this.visible = false
        }
    }
}
</script>

<style lang="less" scoped>
.title {
    padding-left: 10px;
    color: #ccc;
}
/deep/ .ant-modal-title {
    font-size: 12px;
    font-weight: normal;
}
</style>
