/*
 * @Autor: dingxiaolin
 * @Date: 2022-10-05 09:22:06
 * @LastEditors: dingxiaolin
 * @LastEditTime: 2022-10-22 09:13:00
 */
import { axios } from '@/utils/request'
import config from '@/config/config.js'

/**
 * 获取菜单列表
 * @param parameter
 * @returns {*}
 */
export function getMenuList(parameter) {
    return axios({
        url: config.namespace + '/menu/list',
        method: 'get',
        params: parameter
    })
}

/**
 * 获取系统菜单树，用于新增，编辑时选择上级节点
 */
export function getMenuTree(parameter) {
    return axios({
        url: config.namespace + '/menu/tree',
        method: 'get',
        params: parameter
    })
}

/**
 * 增加菜单
 */
export function sysMenuAdd(parameter) {
    return axios({
        url: config.namespace + '/menu/add',
        method: 'post',
        data: parameter
    })
}

/**
 * 增加菜单
 */
export function sysMenuDelete(parameter) {
    return axios({
        url: config.namespace + '/menu/delete',
        method: 'post',
        data: parameter
    })
}

/**
 * 查看菜单详情
 */
export function sysMenuDetail(parameter) {
    return axios({
        url: config.namespace + '/menu/detail',
        method: 'post',
        data: parameter
    })
}

/**
 * 编辑系统菜单
 */
export function sysMenuEdit(parameter) {
    return axios({
        url: config.namespace + '/menu/edit',
        method: 'post',
        data: parameter
    })
}

/**
 * 获取系统菜单树，用于给角色授权时选择
 */
export function SysMenuTreeForGrant(parameter) {
    return axios({
        url: config.namespace + '/menu/treeForGrant',
        method: 'get',
        params: parameter
    })
}

/**
 * 根据系统切换菜单
 */
export function sysMenuChange(parameter) {
    return axios({
        url: config.namespace + '/menu/change',
        method: 'post',
        data: parameter
    })
}
