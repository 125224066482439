/*
 * @Autor: dingxiaolin
 * @Date: 2022-10-05 09:22:06
 * @LastEditors: dingxiaolin
 * @LastEditTime: 2022-10-22 09:12:55
 */
import { axios } from '@/utils/request'
import config from '@/config/config.js'

/**
 * 分页查询文件信息表
 */
export function sysFileInfoPage(parameter) {
    return axios({
        url: config.namespace + '/file/page',
        method: 'get',
        params: parameter
    })
}

/**
 * 获取全部文件信息表
 */
export function sysFileInfoList(parameter) {
    return axios({
        url: config.namespace + '/file/list',
        method: 'get',
        params: parameter
    })
}

/**
 * 上传文件
 */
export function sysFileInfoUpload(parameter) {
    return axios({
        url: config.namespace + '/file/upload',
        method: 'post',
        data: parameter
    })
}

/**
 * 下载文件
 */
export function sysFileInfoDownload(parameter) {
    return axios({
        url: config.namespace + '/file/download',
        method: 'get',
        params: parameter,
        responseType: 'blob'
    })
}

/**
 * 查看图片
 */
export function sysFileInfoPreview(parameter) {
    return axios({
        url: config.namespace + '/file/preview',
        method: 'get',
        params: parameter,
        responseType: 'arraybuffer'
    })
}

/**
 * 查看详情文件信息表
 */
export function sysFileInfoDetail(parameter) {
    return axios({
        url: config.namespace + '/file/detail',
        method: 'get',
        params: parameter
    })
}

/**
 * 删除文件信息表
 *
 * @author Antonio
 * @date 2020/6/30 00:20
 */
export function sysFileInfoDelete(parameter) {
    return axios({
        url: config.namespace + '/file/delete',
        method: 'post',
        data: parameter
    })
}
