/*
 * @Author: shiyuan 
 * @Date: 2021-12-10 17:33:10 
 * @Last Modified by: shiyuan
 * @Last Modified time: 2021-12-23 10:22:58
 */
import gcoord from 'gcoord';
import '../AMap/index.less'
export default {
    name: 'AMap',
    props: {
        position: [],
    },
    data() {
        return {
            /** 地图实例 */
            map: null,
            /** 地图输入框的值 */
            value: '',
            /** 位置搜索插件实例 */
            placeSearch: null,
            /** 点标记实例化 */
            marker: null,
            /** 地址解析实例化 */
            geocoder: null,
        }
    },
    mounted() {
        // 创建地图实例
        this.$nextTick(() => {
            this.initMap()
        })
    },
    watch: {
        position: {
            handler(v) {
            },
            immediate: true,
            deep: true
        }
    },
    methods: {
        initMap() {
            const options = {
                resizeEnable: true,
                viewMode: '2D', //开启3D视图,默认为关闭
                zoom: 16,
                center: this.$props.position,
            }
            this.map = new AMap.Map('amapContainer', options)
            // 插件是 自动补全 地址搜索  地址解析
            this.map.plugin(['AMap.Autocomplete', 'AMap.PlaceSearch', 'AMap.Geocoder'], () => {
                var autoOptions = {
                    input: 'tipinput'
                }
                // 实例化自动完成组件
                var autocomplete = new AMap.Autocomplete(autoOptions)
                // 实例化地址解析
                this.geocoder = new AMap.Geocoder({
                    // city 指定进行编码查询的城市，支持传入城市名、adcode 和 citycode
                    city: ''
                })
                // 增加下拉选择监听事件
                AMap.event.addListener(autocomplete, "select", (e) => {
                    // 高德坐标转gps
                    let areaCode = e.poi.adcode
                    if (e.poi.location) {
                        this.setMapMarker(e.poi.location.lng, e.poi.location.lat, e.poi.name)
                        // 父组件传值
                        this.$emit('onSelect', {
                            address: e.poi.district + e.poi.name + e.poi.address,
                            province: areaCode.slice(0, 2),
                            city: areaCode.slice(0, 4),
                            county: areaCode,
                            lng: e.poi.location.lng,
                            lat: e.poi.location.lat
                        })
                    } else {
                        this.$message.error('请点选准确的地址')
                    }
                });
            })
            // 增加地图绑定事件
            this.map.on('click', (e) => {
                let lng = e.lnglat.getLng()
                let lat = e.lnglat.getLat()
                this.setMapMarker(lng, lat)
                this.geocoder.getAddress([lng, lat], (status, result) => {
                    if (status === 'complete' && result.regeocode) {
                        this.$emit('onClick', {
                            address: result.regeocode.formattedAddress,
                            lng,
                            lat,
                            province: result.regeocode.addressComponent.adcode.slice(0, 2),
                            city: result.regeocode.addressComponent.adcode.slice(0, 4),
                            county: result.regeocode.addressComponent.adcode
                        })
                    } else {
                        console.error('根据经纬度查询地址失败')
                    }
                });
            })
        },
        /** 地图增加点标记 */
        setMapMarker(lng, lat, name) {
            // 每次选中前清空点
            this.marker && this.map.remove(this.marker)
            // 增加点
            this.marker = new AMap.Marker({
                position: new AMap.LngLat(lng, lat),   // 经纬度对象，也可以是经纬度构成的一维数组[116.39, 39.9]
                title: name
            })
            this.map.add(this.marker)
            this.map.setZoomAndCenter(14, [lng, lat])
        },
        /** 清除输入框内容 */
        clear() {
            this.value = ''
            this.marker && this.map.remove(this.marker)
        },

        /**
         * 根据地址转换经纬度
         * @param {String} address 详细地址
         * @param {String} province 省份名称
         * @param {String} city 市区名称
         * @param {String} county 县名称
         */
        onSearch({ address, provinceName, cityName, countyName, county }) {
            this.geocoder = new AMap.Geocoder({
                city: county
            })
            return new Promise(resolve => {
                this.geocoder.getLocation(address, (status, result) => {
                    if (status === 'complete' && result.geocodes.length) {
                        let lnglat = result.geocodes[0].location
                        resolve([lnglat.lng, lnglat.lat])
                    } else {
                        console.log('地址转换失败')
                        this.geocoder.getLocation(provinceName + cityName + countyName, (status, result) => {
                            if (status === 'complete' && result.geocodes.length) {
                                let lnglat = result.geocodes[0].location
                                resolve([lnglat.lng, lnglat.lat])
                            } else {
                                this.$message.error('地址转换失败请手动填写地址')
                            }
                        })
                    }
                });
            })

        },
        /** 初始化marker */
        initMarker(lng, lat) {
            this.setMapMarker(lng, lat)
        },
    },
    render() {
        return <div class='amap-search'>
            <div id="amapContainer" style={{ height: '100%' }}  {...this.$props} {...this.$attrs}></div>
            <div class="amap-info-card">
                <div class="input-item">
                    <a-input-search id='tipinput' value={this.value} onChange={(e) => this.value = e.target.value} Autocomplete='off' placeholder="输入位置查询" />
                </div>
            </div>
        </div>
    }

}

