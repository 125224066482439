var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-modal',{attrs:{"title":"用户增加","centered":"","width":980,"maskClosable":false,"destroyOnClose":true,"visible":_vm.visible,"confirmLoading":_vm.confirmLoading},on:{"ok":_vm.handleSubmit,"cancel":_vm.handleCancel}},[_c('a-spin',{attrs:{"spinning":_vm.confirmLoading}},[_c('a-divider',{attrs:{"orientation":"left"}},[_vm._v("基本信息")]),_c('a-row',{attrs:{"gutter":24}},[_c('a-col',{attrs:{"md":12,"sm":24}},[_c('a-form',{attrs:{"form":_vm.form}},[_c('a-form-item',{attrs:{"label":"账号","labelCol":_vm.labelCol,"wrapperCol":_vm.wrapperCol,"has-feedback":""}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['account', { rules: [{ required: true, min: 5, message: '请输入至少五个字符的账号！' }] }]),expression:"['account', { rules: [{ required: true, min: 5, message: '请输入至少五个字符的账号！' }] }]"}],attrs:{"maxLength":35,"placeholder":"请输入账号"}})],1)],1)],1),_c('a-col',{attrs:{"md":12,"sm":24}},[_c('a-form',{attrs:{"form":_vm.form}},[_c('a-form-item',{attrs:{"label":"姓名","labelCol":_vm.labelCol,"wrapperCol":_vm.wrapperCol,"has-feedback":""}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['name', { rules: [{ required: true, message: '请输入姓名！' }] }]),expression:"['name', { rules: [{ required: true, message: '请输入姓名！' }] }]"}],attrs:{"maxLength":35,"placeholder":"请输入姓名"}})],1)],1)],1)],1),_c('a-row',{attrs:{"gutter":24}},[_c('a-col',{attrs:{"md":12,"sm":24}},[_c('a-form',{attrs:{"form":_vm.form}},[_c('a-form-item',{attrs:{"label":"密码","labelCol":_vm.labelCol,"wrapperCol":_vm.wrapperCol,"has-feedback":""}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                                'password',
                                {
                                    rules: [
                                        { required: true, message: '请输入密码！' },
                                        {
                                            validator: _vm.validateToNextPassword
                                        }
                                    ]
                                }
                            ]),expression:"[\n                                'password',\n                                {\n                                    rules: [\n                                        { required: true, message: '请输入密码！' },\n                                        {\n                                            validator: validateToNextPassword\n                                        }\n                                    ]\n                                }\n                            ]"}],attrs:{"placeholder":"请输入密码","autocomplete":"off","type":"password","maxLength":35}})],1)],1)],1),_c('a-col',{attrs:{"md":12,"sm":24}},[_c('a-form',{attrs:{"form":_vm.form}},[_c('a-form-item',{attrs:{"label":"重复密码","labelCol":_vm.labelCol,"wrapperCol":_vm.wrapperCol,"has-feedback":""}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                                'confirm',
                                { rules: [{ required: true, message: '请再次输入密码！' }, { validator: _vm.compareToFirstPassword }] }
                            ]),expression:"[\n                                'confirm',\n                                { rules: [{ required: true, message: '请再次输入密码！' }, { validator: compareToFirstPassword }] }\n                            ]"}],attrs:{"placeholder":"请再次输入密码","autocomplete":"off","type":"password","maxLength":35}})],1)],1)],1)],1),_c('a-row',{attrs:{"gutter":24}},[_c('a-col',{attrs:{"md":12,"sm":24}},[_c('a-form',{attrs:{"form":_vm.form}},[_c('a-form-item',{attrs:{"label":"昵称","labelCol":_vm.labelCol,"wrapperCol":_vm.wrapperCol,"has-feedback":""}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['nickName']),expression:"['nickName']"}],attrs:{"maxLength":35,"placeholder":"请输入昵称"}})],1)],1)],1),_c('a-col',{attrs:{"md":12,"sm":24}},[_c('a-form',{attrs:{"form":_vm.form}},[_c('a-form-item',{attrs:{"label":"手机号","labelCol":_vm.labelCol,"wrapperCol":_vm.wrapperCol,"has-feedback":""}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                                'phone',
                                {
                                    rules: [
                                        { required: true, message: '请输入手机号' },
                                        {
                                            pattern: /^1[3|4|5|6|7|8|9][0-9]\d{8}$/,
                                            message: '请输入正确的手机号'
                                        },
                                        {
                                            max: 11,
                                            message: '手机号码不得超过11字符'
                                        }
                                    ]
                                }
                            ]),expression:"[\n                                'phone',\n                                {\n                                    rules: [\n                                        { required: true, message: '请输入手机号' },\n                                        {\n                                            pattern: /^1[3|4|5|6|7|8|9][0-9]\\d{8}$/,\n                                            message: '请输入正确的手机号'\n                                        },\n                                        {\n                                            max: 11,\n                                            message: '手机号码不得超过11字符'\n                                        }\n                                    ]\n                                }\n                            ]"}],attrs:{"placeholder":"请输入手机号"}})],1)],1)],1)],1),_c('a-divider',{attrs:{"orientation":"left"}},[_vm._v("员工信息")]),_c('a-row',{attrs:{"gutter":24}},[_c('a-col',{attrs:{"md":12,"sm":24}},[_c('a-form',{attrs:{"form":_vm.form}},[_c('a-form-item',{attrs:{"label":"机构","labelCol":_vm.labelCol,"wrapperCol":_vm.wrapperCol,"has-feedback":""}},[_c('a-tree-select',{directives:[{name:"decorator",rawName:"v-decorator",value:(['sysEmpParam.orgId', { rules: [{ required: true, message: '请选择机构！' }] }]),expression:"['sysEmpParam.orgId', { rules: [{ required: true, message: '请选择机构！' }] }]"}],staticStyle:{"width":"100%"},attrs:{"show-search":"","treeNodeFilterProp":"title","dropdownStyle":{ maxHeight: '300px', overflow: 'auto' },"treeData":_vm.orgTree,"placeholder":"请选择机构","treeDefaultExpandAll":""},on:{"select":_vm.onSelect},scopedSlots:_vm._u([{key:"title",fn:function(ref){
                            var id = ref.id;
return _c('span',{},[_vm._v(_vm._s(id))])}}])})],1),_c('a-form-item',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}]},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['sysEmpParam.orgName']),expression:"['sysEmpParam.orgName']"}]})],1)],1)],1),_c('a-col',{attrs:{"md":12,"sm":24}},[_c('a-form',{attrs:{"form":_vm.form}},[_c('a-form-item',{attrs:{"label":"工号","labelCol":_vm.labelCol,"wrapperCol":_vm.wrapperCol,"has-feedback":""}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['sysEmpParam.jobNum']),expression:"['sysEmpParam.jobNum']"}],attrs:{"maxLength":35,"placeholder":"请输入工号"}})],1)],1)],1)],1),_c('a-row',{attrs:{"gutter":24}},[_c('a-col',{attrs:{"md":24,"sm":24}},[_c('a-form',{attrs:{"form":_vm.form}},[_c('a-form-item',{attrs:{"label":"职位信息","labelCol":_vm.labelCol_JG,"wrapperCol":_vm.wrapperCol_JG,"has-feedback":""}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:(['sysEmpParam.posIdList', { rules: [{ required: true, message: '请选择职位信息！' }] }]),expression:"['sysEmpParam.posIdList', { rules: [{ required: true, message: '请选择职位信息！' }] }]"}],staticStyle:{"width":"100%"},attrs:{"mode":"multiple","placeholder":"请选择职位信息"}},_vm._l((_vm.posList),function(item,index){return _c('a-select-option',{key:index,attrs:{"value":item.id}},[_vm._v(_vm._s(item.name))])}),1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }