/*
 * @Autor: dingxiaolin
 * @Date: 2021-09-23 07:31:51
 * @LastEditors: dingxiaolin
 * @LastEditTime: 2021-09-28 09:43:33
 */
import Vue from 'vue'
import Router from 'vue-router'
import { constantRouterMap } from '@/router/router.config'

// hack router push callback
const originalPush = Router.prototype.push
Router.prototype.push = function push(location, onResolve, onReject) {
    if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
    return originalPush.call(this, location).catch(err => err)
}
/**防重复添加路由 */
Router.prototype.$addRoutes = (params) => {
    new Router({ mode: 'history' }).matcher.addRoutes(params)
}
Vue.use(Router)

const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    scrollBehavior: () => ({ y: 0 }),
    routes: constantRouterMap
})
router.beforeEach((to, from, next) => {
  // 告警详情页，控制返回箭头，返回到初始进来的页面，不受上一个下一个详情影响
  if (to.path == '/shipAlarm/detail' && (to.path != from.path) && from.path && from.path != '/') {
    window.localStorage.setItem('fromPath',from.path)
    next();
  } else {
    next();
  }
});
export default router