/*
 * @Autor: dingxiaolin
 * @Date: 2021-10-12 09:28:39
 * @LastEditors: dingxiaolin
 * @LastEditTime: 2021-12-18 09:27:14
 */
import Vue from 'vue'
import PerfectScrollbar from 'perfect-scrollbar'
import 'perfect-scrollbar/css/perfect-scrollbar.css'
const elScrollBar = el => {
    if (el._ps_ instanceof PerfectScrollbar) {
        el._ps_.update()
    } else {
        el._ps_ = new PerfectScrollbar(el, {
            suppressScrollX: true,
            wheelSpeed: 1,
            wheelPropagation: true,
            minScrollbarLength: 20
        })
    }
}
export const scrollBar = {
    inserted(el, binding, vnode) {
        const rules = ['fixed', 'absolute', 'relative']
        if (!rules.includes(window.getComputedStyle(el, null).position)) {
            console.warn(`perfect-scrollbar所在的容器的position属性必须是以下之一：${rules.join('、')}`)
        }
        elScrollBar(el)
    },
    componentUpdated(el, binding, vnode, oldVnode) {
        try {
            vnode.context.$nextTick(() => {
                // console.log(el);
                elScrollBar(el)
            })
        } catch (error) {
            console.warn(error)
            elScrollBar(el)
        }
    }
}
Vue.directive('scrollBar', scrollBar)
