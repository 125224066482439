<!--
 * @Autor: dingxiaolin
 * @Date: 2022-09-05 11:36:10
 * @LastEditors: dingxiaolin
 * @LastEditTime: 2022-09-28 17:03:10
-->
<template>
    <a-modal centered :width="650" :maskClosable="false" :destroyOnClose="true" :visible="visible" @ok="handleSubmit" @cancel="handleCancel">
        <span slot="title">
            添加关联人员 <span v-if="name">({{ name }})</span> <br />
            <span style="font-size:14px;color:#999"><a-icon type="info-circle" />添加关联人员后，该艘船舶的告警数据仅关联人员可见</span>
        </span>
        <a-input-search enter-button placeholder="搜索" size="large" @search="$refs.table.refresh(true)" v-model="queryParam.name"> </a-input-search>

        <s-table
            ref="table"
            :scroll="{ y: 400 }"
            :columns="columns"
            :data="loadData"
            :alert="true"
            :rowKey="record => record.id"
            :rowSelection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
        >
        </s-table>
    </a-modal>
</template>

<script>
import { sysDictTypeEdit } from '@/api/system/dict'
import { getUserPage } from '@/api/system/user'
import { saveShipUser } from '@/api/alarm/index'
import { STable } from '@/components'

export default {
    components: {
        STable
    },
    data() {
        return {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 5 }
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 15 }
            },
            name: '',
            visible: false,
            confirmLoading: false,
            selectedRowKeys: [],
            // 表头
            columns: [
                {
                    title: '姓名',
                    dataIndex: 'name',
                    ellipsis: true
                },
                {
                    title: '手机',
                    dataIndex: 'phone',
                    ellipsis: true
                },
                {
                    title: '邮箱',
                    dataIndex: 'email',
                    ellipsis: true
                }
            ],
            // 查询参数
            queryParam: { descs: 'create_time', name: '', descs: 'create_time', status: 0, venIdB: '' },
            // 加载数据方法 必须为 Promise 对象
            loadData: parameter => {
                return getUserPage(Object.assign(parameter, this.queryParam)).then(res => {
                    this.userList = res.data.rows
                    return res.data
                })
            },
            form: {
                /**船舶ID */
                shipId: '',
                /**用户ID，逗号分割 */
                userIds: ''
            }
        }
    },
    methods: {
        onSelectChange(v) {
            this.selectedRowKeys = v
            this.form.userIds = v.join(',')
        },
        // 初始化方法
        edit(record) {
            this.visible = true
            setTimeout(() => {
                this.form.setFieldsValue({
                    id: record.id,
                    name: record.name,
                    code: record.code,
                    sort: record.sort,
                    remark: record.remark
                })
            }, 100)
        },

        handleSubmit() {
            console.log('ddd', this.selectedRowKeys)
            if (this.selectedRowKeys.length > 0) {
                saveShipUser(this.form)
                    .then(res => {
                        if (res.success) {
                            this.$message.success('添加人员成功')
                            this.visible = false
                            this.confirmLoading = false
                            this.$emit('ok')
                        } else {
                            this.$message.error('添加人员失败：' + res.msg)
                        }
                    })
                    .finally(res => {
                        this.confirmLoading = false
                    })
            } else {
                this.$message.info('请先选择关联人员')
            }
        },
        handleCancel() {
            this.visible = false
        }
    }
}
</script>
