<template>
    <a-modal :title="title" :width="1100" :visible="visible" :confirm-loading="confirmLoading" @ok="handleOk" @cancel="handleCancel" destroyOnClose>
        <div style="padding-top:20px">
            <div class="table-page-search-wrapper">
                <a-form layout="inline">
                    <a-row :gutter="48">
                        <a-col :md="8" :sm="24">
                            <a-form-item label="所属机构">
                                <DeptTree v-model="queryParam.tenantId_equal" />
                            </a-form-item>
                        </a-col>
                        <a-col :md="8" :sm="24">
                            <a-form-item label="站点编号">
                                <a-input v-model="queryParam.stationCode" placeholder="请输入站点编号"></a-input>
                            </a-form-item>
                        </a-col>
                        <a-col :md="8" :sm="24">
                            <a-form-item label="站点名称">
                                <a-input v-model="queryParam.stationName" placeholder="请输入站点名称"></a-input>
                            </a-form-item>
                        </a-col>

                        <a-col :md="24" :sm="24" align="right">
                            <span class="table-page-search-submitButtons">
                                <a-button type="primary" @click="search">查询</a-button>
                                <a-button style="margin-left: 8px" @click="resetForm()">重置</a-button>
                            </span>
                        </a-col>
                    </a-row>
                </a-form>
            </div>
            <a-table
                :rowSelection="{ type: 'radio', selectedRowKeys: selectedRowKeys, onChange: onChange }"
                :columns="columns"
                :data-source="tableData"
                :rowKey="record => record.id"
                :scrollToFirstRowOnChange="true"
            >
            </a-table>
        </div>
    </a-modal>
</template>
<script>
import { NTable, DeptTree } from '@/components'
import { getStationsList, getLineList } from '@/api/alarm/index'
export default {
    components: {
        NTable,
        DeptTree
    },
    data() {
        return {
            title: '',
            visible: false,
            confirmLoading: false,
            queryParam: {
                tenantId_equal: '', //所属机构
                stationName: '',
                stationCode:'',
                size: -1
            },
            selectedRowKeys: [],
            selectedRows: [],
            // 表头
            columns: [
                {
                    title: '所属机构',
                    dataIndex: 'tenantName',
                    ellipsis: true,
                    width:120
                },
                {
                    title: '站点名称',
                    dataIndex: 'stationName',
                    ellipsis: true
                },
                {
                    title: '站点编号',
                    dataIndex: 'stationCode',
                    ellipsis: true,
                    width:120
                },
                {
                    title: '站点GPS经度',
                    dataIndex: 'stationLng',
                    ellipsis: true,
                    width:120
                },
                {
                    title: '站点GPS纬度',
                    dataIndex: 'stationLat',
                    ellipsis: true,
                    width:120
                },

                {
                    title: '创建时间',
                    dataIndex: 'createTime',
                    ellipsis: true
                }
            ],
            tableData: [],
            lineList: []
        }
    },
    mounted() {},
    methods: {
        resetForm() {
            this.queryParam = this.$options.data.call(this).queryParam
            this.getStationsList()
        },
        show() {
            this.visible = true
            this.$nextTick(() => {
                // 获取全部线路
                getLineList({
                    descs: 'create_time',
                    size: -1,
                    tenantId_equal: this.$store.state.user.tenant.tenantId
                }).then(res => {
                    res.records.forEach(v => {
                        v.label = v.lineSubName + (v.isLoopLine == 1 ? ' 环线' : '')
                    })
                    this.lineList = res.records
                })
                this.search()
            })
        },
        getStationsList() {
            getStationsList(this.queryParam).then(res => {
                this.tableData = res.records
            })
        },
        handleOk() {
            this.$emit('ok', ...this.selectedRows)
            this.visible = false
            this.selectedRowKeys = []
        },
        handleCancel() {
            this.visible = false
        },
        /**查询 */
        search() {
            this.getStationsList()
        },
        onChange(selectedRowKeys, selectedRows) {
            this.selectedRowKeys = selectedRowKeys
            this.selectedRows = selectedRows
        }
    }
}
</script>
<style lang="less" scoped></style>
