<template>
    <div>
        <div class="search flex">
            <a-button-group>
                <a-button :type="!today ? 'primary' : ''" @click="setToday(false)">昨日</a-button>
                <a-button :type="today ? 'primary' : ''" @click="setToday(true)">今日</a-button>
            </a-button-group>
            <a-range-picker v-model="queryDate" format="YYYY-MM-DD HH:mm:ss" @change="dateChange" />
            <a-select style="width: 120px" v-model="queryParam.status_equal">
                <a-select-option value=""> 全部状态 </a-select-option>
                <a-select-option value="1"> 在线 </a-select-option>
                <a-select-option value="2"> 离线 </a-select-option>
                <a-select-option value="3"> 其他 </a-select-option>
            </a-select>
            <a-button type="primary" @click="$refs.table.refresh(true)"> 查询 </a-button>
        </div>
        <div class="log_list">
            <n-table
                ref="table"
                :columns="heartColumns"
                :data="loadData"
                :scrollX="2200"
                :rowKey="record => record.id"
                :scrollToFirstRowOnChange="true"
            >
                <span slot="Time" slot-scope="text">
                    {{ $moment(Number(text.toString().padEnd(13,'0'))).format('YYYY-MM-DD HH:mm:ss') }}
                </span>
                <span slot="Longitude" slot-scope="text">
                    <span>{{ text || '-' }}</span>
                </span>
                <span slot="Latitude" slot-scope="text">
                    <span>{{ text || '-' }}</span>
                </span>
                <span slot="logType" slot-scope="text">
                    <span v-if="text == 0">终端心跳包</span>
                    <span v-if="text == 1">告警信息上行</span>
                </span>
                <span slot="status" slot-scope="text">
                    <span v-if="text == 0">未激活</span>
                    <span v-if="text == 1">在线</span>
                    <span v-if="text == 2">离线</span>
                </span>
                <div slot="txt" slot-scope="value">
                    <span style="white-space: pre-line">
                        {{ value }}
                    </span>
                </div>
            </n-table>
        </div>
    </div>
</template>

<script>
import { aiDeviceLogList } from '@/api/alarm/index'
import { NTable } from '@/components'
export default {
    data() {
        return {
            // 查询参数
            queryParam: {
                descs: 'create_time', //排序字段
                aiDeviceId_equal: this.$route.query.id, //终端ID
                status_equal: '', //在线离线
                logType_equal: '0', //日志类型 0终端心跳包 1告警信息上行
                createTime_ge: this.$moment().format('YYYY-MM-DD') + ' 00:00:00', //日志开始时间 yyyy-MM-dd HH:mm:ss
                createTime_le: this.$moment().format('YYYY-MM-DD') + ' 23:59:59' //日志结束时间 yyyy-MM-dd HH:mm:ss
            },
            queryDate: [this.$moment(this.$moment().format('YYYY-MM-DD 00:00:00')), this.$moment(this.$moment().format('YYYY-MM-DD 23:59:59'))],
            // 加载数据方法 必须为 Promise 对象
            loadData: async parameter => {
                const data = await aiDeviceLogList(Object.assign(parameter, this.queryParam))
                    data.records.forEach(v => {
                        if (v.content != '') {
                            Object.assign(v, JSON.parse(v.content))
                            v.HostMemory = JSON.parse(v.content)?.HostMemory ? JSON.parse(v.content)?.HostMemory[0] : '-'
                            v.Key = JSON.parse(v.content).Key
                            v.Available = JSON.parse(v.content).Available
                            v.Total = JSON.parse(v.content).Total
                            v.Used = JSON.parse(v.content).Used
                        }
                        if (v.status == 2) {
                            v.Status = '离线'
                        }
                    })
                return Promise.resolve(data)
            },
            today: true,
            // 表头
            heartColumns: [
                {
                    title: '上报时间',
                    dataIndex: 'createTime',
                    width: '110px',
                    ellipsis: true
                },
                { title: '设备时间', dataIndex: 'Time', width: '110px', ellipsis: true, scopedSlots: { customRender: 'Time' } },
                {
                    title: '盒子网络地址',
                    dataIndex: 'BoardIp',
                    width: '100px',
                    ellipsis: true
                },
                {
                    title: '盒子芯片温度',
                    dataIndex: 'BoardTemp',
                    width: '100px',
                    ellipsis: true
                },
                {
                    title: '软件版本',
                    dataIndex: 'Version',
                    width: '60px',
                    ellipsis: true
                },
                {
                    title: '位置经度',
                    dataIndex: 'Longitude',
                    scopedSlots: { customRender: 'Longitude' },
                    width: '150px',
                    ellipsis: true
                },
                {
                    title: '位置纬度',
                    dataIndex: 'Latitude',
                    scopedSlots: { customRender: 'Latitude' },
                    width: '150px',
                    ellipsis: true
                },
                {
                    title: '对地速度(km/h)',
                    dataIndex: 'kSpeed',
                    width: '100px',
                    ellipsis: true
                },
                {
                    title: '对地速率(节)',
                    dataIndex: 'nSpeed',
                    width: '100px',
                    ellipsis: true
                },
                {
                    title: '网络接入方式',
                    dataIndex: 'BoardType',
                    scopedSlots: { customRender: 'status6' },
                    width: '100px',
                    ellipsis: true
                },
                {
                    title: '空闲内存',
                    dataIndex: 'HostMemory',
                    width: '80px',
                    ellipsis: true
                },
                {
                    title: '主进程内存占用',
                    dataIndex: 'Malloc',
                    width: '100px',
                    ellipsis: true
                },
                {
                    title: '系统镜像版本',
                    dataIndex: 'System',
                    width: '120px',
                    ellipsis: true
                },
                {
                    title: '状态描述',
                    dataIndex: 'Status',
                    width: '80px',
                    ellipsis: true
                },
                {
                    title: '运行状态',
                    dataIndex: 'Key',
                    width: '80px',
                    ellipsis: true
                },
                {
                    title: '剩余空间(kb)',
                    dataIndex: 'HostDisk.Available',
                    width: '80px',
                    ellipsis: true
                },
                {
                    title: '总空间',
                    dataIndex: 'HostDisk.Total',
                    width: '80px',
                    ellipsis: true
                },
                {
                    title: '已用空间',
                    dataIndex: 'HostDisk.Used',
                    width: '80px',
                    ellipsis: true
                },
                {
                    title: '透传参数',
                    dataIndex: 'status12',
                    width: '100px',
                    ellipsis: true
                }
            ]
        }
    },
    components: {
        NTable
    },
    mounted() {
        this.$refs.table.refresh()
    },
    methods: {
        dateChange(value, dateString) {
            console.log('vv', dateString)
            this.queryParam.createTime_ge = dateString[0]
            this.queryParam.createTime_le = dateString[1]
            this.$refs.table.refresh()
        },
        setToday(v) {
            this.today = v
            this.queryDate = v
                ? [this.$moment(this.$moment().format('YYYY-MM-DD 00:00:00')), this.$moment(this.$moment().format('YYYY-MM-DD 23:59:59'))]
                : [
                      this.$moment(
                          this.$moment()
                              .subtract(1, 'days')
                              .format('YYYY-MM-DD 00:00:00')
                      ),
                      this.$moment(
                          this.$moment()
                              .subtract(1, 'days')
                              .format('YYYY-MM-DD 23:59:59')
                      )
                  ]
            this.queryParam.createTime_ge = this.queryDate[0].format('YYYY-MM-DD') + ' 00:00:00'
            this.queryParam.createTime_le = this.queryDate[1].format('YYYY-MM-DD') + ' 23:59:59'
        }
    }
}
</script>

<style lang="less" scoped>
.log_list {
    margin-top: 10px;
}
/deep/ .ant-table-body {
    max-height: calc(((100vh - 560px) - 0px) - 0px) !important;
}
</style>
