<template>
    <a-card :bordered="false">
        <div class="table-page-search-wrapper">
            <a-form-model :label-col="labelCol" :wrapper-col="wrapperCol">
                <a-row :gutter="48">
                    <a-col :md="8" :sm="24">
                        <a-form-model-item label="所属机构">
                            <dept-tree v-model="queryParam.tenantId_equal" @change="handleDeptChange" />
                        </a-form-model-item>
                    </a-col>
                    <a-col :md="8" :sm="24">
                        <a-form-model-item label="所属线路">
                            <line-select placeholder="请选择所属线路" v-model="lineSubId_equal" :lines="lines" @change="handleLine" />
                        </a-form-model-item>
                    </a-col>
                    <a-col :md="8" :sm="24">
                        <a-form-model-item label="车牌号">
                            <a-select
                                style="width: 100%"
                                showSearch
                                placeholder="请选择车牌号"
                                :filter-option="filterOption"
                                v-model="queryParam.areaName_equal"
                                allowClear
                            >
                                <a-select-option value="">请选择车牌号</a-select-option>
                                <a-select-option v-for="item in carNumbers.filter(item => !!item.busNum)" :key="item.id" :value="item.busNum">{{
                                    item.busNum
                                }}</a-select-option>
                            </a-select>
                        </a-form-model-item>
                    </a-col>
                    <a-col :md="8" :sm="24">
                        <a-form-model-item label="上下行">
                            <a-select
                                style="width: 100%"
                                placeholder="请选择上下行"
                                :filter-option="filterOption"
                                v-model="queryParam.lineType_equal"
                            >
                                <a-select-option v-for="item in upAndDown" :key="item.code" :value="item.code">{{ item.value }}</a-select-option>
                            </a-select>
                        </a-form-model-item>
                    </a-col>
                    <a-col :md="8" :sm="24">
                        <a-form-model-item label="选择时间">
                            <a-range-picker
                                :show-time="{ defaultValue: [$moment('00:00:00', 'HH:mm:ss'), $moment('23:59:59', 'HH:mm:ss')] }"
                                v-model="queryDate"
                                format="YYYY-MM-DD HH:mm:ss"
                                @change="dateChange"
                            />
                        </a-form-model-item>
                    </a-col>

                    <a-col :md="8" :sm="24" align="right">
                        <span class="table-page-search-submitButtons">
                            <a-button type="primary" @click="$refs.table.refresh()">查询</a-button>
                            <a-button style="margin-left: 8px" @click="handleExport">导出</a-button>
                        </span>
                    </a-col>
                </a-row>
            </a-form-model>
        </div>
        <n-table ref="table" :columns="columns" :scrollX="2200" :data="loadData" :rowKey="record => record.id" :scrollToFirstRowOnChange="true">
        </n-table>
    </a-card>
</template>

<script>
import { NTable, DeptTree, LineSelect } from '@/components'
import { getLineList, getBusList, passengerFlowLogs, passengerFlowLogsExcel } from '@/api/alarm/index'
export default {
    components: {
        NTable,
        DeptTree,
        LineSelect
    },

    data() {
        return {
            labelCol: {
                sm: { span: 6 },
                xs: { span: 24 }
            },
            wrapperCol: {
                sm: { span: 18 },
                xs: { span: 24 }
            },
            // 高级搜索 展开/关闭

            queryDate: [this.$moment(this.$moment().format('YYYY-MM-DD 00:00:00')), this.$moment(this.$moment().format('YYYY-MM-DD 23:59:59'))], //告警时间范围

            // 查询参数
            queryParam: {
                // 限制超级管理员
                tenantId_equal: this.$store.state.user.tenant.tenantId == 0 ? '' : this.$store.state.user.tenant.tenantId, //部门
                areaName_equal: undefined, //车牌号
                lineType_equal: '', //线路类型
                lineSubId_equal: undefined, //线路名称
                uploadTime_ge: this.$moment().format('YYYY-MM-DD 00:00:00'), //开始时间
                uploadTime_le: this.$moment().format('YYYY-MM-DD 23:59:59'), //结束时间
                descs: 'upload_time' //排序，默认传upload_time倒序
            },
            lineSubId_equal: undefined,
            /** 线路列表 */
            lines: [],
            /** 车牌列表 */
            carNumbers: [],

            // 表头
            columns: [
                {
                    title: '序号',
                    dataIndex: 'id',
                    key: 'id',
                    customRender: (text, record, index) => index + 1,
                    width: '80px',
                    align: 'center'
                },
                {
                    title: '车牌号',
                    dataIndex: 'areaName',
                    key: 'areaName',
                    width: '100px'
                },
                {
                    title: 'AI终端编号',
                    dataIndex: 'deviceCode',
                    key: 'deviceCode',
                    ellipsis: true
                },
                {
                    title: '所属机构',
                    dataIndex: 'tenantName',
                    key: 'tenantName',
                    ellipsis: true
                },
                {
                    title: '线路名称',
                    dataIndex: 'lineSubName',
                    key: 'lineSubName',
                    width: '140px',
                    ellipsis: true
                },
                {
                    title: '右前',
                    children: [
                        {
                            title: '上',
                            dataIndex: 'rightFrontInNum',
                            key: 'rightFrontInNum',
                            width: '80px'
                        },
                        {
                            title: '下',
                            dataIndex: 'rightFrontLeaveNum',
                            key: 'rightFrontLeaveNum',
                            width: '80px'
                        }
                    ]
                },
                {
                    title: '右中',
                    width: '120px',
                    children: [
                        {
                            title: '上',
                            dataIndex: 'rightMidInNum',
                            key: 'rightMidInNum',
                            width: '80px'
                        },
                        {
                            title: '下',
                            dataIndex: 'rightMidLeaveNum',
                            key: 'rightMidLeaveNum',
                            width: '80px'
                        }
                    ]
                },
                {
                    title: '右后',
                    children: [
                        {
                            title: '上',
                            dataIndex: 'rightBackInNum',
                            key: 'rightBackInNum',
                            width: '80px'
                        },
                        {
                            title: '下',
                            dataIndex: 'rightBackLeaveNum',
                            key: 'rightBackLeaveNum',
                            width: '80px'
                        }
                    ]
                },
                {
                    title: '左前',
                    children: [
                        {
                            title: '上',
                            dataIndex: 'leftFrontInNum',
                            key: 'leftFrontInNum',
                            width: '80px'
                        },
                        {
                            title: '下',
                            dataIndex: 'leftFrontLeaveNum',
                            key: 'leftFrontLeaveNum',
                            width: '80px'
                        }
                    ]
                },
                {
                    title: '左中',
                    children: [
                        {
                            title: '上',
                            dataIndex: 'leftMidInNum',
                            key: 'leftMidInNum',
                            width: '80px'
                        },
                        {
                            title: '下',
                            dataIndex: 'leftMidLeaveNum',
                            key: 'leftMidLeaveNum',
                            width: '80px'
                        }
                    ]
                },
                {
                    title: '左后',
                    children: [
                        {
                            title: '上',
                            dataIndex: 'leftBackInNum',
                            key: 'leftBackInNum',
                            width: '80px'
                        },
                        {
                            title: '下',
                            dataIndex: 'leftBackLeaveNum',
                            key: 'leftBackLeaveNum',
                            width: '80px'
                        }
                    ]
                },
                {
                    title: '上下行',
                    dataIndex: 'lineType',
                    key: 'lineType',
                    width: '80px',
                    customRender: text => {
                        if (text === '0') {
                            return '上行'
                        } else if (text === '1') {
                            return '下行'
                        } else if (text === '2') {
                            return '环线'
                        } else {
                            return ''
                        }
                    }
                },
                {
                    title: '站点',
                    dataIndex: 'stationName',
                    key: 'stationName',
                    width: '140px',
                    ellipsis: true
                },
                {
                    title: '日志生成时间',
                    dataIndex: 'uploadTime',
                    key: 'uploadTime',
                    ellipsis: true
                }
            ],
            upAndDown: [
                { code: '', value: '全部' },
                { code: 0, value: '上行' },
                { code: 1, value: '下行' },
                { code: 2, value: '环线' }
            ],
            loadData: async p => {
                if (!this.queryDate[0]) {
                    this.$message.warning('请选择时间')
                    return
                }
                if (this.queryDate[1].diff(this.queryDate[0], 'hours') > 24) {
                    this.$message.warning('时间长度不能超过24小时')
                    return
                }
                if (this.queryParam.tenantId_equal === '') {
                    this.$message.warning('请选择机构')
                    return
                }
                return await passengerFlowLogs(Object.assign(p, this.queryParam))
            },
            tenantName: this.$store.state.user.tenant.tenantName
        }
    },
    activated() {
        this.init()
    },
    methods: {
        init() {
            this.getBus()
            this.getLines()
        },
        handleLine(v, lineSubId_equal) {
            this.queryParam.lineSubId_equal = lineSubId_equal
        },
        handleDeptChange({ value, title }) {
            this.getBus(value)
            this.getLines()
            this.queryParam.lineSubId_equal = undefined
            this.lineSubId_equal = undefined
            this.queryParam.areaName_equal = undefined
            this.tenantName = title
        },
        filterOption(input, option) {
            return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
        },
        getLines(tenantId = this.$store.state.user.tenant.tenantId) {
            getLineList({
                size: '-1',
                descs: 'create_time', //排序字段
                tenantId_equal: tenantId //所属机构
            }).then(res => {
                this.lines = res.records
            })
        },
        getBus(tenantId = this.$store.state.user.tenant.tenantId) {
            getBusList({
                size: '-1',
                descs: 'create_time', //排序字段
                tenantId_equal: tenantId
            }).then(res => {
                this.carNumbers = res.records
            })
        },
        dateChange(date, dateString) {
            if (date[1].diff(date[0], 'hours') > 24) {
                this.$message.warning('时间长度不能超过24小时')
            }
            this.queryParam.uploadTime_ge = dateString[0]
            this.queryParam.uploadTime_le = dateString[1]
        },

        /** 导出 */
        handleExport() {
            if (this.$moment(this.queryParam.endTime).diff(this.$moment(this.queryParam.beginTime), 'hours') > 24) {
                this.$message.warning('时间长度不能超过24小时')
                return
            }
            if (this.queryParam.tenantId_equal === '') {
                this.$message.warning('请选择机构')
                return
            }
            passengerFlowLogsExcel({ ...this.queryParam, tenantName: this.tenantName })
        }
    }
}
</script>

<style lang="less" scoped>
.alarmTypeName {
    border-radius: 20px;
    padding: 2px 8px;
    width: fit-content;
    background: rgba(250, 152, 164, 0.996078431372549);
    color: #fff;
    font-size: 12px;
}
/deep/ .ant-card {
    height: 100%;
}
/deep/ .ant-card-body {
    height: 100%;
}
/deep/ .ant-table-body {
    max-height: calc(((100vh - 331px) - 94px) - 0px) !important;
}
</style>
