<template>
    <div>
        <div class="flex ac" style="padding: 0 0 10px">
            <a-input v-model="queryParam.logInfo_like" style="width:240px" placeholder="请输入过滤关键词"></a-input>
            <a-button type="primary" style="margin-left:20px" @click="handleQuery(true)"> 查询 </a-button>
        </div>
        <div class="log_list">
            <!-- <a-table
                bordered
                :loading="isLoading"
                :columns="logColumns"
                :data-source="logData"
                size="small"
                :rowKey="record => record.id"
                :scrollToFirstRowOnChange="true"
                :pagination="pagination"
            >
            </a-table> -->
            <n-table ref="table"  
            :columns="columns" 
            :data="loadData" 
            size="middle"
            :rowKey="record => record.id"
            :scrollToFirstRowOnChange="true"
            :scrollY="400"
            >
            
            </n-table>
        </div>
    </div>
</template>
<script>
import { mapState } from 'vuex'
import { NTable } from '@/components'
import { aiDeviceErrorLogList } from "@/api/alarm";
export default {
    props: ['deviceId'],
    components:{
      NTable
    },
    data() {
        return {
            queryParam:{
              "deviceId_equal":this.deviceId,
              "logInfo_like": "",
              "createTime_ge": "",
              "createTime_le": "",
              "descs": "create_time"
            },
            columns: [
                {
                    title: '时间',
                    dataIndex: 'createTime',
                    width: '120px'
                },
                {
                    title: '内容',
                    dataIndex: 'logInfo',
                    width: '400px'
                }
            ],
            loadData: async (parameter) => {
                return  await aiDeviceErrorLogList(Object.assign(parameter, this.queryParam))
            },
            mediaTime: '',
            /** 定时器实例 */
            timer: null,
            /** 弹窗提示key */
            key: 'logKey',
            pagination: {
                current: 1,
                pageSize: 31,
                total: 0,
                // showSizeChanger: true,
                showTotal: (total, range) => {
                    return range[0] + '-' + range[1] + '共' + total + '条'
                },
                onChange: this.onChange,
                onShowSizeChange: this.onPageSizeChnage
            },
            isLoading: false
        }
    },
    computed: {
        ...mapState({
            mqtt: state => state.app.mqtt
        })
    },
    mounted() {
        // this.handleQuery()
    },
    
    methods: {
        handleQuery() {
          this.$refs.table.refresh()
        },
        setError() {
            // 自定义错误处理
            this.timer = setTimeout(() => {
                this.isLoading = false
            }, 1000 * 60)
        },
        cancelTimer() {
            clearTimeout(this.timer)
            this.isLoading = false
        },
        onChange(page, pageSize) {
            this.pagination.current = page
            this.pagination.pageSize = pageSize
            this.handleQuery()
        },
        onPageSizeChnage(current, size) {
            this.pagination.current = current
            this.pagination.pageSize = size
            this.handleQuery()
        }
    }
}
</script>

<style lang="less" scoped>
.deepGreen {
    color: #67c23a;
}
.lightGreen {
    color: rgb(225, 243, 216);
}
.deepRed {
    color: #f56c6c;
}
.lightRed {
    color: rgb(253, 226, 226);
}
/deep/ .ant-table-body {
    max-height: calc(((100vh - 560px) - 0px) - 0px) !important;
}
</style>
