<template>
    <a-modal
        :title="`${isAdd ? '新增' : '编辑'}通道`"
        centered
        :width="700"
        :visible="visible"
        :confirmLoading="confirmLoading"
        @ok="handleOk"
        @cancel="handleCancel"
        destroyOnClose
    >
        <a-form-model ref="form" :model="form" :rules="formRules" :label-col="labelCol" :wrapper-col="wrapperCol">
            <a-form-model-item label="通道名称" prop="mediaName" :rules="formRules.change">
                <a-input :disabled="!isAdd" v-model="form.mediaName" placeholder="请输入通道名称" />
            </a-form-model-item>
            <a-form-model-item label="关联门型" prop="doorType" :rules="formRules.change">
                <a-radio-group v-model="form.doorType">
                    <a-radio v-for="item in busModelDoorLink" :key="item.id" :value="item.busDoorType">{{ item.busDoorTypeName }}</a-radio>
                </a-radio-group>
            </a-form-model-item>
            <a-form-model-item label="客流统计" prop="klStatistics" :rules="formRules.change">
                <a-checkbox-group @change="klChange" v-model="form.klStatistics">
                    <a-checkbox value="0">上客</a-checkbox>
                    <a-checkbox value="1">下客</a-checkbox>
                </a-checkbox-group>
            </a-form-model-item>
            <a-form-model-item label="人脸抓拍">
                <a-radio-group v-model="form.faceType">
                    <a-radio value="0">上客</a-radio>
                    <a-radio value="1">下客</a-radio>
                </a-radio-group>
            </a-form-model-item>
            <a-form-model-item v-if="jhxDevice" label="通道URL" prop="mediaUrl1" :rules="formRules.change">
                <a-input v-model="form.mediaUrl1" placeholder="请输入视频地址" />
            </a-form-model-item>
            <a-form-model-item v-if="jhxDevice" label="RTSP本地代理" prop="rtspTransport1">
                <a-col :md="12" :sm="24">
                    <a-switch v-model="form.rtspTransport1" />
                </a-col>
                <a-col :md="12" :sm="24"> 转发到国际服务: <a-switch v-model="form.gbTransport1" /> </a-col>
            </a-form-model-item>
            <a-form-model-item v-if="jhxDevice" label="国际通道编号" prop="subId">
                <a-input v-model="form.subId" placeholder="请输入国际通道编号" />
            </a-form-model-item>
            <a-form-model-item label="通道描述" prop="mediaDesc">
                <a-input v-model="form.mediaDesc" placeholder="请输入通道描述" />
            </a-form-model-item>
        </a-form-model>
    </a-modal>
</template>
<script>
import { formRules } from '@/utils/validator'
import {
    aiDeviceMediaSave,
    aiDeviceMediaUpdate,
    aiDeviceMediaDetail,
    busModelDoorLinkList,
    updateForOtherDevice,
    saveForOtherDevice
} from '@/api/alarm'
export default {
    props: ['detail'],
    data() {
        return {
            isAdd: true,
            sceneList: [],
            doorList: [], // 门型列表
            form: {
                klStatistics: [],
                id: '', //多媒体通道ID
                deviceId: '', //设备ID
                mediaName: '', //相机编号
                mediaUrl1: '', //视频地址
                mediaUrl: '', //视频地址
                mediaDesc: '', //相机描述
                doorType: '', //门型 1-右前，2-右中 3-右后 4-左前 5-左中 6-左后
                faceType: '', //人脸抓拍类型 0-上客 1-下客
                isCountBoarding: '1', //上客是否统计 0-否 1-是
                isCountDisembark: '1', //下客是否统计 0-否 1-是
                rtspTransport: false, //RTSP本地代理 0-否 1-是
                gbTransport: false, //转发到国标服务 0-否 1-是
                subId: '' //国标平台通道编号
            },

            formRules: formRules,
            labelCol: {
                xs: { span: 24 },
                sm: { span: 5 }
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 15 }
            },
            visible: false,
            confirmLoading: false,
            /** 表格行数据 */
            tableCell: null,
            busModelDoorLink: [] // 关联的车辆的全部门型列表
        }
    },
    computed: {
        jhxDevice() {
            if (this.detail.deviceModel == '0' || this.detail.deviceModel == '1') {
                return true
            }
            return false
        }
    },
    methods: {
        /*   init方法是是初始化 用户打开该组件 */
        async init(record, busInfo) {
            this.tableCell = record

            console.log(record, busInfo, 'busInfo')
            this.visible = true /* visiable的值为true 打开弹窗 */
            /* 如果为null表示新增接着初始化表单 */
            if (record === null) {
                /*  isAdd 记录用户行为 新增or编辑 */
                this.form = this.$options.data().form
                this.form.deviceId = this.$route.query.id
                this.isAdd = true
            } else {
                this.isAdd = false //否则就不记录
                // let res = await this.getDetail(record.id)
                this.form = {
                    id: record.id,
                    deviceId: record.deviceId, //设备ID
                    mediaName: record.mediaName, //相机编号
                    // "mediaUrl":record.mediaUrl, //视频地址
                    mediaDesc: record.mediaDesc, //相机描述
                    doorType: record.doorType, //门型 1-右前，2-右中 3-右后 4-左前 5-左中 6-左后
                    faceType: record.faceType, //人脸抓拍类型 0-上客 1-下客
                    isCountBoarding: record.isCountBoarding + '', //上客是否统计 0-否 1-是
                    isCountDisembark: record.isCountDisembark + '', //下客是否统计 0-否 1-是
                    subId: record.subId, //国标平台通道编号
                    mediaUrl1: record.mediaUrl,
                    rtspTransport1: Boolean(record.rtspTransport),
                    gbTransport1: Boolean(record.gbTransport),
                    klStatistics: []
                }
                console.log(this, this.form.klStatistics, 'klStatistics')
                if (record.isCountBoarding) {
                    // 上客
                    this.form.klStatistics.push('0')
                }
                if (record.isCountDisembark) {
                    this.form.klStatistics.push('1')
                }
            }
            if (busInfo?.busModelId) {
                busModelDoorLinkList({
                    size: '-1', //每页数量
                    ascs: 'bus_door_tag', //排序字段
                    busModelId_equal: busInfo.busModelId //车型ID
                }).then(res => {
                    console.log(res, 'busModelDoorLink')
                    this.busModelDoorLink = res.records
                })
            }
        },

        getDetail(id) {
            return aiDeviceMediaDetail({ id })
        },
        handleOk() {
            this.$refs.form.validate(res => {
                if (!res) {
                    return
                }
                let addApi = this.jhxDevice ? aiDeviceMediaSave : saveForOtherDevice
                let updateApi = this.jhxDevice ? aiDeviceMediaUpdate : updateForOtherDevice
                const reqList = this.isAdd ? addApi : updateApi
                this.confirmLoading = true
                if (this.jhxDevice) {
                    this.$parent.getRealTaskStatus(() => {
                        if (!this.isAdd) {
                            this.tableCell.editText = '修改中...'
                            this.tableCell.editStatus = true
                        }
                        this.form.mediaUrl = encodeURIComponent(this.form.mediaUrl1)
                        this.form.rtspTransport = this.form.rtspTransport1 ? '1' : '0'
                        this.form.gbTransport = this.form.gbTransport1 ? '1' : '0'
                        reqList({
                            ...this.form
                            // rtspTransport: Number(this.form.rtspTransport),
                            // gbTransport: Number(this.form.gbTransport)
                        })
                        this.$parent.setError()
                    })
                } else {
                    this.form.mediaUrl = encodeURIComponent(this.form.mediaUrl1)
                    this.form.rtspTransport = this.form.rtspTransport1 ? '1' : '0'
                    this.form.gbTransport = this.form.gbTransport1 ? '1' : '0'
                    reqList({
                        ...this.form
                    })
                        .then(() => {
                            this.$emit('ok')
                            this.visible = false
                            this.$message.success(this.isAdd ? '添加成功' : '修改成功')
                            this.confirmLoading = false
                        })
                        .catch(err => {
                            this.$message.error(err)
                            this.confirmLoading = false
                        })
                }
            })
        },
        handleCancel() {
            this.confirmLoading = false
            this.visible = false // 点击确定关闭弹窗
        },
        /**客流选择 */
        klChange(e) {
            console.log('e', e, this.form.klStatistics)
            this.form.isCountBoarding = e.includes('0') ? '1' : '0'
            this.form.isCountDisembark = e.includes('1') ? '1' : '0'
        }
    }
}
</script>
