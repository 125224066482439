import Vue from 'vue'
import router from './index'
import store from '@/store'

import NProgress from 'nprogress' // progress bar
import '@/components/NProgress/nprogress.less' // progress bar custom style
import { setDocumentTitle, domTitle } from '@/utils/domUtil'
import { ACCESS_TOKEN, ALL_APPS_MENU } from '@/store/mutation-types'

import { Modal, notification } from 'ant-design-vue'
import { timeFix } from '@/utils/util' /// es/notification
NProgress.configure({ showSpinner: false })
const whiteList = [
    'login',
    'export'
] // no redirect whitelist
// 无默认首页的情况
const defaultRoutePath = '/welcome'
const loginRoutePath = '/login'
store.state.user.roles = [];
router.beforeEach((to, from, next) => {
    NProgress.start() // start progress bar 
    to.meta && typeof to.meta.title !== 'undefined' && setDocumentTitle(`${to.meta.title}`)
    if (Vue.ls.get(ACCESS_TOKEN)) {
        /* has token */
        if (to.path === loginRoutePath) {
            next({ path: defaultRoutePath })
            NProgress.done()
        } else {
            if (store.state.user.roles.length === 0) {
                store
                    .dispatch('GetInfo')
                    .then(res => {
                        if (res.menus.length < 1) {
                            Modal.error({
                                title: '提示：',
                                content: '无菜单权限，请联系管理员',
                                okText: '确定',
                                onOk: () => {
                                    store.dispatch('Logout').then(() => {
                                        window.location.reload()
                                    })
                                }
                            })
                            return
                        }
                        // eslint-disable-next-line camelcase
                        const all_app_menu = Vue.ls.get(ALL_APPS_MENU)
                        let antDesignmenus
                        // eslint-disable-next-line camelcase
                        if (all_app_menu == null) {
                            const applocation = []
                            res.apps.forEach(item => {
                                const apps = { code: '', name: '', active: '', menu: '' }
                                if (item.active) {
                                    apps.code = item.code
                                    apps.name = item.name
                                    apps.active = item.active
                                    apps.menu = res.menus
                                    antDesignmenus = res.menus
                                } else {
                                    apps.code = item.code
                                    apps.name = item.name
                                    apps.active = item.active
                                    apps.menu = ''
                                }
                                applocation.push(apps)
                            })
                            Vue.ls.set(ALL_APPS_MENU, applocation, 7 * 24 * 60 * 60 * 1000)
                            // 延迟 1 秒显示欢迎信息
                            setTimeout(() => {
                                notification.success({
                                    message: '欢迎',
                                    description: `${timeFix()}，欢迎回来`
                                })
                            }, 1000)
                        } else {
                            // antDesignmenus = Vue.ls.get(ALL_APPS_MENU)[0].menu
                            antDesignmenus = JSON.parse(window.localStorage.getItem('pro__USER_INFO')).value.menus
                        }
                        console.log(antDesignmenus, 'antDesignmenusantDesignmenus');
                        store.dispatch('GenerateRoutes', { antDesignmenus }).then(() => {
                            // 动态添加可访问路由表user
                            router.addRoutes(store.state.route.addRouters)
                            // 请求带有 redirect 重定向时，登录自动重定向到该地址
                            const dashboard = to.path == '/welcome' ? store.state.route.firstRoute : to.path
                            const redirect = decodeURIComponent(from.query.redirect || dashboard || store.state.route.firstRoute)
                            if (to.path === redirect) {
                                // next({ path: redirect })
                                next({ ...to, replace: true }) // 带参路由覆盖原路由，防参数丢失
                            } else {
                                // 跳转到目的路由
                                next({ path: redirect })
                            }
                            console.log('redirect', redirect)
                        })
                    })
                    .catch(() => {
                        store.dispatch('Logout').then(() => {
                            next({ path: loginRoutePath }) // , query: { redirect: to.fullPath }
                        })
                    })
            } else {
                next()
            }
        }
    } else {
        if (whiteList.includes(to.name)) {
            // 在免登录白名单，直接进入
            next()
        } else {
            next({ path: loginRoutePath }) // , query: { redirect: to.fullPath }
            NProgress.done()
        }
    }
})

router.afterEach(() => {
    NProgress.done() // finish progress bar
})
