<template>
    <div>
        <a-empty v-if="empty" style="color: #fff" />
        <div ref="chart" :style="{ height: '400px', overflow: 'hidden', position: 'relative' }" v-else></div>
    </div>
</template>

<script>
import * as echarts from 'echarts'
export default {
    props: {
        title: {
            type: String,
            default: ''
        },
        data: {
            type: Object,
            default: {}
        }
    },

    watch: {
        data: {
            handler: function(val, oldVal) {
                console.log('cout', val)
                if (val && val.xdata && val.xdata.length == 0) {
                    this.empty = true
                } else {
                    this.empty = false
                    this.$nextTick(() => {
                        this.init()
                    })
                }
            },
            deep: true
        }
    },
    data() {
        return {
            empty: true,
            myChart: null,
            option: null
        }
    },
    methods: {
        init() {
            this.myChart = echarts.init(this.$refs.chart)
            window.addEventListener('resize', () => {
                setTimeout(() => {
                    this.myChart.resize()
                }, 200)
            })
            this.setOptions()
        },
        setOptions() {
            this.myChart.setOption(
                (this.option = {
                    title: {
                        show: true,
                        text: this.title,
                        textStyle: {
                            fontWeight: 'lighter'
                        }
                    },
                    tooltip: {
                        trigger: 'axis'
                    },
                    legend: {
                        data: ['上车人数', '下车人数']
                    },
                    grid: {
                        left: '3%',
                        right: '3%',
                        bottom: '3%',
                        containLabel: true
                    },
                    xAxis: {
                        type: 'category',
                        boundaryGap: false,
                        data: this.data.xdata,
                        axisLabel: {
                            color: '#333',
                            //  让x轴文字方向为竖向
                            interval: 0,
                            formatter: function(value) {
                                return value.split('').join('\n')
                            }
                        }
                    },
                    yAxis: {
                        type: 'value'
                    },
                    series: [
                        {
                            name: '上车人数',
                            type: 'line',
                            stack: 'Total',
                            data: this.data.inNum
                        },
                        {
                            name: '下车人数',
                            type: 'line',
                            stack: 'Total',
                            data: this.data.leaveNum
                        }
                    ]
                    // series: xSeries
                }),
                true
            )
            console.log('cout', this.option)
            this.myChart.setOption(this.option)
        }
    }
}
</script>

<style></style>
