<template>
    <div>
        <div class="flex sb ac">
            <a-button class="btn-add" type="primary" @click="addMedia()" v-if="hasPerm('aiDevice:detail:media')" :disabled="addBtndiabled"
                >新增</a-button
            >
            <div class="btn-tb" style="margin-left:auto" v-if="jhxDevice">
                最新同步时间：{{ mediaTime }}
                <a-button class="btn-add" type="default" @click="sync_media" :disabled="detail.deviceStatus != 1"> {{ syncBtnText }} </a-button>
            </div>
        </div>
        <div class="search flex"></div>
        <div class="log_list">
            <a-table
                bordered
                :loading="isLoading"
                :columns="jhxDevice ? mediaColumns : mediaColumns2"
                :data-source="mediaData"
                size="small"
                :rowKey="record => record.id"
                :scroll="{ x: 1600 }"
                :scrollToFirstRowOnChange="true"
                :pagination="pagination"
            >
                <span slot="rtspTransport" slot-scope="text">
                    <span v-if="text">是2222</span>
                    <span v-else>否</span>
                </span>
                <span slot="doorType" slot-scope="text">
                    <span v-if="text">{{ getDoorName(text) }}</span>
                </span>
                <span slot="isCountBoarding" slot-scope="text, record">
                    <span v-if="record.isCountBoarding">上</span><span v-if="record.isCountBoarding && record.isCountDisembark">、</span>
                    <span v-if="record.isCountDisembark">下</span>
                </span>
                <!-- v-if="hasPerm('aiDevice:detail:media')" :disabled="record.editStatus" -->
                <span slot="action" slot-scope="text, record">
                    <a-button @click="$refs.editForm.init(record, busInfo)" type="link">编辑</a-button>
                    <a-divider type="vertical" v-if="jhxDevice" />
                    <a-button v-if="jhxDevice" type="link" style="padding:0" @click="$refs.senceForm.init(record)">客流/人脸设置</a-button>
                    <a-divider type="vertical" />
                    <a-popconfirm placement="topRight" title="你确定要删除吗？" @confirm="() => del(record)">
                        <a-button type="link" style="color:red">删除</a-button>
                    </a-popconfirm>
                </span>
            </a-table>
        </div>
        <!-- 编辑 -->
        <edit ref="editForm" :detail="detail" @ok="handleEditOk" />
        <!-- 场景设置 -->
        <senceForm ref="senceForm" @getMediaList="getMediaList()" />
    </div>
</template>
<script>
import Edit from './editForm.vue'
import senceForm from './senceForm.vue'
import {
    aiDeviceMediaSynInfo,
    aiDeviceMediaList,
    aiDeviceMediaRemove,
    aiDeviceTasklist,
    updateScene,
    busInfoDetail,
    removeForOtherDevice
} from '@/api/alarm'
import { sysDictTypeDropDown } from '@/api/comm/index'
import { formRules } from '@/utils/validator'
import { getDoorName } from '@/utils/returnStatus.js'
export default {
    components: {
        Edit,
        senceForm
    },
    props: ['deviceTag', 'detail', 'busInfo', 'addBtndiabled'],
    data() {
        return {
            getDoorName: getDoorName,
            labelCol: {
                xs: { span: 24 },
                sm: { span: 5 }
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 15 }
            },
            mediaColumns: [
                {
                    title: '通道名称',
                    dataIndex: 'mediaName',
                    width: '180px'
                },
                {
                    title: '通道URL',
                    scopedSlots: { customRender: 'mediaUrl' },
                    dataIndex: 'mediaUrl',
                    ellipsis: true,
                    width: '300px'
                },
                {
                    title: '门型',
                    dataIndex: 'doorType',
                    scopedSlots: { customRender: 'doorType' },
                    width: '200px'
                },
                {
                    title: '上/下客',
                    scopedSlots: { customRender: 'isCountBoarding' },
                    dataIndex: 'isCountBoarding',
                    width: '100px'
                },
                {
                    title: '通道别名',
                    dataIndex: 'mediaDesc',
                    width: '150px'
                },
                {
                    title: '是否以RTSP转发',
                    dataIndex: 'rtspTransport',
                    scopedSlots: { customRender: 'rtspTransport' },
                    width: '150px'
                },
                {
                    title: '通道状态',
                    dataIndex: 'mediaStatusDesc',
                    width: '150px'
                },
                {
                    title: '操作',
                    dataIndex: 'action',
                    width: '280px',
                    scopedSlots: { customRender: 'action' }
                }
            ],
            mediaColumns2: [
                {
                    title: '通道名称',
                    dataIndex: 'mediaName',
                    width: '180px'
                },
                {
                    title: '门型',
                    dataIndex: 'doorType',
                    scopedSlots: { customRender: 'doorType' },
                    width: '200px'
                },
                {
                    title: '上/下客',
                    scopedSlots: { customRender: 'isCountBoarding' },
                    dataIndex: 'isCountBoarding',
                    width: '100px'
                },
                {
                    title: '通道别名',
                    dataIndex: 'mediaDesc',
                    width: '150px'
                },
                {
                    title: '操作',
                    dataIndex: 'action',
                    width: '280px',
                    scopedSlots: { customRender: 'action' }
                }
            ],
            formRules: formRules,
            mediaData: [],
            mediaTime: '',
            queryParam: {
                deviceId_equal: '',
                descs: 'create_time'
            },
            /** 定时器实例 */
            timer: null,
            /** 弹窗提示key */
            key: 'mediaKey',
            pagination: {
                current: 1,
                pageSize: 10,
                total: 0,
                showSizeChanger: true,
                showTotal: (total, range) => {
                    if (total) {
                        return range[0] + '-' + range[1] + '共' + total + '条'
                    } else {
                        return ''
                    }
                },
                onChange: this.onChange,
                onShowSizeChange: this.onPageSizeChnage
            },
            form: {
                id: '',
                sceneId: '',
                sceneName: ''
            },
            isLoading: false,
            /**场景弹框 */
            visible: false,
            syncBtnText: '同步'
            // busInfo:{}
        }
    },
    computed: {
        jhxDevice() {
            if (this.detail.deviceModel == '0' || this.detail.deviceModel == '1') {
                return true
            }
            return false
        }
    },
    mounted() {
        this.getMediaList(true)
        // this.getBusInfoDetail()
    },

    methods: {
      handleEditOk(){
        this.getMediaList(true)
      },
        getBusInfoDetail() {
            //获取终端绑定的车辆详情
            busInfoDetail({
                id: this.$route.query.areaId // areaId
            }).then(res => {
                this.busInfo = res
                console.log(this.busInfo, 'this.busInfo')
            })
        },
        async addMedia() {
            let res = await busInfoDetail({ id: this.$route.query.areaId })
            console.log(res, 'this.busInfo2')
            if (res.id) {
                this.$refs.editForm.init(null, res)
            } else {
                this.$message.warning('请先将终端绑定车辆信息')
            }
        },
        handleOk() {
            this.$refs.form.validate(res => {
                if (!res) {
                    return
                }
                updateScene(this.form).then(res => {
                    this.$message.success('场景设置成功')
                    this.visible = false
                    this.getMediaList(true)
                })
            })
        },
        getRealTaskStatus(fn) {
            return aiDeviceTasklist({
                current: -1,
                size: -1,
                deviceTag_like: this.deviceTag,
                taskStatus_equal: 0
            }).then(({ records }) => {
                if (records.length > 0) {
                    this.$message.error('当前有任务在执行')
                    return
                }
                fn && fn()
            })
        },
        /** 同步 */
        sync_media() {
            this.getRealTaskStatus(() => {
                aiDeviceMediaSynInfo({
                    deviceId: this.$route.query.id
                })
                this.syncBtnText = '同步中...'
                this.mediaTime = this.$moment().format('YYYY-MM-DD HH:mm:ss')
                this.cancelTimer()
                this.setError()
            })
        },
        setError() {
            // 自定义错误处理
            this.timer = setTimeout(() => {
                // this.$message.error({
                //     key: this.key,
                //     content: '操作超时'
                // })
                this.$refs.editForm.confirmLoading = false
                this.syncBtnText = '同步'
                this.getMediaList()
                this.isLoading = false
            }, 1000 * 60)
        },
        cancelTimer() {
            clearTimeout(this.timer)
            this.isLoading = false
            this.$refs.editForm.confirmLoading = false
        },
        /** 获取媒体列表 */
        getMediaList(isFirst) {
            this.isLoading = true
            aiDeviceMediaList({
                ...this.queryParam,
                current: this.pagination.current,
                size: this.pagination.pageSize,
                deviceId_equal: this.$route.query.id
            }).then(re => {
                re.records.forEach((item, index) => {
                    re.records[index].editText = '编辑'
                    re.records[index].editStatus = false
                    re.records[index].deleteText = '删除'
                    re.records[index].deleteStatus = false
                })
                this.isLoading = false
                this.mediaData = re.records

                this.syncBtnText = '同步'
                if (isFirst) {
                    this.mediaTime = re?.records[0]?.updateTime
                }
                if (!re) {
                    return
                } else {
                    this.pagination.total = Number(re.total)
                }
            })
        },
        del(record) {
            record.deleteText = '删除中...'
            record.deleteStatus = true
            this.setError()
            this.getRealTaskStatus(() => {
                let removeApi = this.jhxDevice ? aiDeviceMediaRemove : removeForOtherDevice
                removeApi({
                    id: record.id, //多媒体通道ID
                    deviceId: record.deviceId //设备ID})
                })
            })
        },
        onChange(page, pageSize) {
            this.pagination.current = page
            this.pagination.pageSize = pageSize
            this.getMediaList(true)
        },
        onPageSizeChnage(current, size) {
            this.pagination.current = current
            this.pagination.pageSize = size
            this.getMediaList(true)
        }
    }
}
</script>

<style lang="less" scoped>
.deepGreen {
    color: #67c23a;
}
.lightGreen {
    color: rgb(225, 243, 216);
}
.deepRed {
    color: #f56c6c;
}
.lightRed {
    color: rgb(253, 226, 226);
}
/deep/ .ant-table-body {
    max-height: calc(((100vh - 560px) - 0px) - 0px) !important;
}
</style>
