/*
 * @Autor: dingxiaolin
 * @Date: 2021-12-01 08:58:39
 * @LastEditors: dingxiaolin
 * @LastEditTime: 2022-10-22 09:13:41
 */
import { axios } from '@/utils/request'
import config from '@/config/config.js'

/**
 * 获取机构树
 */
export function getOrgTree(parameter) {
    return axios({
        url: config.namespace + '/org/tree',
        method: 'get',
        params: parameter
    })
}

/**
 * 获取机构列表
 */
export function getOrgList(parameter) {
    return axios({
        url: config.namespace + '/org/list',
        method: 'get',
        params: parameter
    })
}

/**
 * 获取机构列表详情
 */
export function getOrgDetail(parameter) {
    return axios({
        url: config.namespace + '/org/detail',
        method: 'get',
        params: parameter
    })
}
/**
 * 获取机构列表
 */
export function getOrgPage(parameter) {
    return axios({
        url: config.namespace + '/org/page',
        method: 'get',
        params: parameter
    })
}

/**
 * 新增机构
 */
export function sysOrgAdd(parameter) {
    return axios({
        url: config.namespace + '/org/add',
        method: 'post',
        data: parameter
    })
}

/**
 * 编辑机构
 */
export function sysOrgEdit(parameter) {
    return axios({
        url: config.namespace + '/org/edit',
        method: 'post',
        data: parameter
    })
}

/**
 * 删除机构
 */
export function sysOrgDelete(parameter) {
    return axios({
        url: config.namespace + '/org/delete',
        method: 'post',
        data: parameter
    })
}


/** 确警信息修改 */
export function sysOrgAlarmNoticeEdit(parameter) {
    return axios({
        url: config.namespace + '/org/editAlarmConfirmNotice',
        method: 'post',
        data: parameter
    })
}

