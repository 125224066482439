<template>
    <div>
        <div class="flex sb ac">
            <a-button class="btn-add" type="primary" @click="show()" v-if="hasPerm('aiDevice:detail:task')">新增</a-button>
            <div class="btn-tb" style="margin-left:auto">
                最新同步时间：{{ taskTime }}
                <a-button class="btn-add" type="default" @click="sync_task"> {{ syncBtnText }} </a-button>
            </div>
        </div>

        <div class="search flex"></div>
        <div class="log_list">
            <a-table
                bordered
                :loading="isLoading"
                :columns="taskColumns"
                :data-source="taskData"
                size="small"
                :rowKey="record => record.id"
                :scrollToFirstRowOnChange="true"
                :pagination="pagination"
            >
                <span slot="taskName" slot-scope="text, record">
                    {{ text }}
                    <a-button @click="preview(record)">预览</a-button>
                </span>
                <span slot="disposeInfo" slot-scope="text">
                    <span>{{ text ? JSON.parse(text).map(v => v.name) : '-' }}</span>
                </span>
                <span slot="action" slot-scope="text, record, index" v-if="hasPerm('aiDevice:detail:task')">
                    <a-button
                        v-if="record.taskStatus == 4 || record.taskStatus == 2"
                        @click="handleStopClick(record, index)"
                        :class="['deepRed', record.stopStatus ? 'lightRed' : '']"
                        type="link"
                        :disabled="record.stopStatus"
                        >{{ record.stopText }}
                    </a-button>
                    <a-button
                        v-if="record.taskStatus == 0"
                        @click="handleRunClick(record, index)"
                        :class="['deepGreen', record.runStatus ? 'lightGreen' : '']"
                        type="link"
                        :disabled="record.runStatus"
                    >
                        {{ record.runText }}
                    </a-button>
                    <a-button
                        @click="show(record)"
                        :class="['deepBlue', record.editStatus ? 'lightBlue' : '']"
                        type="link"
                        :disabled="record.editStatus"
                    >
                        {{ record.editText }}
                    </a-button>
                    <a-button
                        @click="settingArea(record)"
                        :class="['deepBlue', record.areaStatus ? 'lightBlue' : '']"
                        type="link"
                        :disabled="record.areaStatus"
                    >
                        {{ record.areaText }}
                    </a-button>
                    <a-popconfirm placement="topRight" title="你确定要删除该任务吗？" @confirm="() => handleDeleteClick(record, index)">
                        <a-button
                            v-if="record.taskStatus == 0"
                            :class="['deepRed', record.deleteStatus ? 'lightRed' : '']"
                            type="link"
                            :disabled="record.deleteStatus"
                        >
                            {{ record.deleteText }}
                        </a-button>
                    </a-popconfirm>
                </span>
            </a-table>
        </div>
        <edit ref="edit" />
        <a-modal :visible="isPreview" title="任务预览" @cancel="() => (isPreview = false)">
            <template #footer>
                <a-button @click="() => (isPreview = false)">取消</a-button>
            </template>
            <a-spin tip="Loading..." :spinning="previewImgSrc == ''">
                <img :src="previewImgSrc" alt="" width="100%" height="100%" />
            </a-spin>
        </a-modal>
    </div>
</template>

<script>
import { aiDeviceAlgorithmSynInfo, aiDeviceAlgorithmList, aiDeviceTaskOpForRJ, aiDeviceTasklist } from '@/api/alarm'
import Edit from './editForm.vue'
import { mapMutations, mapState } from 'vuex'
export default {
    components: {
        Edit
    },
    props: ['deviceTag'],
    computed: {
        ...mapState({
            mqtt: state => state.app.mqtt
        })
    },
    data() {
        return {
            taskData: [],
            taskColumns: [
                {
                    title: '任务编号',
                    dataIndex: 'taskName',
                    width: '120px',
                    scopedSlots: { customRender: 'taskName' }
                },
                {
                    title: '通道名称',
                    dataIndex: 'mediaName',
                    width: '120px',
                    ellipsis: true
                },
                {
                    title: '状态描述',
                    dataIndex: 'taskStatusDesc',
                    width: '100px',
                    ellipsis: true
                },
                {
                    title: '算法配置信息',
                    dataIndex: 'disposeInfo',
                    scopedSlots: { customRender: 'disposeInfo' },
                    width: '370px',
                    ellipsis: true
                },
                {
                    title: '操作',
                    dataIndex: 'action',
                    scopedSlots: { customRender: 'action' },
                    width: '150px'
                }
            ],
            taskTime: '',
            queryParam: {
                current: 1,
                size: 10,
                deviceId_equal: '',
                descs: 'create_time'
            },
            /** 弹窗提示key */
            key: 'taskKey',
            pagination: {
                current: 1,
                pageSize: 10,
                total: 0,
                showSizeChanger: true,
                showTotal: (total, range) => {
                    return range[0] + '-' + range[1] + '共' + total + '条'
                },
                onChange: this.onPageChange,
                onShowSizeChange: this.onShowSizeChange
            },
            timer: null,
            isPreview: false,
            previewImgSrc: '',
            isLoading: false,
            syncBtnText: '同步'
        }
    },
    created() {
        this.$root.$on('task', res => {
            console.log('first', res)
            if (res.Event == '/alg_ability_fetch') {
                this.SAVE_TAGS(res.Ability)
            }
            if (res.Event == '/alg_task_snap') {
                if (res.Result.Code == 0) {
                    this.previewImgSrc = 'data:image/jpeg;base64,' + res.Base64
                } else {
                    this.isLoading = false
                    this.isPreview = false
                    this.$message.error({
                        key: this.key,
                        content: '图片获取失败'
                    })
                }
            }
        })
    },
    mounted() {
        this.getTaskList(true)
    },
    destroyed() {
        this.$root.$off('task')
    },
    methods: {
        ...mapMutations(['SAVE_TAGS']),
        /** 查找当前任务队列  */
        getRealTaskStatus(fn) {
            return aiDeviceTasklist({
                current: -1,
                size: -1,
                deviceTag_like: this.deviceTag,
                taskStatus_equal: 0
            }).then(({ records }) => {
                if (records.length > 0) {
                    this.$message.error('当前有任务在执行')
                    return
                }
                fn && fn()
            })
        },
        /**同步任务 */
        sync_task() {
            this.getRealTaskStatus(() => {
                // 同步
                aiDeviceAlgorithmSynInfo({
                    deviceId: this.$route.query.id
                })
                this.syncBtnText = '同步中...'

                this.taskTime = this.$moment().format('YYYY-MM-DD HH:mm:ss')
                this.cancelTimer()
                this.setError()
                this.$root.$emit('clostTab', 'detail/zone')
            })
        },
        setError() {
            // 自定义错误处理
            this.timer = setTimeout(() => {
                // this.$message.error({
                //     key: this.key,
                //     content: '操作超时'
                // })
                this.$refs.edit.confirmLoading = false
                this.getTaskList()
                this.syncBtnText = '同步'
                this.isLoading = false
            }, 1000 * 60)
        },
        /** 获取任务列表 */
        getTaskList(isFirst = false) {
            this.isLoading = true
            aiDeviceAlgorithmList({
                ...this.queryParam,
                current: this.pagination.current,
                size: this.pagination.pageSize,
                deviceId_equal: this.$route.query.id
            }).then(re => {
                re.records.forEach((item, index) => {
                    re.records[index].stopText = '停止'
                    re.records[index].runText = '启动'
                    re.records[index].deleteText = '删除'
                    re.records[index].editText = '编辑'
                    re.records[index].areaText = '区域配置'
                    re.records[index].areaStatus = re.records[index].taskStatus == 0 ? true : false
                    re.records[index].editStatus = false
                    re.records[index].stopStatus = false
                    re.records[index].runStatus = false
                    re.records[index].deleteStatus = false
                })
                this.syncBtnText = '同步'
                this.isLoading = false
                this.taskData = re.records
                // this.pagination.total = re.total
                this.pagination.total = Number(re.total)
                if (isFirst) {
                    this.taskTime = re.records[0].updateTime
                }
            })
        },
        /** 区域配置 */
        settingArea(record) {
            this.$router.push({
                path: `/aiDevices/detail/zone?id=${record.id}&deviceTag=${this.deviceTag}&backId=${this.$route.query.id}&areaId=${this.$route.query.areaId}`
            })
        },
        /** 添加任务 */
        show(record = null) {
            this.$refs.edit.show(record)
            const deviceTags = this.$store.state.app.deviceTagsList
            console.log('111111', deviceTags)
            if (!deviceTags || deviceTags.length == 0) {
                let params = {
                    BoardId: this.deviceTag,
                    Event: '/alg_ability_fetch'
                }
                console.log('222222', params)
                this.mqtt.publish('/edge_app_controller', JSON.stringify(params))
            }
        },
        /** 删除任务 */
        handleDeleteClick(record, index) {
            this.getRealTaskStatus(() => {
                aiDeviceTaskOpForRJ({
                    id: record.id, //算法任务ID
                    deviceId: record.deviceId, //设备ID
                    taskTag: '3' //任务操作 0-升级 1-任务启动 2-任务停止 3-任务删除})
                })
                record.deleteText = '删除中'
                record.deleteStatus = true
                this.setError()
            })
        },
        /** 停止任务 */
        handleStopClick(record, index) {
            this.getRealTaskStatus(() => {
                aiDeviceTaskOpForRJ({
                    id: record.id, //算法任务ID
                    deviceId: record.deviceId, //设备ID
                    taskTag: '2' //任务操作 0-升级 1-任务启动 2-任务停止 3-任务删除})
                })
                record.stopText = '停止中'
                record.stopStatus = true
                this.setError()
            })
        },
        /** 启动任务 */
        handleRunClick(record, index) {
            this.getRealTaskStatus(() => {
                aiDeviceTaskOpForRJ({
                    id: record.id, //算法任务ID
                    deviceId: record.deviceId, //设备ID
                    taskTag: '1' //任务操作 0-升级 1-任务启动 2-任务停止 3-任务删除})
                })
                record.runText = '开启中'
                record.runStatus = true
                this.setError()
            })
        },
        cancelTimer() {
            console.log('clear', this.timer, typeof this.timer)
            clearTimeout(this.timer)
            this.isLoading = false
            this.$refs.edit.confirmLoading = false
        },
        /** 预览 */
        preview(record) {
            this.isPreview = true
            this.previewImgSrc = ''
            let params = {
                BoardId: this.deviceTag,
                Event: '/alg_task_snap',
                AlgTaskSession: record.taskName
            }
            this.mqtt.publish('/edge_app_controller', JSON.stringify(params))
        },
        onPageChange(page, pageSize) {
            this.pagination.current = page
            this.pagination.pageSize = pageSize
            this.getTaskList(true)
        },
        onShowSizeChange(current, size) {
            console.log(current, size)
            this.pagination.current = current
            this.pagination.pageSize = size
            this.getTaskList(true)
        }
    }
}
</script>

<style lang="less" scoped>
.deepGreen {
    color: #67c23a;
}
.lightGreen {
    color: rgb(225, 243, 216);
}
.deepRed {
    color: #f56c6c;
}
.lightRed {
    color: rgb(253, 226, 226);
}
.deepBlue {
    color: #409eff;
}
.lightBlue {
    color: rgb(121, 187, 255);
}
</style>
