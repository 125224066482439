/*
 * @Autor: dingxiaolin
 * @Date: 2021-06-30 10:42:36
 * @LastEditors: dingxiaolin
 * @LastEditTime: 2023-03-17 08:23:00
 */
export default {
    primaryColor: null, // primary color of ant design
    navTheme: 'dark', // theme for nav menu
    colorWeak: false,
    multiTab: true,
    clientId: 'cloud_data', // 客户端id
    clientSecret: 'cloud_data_secret', // 客户端密钥
    production: process.env.NODE_ENV === 'production',
    vue_ls_Options: {
        namespace: 'pro__', // key prefix
        name: 'ls', // name variable Vue.[ls] or this.[$ls],
        storage: 'local' // storage name session, local, memory
    },
    oss: {
        region: 'oss-cn-shanghai',
        secure: true,
        accessKeyId: 'LTAI5tF7BEedCDtRH6UEzpNj',
        accessKeySecret: 'qWGqHySYiuT5tLxdW3UJSRMxi2vTCd',
        bucket: 'oss-jhx-maritime'
    }
}
