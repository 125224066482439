/**
 * 该文件是为了按需加载，剔除掉了一些不需要的框架组件。
 * 减少了编译支持库包大小
 * @author Antonio
 * 当需要更多组件依赖时，在该文件加入即可
 */
import Vue from 'vue'
import {
    ConfigProvider,
    Layout,
    Input,
    InputNumber,
    Button,
    Switch,
    Radio,
    Checkbox,
    Select,
    Card,
    Form,
    Row,
    Col,
    Modal,
    Table,
    Tabs,
    Icon,
    Badge,
    Popover,
    Dropdown,
    List,
    Avatar,
    Breadcrumb,
    Steps,
    Spin,
    Menu,
    Drawer,
    Tooltip,
    Alert,
    Tag,
    Divider,
    DatePicker,
    TimePicker,
    Upload,
    Progress,
    Skeleton,
    Popconfirm,
    message,
    notification,
    TreeSelect,
    Tree,
    Transfer,
    Empty,
    PageHeader,
    Pagination,
    Descriptions,
    FormModel,
    Result,
    Timeline,
    Collapse
} from 'ant-design-vue'
import { hasBtnPermission } from '@/utils/permissions' // button permission
import moment from 'moment'
import _ from 'lodash'
import { filterEnum } from '@/utils/util'
// import $ from 'jquery'
Vue.use(FormModel)
Vue.use(ConfigProvider)
Vue.use(Layout)
Vue.use(Input)
Vue.use(InputNumber)
Vue.use(Button)
Vue.use(Switch)
Vue.use(Radio)
Vue.use(Checkbox)
Vue.use(Select)
Vue.use(Card)
Vue.use(Form)
Vue.use(Row)
Vue.use(Col)
Vue.use(Modal)
Vue.use(Table)
Vue.use(Tabs)
Vue.use(Icon)
Vue.use(Badge)
Vue.use(Popover)
Vue.use(Dropdown)
Vue.use(List)
Vue.use(Avatar)
Vue.use(Breadcrumb)
Vue.use(Steps)
Vue.use(Spin)
Vue.use(Menu)
Vue.use(Drawer)
Vue.use(Tooltip)
Vue.use(Alert)
Vue.use(Tag)
Vue.use(Divider)
Vue.use(DatePicker)
Vue.use(TimePicker)
Vue.use(Upload)
Vue.use(Progress)
Vue.use(Skeleton)
Vue.use(Popconfirm)
Vue.use(notification)
Vue.use(TreeSelect)
Vue.use(Tree)
Vue.use(Transfer)
Vue.use(Empty)
Vue.use(PageHeader)
Vue.use(Descriptions)
Vue.use(Result)
Vue.use(Timeline)
Vue.use(Collapse)
Vue.use(Pagination)

Vue.prototype.window = window
Vue.prototype.$confirm = Modal.confirm
Vue.prototype.$message = message
Vue.prototype.$notification = notification
Vue.prototype.$info = Modal.info
Vue.prototype.$success = Modal.success
Vue.prototype.$error = Modal.error
Vue.prototype.$warning = Modal.warning
Vue.prototype._ = _
Vue.prototype.hasPerm = hasBtnPermission
Vue.prototype.$moment = moment
Vue.prototype.$filterEnum = filterEnum
