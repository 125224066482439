<template>
    <a-modal :title="title" :visible="visible" :width="700" :confirm-loading="confirmLoading" @ok="handleOk" @cancel="handleCancel" destroyOnClose>
        <a-form-model ref="form" :model="form" :rules="formRules" :label-col="labelCol" :wrapper-col="wrapperCol">
            <a-form-model-item label="所属机构" prop="tenantId" :rules="formRules.change">
                <DeptTree :all="false" v-model="form.tenantId" :disabled="title == '编辑站点'"></DeptTree>
            </a-form-model-item>
            <a-form-model-item label="线路名称" prop="lineMainName" :rules="formRules.blur">
                <a-input v-model="form.lineMainName" placeholder="请输入线路名称" :maxLength="20" />
            </a-form-model-item>

            <a-form-model-item label="是否环线" prop="isLoopLine" :rules="formRules.blur">
                <a-select style="width: 100%" placeholder="请选择是否环线" v-model="form.isLoopLine">
                    <a-select-option value="0"> 否</a-select-option>
                    <a-select-option value="1"> 是</a-select-option>
                </a-select>
            </a-form-model-item>
            <a-form-model-item label="上行末班车时间" prop="upTime" :rules="formRules.blur">
                <a-row>
                    <a-col :span="8"><a-time-picker format="HH:mm" v-model="upBeginTime"/></a-col>
                    <a-col :span="8"><a-time-picker format="HH:mm" v-model="upEndTime"/></a-col>
                </a-row>
            </a-form-model-item>
            <a-form-model-item label="下行末班车时间" prop="downTime" :rules="formRules.blur">
                <a-row>
                    <a-col :span="8"><a-time-picker format="HH:mm" v-model="downBeginTime"/></a-col>
                    <a-col :span="8"><a-time-picker format="HH:mm" v-model="downEndTime"/></a-col>
                </a-row>
            </a-form-model-item>
            <a-form-model-item label="备注">
                <a-textarea v-model="form.memo" placeholder="请输入备注" :auto-size="{ minRows: 3, maxRows: 5 }" :maxLength="100" />
            </a-form-model-item>
        </a-form-model>
    </a-modal>
</template>
<script>
import { formRules } from '@/utils/validator'
import { DeptTree, NTable } from '@/components'
import { lineAdd, lineDetail, lineUpdate } from '@/api/alarm/index'
export default {
    components: {
        DeptTree,
        NTable
    },
    data() {
        return {
            formRules: formRules,
            labelCol: {
                xs: { span: 24 },
                sm: { span: 5 }
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 15 }
            },
            visible: false,
            title: '新增线路',
            confirmLoading: false,
            stationsList: [],
            isAdd: false,
            // 表头
            columns: [
                {
                    title: '组织机构名称',
                    dataIndex: 'tenantName',
                    ellipsis: true
                },
                {
                    title: '站点名称',
                    dataIndex: 'stationName',
                    ellipsis: true
                },
                {
                    title: '站点编号',
                    dataIndex: 'stationCode',
                    ellipsis: true
                },
                {
                    title: '站点GPS经度',
                    dataIndex: 'stationLng',
                    ellipsis: true
                },
                {
                    title: '站点GPS纬度',
                    dataIndex: 'stationLat',
                    ellipsis: true
                },

                {
                    title: '创建时间',
                    dataIndex: 'createTime',
                    ellipsis: true
                }
            ],
            form: {
                id: '',
                lineMainName: '',
                isLoopLine: '',
                upTime: '',
                downTime: '',
                memo: '',
                tenantId: ''
            },
            upBeginTime: null,
            upEndTime: null,
            downBeginTime: null,
            downEndTime: null
        }
    },
    methods: {
        show(record) {
            this.visible = true
            this.form = this.$options.data.call(this).form
            this.title = '新增线路'
            this.isAdd = true
            if (record) {
                this.isAdd = false
                this.title = '编辑线路'
                this.getDetail(record.id)
            }
        },
        getDetail(id) {
            lineDetail({
                id
            }).then(res => {
                Object.keys(this.form).forEach(key => (this.form[key] = res[key]))
                this.form.isLoopLine = res.isLoopLine.toString()
                let [upBeginTime, upEndTime] = res.upTime.split('-')
                let [downBeginTime, downEndTime] = res.downTime.split('-')
                this.upBeginTime = this.$moment()
                    .hour(upBeginTime.split(':')[0])
                    .minute(upBeginTime.split(':')[1])
                this.upEndTime = this.$moment()
                    .hour(upEndTime.split(':')[0])
                    .minute(upEndTime.split(':')[1])
                this.downBeginTime = this.$moment()
                    .hour(downBeginTime.split(':')[0])
                    .minute(downBeginTime.split(':')[1])
                this.downEndTime = this.$moment()
                    .hour(downEndTime.split(':')[0])
                    .minute(downEndTime.split(':')[1])
            })
        },
        handleStationsChange() {},
        handleOk() {
            let api = this.isAdd ? lineAdd : lineUpdate
            console.log(this.form)
            if (this.upBeginTime && this.upEndTime) {
                this.form.upTime = this.upBeginTime.format('HH:mm') + '-' + this.upEndTime.format('HH:mm')
            } else {
                this.form.upTime = null
            }
            if (this.downBeginTime && this.downEndTime) {
                this.form.downTime = this.downBeginTime.format('HH:mm') + '-' + this.downEndTime.format('HH:mm')
            } else {
                this.form.downTime = null
            }
            this.$refs.form.validate(valid => {
                if (valid) {
                    this.confirmLoading = true
                    let form = {
                        ...this.form
                    }
                    api(form)
                        .then(res => {
                            this.$message.success(this.isAdd ? '新增成功' : '修改成功')
                            this.visible = false
                            this.confirmLoading = false
                            this.$emit('ok')
                        })
                        .catch(err => {
                            this.confirmLoading = false
                        })
                }
            })
        },
        handleCancel() {
            this.visible = false
            this.upBeginTime = null
            this.upEndTime = null
            this.downBeginTime = null
            this.downEndTime = null
        }
    }
}
</script>
<style lang="less" scoped></style>
