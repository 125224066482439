<template>
    <a-card :bordered="false">
        <div class="table-page-search-wrapper">
            <a-form-model :label-col="labelCol" :wrapper-col="wrapperCol">
                <a-row :gutter="48">
                    <a-col :md="4" :sm="24">
                        <a-form-model-item label="通知类型">
                            <a-select style="width: 100%" placeholder="请选择通知类型" v-model="queryParam.noticeType_equal">
                                <a-select-option value="">全部</a-select-option>
                                <a-select-option v-for="(item, index) in noticeTypeList" :key="index" :value="item.code">{{
                                    item.value
                                }}</a-select-option>
                            </a-select>
                        </a-form-model-item>
                    </a-col>
                    <a-col :md="4" :sm="24">
                        <a-form-model-item label="通知标题">
                            <a-input :maxLength="35" v-model="queryParam.noticeTitle_like" allow-clear placeholder="请输入通知标题" />
                        </a-form-model-item>
                    </a-col>
                    <a-col :md="6" :sm="24">
                        <a-form-model-item label="通知内容">
                            <a-input :maxLength="35" v-model="queryParam.noticeDesc_like" allow-clear placeholder="请输入通知内容" />
                        </a-form-model-item>
                    </a-col>
                    <a-col :md="6" :sm="24">
                        <a-form-model-item label="通知时间">
                            <a-range-picker
                                :show-time="{ defaultValue: [$moment('00:00', 'HH:mm:ss'), $moment('00:00:00', 'HH:mm:ss')] }"
                                v-model="queryDate"
                                format="YYYY-MM-DD HH:mm"
                                @change="dateChange"
                            />
                        </a-form-model-item>
                    </a-col>
                    <a-col :md="4" :sm="24"  align="right">
                        <span class="table-page-search-submitButtons">
                            <a-button type="primary" @click="$refs.table.refresh(true)">查询</a-button>
                            <a-button style="margin-left: 8px" @click="() => ((queryParam = {}), (queryDate = null))">重置</a-button>
                        </span>
                    </a-col>
                </a-row>
            </a-form-model>
        </div>
        <a-button type="primary" class="mb10" @click="$refs.editForm.edit()">添加通知</a-button>
        <n-table ref="table" :showPagination="false" :showSizeChanger="false" :columns="columns" :data="loadData" :rowKey="(record,index) => index" :scrollToFirstRowOnChange="true">
            <span slot="noticeType" slot-scope="text">
                <!-- <span v-if="text == '0'">告警通知</span>
                <span v-if="text == '1'">船舶状态通知</span> -->
                {{ $filterEnum(noticeTypeList, text) }}
            </span>
            <span slot="noticeStatus" slot-scope="text, record">
                <span v-if="record.noticeStatus === '1'">发送成功</span>
                <span v-else>待发送</span>
            </span>
            <span slot="action" slot-scope="text, record">
                <a @click="$refs.editForm.edit(record)" v-if="record.noticeStatus === '1'">查看</a>
                <a v-else @click="$refs.editForm.edit(record)">编辑</a>
            </span>
            
        </n-table>
        <edit-form ref="editForm" @ok="handleOk" :noticeTypeList="noticeTypeList" />
    </a-card>
</template>

<script>
import { NTable } from '@/components'
import editForm from './editForm'
import { sysDictTypeDropDown } from '@/api/comm/index'
import { noticeConfigList } from '@/api/alarm'

export default {
    components: {
        NTable,
        editForm
    },

    data() {
        return {
            labelCol: {
                sm: { span: 6 },
                xs: { span: 24 }
            },
            wrapperCol: {
                sm: { span: 18 },
                xs: { span: 24 }
            },
            // 查询参数
            queryParam: {
                /**0告警1船舶状态 */
                noticeType_equal: '',
                noticeTitle_like: '',
                noticeDesc_like: '',
                noticeTime_ge: '',
                noticeTime_le: '',
                descs: 'notice_time'
            },
            queryDate: null,
            // 表头
            columns: [
                {
                    title: '通知类型',
                    dataIndex: 'noticeType',
                    scopedSlots: { customRender: 'noticeType' },
                    width: 150
                },
                {
                    title: '通知标题',
                    dataIndex: 'noticeTitle',
                    width: 250,
                    ellipsis: true
                },
                {
                    title: '通知内容',
                    dataIndex: 'noticeContent',
                    ellipsis: true
                },
                {
                    title: '通知时间',
                    dataIndex: 'noticeTime',
                    width: 180
                },
                {
                    title: '通知状态',
                    dataIndex: 'noticeStatus',
                    scopedSlots: { customRender: 'noticeStatus' },
                    width: 180
                },
                {
                    title: '操作',
                    dataIndex: 'action',
                    scopedSlots: { customRender: 'action' },
                    width: 180
                }
            ],
            // // 加载数据方法 必须为 Promise 对象
            loadData: async (parameter) => {
                return await noticeConfigList(Object.assign(parameter, this.queryParam))
            },
            noticeTypeList: [
              {
                code:'2',
                value:'系统通知'
              },{
                code:'3',
                value:'系统升级通知'
              },
            ]
        }
    },

    mounted() {
      console.log('ddddd');
      // this.getDict()
    },
    methods: {
        // getDict() {
        //     sysDictTypeDropDown({ code: 'noticeType' }).then(res => {
        //         this.noticeTypeList = res
        //     })
        // },
        dateChange(date, dateString) {
            this.queryParam.noticeTime_ge = dateString[0]
            this.queryParam.noticeTime_le = dateString[1]
        },
        handleOk() {
            this.$refs.table.refresh()
        }
    }
}
</script>

<style lang="less" scoped></style>
