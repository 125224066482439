/*
 * @Autor: dingxiaolin
 * @Date: 2021-09-27 10:08:25
 * @LastEditors: gsy 1443717855@qq.com
 * @LastEditTime: 2024-02-28 10:26:01
 */
import { Post, Get, Patch, Put, Delete, Download, ax } from '@/utils/newRequest'
import qs from 'qs'
import config from '@/config/config.js'
/**客服用 --导出 */

export function downLoadFacePic(body) {
    return Download(config.namespace + '/dnl/downLoadFacePic?' + qs.stringify(body), {})
}
/*************************************************** */
/** 告警列表删除 */
export function alarmRemove(body) {
    return Post(config.namespace + '/alarm/remove', body)
}
/** 告警列表导出 */
export function alarmExport(body) {
    return Download(config.namespace + '/alarm/exportAlarmInfo', body)
}
/**
 * 船舶预警
 */

/** 获取行政区划 */

export function getRegion(body) {
    return Get(config.namespace + '/region/select', body)
}

/**
 * 获取当前用户的所属机构列表
 */
export function getDeptTree(body) {
    return Get(config.namespace + '/org/tenantManageTree', body)
}
/**
 * 获取预警列表
 */
export function alarmList(body) {
    return Post(config.namespace + '/alarm/list', body)
}
/**
 * 告警确认处理(单个)
 */
export function confirmAlarm(body) {
    return Post(config.namespace + '/alarm/confirm', body)
}
/**
 * 告警确认处理(批量)
 */
export function confirmAlarmBatch(body) {
    return Post(config.namespace + '/alarm/batchConfirm', body)
}
/**
 * 获取预警详情
 */
export function alarmDetail(body) {
    return Post(config.namespace + '/alarm/detail', body)
}
/**
 * 编辑误报规则
 */
export function updateMisReportFilter(body) {
    return Post(config.namespace + '/sameMisReportFilteringRules/update', body)
}

/**
 * 获取预警处理 单个
 */
export function alarmDeal(body) {
    return Post(config.namespace + '/deal/deal', body)
}
/////
/**
 * 告警类型列表信息
 */
export function alarmType(body) {
    return Post(config.namespace + '/alarmType/list', body)
}
/**
 * 告警模型列表
 */
export function alarmTypeLinkList(body) {
    return Post(config.namespace + '/alarmTypeLink/list', body)
}
/**
 * 编辑告警模型列表
 */
export function updateAlarmTypeLinkList(body) {
    return Post(config.namespace + '/alarmTypeLink/update', body)
}
/**
 * 告警类型状态修改
 */
export function saveType(body) {
    return Post(config.namespace + '/alarmType/save', body)
}
/**
 * 告警类型状态修改
 */
export function updateState(body) {
    return Post(config.namespace + '/alarmType/updateState', body)
}
/**
 * 告警类型详情
 */
export function getTypeDetail(body) {
    return Post(config.namespace + '/alarmType/detail', body)
}
/**
 * 修改告警类型
 */
export function updateType(body) {
    return Post(config.namespace + '/alarmType/update', body)
}
/**
 * 删除告警类型
 */
export function removeType(body) {
    return Post(config.namespace + '/alarmType/remove', body)
}
/**
 * 批量处理告警
 */
export function batchDeal(body) {
    return Post(config.namespace + '/deal/batchDeal', body)
}
/**
 * AI终端列表
 */
export function aiDeviceList(body) {
    return Post(config.namespace + '/alarmAiDevice/list', body)
}
/**
 * 未绑摄像头列表
 */
export function unLinkList(body) {
    return Post(config.namespace + '/alarmDevice/unLinkList', body)
}
/**
 * 新增AI终端
 */
export function saveAiDevice(body) {
    return Post(config.namespace + '/alarmAiDevice/save', body)
}
/**
 * 新增AI终端详情
 */
export function aiDeviceDetail(body) {
    return Post(config.namespace + '/alarmAiDevice/detail', body)
}
/**
 * 编辑AI终端
 */
export function editAiDevice(body) {
    return Post(config.namespace + '/alarmAiDevice/update', body)
}
/**
 * 船舶MMSI重复查询
 */
export function existMMSI(body) {
    return Post(config.namespace + '/shipInfo/existMMSI', body)
}
/**
 * 船舶管理列表
 */
export function shipInfoList(body) {
    return Post(config.namespace + '/shipInfo/list', body)
}
/**
 * 获取未绑定的船舶信息
 */
export function getUnbindShipInfoList(body) {
    return Post(config.namespace + '/shipInfo/getUnbindShipInfoList', body)
}
/**
 * 新增船舶信息
 */
export function saveShipInfo(body) {
    return Post(config.namespace + '/shipInfo/save', body)
}
/**
 * 船舶信息详情
 */
export function shipInfoDetail(body) {
    return Post(config.namespace + '/shipInfo/detail', body)
}
/**
 * 修改船舶信息
 */
export function updateShipInfo(body) {
    return Post(config.namespace + '/shipInfo/update', body)
}
/**
 * 获取船员管理列表
 */
export function marinerInfoList(body) {
    return Post(config.namespace + '/marinerInfo/list', body)
}
/**
 * 新增船员信息
 */
export function saveMarinerInfo(body) {
    return Post(config.namespace + '/marinerInfo/save', body)
}
/**
 * 修改船员信息
 */
export function updateMarinerInfo(body) {
    return Post(config.namespace + '/marinerInfo/update', body)
}
/**
 * 获取船员信息详情
 */
export function marinerInfoDetail(body) {
    return Post(config.namespace + '/marinerInfo/detail', body)
}
/**
 * 获取码头管理列表
 */
export function warfInfoList(body) {
    return Post(config.namespace + '/warfInfo/list', body)
}
/**
 * 新增码头信息
 */
export function saveWarfInfo(body) {
    return Post(config.namespace + '/warfInfo/save', body)
}
/**
 * 修改码头信息
 */
export function updateWarfInfo(body) {
    return Post(config.namespace + '/warfInfo/update', body)
}
/**
 * 获取码头信息详情
 */
export function warfInfoDetail(body) {
    return Post(config.namespace + '/warfInfo/detail', body)
}
/**
 * 告警消息列表
 */
export function noticeOpList(body) {
    return Post(config.namespace + '/noticeOp/list', body)
}
/**
 * 告警消息未读总数
 */
export function unReadCount(body) {
    return Post(config.namespace + '/noticeOp/unReadCount', body)
}
/**
 * 所有船舶轨迹信息
 */
export function shipTravelReal(body) {
    return Post(config.namespace + '/shipTravelReal/list', body)
}
/**
 * 船舶轨迹信息详情
 */
export function shipTravelHis(body) {
    return Post(config.namespace + '/shipTravelHis/list', body)
}
/**
 * 删除AI终端
 */
export function removeDevice(body) {
    return Post(config.namespace + '/alarmAiDevice/remove', body)
}
/**
 * 删除船舶信息
 */
export function removeShip(body) {
    return Post(config.namespace + '/shipInfo/remove', body)
}
/**
 * 删除船员信息
 */
export function removeMariner(body) {
    return Post(config.namespace + '/marinerInfo/remove', body)
}
/**
 * 删除码头信息
 */
export function removeWarf(body) {
    return Post(config.namespace + '/warfInfo/remove', body)
}
/**
 * 短信管理列表
 */
export function smsList(body) {
    return Post(config.namespace + '/sms/list', body)
}
/**
 * 获取危险化学品目录
 */
export function hcwInfoList(body) {
    return Post(config.namespace + '/hcwInfo/list', body)
}
/**
 * 获取码头基础数据
 */
export function wharfBaseInfoList(body) {
    return Post(config.namespace + '/wharfBaseInfo/list', body)
}
/**
 * AI终端日志管理
 */
export function aiDeviceLogList(body) {
    return Post(config.namespace + '/aiDeviceLog/list', body)
}
/**
 * WEB/AI终端/锐景AI终端固件升级
 */
export function aiDeviceLevelForRJ(body) {
    return Post(config.namespace + '/alarmAiDevice/aiDeviceLevelForRJ', body)
}
/**
 * 终端设备任务列表
 */
export function aiDeviceTasklist(body) {
    return Post(config.namespace + '/aiDeviceTask/list', body)
}
/**
 * 终端媒体通道修改
 */
export function aiDeviceMediaUpdate(body) {
    return Post(config.namespace + '/aiDeviceMedia/update', body)
}
/**
 * 终端媒体通道新增
 */
export function aiDeviceMediaSave(body) {
    return Post(config.namespace + '/aiDeviceMedia/save', body)
}
/**
 * 终端媒体通道删除
 */
export function aiDeviceMediaRemove(body) {
    return Post(config.namespace + '/aiDeviceMedia/remove', body)
}
/**
 * 其他终端媒体通道修改
 */
export function updateForOtherDevice(body) {
    return Post(config.namespace + '/aiDeviceMedia/updateForOtherDevice', body)
}
/**
 * 其他终端媒体通道新增
 */
export function saveForOtherDevice(body) {
    return Post(config.namespace + '/aiDeviceMedia/saveForOtherDevice', body)
}
/**
 * 其他终端媒体通道删除
 */
export function removeForOtherDevice(body) {
    return Post(config.namespace + '/aiDeviceMedia/removeForOtherDevice', body)
}
/**
 * 终端媒体通道列表
 */
export function aiDeviceMediaList(body) {
    return Post(config.namespace + '/aiDeviceMedia/list', body)
}

/**
 * 终端媒体通道同步
 */
export function aiDeviceMediaSynInfo(body) {
    return Post(config.namespace + '/aiDeviceMedia/synInfo', body)
}

/** 终端媒体通道详情 */
export function aiDeviceMediaDetail(body) {
    return Post(config.namespace + '/aiDeviceMedia/detail', body)
}

/**算法任务 */
/** 同步任务 */
export function aiDeviceAlgorithmSynInfo(body) {
    return Post(config.namespace + '/aiDeviceAlgorithm/synInfo', body)
}
/** 获取任务列表 */

export function aiDeviceAlgorithmList(body) {
    return Post(config.namespace + '/aiDeviceAlgorithm/list', body)
}

/** 算法任务操作 */
export function aiDeviceTaskOpForRJ(body) {
    return Post(config.namespace + '/aiDeviceAlgorithm/aiDeviceTaskOpForRJ', body)
}

/** 算法任务添加 */
export function aiDeviceAlgorithmSave(body) {
    return Post(config.namespace + '/aiDeviceAlgorithm/save', body)
}

/** 终端任务详情 */
export function aiDeviceAlgorithmDetail(body) {
    return Post(config.namespace + '/aiDeviceAlgorithm/detail', body)
}

/** 终端任务修改*/
export function aiDeviceAlgorithmUpdate(body) {
    return Post(config.namespace + '/aiDeviceAlgorithm/update', body)
}

/** 终端设备网络同步 */
export function aiDeviceNetworkInfoSynInfo(body) {
    return Post(config.namespace + '/aiDeviceNetworkInfo/synInfo', body)
}
/** 终端设备网络配置详情 */
export function aiDeviceNetworkInfoDetail(body) {
    return Post(config.namespace + '/aiDeviceNetworkInfo/detail', body)
}

/** 终端设备网络配置新增/修改 */
export function aiDeviceNetworkInfoUpdate(body) {
    return Post(config.namespace + '/aiDeviceNetworkInfo/update', body)
}

/** 终端设备重启 */
export function aiDeviceReStart(body) {
    return Post(config.namespace + '/alarmAiDevice/rebootDevice', body)
}

/** 参数配置同步 */
/** 终端设备参数配置同步 */
export function aiDeviceSetConfigSynInfo(body) {
    return Post(config.namespace + '/aiDeviceParamInfo/synInfo', body)
}

/** 获取设备参数配置列表*/

export function aiDeviceSetConfigList(body) {
    return Post(config.namespace + '/aiDeviceParamInfo/list', body)
}

/** 新增 设备参数配置*/

export function aiDeviceSetConfigAdd(body) {
    return Post(config.namespace + '/aiDeviceParamInfo/save', body)
}
/** 修改 设备参数配置*/
export function aiDeviceSetConfigUpdate(body) {
    return Post(config.namespace + '/aiDeviceParamInfo/update', body)
}

/**  设备参数配置 详情*/

export function aiDeviceSetConfigDetail(body) {
    return Post(config.namespace + '/aiDeviceParamInfo/detail', body)
}

// 数据统计

/** 告警统计图表 */

export function getAlarmDataStatistic(body) {
    return Post(config.namespace + '/dataStatistics/alarmDataStatistic', body)
}

/** 告警统计导出 */

export function exportAlarmDataStatistic(body) {
    return Download(config.namespace + '/dataStatistics/exportAlarmDataStatistic', body)
}

/** 获取处理反馈统计 */
export function getProcessFeedbackStatistics(body) {
    return Post(config.namespace + '/dataStatistics/processFeedbackStatistics', body)
}

/** 处理闭环率及误报警率*/
export function getClosedLoopRateAndFalseAlarmRate(body) {
    return Post(config.namespace + '/dataStatistics/closedLoopRateAndFalseAlarmRate', body)
}
/** 告警处理统计导出 */

export function exportAlarmStatisticsInfo(body) {
    return Download(config.namespace + '/dataStatistics/exportAlarmStatisticsInfo', body)
}

/** 监控统计图表 */

export function getMonitoringStatistics(body) {
    return Post(config.namespace + '/dataStatistics/monitoringStatistics', body)
}
/** 监控统计导出 */

export function exportMonitoringStatisticsInfo(body) {
    return Download(config.namespace + '/dataStatistics/exportMonitoringStatisticsInfo', body)
}

/** 区域管理 */

/** 获取区域列表 */
export function getAreaInfoList(body) {
    return Post(config.namespace + '/areaInfo/list', body)
}

/** 区域增加 */
export function saveAreaInfo(body) {
    return Post(config.namespace + '/areaInfo/save', body)
}

/** 区域更新 */
export function updateAreaInfo(body) {
    return Post(config.namespace + '/areaInfo/update', body)
}

/** 区域删除 */
export function deleteAreaInfo(body) {
    return Post(config.namespace + '/areaInfo/remove', body)
}

/** 区域详情 */
export function getAreaInfoDetail(body) {
    return Post(config.namespace + '/areaInfo/detail', body)
}

/**
 * 固件管理列表
 */
export function firmwareInfoList(body) {
    return Post(config.namespace + '/firmwareInfo/list', body)
}
/**
 * 固件管理详情
 */
export function firmwareInfoDetail(body) {
    return Post(config.namespace + '/firmwareInfo/detail', body)
}
/**
 * 固件管理新增
 */
export function savefirmwareInfo(body) {
    return Post(config.namespace + '/firmwareInfo/save', body)
}
/**
 * 固件管理删除
 */
export function removefirmwareInfo(body) {
    return Post(config.namespace + '/firmwareInfo/remove', body)
}

/** 获取过滤规则 */
export function getFilterRuleList(body) {
    return Post(config.namespace + '/navigationStatusFilteringRules/list', body)
}

/** 新增过滤规则 */
export function saveFilterRule(body) {
    return Post(config.namespace + '/navigationStatusFilteringRules/save', body)
}

/** 删除过滤规则 */
export function removeFilterRule(body) {
    return Post(config.namespace + '/navigationStatusFilteringRules/remove', body)
}

/** 同种误报列表 */
export function getSameReportList(body) {
    return Post(config.namespace + '/sameMisReportFilteringRules/list', body)
}
/** 过滤规则-同种误报-设置规则是否可用 */
export function getRuleEnable(body) {
    return Post(config.namespace + '/sameMisReportFilteringRules/getRuleEnable', body)
}

/** 过滤规则-同种误报-设置规则是否可用 */
export function setRuleEnable(body) {
    return Post(config.namespace + '/sameMisReportFilteringRules/setRuleEnable', body)
}

/** 过滤规则-同种误报-解除规则 */
export function clearAlarmRule(body) {
    return Post(config.namespace + '/sameMisReportFilteringRules/clearAlarmRule', body)
}

/**
 * 恢复误警详情
 */
export function restoreAlarm(body) {
    return Post(config.namespace + '/alarm/restore', body)
}

/**
 * 同种误报-列表查询
 */
export function sameRulesStatuslist(body) {
    return Post(config.namespace + '/sameMisReportFilteringRules/list', body)
}

/**
 * AI终端媒体通道修改场景
 */
export function updateScene(body) {
    return Post(config.namespace + '/aiDeviceMedia/updateScene', body)
}

/**
 * 查询设备树
 */
export function getRJDeviceTree(body) {
    return Post(config.namespace + '/alarmAiDevice/getRJDeviceTree', body)
}

/**
 * 获取GBS流媒体token
 */
export function getGBSToken(body) {
    return Post(config.namespace + '/liveGBS/getToken', body)
}

/**工服列表 */
export function getSuitGroupList(body) {
    return Post(config.namespace + '/aiDeviceSuitGroupInfo/list', body)
} //新增工装组信息
/**工服新增类别 */ export function addSuitGroup(body) {
    //
    return Post(config.namespace + '/aiDeviceSuitGroupInfo/save', body)
}
///aiDeviceSuitInfo/remove 删除  //aiDeviceSuitInfo/save  /aiDeviceSuitInfo/synInfo 同步
/**工服删除 */
export function removeSuitInfo(body) {
    //
    return Post(config.namespace + '/aiDeviceSuitInfo/remove', body)
}
/**添加工服 */
export function addSuitInfo(body) {
    //
    return Post(config.namespace + '/aiDeviceSuitInfo/save', body)
}
/**同步工服 */
export function synSuit(body) {
    //
    return Post(config.namespace + '/aiDeviceSuitGroupInfo/synInfo', body)
}
/**船舶关联人员绑定 */
export function saveShipUser(body) {
    return Post(config.namespace + '/shipUserBind/save', body)
}
/**船舶关联人员删除 */
export function removeShipUser(body) {
    return Post(config.namespace + '/shipUserBind/remove', body)
}
/**船舶关联人员列表 */
export function getShipUserList(body) {
    return Post(config.namespace + '/shipUserBind/list', body)
}

/**根据行业获取场景信息 */
export function getSceneCodeList(body) {
    return Post(config.namespace + '/industrySceneLink/getSceneCodeList', body)
}
/**获取告警时效信息 */
export function tenantAlarmTypeTimeList(body) {
    return Post(config.namespace + '/tenantAlarmTypeTime/list', body)
}
/**新增告警时效信息 */
export function tenantAlarmTypeTimeSave(body) {
    return Post(config.namespace + '/tenantAlarmTypeTime/save', body)
}
/**告警通知设置-获取告警类型信息 */
export function getTenantAlarmTypelist(body) {
    return Post(config.namespace + '/tenantAlarmType/getTenantAlarmTypelist', body)
}
/**编辑告警通知设置 */
export function updateTenantAlarmType(body) {
    return Post(config.namespace + '/tenantAlarmType/update', body)
}
/**异常日志 */
export function aiDeviceErrorLogList(body) {
    return Post(config.namespace + '/aiDeviceErrorLog/list', body)
}
/**电子围栏列表 */
export function electricFenceConfigList(body) {
    return Post(config.namespace + '/electricFenceConfig/list', body)
}
/**电子围栏详情 */
export function electricFenceConfigDetail(body) {
    return Post(config.namespace + '/electricFenceConfig/detail', body)
}
/**新增电子围栏 */
export function saveElectricFenceConfig(body) {
    return Post(config.namespace + '/electricFenceConfig/save', body)
}
/**删除电子围栏 */
export function removeElectricFenceConfig(body) {
    return Post(config.namespace + '/electricFenceConfig/remove', body)
}
/**修改电子围栏 */
export function updateElectricFenceConfig(body) {
    return Post(config.namespace + '/electricFenceConfig/update', body)
}
/**修改电子围栏状态 */
export function electricFenceStatus(body) {
    return Post(config.namespace + '/electricFenceConfig/electricFenceStatus', body)
}
/**
 * 发布通知列表
 */
export function noticeConfigList(body) {
    return Post(config.namespace + '/noticeConfig/list', body)
}
/**
 * 编辑告警处理描述
 */
export function updateAlarmDealDesc(body) {
    return Post(config.namespace + '/alarm/updateAlarmDealDesc', body)
}

/**
 * 车流统计
 */
export function statisticsPassengerFlowInfo(body) {
    return Post(config.namespace + '/statisticsPassengerFlow/statisticsPassengerFlowInfo', body)
}

/**
 * 获取未绑定的车辆信息
 */
export function getUnbindBusInfoList(body) {
    return Post(config.namespace + '/busInfo/getUnbindBusInfoList', body)
}
/**
 * 终端列表导出
 */
export function exportAiDeviceInfo(body) {
    return Download(config.namespace + '/alarmAiDevice/exportAiDeviceInfo', body)
}
/**
 * 车辆详情
 */
export function busInfoDetail(body) {
    return Post(config.namespace + '/busInfo/detail', body)
}
/**
 * 门型信息
 */
export function busModelDoorLinkList(body) {
    return Post(config.namespace + '/busModelDoorLink/list', body)
}
/**
 * AI终端多媒体通道上下客人脸修改
 */
export function updateFaceAiDeviceMedia(body) {
    return Post(config.namespace + '/aiDeviceMedia/updateFace', body)
}
/**
 * 人脸抓拍列表
 */
export function faceInfoList(body) {
    return Post(config.namespace + '/faceInfo/list', body)
}
/**
 * 人脸列表导出
 */
export function exportFaceInfo(body) {
    return Download(config.namespace + '/faceInfo/exportFaceInfo', body)
}

/**
 * 线路列表
 */
export function getLineList(body) {
    return Post(config.namespace + '/lineInfo/list', body)
}
/** 线路新增 */
export function lineAdd(body) {
    return Post(config.namespace + '/lineInfo/save', body)
}
/** 线路修改 */
export function lineUpdate(body) {
    return Post(config.namespace + '/lineInfo/update', body)
}
/** 线路删除 */
export function lineRemove(body) {
    return Post(config.namespace + '/lineInfo/remove', body)
}
/** 线路详情 */
export function lineDetail(body) {
    return Post(config.namespace + '/lineInfo/detail', body)
}
/** 线路关联站点更新 */
export function lineStationsAdd(body) {
  return Post(config.namespace + '/lineInfo/addStation', body)
}
export function lineStationsUpdate(body) {
  return Post(config.namespace + '/lineInfo/updateStation', body)
}
/** 删除站点 */
export function lineStationDel(body) {
  return Post(config.namespace + '/lineInfo/deleteStation', body)
}
/** 获取线路关联站点 */
export function getLineStations(body) {
  return Post(config.namespace + '/lineInfo/getStationList', body)
}

/**
 * 公交车辆管理列表
 */
export function getBusList(body) {
    return Post(config.namespace + '/busInfo/list', body)
}
/**
 * 车辆客流趋势
 */
export function vehiclePassengerFlow(body) {
    return Post(config.namespace + '/passengerFlowInfo/vehiclePassengerFlow', body)
}

/**
 * 车辆客流明细
 */
export function passengerFlowDetails(body) {
    return Post(config.namespace + '/passengerFlowInfo/passengerFlowDetails', body)
}

/**
 * 线路站点分析
 */
export function lineStationAnalysis(body) {
    return Post(config.namespace + '/passengerFlowInfo/lineStationAnalysis', body)
}
/**
 * 客流日志明细
 */
export function passengerFlowLogs(body) {
    return Post(config.namespace + '/passengerFlowInfo/passengerFlowLogs', body)
}

/**
 * 线路高峰期分析
 */
export function linePeakPeriodAnalysis(body) {
    return Post(config.namespace + '/passengerFlowInfo/linePeakPeriodAnalysis', body)
}

/** 车辆客流趋势信息导出 */
export function exportVehiclePassEngerFlowInfo(body) {
    return Download(config.namespace + '/passengerFlowInfo/exportVehiclePassEngerFlowInfo', body)
}

/** 车辆客流明细导出 */
export function exportPassengerFlowDetails(body) {
    return Download(config.namespace + '/passengerFlowInfo/exportPassengerFlowDetails', body)
}

/** 车辆客流明细导出 */
export function linePassengerFlowTrends(body) {
    return Post(config.namespace + '/passengerFlowInfo/linePassengerFlowTrends', body)
}

/** 线路客流趋势信息导出 */
export function linePassengerFlowTrendsExport(body) {
    return Download(config.namespace + '/passengerFlowInfo/linePassengerFlowTrendsExport', body)
}

/** 线路高峰期分析信息导出 */
export function linePeakAnalysisInformationExport(body) {
    return Download(config.namespace + '/passengerFlowInfo/linePeakAnalysisInformationExport', body)
}

/** 线路大站分析导出 */
export function excelLineStationAnalysis(body) {
    return Download(config.namespace + '/passengerFlowInfo/excelLineStationAnalysis', body)
}
/** 客流日志明细导出 */
export function passengerFlowLogsExcel(body) {
    return Download(config.namespace + '/passengerFlowInfo/passengerFlowLogsExcel', body)
}
/** 线路同步 */
export function synInfo(body) {
    return Post(config.namespace + '/lineInfo/synInfo', body)
}
/** 线路大站分析站点数据 */
export function lineLargeStationAnalysis(body) {
    return Post(config.namespace + '/stationInfo/lineLargeStationAnalysis', body)
}
/** 线路大站分析站点数据导出 */
export function exportLineLargeStationAnalysis(body) {
    return Download(config.namespace + '/stationInfo/exportLineLargeStationAnalysis', body)
}

/** 站点列表 */
export function getStationsList(body) {
    return Post(config.namespace + '/baseStationInfo/list', body)
}

/** 站点新增 */
export function stationsAdd(body) {
    return Post(config.namespace + '/baseStationInfo/save', body)
}

/** 站点修改 */
export function stationsUpdate(body) {
    return Post(config.namespace + '/baseStationInfo/update', body)
}
/** 站点删除 */
export function stationsRemove(body) {
    return Post(config.namespace + '/baseStationInfo/remove', body)
}
