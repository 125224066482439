/*
 * @Autor: dingxiaolin
 * @Date: 2021-09-29 10:04:34
 * @LastEditors: dingxiaolin
 * @LastEditTime: 2022-08-08 17:25:18
 */
import Vue from 'vue'
import VueStorage from 'vue-ls'
import config from '@/config/defaultSettings'

// base library
import '@/core/lazy_lib/components_use'

// ext library
import VueClipboard from 'vue-clipboard2'
import VueCropper from 'vue-cropper'
import MultiTab from '@/components/MultiTab'
import PageLoading from '@/components/PageLoading'
import PermissionHelper from '@/utils/helper/permission'
import preview from 'vue-photo-preview';
import 'vue-photo-preview/dist/skin.css'
import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/dist/css/swiper.css'
import fullscreen from 'vue-fullscreen'


//自定义指令
import './directives/action'
import './directives/scrollBar'

VueClipboard.config.autoSetContainer = true
Vue.use(fullscreen)
Vue.use(MultiTab)
Vue.use(PageLoading)
Vue.use(VueStorage, config.vue_ls_Options)
Vue.use(VueClipboard)
Vue.use(PermissionHelper)
//头像上传
Vue.use(VueCropper)
//图片预览
Vue.use(preview)
//滚动滑动
Vue.use(VueAwesomeSwiper)

process.env.NODE_ENV !== 'production' && console.warn('[antd-pro] NOTICE: Antd use lazy-load.')
