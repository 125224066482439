import Vue from 'vue'
import axios from 'axios'
// import store from '@/store'
import { message, Modal, notification } from 'ant-design-vue' /// es/notification
import { Base64 } from 'js-base64'
import config from '@/config/defaultSettings'
import { ACCESS_TOKEN } from '@/store/mutation-types'
import { downloadFile } from '@/utils/util'

var fileDownload = require('js-file-download')
const { clientId, clientSecret } = config
function host() {
    if (process.env.NODE_ENV === 'production') {
        return '/'
    } else {
        // return 'http://192.168.1.114:8180/';
        return '/' //代理
    }
}
// 创建 axios 实例
export const ax = axios.create({
    baseURL: host(), // api base_url
    timeout: 30000 // 请求超时时间
})

const codeMessage = {
    200: '服务器成功返回请求的数据。',
    201: '新建或修改数据成功。',
    202: '一个请求已经进入后台排队（异步任务）。',
    204: '删除数据成功。',
    400: '发出的请求有错误，服务器没有进行新建或修改数据的操作。',
    401: '此账号已在其他设备登录，请重新登录', // 用户没有权限（令牌、用户名、密码错误）。
    403: '用户未得到授权，访问被禁止。',
    404: '发出的请求针对的是不存在的记录，服务器没有进行操作。',
    406: '请求的格式不可得。',
    410: '请求的资源被永久删除，且不会再得到的。',
    422: '当创建一个对象时，发生一个验证错误。',
    500: '服务器发生错误，请检查服务器。',
    502: '网关错误。',
    503: '服务不可用，服务器暂时过载或维护。',
    504: '网关超时。'
}

const err = error => {
    if (error.response) {
        const data = error.response.data
        if (error.response.status === 503) {
            notification.destroy()
            notification.warning({
                message: '系统版本更新中',
                description: '预计需要10分钟，请稍后'
            })
            return
        }
        if (error.response.status === 500 || error.response.status !== 200) {
            if (data.msg.length > 0) {
                message.error(data.msg)
            }
        }
        if (error.response.status === 401) {
            // && !(data.result && data.result.isLogin)
            notification.error({
                message: codeMessage[error.response.status] || 'Unauthorized'
                // description: 'Authorization verification failed'
            })
            if (Vue.ls.get(ACCESS_TOKEN)) {
                store.dispatch('Logout').then(() => {
                    setTimeout(() => {
                        window.location.reload()
                    }, 1500)
                })
            }
        }

    }
    if (error.message == 'timeout of 30000ms exceeded') {
        message.error('请求超时')
    }
    return Promise.reject(error)
}

// request interceptor
ax.interceptors.request.use(config => {
    if (config.url.includes('dnl/auth')) {
        config.headers['Authorization'] = `Basic ${Base64.encode(`${clientId}:${clientSecret}`)}`
    }

    if (
        navigator.userAgent.match(/Android/i) ||
        navigator.userAgent.match(/webOS/i) ||
        navigator.userAgent.match(/iPhone/i) ||
        navigator.userAgent.match(/iPad/i) ||
        navigator.userAgent.match(/iPod/i) ||
        navigator.userAgent.match(/BlackBerry/i) ||
        navigator.userAgent.match(/Windows Phone/i)
    ) {
        //移动端
        config.headers['User-Type'] = 'mobile'
    } else {
        //pc端
        config.headers['User-Type'] = 'web'
    }

    if (Vue.ls.get(ACCESS_TOKEN)) {
        config.headers['Authorization'] = 'bearer ' + Vue.ls.get(ACCESS_TOKEN)
    }
    return config
}, err)

/**
 * response interceptor
 * 所有请求统一返回
 */
ax.interceptors.response.use(response => {
    if (response.request.responseType === 'blob') {
        return response
    }
    if (!response.data.success) {
        switch (response.data.code) {
            case 403: //重新登录
                message.error('请重新登录')
                window.location.reload()
                // Modal.error({
                //     title: '提示：',
                //     content: response.data.msg,
                //     okText: '重新登录',
                //     onOk: () => {
                //         Vue.ls.remove(ACCESS_TOKEN)
                //         window.location.reload()
                //     }
                // })
                break
            default:
                return Promise.reject(response.data.msg)
        }
    } else {
        return Promise.resolve(response.data)
    }
}, err)

/* 手动取消请求的不显示报错 */
function handleError(err) {
    // 如果是手动取消的请求，不显示错误信息
    console.log('handleError', err, typeof err == 'string')
    if (typeof err == 'string') {
        message.error(err)
    } else if (!err) {
        return
    }
}

/* GET  */
export function Get(url, data) {
    // `params`是要与请求一起发送的URL参数
    return ax
        .get(url, {
            params: data
        })
        .then(res => {
            return res.data ? res.data : res
        })
        .catch(err => {
            handleError(err)
            throw err
        })
}

//下载excel
export function Download(url, data) {
    return axios({
        baseURL: host(),
        url: url,
        params: data,
        headers: { Authorization: `bearer ${Vue.ls.get(ACCESS_TOKEN)}` },
        method: 'get',
        responseType: 'blob'
    })
        .then(res => {
            // downloadFile(res);
            ab2str(res.data, function (str) {
                try {
                    var obj = JSON.parse(str)
                    if (typeof obj == 'object' && obj) {
                        if (obj.msg) {
                            handleError(obj.msg)
                            return
                        }
                    } else {
                        downloadFile(res)
                        message.success('导出成功')
                    }
                } catch (e) {
                    message.success('导出成功')
                    downloadFile(res)
                }
            })
        })
        .catch(err => {
            handleError('导出失败')
        })
    function ab2str(u, f) {
        var b = new Blob([u])
        var r = new FileReader()
        r.readAsText(b, 'utf-8')
        r.onload = function () {
            if (f) f.call(null, r.result)
        }
    }
}

//上传流文件
export function Import(url, data) {
    const formData = new FormData()
    formData.append('excelFile', data)
    return ax
        .post(url, formData, {
            method: 'post',
            headers: { 'Content-Type': 'multipart/form-data' }
        })
        .then(res => {
            return res.data ? res.data : res
        })
        .catch(err => {
            handleError(err)
            throw err
        })
}

/* POST */
export function Post(url, data) {
    return ax
        .post(url, data)
        .then(res => {
            return res.data ? res.data : res
        })
        .catch(err => {
            handleError(err)
            throw err
        })
}
/* PUT */
export function Put(url, data) {
    return ax
        .put(url, data)
        .then(res => {
            return res.data ? res.data : res
        })
        .catch(err => {
            handleError(err)
            throw err
        })
}
/* PATCH */
export function Patch(url, data) {
    return ax
        .patch(url, data)
        .then(res => {
            return res.data ? res.data : res
        })
        .catch(err => {
            handleError(err)
            throw err
        })
}
/* DELETE */
export function Delete(url, data) {
    return ax
        .delete(url, data)
        .then(res => {
            return res.data ? res.data : res
        })
        .catch(err => {
            handleError(err)
            throw err
        })
}
