var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"box"},[_c('div',{staticClass:"videobox"},[_c('div',{staticStyle:{"position":"relative"}},[_c('img',{staticStyle:{"width":"120px","height":"90px"},attrs:{"src":_vm.info.alarmPic}}),(_vm.info.relativeType === '0' && _vm.info.relativeBox)?_c('div',{ref:"facebox",style:({
                    position: 'absolute',
                    top: ((Math.abs(_vm.info.relativeBox[1]) * 100) + "%"),
                    left: ((Math.abs(_vm.info.relativeBox[0]) * 100) + "%"),
                    width: ((_vm.info.relativeBox[2] * 100) + "%"),
                    height: ((_vm.info.relativeBox[3] * 100) + "%"),
                    border: '1px solid #e4393c',
                    zIndex: 1499
                })}):_vm._e()])]),_c('div',{staticClass:"content"},[_c('div',{staticClass:"font1"},[_vm._v(_vm._s(_vm.info.alarmTypeName))]),_c('div',{staticClass:"font2"},[_vm._v(_vm._s(_vm.info.areaName))]),_c('div',{staticClass:"font2"},[_vm._v(_vm._s(_vm.info.alarmTime))]),_c('div',[_c('span',{class:'level' + _vm.info.alarmLevel},[_vm._v(" "+_vm._s(_vm.info.alarmLevelName)+" ")]),_c('a',{staticClass:"rt",class:_vm.get_status(_vm.info.confirmStatus, _vm.info.isDeal).color},[_vm._v(_vm._s(_vm.get_status(_vm.info.confirmStatus, _vm.info.isDeal).text))])])])])}
var staticRenderFns = []

export { render, staticRenderFns }