<template>
    <a-modal
        :title="title"
        centered
        :width="700"
        :visible="visible"
        :confirmLoading="confirmLoading"
        @ok="handleOk"
        @cancel="visible = false"
        destroyOnClose
    >
        <a-form-model ref="form" :model="form" :rules="formRules" :label-col="labelCol" :wrapper-col="wrapperCol">
            <a-form-model-item label="固件版本" prop="firmwareVersion" :rules="formRules.blur">
                <a-input v-model="form.firmwareVersion" placeholder="请输入固件版本" />
            </a-form-model-item>
            <a-form-model-item label="固件描述">
                <a-textarea v-model="form.firmwareDesc" :maxLength="500" placeholder="请输入固件描述" :auto-size="{ minRows: 3, maxRows: 5 }" />
            </a-form-model-item>
            <a-form-model-item label="固件上传" prop="firmwareUrl" :rules="formRules.blur" style="margin-bottom: 0">
                <a-upload
                    action=""
                    :multiple="false"
                    :customRequest="customRequest"
                    :file-list="fileList"
                    accept=".patch"
                    @change="uploadChange"
                    v-model="form.firmwareUrl"
                >
                    <a-button> <a-icon type="upload" /> 上传文件 </a-button>
                </a-upload>
            </a-form-model-item>
        </a-form-model>
    </a-modal>
</template>

<script>
import { firmwareInfoList, firmwareInfoDetail, savefirmwareInfo, removefirmwareInfo } from '@/api/alarm/index'
import { DeptTree } from '@/components'
import { formRules } from '@/utils/validator'
import { uploadFile } from '@/utils/util'
import { sysDictTypeDropDown } from '@/api/comm/index'
import BMF from 'browser-md5-file'
const bmf = new BMF()
export default {
    data() {
        return {
            title: '添加船员',
            formRules: formRules,
            labelCol: {
                xs: { span: 24 },
                sm: { span: 5 }
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 15 }
            },
            previewVisible: false,
            previewImage: '',
            visible: false,
            confirmLoading: false,
            detailData: {},
            form: {
                /**固件版本 */
                firmwareVersion: '',
                /**文件名 */
                firmwareName: '',
                /**固件地址 */
                firmwareUrl: '',
                /**固件大小 */
                firmwareSize: '',
                /**固件描述 */
                firmwareDesc: '',
                /**固件MS5 */
                firmwareMd5: ''
            },
            fileList: [],
            certStateList: []
        }
    },
    props: {
        sexList: {
            type: Array,
            default: []
        }
    },
    components: {
        DeptTree
    },
    methods: {
        /**
         * 获取字典数据
         */
        getDict() {
            sysDictTypeDropDown({ code: 'certState' }).then(res => {
                this.certStateList = res
            })
        },
        // 初始化方法
        show(record) {
            this.visible = true
            this.title = '上传固件'
            this.fileList = []
            this.form = {
                firmwareVersion: '',
                /**文件名 */
                firmwareName: '',
                /**固件地址 */
                firmwareUrl: '',
                /**固件大小 */
                firmwareSize: '',
                /**固件描述 */
                firmwareDesc: '',
                /**固件MS5 */
                firmwareMd5: ''
            }
        },
        // async getDetail(record) {
        //     this.detailData = await marinerInfoDetail({ id: record.id })
        //     this.form = this.detailData
        //     this.form.queryDate = [this.detailData.certStartDate, this.detailData.certEndDate]
        //     this.fileList = [
        //         {
        //             uid: '-1',
        //             status: 'done',
        //             name: this.form.fileUrl,
        //             response: this.form.fileUrl, //冗余一个response字段用来预览
        //             url: this.form.fileUrl
        //         }
        //     ]
        // },
        handleOk() {
            this.confirmLoading = true
            console.log(this.fileList)
            if(!this.fileList.length){  // fix删除固件验证 #2275
              this.confirmLoading = false
              this.$message.error('请上传固件文件')
              return
            }
            this.$refs.form.validate(valid => {
                if (valid) {
                    savefirmwareInfo(this.form)
                        .then(res => {
                            this.$message.success('上传固件成功')
                            this.visible = false
                            this.confirmLoading = false
                            this.$emit('ok')
                            this.$refs.form.resetFields()
                        })
                        .catch(() => {
                            this.confirmLoading = false
                        })
                } else {
                    this.confirmLoading = false
                }
            })
        },
        /**组织机构 */
        deptChange(v) {
            this.form.tenantId = v.value
            this.form.tenantName = v.title
        },
        /**日期 */
        dateChange(v, string) {
            this.form = {
                ...this.form,
                queryDate: v
            }
            this.form.certStartDate = string[0]
            this.form.certEndDate = string[1]
        },
        /**预览图片 */
        handlePreview(file) {
            this.previewImage = file.response
            this.previewVisible = true
        },
        async customRequest(options) {
            this.form.firmwareName = options.file.name
            this.form.firmwareSize = options.file.size
            const url = await uploadFile(options.file.name, options.file, 'patch')
            options.onSuccess(url, options.file) //url即为response的冗余字段
            this.form.firmwareUrl = url.replace('https', 'http')
            bmf.md5(
                options.file,
                (err, md5) => {
                    if (err) {
                        this.$message.warning('md5生成失败')
                        return
                    } else {
                        this.form.firmwareMd5 = md5
                    }
                },
                progress => {}
            )
        },
        async uploadChange({ file, fileList, event }) {
            // this.fileList = fileList
            this.fileList = fileList.slice(-1);
        }
    }
}
</script>
<style scoped lang="less">
/deep/ .ant-calendar-picker {
    width: 100% !important;
}
.avatar {
    width: 100px;
    height: 100px;
}
</style>
