<template>
    <a-card :bordered="false">
        <div class="table-page-search-wrapper">
            <a-form-model :label-col="labelCol" :wrapper-col="wrapperCol">
                <a-row :gutter="48">
                    <a-col :md="6" :sm="24">
                        <a-form-model-item label="选择时间">
                            <a-range-picker v-model="queryDate" format="YYYY-MM-DD" @change="dateChange" />
                        </a-form-model-item>
                    </a-col>
                    <a-col :md="6" :sm="24">
                        <a-form-model-item label="所属机构">
                            <dept-tree v-model="queryParam.tenantId_equal" :all="false" @change="handleDeptChange" />
                        </a-form-model-item>
                    </a-col>
                    <a-col :md="6" :sm="24">
                        <a-form-model-item label="所属线路">
                            <line-select placeholder="请选择所属线路" v-model="lineSubId_equal" :lines="lines" @change="handleLine" />
                        </a-form-model-item>
                    </a-col>

                    <a-col :md="6" :sm="24" align="right">
                        <span class="table-page-search-submitButtons">
                            <a-button type="primary" @click="() => search()">查询</a-button>
                            <a-button style="margin-left: 8px" @click="handleExport">导出</a-button>
                        </span>
                    </a-col>
                </a-row>
            </a-form-model>
        </div>
        <a-table
            ref="table"
            bordered
            size="small"
            :columns="columns"
            :pagination="false"
            :dataSource="dataSource"
            :rowKey="record => record.uniqueNumber"
            :scrollToFirstRowOnChange="true"
            :scroll="{ y: 650 }"
        >
        </a-table>
    </a-card>
</template>

<script>
import { NTable, DeptTree, LineSelect } from '@/components'
import { lineStationAnalysis, getLineList, excelLineStationAnalysis } from '@/api/alarm/index'
export default {
    components: {
        NTable,
        DeptTree,
        LineSelect
    },

    data() {
        return {
            labelCol: {
                sm: { span: 6 },
                xs: { span: 24 }
            },
            wrapperCol: {
                sm: { span: 18 },
                xs: { span: 24 }
            },
            queryDate: null, //告警时间范围
            // 查询参数
            queryParam: {
                uploadTime_ge: '', //查询开始时间yyyy-MM-dd HH:mm:ss
                uploadTime_le: '', //查询开始时间yyyy-MM-dd HH:mm:ss
                tenantId_equal: '', //部门
                lineSubId_equal: undefined //线路名称
            },
            lineSubId_equal: undefined,
            dataSource: [],
            // 表头
            columns: [
                {
                    title: '序号',
                    dataIndex: 'uniqueNumber',
                    key: 'uniqueNumber',
                    customRender: (text, record, index) => index + 1,
                    align: 'center',
                    width: '80px'
                },
                {
                    title: '线路名称',
                    dataIndex: 'lineSubName',
                    key: 'lineSubName'
                },
                {
                    title: '站点名称',
                    dataIndex: 'stationName',
                    key: 'stationName'
                },
                {
                    title: '上下行',
                    dataIndex: 'lineType',
                    key: 'lineType'
                },
                {
                    title: '上客人数',
                    dataIndex: 'inNum',
                    key: 'inNum'
                },
                {
                    title: '下客人数',
                    dataIndex: 'leaveNum',
                    key: 'leaveNum'
                }
            ],
            /** 线路列表 */
            lines: [],
            tenantName: ''
        }
    },
    activated() {
        this.getLines()
    },
    methods: {
        handleLine(v, lineSubId_equal) {
            this.queryParam.lineSubId_equal = lineSubId_equal
        },
        search() {
            if (!this.queryParam.uploadTime_le) {
                this.$message.warning('请选择时间')
                return
            }
            if (this.$moment(this.queryParam.uploadTime_le).diff(this.$moment(this.queryParam.uploadTime_ge), 'days') > 31) {
                this.$message.warning('时间长度不能超过31天')
                return
            }
            if (!this.queryParam.lineSubId_equal) {
                this.$message.warning('请选择所属线路')
                return
            }
            lineStationAnalysis(this.queryParam).then(res => {
                this.dataSource = res
            })
        },
        handleDeptChange({ value, title }) {
            this.getLines(value)
            this.queryParam.lineSubId_equal = undefined
            this.tenantName = title
            this.lineSubId_equal = undefined
        },
        filterOption(input, option) {
            return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
        },
        getLines(tenantId = this.$store.state.user.tenant.tenantId) {
            getLineList({
                size: '-1',
                descs: 'create_time', //排序字段
                tenantId_equal: tenantId //所属机构
            }).then(res => {
                this.lines = res.records
            })
        },
        dateChange(date, dateString) {
            this.queryParam.uploadTime_ge = dateString[0] ? this.$moment(dateString[0]).format('YYYY-MM-DD 00:00:00') : ''
            this.queryParam.uploadTime_le = dateString[1] ? this.$moment(dateString[1]).format('YYYY-MM-DD 23:59:59') : ''
        },

        /** 导出 */
        handleExport() {
            if (!this.queryParam.uploadTime_le) {
                this.$message.warning('请选择时间')
                return
            }
            if (this.$moment(this.queryParam.uploadTime_le).diff(this.$moment(this.queryParam.uploadTime_ge), 'days') > 31) {
                this.$message.warning('时间长度不能超过31天')
                return
            }
            if (!this.queryParam.lineSubId_equal) {
                this.$message.warning('请选择所属线路')
                return
            }
            const item = this.lines.find(item => item.lineSubId === this.queryParam.lineSubId_equal)
            let lineSubName = item?.lineSubName || ''
            excelLineStationAnalysis({ ...this.queryParam, tenantName: this.tenantName, lineSubName })
        }
    }
}
</script>

<style lang="less" scoped>
.alarmTypeName {
    border-radius: 20px;
    padding: 2px 8px;
    width: fit-content;
    background: rgba(250, 152, 164, 0.996078431372549);
    color: #fff;
    font-size: 12px;
}
/deep/ .ant-card {
    height: 100%;
}
/deep/ .ant-card-body {
    height: 100%;
}
</style>
