<template>
  <a-modal
    title="授权菜单"
    :width="600"
    centered
    :maskClosable="false"
    :destroyOnClose="true"
    :visible="visible"
    :confirmLoading="confirmLoading"
    @ok="handleSubmit"
    @cancel="handleCancel"
  >
    <a-spin :spinning="formLoading">
      <a-form :form="form">
        <a-form-item label="菜单权限" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <a-tree
            multiple
            checkable
            :auto-expand-parent="autoExpandParent"
            :expanded-keys="expandedKeys"
            :tree-data="menuTreeData"
            v-model="selectedKeys"
            :replaceFields="replaceFields"
            @expand="onExpand"
            @check="treeCheck"
          />
        </a-form-item>
      </a-form>
    </a-spin>
  </a-modal>
</template>

<script>
import { SysMenuTreeForGrant } from '@/api/system/menu'
import { sysRoleOwnMenu, sysRoleGrantMenu } from '@/api/system/role'
import { uniqueTree } from '@/utils/util'
export default {
  data() {
    return {
      labelCol: {
        style: { 'padding-right': '20px' },
        xs: { span: 24 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 15 }
      },
      menuTreeData: [],
      expandedKeys: [],
      checkedKeys: [],
      visible: false,
      confirmLoading: false,
      formLoading: true,
      autoExpandParent: true,
      selectedKeys: [],
      subValues: [],
      roleEntity: [],
      childArr: [],
      replaceFields: {
        key: 'id'
      },
      form: this.$form.createForm(this)
    }
  },

  methods: {
    // 初始化方法
    roleMenu(record) {
      this.formLoading = true
      this.roleEntity = record
      this.visible = true
      this.childArr = []
      this.getMenuTree()
    },

    /**
     * 获取菜单列表
     */
    getMenuTree() {
      SysMenuTreeForGrant().then(res => {
        if (res.success) {
          this.menuTreeData = res.data
          // 默认展开目录级
          this.menuTreeData.forEach(item => {
            this.expandedKeys.push(item.id)
          })
          this.requestList(res.data)
          this.expandedMenuKeys(this.roleEntity)
        }
      })
    },

    // 递归筛选出来所有的子节点，存放在childArr数组中
    requestList(data) {
      data &&
        data.map(item => {
          if (item.children && item.children.length > 0) {
            this.requestList(item.children)
          } else {
            this.childArr.push(item.id)
          }
          return null
        })
      // console.log('子节点', this.childArr)
      // return childArr
    },
    /**
     * 此角色已有菜单权限
     */
    expandedMenuKeys(record) {
      sysRoleOwnMenu({ id: record.id }).then(res => {
        if (res.success) {
          this.selectedKeys = uniqueTree(res.data, this.childArr)
          console.log('checkedKeys', this.selectedKeys)
        }
        this.formLoading = false
      })
    },

    treeCheck(checkKeys, info) {
      const checkedKeysResult = [...checkKeys, ...info.halfCheckedKeys]
      this.checkedKeys = checkedKeysResult
      // console.log('treeCheck', checkKeys, info)
    },
    onExpand(expandedKeys) {
      this.expandedKeys = expandedKeys
      this.autoExpandParent = false
    },

    handleSubmit() {
      const {
        form: { validateFields }
      } = this
      this.confirmLoading = true
      this.formLoading = true;
      console.log('checkedKeys', this.checkedKeys)
      if(this.checkedKeys.length>0){
        validateFields((errors, values) => {
          if (!errors) {
            sysRoleGrantMenu({
              id: this.roleEntity.id,
              grantMenuIdList: this.checkedKeys.checked ? this.checkedKeys.checked : this.checkedKeys
            })
              .then(res => {
                if (res.success) {
                  this.$message.success('授权成功')
                  this.confirmLoading = false
                  this.formLoading = false;
                  this.$emit('ok', values)
                  this.handleCancel()
                } else {
                  this.$message.error('授权失败：' + res.msg)
                }
              })
              .finally(res => {
                this.confirmLoading = false
                this.formLoading = false;
              })
          } else {
            this.confirmLoading = false
            this.formLoading = false;
          }
        })
      }else{
        this.$emit('ok', '')
        this.handleCancel()
        this.formLoading = false;
        this.confirmLoading = false
      }
      
    },
    handleCancel() {
      // 清空已选择的
      this.checkedKeys = []
      this.selectedKeys = []
      // 清空已展开的
      this.expandedKeys = []
      this.visible = false
    }
  }
}
</script>
