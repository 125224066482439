<template>
  <a-modal
        :title="title"
        centered
        :width="700"
        :visible="visible"
        :confirmLoading="confirmLoading"
        @ok="handleOk"
        @cancel="handleCancel"
        destroyOnClose
    >
    <a-input-search placeholder="搜索机构" style="width: 200px" @search="onSearch" />
    <a-table
      :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
      :columns="columns"
      :data-source="orgLists"
      :rowKey="record => record.tenantId"
    />
  </a-modal>
</template>

<script>
import { NTable } from '@/components'
export default {
    data() {
        return {
            fromEmail:false,
            title:'选择用户',
            visible:false,
            confirmLoading:false,
            selectedRowKeys: [],
            selectedRows:[],
            queryParam:{
              descs: 'create_time',
              size:-1
            },
            // 表头
            columns: [
                {
                    title: '机构名称',
                    dataIndex: 'name',
                    ellipsis: true,
                    key: 'name'
                },
                {
                    title: '地址',
                    dataIndex: 'orgAddress',
                    ellipsis: true,
                    key: 'orgAddress'
                },
                {
                    title: '行业',
                    dataIndex: 'industryName',
                    ellipsis: true,
                    key: 'industryName'
                },
            ],
        }
    },
    props: {
      orgLists:{
        type:Array,
        default: [],
      },
      tenantName:{
          type: String,
          default: ''
      }
    },
    components:{NTable},
    mounted(){
    },
    methods: {
      edit(choosedOrgList) {
          this.visible = true
          this.selectedRowKeys = []
          this.selectedRows = []
          this.selectedRows = [...choosedOrgList]
          console.log(this.selectedRows,'this.selectedRows');
          this.selectedRows.map(item=>{
            this.selectedRowKeys.push(item.tenantId)
          })
          console.log(this.selectedRows,'this.selectedRows',this.selectedRowKeys);
      },
      
      onSearch(value) {
        this.$emit('getOrgList',value)
      },
      onSelectChange(selectedRowKeys, selectedRows) {
          this.selectedRowKeys = selectedRowKeys
          this.selectedRows = selectedRows
      },
      handleOk(){
        console.log(this.fromEmail,'this.fromEmail');
        this.$emit('ok',this.selectedRows)
        this.visible = false
      },
      handleCancel(){
        this.visible = false
      },
      
    }
  
}
</script>

<style>
.choosArea{
  padding: 0 5px;
  border: #ddd dashed 1px;
  margin-bottom: 5px;
}
</style>