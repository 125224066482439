/*
 * @Autor: dingxiaolin
 * @Date: 2021-09-23 07:31:51
 * @LastEditors: dingxiaolin
 * @LastEditTime: 2021-12-15 09:38:27
 */
import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate"
Vue.use(Vuex)

/********************************自动导包 start*****************************/
const file = require.context('./modules', false, /\.js$/);
const modules = {};
file.keys().forEach((key) => {
    const name = key
        .split('/')
        .pop()
        .replace(/\.\w+$/, '')
    modules[name] = file(key).default
});
/********************************自动导包 end********************************/


export default new Vuex.Store({
    modules,
    state: {},
    mutations: {},
    actions: {},
    plugins: [createPersistedState({
        storage: window.localStorage
    })]
})
