/*
 * @Autor: dingxiaolin
 * @Date: 2022-01-27 13:04:03
 * @LastEditors: dingxiaolin
 * @LastEditTime: 2022-01-27 17:11:49
 */
/**
 * 
 * @param {*} confirmStatus 确警状态
 * @param {*} isDeal 处理状态
 */
export var get_status = function (confirmStatus, isDeal) {
    if (isDeal == 2) {
        return { text: '忽略', color: 'grey' }
    }
    if (isDeal == 1) {
        if (confirmStatus == 1) {
            return { text: '处理完成', color: 'green' }
        }
        if (confirmStatus == 2) {
            //其它表示 “误报”
            return { text: '误报', color: 'red' }
        }
    }
    if (isDeal == 0) {
        if (confirmStatus == 1) {
            return { text: '已确警', color: 'orange' }
        }
        if (confirmStatus == 0) {
            return { text: '待确警', color: 'blue' }
        }
    } else {
        return { text: '', color: '' }
    }

}
/**
 * 
 * @param {*} doorType 门型
 */
 export var getDoorName = function (doorType) {
   let arr = doorType.split(',') //"doorType":"1",//门型 1-右前，2-右中 3-右后 4-左前 5-左中 6-左后
   let doorname = ''
   console.log(arr,'doorarr');
   arr.map(i=>{
    switch (i) {
      case '1':
        doorname +=  '右前'
        break;
      case '2':
        doorname +=  '右中'
        break;
      case '3':
        doorname +=  '右后'
        break;
      case '4':
        doorname +=  '左前'
        break;
      case '5':
        doorname +=  '左中'
        break;
      case '6':
        doorname +=  '左后'
        break;
      default:
        break;
    }
   })
   return doorname
 }