import Vue from 'vue'
import axios from 'axios'
// import store from '@/store'
import { message, Modal, notification } from 'ant-design-vue' /// es/notification
import { VueAxios } from './axios'
import { Base64 } from 'js-base64'
import defaultSettings from '@/config/defaultSettings'
import { ACCESS_TOKEN } from '@/store/mutation-types'
import config from '@/config/config.js'

var fileDownload = require('js-file-download')
const { clientId, clientSecret } = defaultSettings
function host() {
    if (process.env.NODE_ENV === 'production') {
        return '/'
    } else {
        return '/' //代理
        // return 'http://192.168.1.102:8180/'; //波波
    }
}
// 创建 axios 实例
const service = axios.create({
    baseURL: host(),
    timeout: 30000 // 请求超时时间
})

const codeMessage = {
    200: '服务器成功返回请求的数据。',
    201: '新建或修改数据成功。',
    202: '一个请求已经进入后台排队（异步任务）。',
    204: '删除数据成功。',
    400: '发出的请求有错误，服务器没有进行新建或修改数据的操作。',
    401: '此账号已在其他设备登录，请重新登录', // 用户没有权限（令牌、用户名、密码错误）。
    403: '用户未得到授权，访问被禁止。',
    404: '发出的请求针对的是不存在的记录，服务器没有进行操作。',
    406: '请求的格式不可得。',
    410: '请求的资源被永久删除，且不会再得到的。',
    422: '当创建一个对象时，发生一个验证错误。',
    500: '服务器发生错误，请检查服务器。',
    502: '网关错误。',
    503: '服务不可用，服务器暂时过载或维护。',
    504: '网关超时。'
}

const err = error => {
    const ignoreUrl = [config.namespace + '/user/getLoginUser']
    if (error.response) {
        const data = error.response.data
        if (error.response.status === 503) {
            notification.destroy()
            notification.warning({
                message: '系统版本更新中',
                description: '预计需要10分钟，请稍后'
            })
            return
        }
        if (error.response.status === 500 || error.response.status !== 200) {
            if (data.msg.length > 0 && !ignoreUrl.includes(error.response.config.url)) {
                message.error(data.msg)
            }
        }
        if (error.response.status === 401) {
            // && !(data.result && data.result.isLogin)
            notification.error({
                message: codeMessage[error.response.status] || 'Unauthorized'
                // description: 'Authorization verification failed'
            })
            if (token) {
                store.dispatch('Logout').then(() => {
                    setTimeout(() => {
                        window.location.reload()
                    }, 1500)
                })
            }
        }
    }
    return Promise.reject(error)
}

// request interceptor
service.interceptors.request.use(config => {
    if (config.url.includes('dnl/auth')) {
        config.headers['Authorization'] = `Basic ${Base64.encode(`${clientId}:${clientSecret}`)}`
    }

    if (
        navigator.userAgent.match(/Android/i) ||
        navigator.userAgent.match(/webOS/i) ||
        navigator.userAgent.match(/iPhone/i) ||
        navigator.userAgent.match(/iPad/i) ||
        navigator.userAgent.match(/iPod/i) ||
        navigator.userAgent.match(/BlackBerry/i) ||
        navigator.userAgent.match(/Windows Phone/i)
    ) {
        //移动端
        config.headers['User-Type'] = 'mobile'
    } else {
        //pc端
        config.headers['User-Type'] = 'web'
    }

    if (Vue.ls.get(ACCESS_TOKEN)) {
        config.headers['Authorization'] = 'bearer ' + Vue.ls.get(ACCESS_TOKEN)
    }
    return config
}, err)

/**
 * response interceptor
 * 所有请求统一返回
 */
service.interceptors.response.use(response => {
    if (response.request.responseType === 'blob') {
        return response
    }
    const code = response.data.code
    if (code === 403) {
        // Modal.error({
        //     title: '提示：',
        //     content: response.data.msg,
        //     okText: '重新登录',
        //     onOk: () => {
        //         window.location.reload()
        //         Vue.ls.remove(ACCESS_TOKEN)
        //     }
        // })
    } else {
        return response.data
    }
}, err)

const installer = {
    vm: {},
    install(Vue) {
        Vue.use(VueAxios, service)
    }
}

export { installer as VueAxios, service as axios }
