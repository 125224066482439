<template>
    <a-card :bordered="false">
        <div class="table-page-search-wrapper">
            <a-form layout="inline">
                <a-row :gutter="48">
                    <a-col :md="6" :sm="24">
                        <a-form-item label="所属机构">
                            <DeptTree v-model="queryParam.tenantId_equal" @change="handleTanentChange" />
                        </a-form-item>
                    </a-col>
                    <a-col :md="6" :sm="24">
                        <a-form-item label="所属线路">
                            <a-select
                                showSearch
                                v-model="line_id"
                                option-filter-prop="children"
                                :filter-option="filterOption"
                                placeholder="请选择所属线路"
                                @change="lineChange"
                            >
                                <a-select-option value="">全部</a-select-option>
                                <!--  {{ item.isLoopLine == 1 ? ' 环线' : '非环' }} -->
                                <a-select-option v-for="(item, index) in lineList" :key="index" :value="item.id">{{
                                    item.lineSubName
                                }}</a-select-option>
                            </a-select>
                        </a-form-item>
                    </a-col>
                    <a-col :md="6" :sm="24" align="right" :offset="6">
                        <span class="table-page-search-submitButtons">
                            <a-button type="primary" @click="search">查询</a-button>
                            <a-button style="margin-left: 8px" @click="resetForm()">重置</a-button>
                        </span>
                    </a-col>
                </a-row>
            </a-form>
        </div>
        <div class="table-operator">
            <a-button style="margin-left:auto" type="primary" @click="$refs.edit.show()" v-if="hasPerm('line:add')">新增</a-button>
            <a-button type="primary" @click="handleStationsBind">关联站点</a-button>
            <a-button @click="asyncLine" style="margin-left:auto" v-if="hasPerm('async:line')">同步数据</a-button>
        </div>
        <div class="flex">
            <n-table
                ref="table"
                :columns="columns"
                :data="loadData"
                :scrollX="1190"
                :rowKey="record => record.id"
                :rowSelection="{ type: 'radio', onChange: onSelectChange }"
            >
                <span slot="isLoopLine" slot-scope="text">
                    {{ text == 1 ? '是' : '否' }}
                </span>
                <span slot="upNum" slot-scope="text">
                    {{ text || '-' }}
                </span>
                <span slot="downNum" slot-scope="text">
                    {{ text || '-' }}
                </span>
                <span slot="upTime" slot-scope="text">
                    {{ text || '-' }}
                </span>
                <span slot="downTime" slot-scope="text">
                    {{ text || '-' }}
                </span>
                <span slot="action" slot-scope="text, record">
                    <a-button type="link" @click="$refs.edit.show(record)">修改</a-button>
                    <a-divider type="vertical" />
                    <a-button type="link" @click="handlePreview(record)">预览</a-button>
                </span>
            </n-table>
            <div style="margin-left:10px">
                <n-table ref="tableStations" :columns="columnStations" :data="loadDataStations" :rowKey="record => record.id">
                    <span slot="stationType" slot-scope="text">
                        <template v-if="text == 0">
                            <a-tag color="green">上行</a-tag>
                        </template>
                        <template v-if="text == 1"><a-tag color="orange">下行</a-tag></template>
                        <template v-if="text == 2"><a-tag color="green">环线</a-tag></template>
                    </span>
                    <span slot="action" slot-scope="text, record">
                        <a-button
                            type="link"
                            size="small"
                            @click="
                                () => {
                                    let row = $refs.table._data.localDataSource.find(item => item.id == selectedKeys[0])
                                    $refs.bindStations.show({ ...row, ...record, lineId: selectedKeys[0] }, 'edit')
                                }
                            "
                            >编辑</a-button
                        >
                        <a-divider type="vertical" />
                        <a-popconfirm size="small" placement="topRight" title="你确定要删除该站点吗？" @confirm="() => del(record)">
                            <a-button type="link" style="color:red"> 删除</a-button>
                        </a-popconfirm>
                    </span>
                </n-table>
            </div>
        </div>
        <edit ref="edit" @ok="editOk" />
        <bind-stations ref="bindStations" @ok="editOk" />
        <line-preview ref="preview" />
    </a-card>
</template>

<script>
import { NTable, DeptTree } from '@/components'
import { getLineList, synInfo, getLineStations, lineStationDel } from '@/api/alarm/index'
import Edit from './components/edit'
import BindStations from './components/bindStations.vue'
import linePreview from './components/linePreview.vue'
export default {
    components: {
        NTable,
        DeptTree,
        Edit,
        BindStations,
        linePreview
    },

    data() {
        return {
            line_id: '',
            loading: false,
            /**导入模态框 */
            dialogVisible: false,
            /**结果模态框 */
            resultVisible: false,
            /**结果列表模态框 */
            tableVisible: false,
            /**门数 */
            doorNumList: [1, 2, 3, 4, 5, 6],
            /**车型 */
            busTypeList: [],
            lineList: [],
            labelCol: {
                sm: { span: 8 },
                xs: { span: 24 }
            },
            wrapperCol: {
                sm: { span: 16 },
                xs: { span: 24 }
            },
            // 查询参数
            queryParam: {
                descs: 'create_time', //排序字段
                tenantId_equal: '', //所属机构
                lineSubName_equal: undefined //线路名
            },
            selectedKeys: [],
            // 表头
            columns: [
                {
                    title: '线路名称',
                    dataIndex: 'lineSubName',
                    ellipsis: true,
                    width: 120
                },
                {
                    title: '是否环线',
                    dataIndex: 'isLoopLine',
                    scopedSlots: { customRender: 'isLoopLine' },
                    width: 80
                },
                {
                    title: '所属机构',
                    dataIndex: 'tenantName',
                    ellipsis: true,
                    width: 140
                },
                {
                    title: '车辆数',
                    dataIndex: 'busNum',
                    width: 70
                },
                {
                    title: '上行站点数',
                    dataIndex: 'upNum',
                    scopedSlots: { customRender: 'upNum' },
                    width: 90
                },
                {
                    title: '下行站点数',
                    dataIndex: 'downNum',
                    scopedSlots: { customRender: 'downNum' },
                    width: 90
                },
                {
                    title: '上行首末班时间',
                    dataIndex: 'upTime',
                    scopedSlots: { customRender: 'upTime' },
                    ellipsis: true,
                    width: 120
                },
                {
                    title: '下行首末班时间',
                    dataIndex: 'downTime',
                    scopedSlots: { customRender: 'downTime' },
                    ellipsis: true,
                    width: 120
                },
                {
                    title: '创建时间',
                    dataIndex: 'createTime',
                    width: 150
                },
                {
                    title: '操作',
                    dataIndex: 'action',
                    align: 'center',
                    fixed: 'right',
                    scopedSlots: { customRender: 'action' }
                }
            ],
            // 表头
            columnStations: [
                {
                    title: '序号',
                    dataIndex: 'sort',
                    ellipsis: true,
                    width: 60
                },
                {
                    title: '站点名称',
                    dataIndex: 'stationName',
                    ellipsis: true,
                    width: 120
                },
                {
                    title: '方向',
                    dataIndex: 'stationType',
                    scopedSlots: { customRender: 'stationType' },
                    width: 86
                },
                {
                    title: '操作',
                    dataIndex: 'action',
                    scopedSlots: { customRender: 'action' },
                    width: 120
                }
            ],
            // 加载数据方法 必须为 Promise 对象
            loadData: async parameter => {
                return await getLineList(Object.assign(parameter, this.queryParam))
            },
            loadDataStations: async parameter => {
                if (this.selectedKeys.length == 0) {
                    return
                }
                return await getLineStations(Object.assign(parameter, { id: this.selectedKeys[0] }))
            },
            queryDate: null
        }
    },
    mounted() {
        // 获取全部线路
        getLineList({
            descs: 'create_time',
            size: -1,
            tenantId_equal: this.$store.state.user.tenant.tenantId
        }).then(res => {
            res.records.forEach(v => {
                v.label = v.lineSubName + (v.isLoopLine == 1 ? ' 环线' : '')
            })
            this.lineList = res.records
        })
    },
    methods: {
        del(record) {
            lineStationDel({
                stationId: record.id,
                lineId: this.selectedKeys[0],
                stationType: record.stationType
            }).then(res => {
                this.$message.success('删除成功')
                this.$refs.tableStations.refresh(true)
            })
        },
        handlePreview(record) {
            this.$refs.preview.show(record)
        },
        onSelectChange(key) {
            this.selectedKeys = key
            this.$refs.tableStations.refresh(true)
        },
        handleStationsBind() {
            if (this.selectedKeys.length == 0) {
                this.$message.warning('请选择线路')
                return
            }
            let row = this.$refs.table._data.localDataSource.find(item => item.id == this.selectedKeys[0])
            this.$refs.bindStations.show({ lineId: row.id, ...row }, 'add')
        },
        filterOption(input, option) {
            return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
        },
        editOk() {
            this.$refs.tableStations.refresh(true)
        },
        handleTanentChange() {
            this.queryParam.lineSubName_equal = ''
            this.line_id = ''
            getLineList({ descs: 'create_time', size: -1, tenantId_equal: this.queryParam.tenantId_equal }).then(res => {
                this.lineList = res.records
            })
        },
        handleOk() {
            if (this.file && this.fileList.length) {
                this.loading = true
                importExcel(this.file).then(res => {
                    this.loading = false
                    console.log('file', res)
                    if (res.resultCode === '0') {
                        let { totalNum, succfNum, errorNum, errorInfo } = res.resultPojo
                        this.errorNum = errorNum
                        this.dialogVisible = false
                        this.resultVisible = true
                        this.resultText = `共上传${totalNum}条数据，成功导入${succfNum}条，失败`
                        if (errorNum > 0) {
                            errorInfo.forEach((v, i) => {
                                v.index = i + 1
                            })
                            this.resultData = errorInfo
                        }
                        this.$refs.table.refresh()
                    }
                    if (res.resultCode === '1') {
                        console.log(this.$message)
                        this.$message.error(res.resultMsg)
                    }
                })
            } else {
                this.$message.error('请选择上传的模板')
            }
        },
        resetForm() {
            this.queryParam = {
                descs: 'line_sub_name', //排序字段
                tenantId_equal: '', //所属机构
                lineSubName_equal: undefined //线路名
            }
            this.line_id = ''
            this.queryDate = null
        },
        customRequest(event) {
            this.file = event.file
        },
        handleCancel() {
            this.file = null
            this.dialogVisible = false
        },
        /**查询 */
        search() {
            this.$refs.table.refresh(true)
        },
        /**同步线路 */
        asyncLine() {
            synInfo({}).then(res => {
                this.$message.success('同步线路成功')
            })
        },
        lineChange(id) {
            let data = this.lineList.filter(v => v.id == id)
            if (data.length > 0) {
                this.queryParam.lineSubName_equal = data[0].lineSubName
            } else {
                console.log('trest')
                this.line_id = ''
                this.queryParam.lineSubName_equal = ''
            }
        }
    }
}
</script>

<style lang="less" scoped>
.align .ant-form-item-children {
    display: flex;
    .ant-input-affix-wrapper {
        width: 45%;
    }
    .line {
        width: 10%;
        display: inline-block;
        text-align: center;
    }
}
.step1 {
    p span {
        color: red;
        cursor: pointer;
        text-decoration: underline;
    }
}
.resultContent {
    display: flex;
    flex-direction: column;
    align-items: center;
}
</style>
