<!--
 * @Author: gsy 1443717855@qq.com
 * @LastEditors: gsy 1443717855@qq.com
 * @Date: 2024-02-20 15:27:22
 * @LastEditTime: 2024-02-29 11:38:49
 * @Description: 
-->
<template>
    <a-modal :title="title" :width="1000" :visible="visible" :confirm-loading="confirmLoading" @ok="handleOk" @cancel="handleCancel" destroyOnClose>
        <div id="shipcontainer">
            <a-radio-group v-if="!isRound" v-model="value" @change="onChange" class="btn">
                <a-radio-button value="0">
                    上行
                </a-radio-button>
                <a-radio-button value="1">
                    下行
                </a-radio-button>
            </a-radio-group>
        </div>
    </a-modal>
</template>
<script>
import { lineDetail } from '@/api/alarm/index'
export default {
    data() {
        this.map = null
        return {
            title: '线路预览',
            visible: false,
            confirmLoading: false,
            mapOptions: {
                resizeEnable: true,
                viewMode: '2D', //开启3D视图,默认为关闭
                zoom: 16
            },
            value: '0',
            info: {}
        }
    },
    computed: {
        isRound() {
            return this.info.isLoopLine === 1
        }
    },
    methods: {
        onChange() {
            this.renderLine()
        },
        renderLine() {
            // 线路类型 上行 下行
            this.map.clearMap()
            if (this.info.isLoopLine !== 1) {
                if (this.value === '0') {
                    // 上行
                    this.generateLine('upStationList')
                } else {
                    this.generateLine('downStationList')
                }
            } else {
                // 环线
                // this.info.roundStationList.push(this.info.roundStationList[0])
                this.generateLine('roundStationList')
            }
        },
        /**
         * @description: 生成地图中的覆盖物线路组合
         * @param {String} type 类型 上下行 upStationList  downStationList
         * @return {*}
         * @author: shiyuan
         */
        generateLine(typeKey) {
            let path = this.info[typeKey].map(item => {
                return [item.stationLng, item.stationLat]
            })
            console.log(typeKey,path)
            var polyline = new AMap.Polyline({
                path: path,
                isOutline: true,
                outlineColor: '#ffeeff',
                borderWeight: 3,
                strokeColor: '#3366FF',
                strokeOpacity: 1,
                strokeWeight: 1,
                // 折线样式还支持 'dashed'
                strokeStyle: 'solid',
                // strokeStyle是dashed时有效
                strokeDasharray: [10, 5],
                lineJoin: 'round',
                lineCap: 'round',
                zIndex: 50
            })
            var markers = []
            path.forEach((item, i) => {
                var icon = '//a.amap.com/jsapi_demos/static/demo-center/icons/dir-via-marker.png'
                if (i == 0) {
                    var startIcon = new AMap.Icon({
                        // 图标尺寸
                        size: new AMap.Size(25, 34),
                        // 图标的取图地址
                        image: '//a.amap.com/jsapi_demos/static/demo-center/icons/dir-marker.png',
                        // 图标所用图片大小
                        imageSize: new AMap.Size(135, 40),
                        // 图标取图偏移量
                        imageOffset: new AMap.Pixel(-9, -3)
                    })
                    icon = startIcon
                }
                if (i === path.length - 1) {
                    var endIcon = new AMap.Icon({
                        size: new AMap.Size(25, 34),
                        image: '//a.amap.com/jsapi_demos/static/demo-center/icons/dir-marker.png',
                        imageSize: new AMap.Size(135, 40),
                        imageOffset: new AMap.Pixel(-95, -3)
                    })
                    icon = endIcon
                }
                var marker = new AMap.Marker({
                    map: this.map,
                    icon,
                    position: path[i]
                })
                marker.setLabel({
                    direction: 'top',
                    offset: new AMap.Pixel(10, 0), //设置文本标注偏移量
                    content: `第${i + 1}站--${this.info[typeKey][i].stationName}` //设置文本标注内容
                })
                var circle = new AMap.Circle({
                    center: path[i],
                    radius: path[i].radius || 0, //半径
                    borderWeight: 3,
                    strokeOpacity: 1,
                    strokeWeight: 6,
                    strokeOpacity: 0.2,
                    fillOpacity: 0.4,
                    // 线样式还支持 'dashed'
                    fillColor: '#1791fc',
                    zIndex: 50
                })
                this.map.add(circle)
                markers.push(marker)
            })
            this.map.add([polyline])
            this.map.setFitView()
        },
        getDetail(record) {
            lineDetail({
                id: record.id
            }).then(res => {
                this.info = res
                this.renderLine()
            })
        },
        show(record) {
            // 创建地图实例
            setTimeout(() => {
                this.map = new AMap.Map('shipcontainer', this.mapOptions)
                this.getDetail(record)
            }, 50)
            this.visible = true
        },
        handleOk() {
            this.visible = false
        },
        handleCancel() {
            this.visible = false
        }
    }
}
</script>
<style lang="less" scoped>
#shipcontainer {
    width: 100%;
    height: 600px;
    position: relative;
}
.btn {
    position: absolute;
    z-index: 999;
}
/deep/ .amap-icon img {
    width: 25px;
    height: 34px;
}
</style>
