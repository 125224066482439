<template>
    <a-modal
        :title="title"
        centered
        :width="980"
        :visible="visible"
        :maskClosable="false"
        :destroyOnClose="true"
        :confirmLoading="confirmLoading"
        @ok="handleSubmit"
        @cancel="handleCancel"
    >
        <a-spin :spinning="confirmLoading">
            <a-form-model ref="ruleForm" :rules="formRules" :model="form" :label-col="labelCol" :wrapper-col="wrapperCol">
                <a-form-model-item label="摄像头名称" prop="deviceName" :rules="formRules.blur">
                    <a-input v-model="form.deviceName" placeholder="请输入摄像头名称" :maxLength="35" />
                </a-form-model-item>
                <a-form-model-item label="监控场景" prop="alarmSceneType" :rules="formRules.change">
                    <a-select v-model="form.alarmSceneType" style="width: 100%" placeholder="请选择监控场景" @change="selectAlarmSceneType">
                        <a-select-option value="">请选择</a-select-option>
                        <a-select-option v-for="(item, index) in sceneTypeList" :key="index" :value="item.code">{{ item.value }}</a-select-option>
                    </a-select>
                </a-form-model-item>
                <a-form-model-item label="所属机构" prop="tenantId" :rules="formRules.blur">
                    <dept-tree v-model="form.tenantId" :all="false" @change="handleTenantChange" :disabled="title == '修改摄像头'" />
                </a-form-model-item>
                <a-form-model-item label="备注" prop="memo">
                    <a-textarea v-model="form.memo" :maxLength="255" :rows="4" placeholder="请输入备注"></a-textarea>
                </a-form-model-item>
            </a-form-model>
        </a-spin>
    </a-modal>
</template>

<script>
import { alarmDeviceAdd, alarmDeviceEdit } from '@/api/alarm/alarmDevice'
import { sysDictTypeDropDown } from '@/api/comm/index'
import { getDeptTree } from '@/api/alarm'
import DeptTree from '@/components/DeptTree'
import { formRules } from '@/utils/validator'
import { mapState, mapActions } from 'vuex'

export default {
    components: {
        DeptTree
    },
    computed: {
        ...mapState({
            tenant: state => state.user.tenant
        })
    },
    data() {
        return {
            title: '新增摄像头',
            formRules: formRules,
            labelCol: {
                xs: { span: 24 },
                sm: { span: 5 }
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 15 }
            },
            visible: false,
            confirmLoading: false,
            //监控场景
            sceneTypeList: [],
            form: {
                id: '',
                alarmSceneName: '',
                deviceName: '',
                alarmSceneType: '',
                memo: '',
                tenantId: this.$store.state.user.tenant.tenantId || '',
                tenantName: this.$store.state.user.tenant.tenantName || ''
            },
            treeData: []
        }
    },

    created() {
        this.sysDictTypeDropDown()
        this.getDeptTree()
        this.getUserTenant()
    },
    methods: {
        ...mapActions(['getUserTenant']),
        // 初始化方法
        add() {
            this.visible = true
            this.title = '新增摄像头'
            this.form = {
                id: '',
                alarmSceneName: '',
                deviceName: '',
                alarmSceneType: '',
                memo: '',
                tenantId: this.tenant.tenantId || '',
                tenantName: this.tenant.tenantName || '',
                version: ''
            }
        },
        // 开启编辑
        edit(record) {
            this.visible = true
            this.title = '修改摄像头'
            this.form = {
                id: record.id,
                deviceName: record.deviceName,
                alarmSceneType: record.alarmSceneType,
                alarmSceneName: record.alarmSceneName,
                memo: record.memo,
                tenantName: record.tenantName,
                version: record.version,
                tenantId: record.tenantId
            }
        },
        /** 获取部门树 */
        getDeptTree() {
            getDeptTree().then(res => {
                this.treeData = res
            })
        },
        handleSubmit() {
            this.confirmLoading = true
            this.$refs.ruleForm.validate(valid => {
                if (valid) {
                    // success
                    if (this.form.id) {
                        // edit
                        alarmDeviceEdit(this.form).then(res => {
                            this.$message.success('修改成功')
                            this.visible = false
                            this.confirmLoading = false
                            this.$emit('ok', this.form)
                        })
                    } else {
                        // add
                        alarmDeviceAdd(this.form).then(res => {
                            this.$message.success('新增成功')
                            this.visible = false
                            this.confirmLoading = false
                            this.$emit('ok', this.form)
                            this.$refs.ruleForm.resetFields()
                        })
                    }
                } else {
                    // error
                    this.confirmLoading = false
                }
            })
        },
        handleCancel() {
            this.visible = false
        },
        /**
         * 获取所属分类
         */
        sysDictTypeDropDown() {
            sysDictTypeDropDown({ code: 'scene' }).then(res => {
                this.sceneTypeList = res
            })
        },
        //选择场景
        selectAlarmSceneType(code) {
            //传进来的val是select组件选中的value值，也就是code
            var obj = this.sceneTypeList.find(function(item) {
                return item.code === code
            })
            this.form.alarmSceneName = obj.value
        },
        handleTenantChange(options) {
            this.form.tenantName = options.title
        }
    }
}
</script>
