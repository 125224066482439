import { axios } from '@/utils/request'
import config from '@/config/config.js'

/**
 * 在线用户列表
 */
export function sysOnlineUserList(parameter) {
    return axios({
        url: config.namespace+'/online/list',
        method: 'get',
        params: parameter
    })
}

/**
 * 强制下线
 */
export function sysOnlineUserForceExist(parameter) {
    return axios({
        url: config.namespace+'/online/forceExist',
        method: 'post',
        data: parameter
    })
}
