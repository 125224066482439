import Vue from 'vue'
import {
    tokenLogin,
    login,
    getLoginUser,
    logout
} from '@/api/system/auth'
import {
    sysDictTypeTree
} from '@/api/system/dict'
import {
    sysMenuChange
} from '@/api/system/menu'
import {
    getPasswordStrength
} from '@/utils/util'
import {
    message,
    Modal,
    notification
} from 'ant-design-vue' /// es/notification
import {
    ACCESS_TOKEN,
    ALL_APPS_MENU,
    DICT_TYPE_TREE_DATA,
    PW_LEVEL,
    USER_INFO
} from '@/store/mutation-types'

import store from '../index'
import router from '../../router'
import config from '@/config/config.js'

const user = {
    state: {
        token: '',
        name: '',
        avatar: '',
        buttons: [], // 按钮权限
        admintype: '', // 是否是超管
        roles: [],
        info: {},
        appsmenu: [],
        pwLevel: '',
        venIdc: '',
        payData: {},
        id: '', //用户id
        tenant: {}, // 当前用户的部门id
    },

    mutations: {
        SET_TOKEN: (state, token) => {
            state.token = token
            Vue.ls.set(ACCESS_TOKEN, token, 7 * 24 * 60 * 60 * 1000)
        },
        SET_NAME: (state, {
            name
        }) => {
            state.name = name
        },
        SET_AVATAR: (state, avatar) => {
            state.avatar = avatar
        },
        SET_ROLES: (state, roles) => {
            state.roles = roles
        },
        SET_INFO: (state, info) => {
            state.info = info
            Vue.ls.set(USER_INFO, info)
        },
        GET_INFO: state => {
            state.info = Vue.ls.get('USER_INFO')
        },
        SET_MENUS: (state, appsmenu) => {
            state.appsmenu = appsmenu
            Vue.ls.set(ALL_APPS_MENU, appsmenu)
        },
        GET_MENUS: state => {
            state.appsmenu = Vue.ls.get('ALL_APPS_MENU')
        },
        SET_PAY_DATA: (state, payData) => {
            state.payData = payData
            Vue.ls.set(PAY_DATA, payData)
        },
        GET_PAY_DATA: state => {
            state.info = Vue.ls.get('USER_INFO')
        },
        SET_PW_LEVEL: (state, pwLevel) => {
            state.pwLevel = pwLevel
            Vue.ls.set(PW_LEVEL, pwLevel)
        },
        GET_PW_LEVEL: state => {
            state.pwLevel = Vue.ls.get('PW_LEVEL')
        },
        SET_BUTTONS: (state, buttons) => {
            state.buttons = buttons
        },
        SET_ADMINTYPE: (state, admintype) => {
            state.admintype = admintype
        },
        SET_TENANT: (state, tenant) => {
            state.tenant = {
                tenantId: tenant.tenantId == '0' ? '' : tenant.tenantId,
                tenantName: tenant.tenantId == '0' ? '' : tenant.tenantName
            }
            console.log('1111111111111', state.tenant)
            Vue.ls.set('USER_TENANT', state.tenant)
        },
        GET_TENANT: state => {
            state.tenant = Vue.ls.get('USER_TENANT')
        },

    },
    actions: {
        setPayData({
            commit
        }, payData) {
            commit('SET_PAY_DATA', payData)
        },
        getPayData({
            commit
        }, payData) {
            commit('GET_PAY_DATA', payData)
        },
        setPwLevel({
            commit
        }, pwLevel) {
            commit('SET_PW_LEVEL', pwLevel)
        },
        getPwLevel({
            commit
        }, pwLevel) {
            commit('GET_PW_LEVEL', pwLevel)
        },
        getUserInfo({
            commit
        }, info) {
            commit('GET_INFO', info)
        },
        getUserTenant({
            commit
        }, tenant) {
            commit('GET_TENANT', tenant)
        },
        // 登录
        Login({
            commit
        }, userInfo) {
            return new Promise((resolve, reject) => {
                // tokenLogin
                tokenLogin(userInfo)
                    .then(response => {
                        if (!response.success) {
                            message.error(response.msg)
                            reject(response.msg)
                            return
                        }
                        /** 判断密码强度 */
                        var strength = getPasswordStrength(userInfo.password)
                        if (strength < 3) {
                            commit('SET_PW_LEVEL', '低')
                        } else if (strength >= 3 && strength < 5) {
                            commit('SET_PW_LEVEL', '中')
                        } else {
                            commit('SET_PW_LEVEL', '高')
                        }

                        // accessToken
                        const result = response.data.accessToken.token
                        console.log('result', result)
                        window.localStorage.setItem('token', result)
                        commit('SET_TOKEN', result)
                        commit('SET_TENANT', {
                            tenantId: response.data.authUser.tenantId,
                            tenantName: response.data.authUser.tenantName
                        })
                        resolve()
                    })
                    .catch(error => {
                        reject('后端未启动或代理错误')
                    })
            })
        },

        // 获取用户信息
        GetInfo({
            commit
        }) {
            return new Promise((resolve, reject) => {
                getLoginUser()
                    .then(response => {
                        if (response.success) {
                            const data = response.data
                            commit('SET_INFO', data)
                            commit('SET_ADMINTYPE', data.adminType)
                            commit('SET_ROLES', data.roles)
                            commit('SET_BUTTONS', data.permissions)
                            commit('SET_NAME', {
                                name: data.name
                            })
                            console.log('dddddd', data.avatar)
                            if (data.avatar != null) {
                                commit('SET_AVATAR', data.avatar)
                            }
                            resolve(data)
                        } else {
                            // eslint-disable-next-line no-undef
                            reject(new Error(data.msg))
                        }
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        },

        // 登出
        Logout({
            commit,
            state
        }) {
            return new Promise(resolve => {
                logout(state.token)
                    .then(() => {
                        resolve()
                    })
                    .catch(() => {
                        resolve()
                    })
                    .finally(() => {
                        // commit('SET_TOKEN', '')
                        // commit('SET_ROLES', [])
                        // commit('SET_BUTTONS', [])
                        // commit('SET_ADMINTYPE', '')
                        // Vue.ls.clear()
                        localStorage.clear()
                        router.push('/')
                        location.reload()
                    })
            })
        },

        // 加载所有字典数据
        dictTypeData() {
            return new Promise((resolve, reject) => {
                sysDictTypeTree()
                    .then(data => {
                        if (data.success) {
                            const result = data.data
                            Vue.ls.set(DICT_TYPE_TREE_DATA, result)
                            resolve()
                        } else {
                            // eslint-disable-next-line no-undef
                            reject(new Error(data.msg))
                        }
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        },

        // 切换应用菜单
        MenuChange({
            commit
        }, application) {
            return new Promise(resolve => {
                sysMenuChange({
                    application: application.code
                })
                    .then(res => {
                        const apps = {
                            code: '',
                            name: '',
                            active: '',
                            menu: ''
                        }
                        apps.active = true
                        apps.menu = res.data
                        const all_app_menu = Vue.ls.get(ALL_APPS_MENU)
                        // eslint-disable-next-line camelcase
                        const new_false_all_app_menu = []
                        // 先去除所有默认的，以为此时切换的即将成为前端缓存默认的应用
                        all_app_menu.forEach(item => {
                            if (item.active) {
                                item.active = false
                            }
                            new_false_all_app_menu.push(item)
                        })
                        // 此时缓存中全部都是不默认的应用
                        commit('SET_MENUS', new_false_all_app_menu)
                        apps.name = application.name
                        apps.code = application.code
                        const applocationR = []
                        applocationR.push(apps)
                        /** 同步tabbar */
                        let userInfos = Vue.ls.get(USER_INFO)
                        let appsMenu = JSON.parse(JSON.stringify(userInfos.apps))
                        appsMenu.map((itemu, indexu) => {
                            if (itemu.code == application.code) {
                                itemu.active = true
                            } else {
                                itemu.active = false
                            }
                        })
                        userInfos.apps = JSON.parse(JSON.stringify(appsMenu))
                        commit('SET_INFO', userInfos)
                        commit('SET_MENUS', applocationR)
                        resolve(res)
                        const antDesignmenus = res.data
                        store.dispatch('GenerateRoutes', {
                            antDesignmenus
                        }).then(() => {
                            router.addRoutes(store.state.route.addRouters)
                        })
                    })
                    .catch(() => {
                        resolve()
                    })
            })
        }
    }
}

export default user