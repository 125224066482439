<template>
    <div>
        <a-card :bordered="false">
            <div class="table-page-search-wrapper">
                <a-form-model :label-col="labelCol" :wrapper-col="wrapperCol">
                    <a-row :gutter="48">
                        <a-col :md="6" :sm="24">
                            <a-form-model-item label="所属机构">
                                <dept-tree v-model="queryParam.tenantId_equal" :all="false" @change="handleDeptChange" />
                            </a-form-model-item>
                        </a-col>
                        <a-col :md="6" :sm="24">
                            <a-form-model-item label="选择线路">
                                <line-select placeholder="请选择所属线路" v-model="lineSubId_equal" :lines="lines" @change="handleLine" />
                            </a-form-model-item>
                        </a-col>
                        <a-col :md="6" :sm="24">
                            <a-form-model-item label="时间">
                                <a-range-picker
                                    :show-time="{ defaultValue: [$moment('00:00:00', 'HH:mm:ss'), $moment('23:59:59', 'HH:mm:ss')] }"
                                    v-model="queryDate"
                                    format="YYYY-MM-DD"
                                    @change="dateChange"
                                />
                            </a-form-model-item>
                        </a-col>
                        <a-col :md="6" :sm="24">
                            <a-form-model-item label="上下行">
                                <a-select placeholder="请选择上下行" v-model="queryParam.lineType_equal" allowClear>
                                    <a-select-option key="0" :value="0">上行</a-select-option>
                                    <a-select-option key="1" :value="1">下行</a-select-option>
                                    <a-select-option key="2" :value="2">环线</a-select-option>
                                </a-select>
                            </a-form-model-item>
                        </a-col>
                        <a-col :md="6" :sm="24" :offset="18" align="right">
                            <span class="table-page-search-submitButtons">
                                <a-button type="primary" @click="onQuery">查询</a-button>
                                <a-button style="margin-left: 8px" @click="() => reset()">重置</a-button>
                                <a-button style="margin-left: 8px" @click="() => exportData()">导出</a-button>
                            </span>
                        </a-col>
                    </a-row>
                </a-form-model>
            </div>
        </a-card>
        <div class="chart-container">
            <a-card>
                <chart ref="alarmTotal" :data="data" />
            </a-card>
            <a-card>
                <a-table
                    bordered
                    :loading="isLoading"
                    :columns="Columns"
                    :data-source="lineData"
                    size="small"
                    :rowKey="record => record.id"
                    :scrollToFirstRowOnChange="true"
                    :scroll="{ y: 450 }"
                    :pagination="false"
                >
                </a-table>
            </a-card>
        </div>
    </div>
</template>

<script>
import { getLineList, lineLargeStationAnalysis, exportLineLargeStationAnalysis } from '@/api/alarm/index'
import { LineSelect, DeptTree } from '@/components'
import Chart from './chart.vue'
export default {
    components: {
        DeptTree,
        LineSelect,
        Chart
    },
    data() {
        return {
            /** 线路列表 */
            lines: [],
            isLoading: false,
            data: { xdata: [], inNum: [], leaveNum: [] },
            lineData: [],
            labelCol: {
                sm: { span: 6 },
                xs: { span: 24 }
            },
            lineSubId_equal: undefined,
            wrapperCol: {
                sm: { span: 18 },
                xs: { span: 24 }
            },

            // 查询参数
            queryParam: {
                uploadYearMonthDay_ge: this.$moment()
                    .subtract(1, 'days')
                    .format('YYYY-MM-DD'),
                uploadYearMonthDay_le: this.$moment()
                    .subtract(1, 'days')
                    .format('YYYY-MM-DD'),
                lineSubId_equal: '',
                lineType_equal: 0,
                tenantId_equal: this.$store.state.user.tenant.tenantId == 0 ? '' : this.$store.state.user.tenant.tenantId,
                ascs: 'sort'
            },
            queryDate: [
                this.$moment()
                    .subtract(1, 'days')
                    .format('YYYY-MM-DD'),
                this.$moment()
                    .subtract(1, 'days')
                    .format('YYYY-MM-DD')
            ],
            Columns: [
                {
                    title: '序号',
                    dataIndex: 'sort',
                    align: 'center',
                    width: '80px'
                },
                {
                    title: '线路名称',
                    dataIndex: 'lineSubName',
                    width: '100px',
                    ellipsis: true
                },
                {
                    title: '站点名称',
                    dataIndex: 'stationName',
                    width: '100px',
                    ellipsis: true
                },
                {
                    title: '统计日期',
                    dataIndex: 'uploadDate',
                    width: '100px',
                    ellipsis: true
                },
                {
                    title: '上下行',
                    dataIndex: 'lineType',
                    width: '100px',
                    ellipsis: true
                },
                {
                    title: '上客统计（人数）',
                    dataIndex: 'inNum',
                    width: '100px',
                    ellipsis: true
                },
                {
                    title: '下客统计（人数）',
                    dataIndex: 'leaveNum',
                    width: '100px',
                    ellipsis: true
                }
            ],
            pagination: {
                current: 1,
                pageSize: -1,
                total: 0,
                showSizeChanger: false,
                showTotal: (total, range) => {
                    return '共' + total + '条'
                }
            }
        }
    },

    mounted() {
        this.getLines()
    },

    methods: {
        reset() {
            this.queryParam = {
                uploadYearMonthDay_ge: this.$moment()
                    .subtract(1, 'days')
                    .format('YYYY-MM-DD'),
                uploadYearMonthDay_le: this.$moment()
                    .subtract(1, 'days')
                    .format('YYYY-MM-DD'),
                lineSubId_equal: '',
                lineType_equal: 0,
                ascs: 'create_time'
            }
            this.queryDate = [
                this.$moment()
                    .subtract(1, 'days')
                    .format('YYYY-MM-DD'),
                this.$moment()
                    .subtract(1, 'days')
                    .format('YYYY-MM-DD')
            ]
        },
        exportData() {
            exportLineLargeStationAnalysis(this.queryParam)
        },
        handleLine(v, lineSubId_equal) {
            console.log('cout', v, lineSubId_equal)
            this.queryParam.lineSubId_equal = lineSubId_equal
        },
        getLines(tenantId = this.$store.state.user.tenant.tenantId) {
            getLineList({
                size: '-1',
                descs: 'create_time', //排序字段
                tenantId_equal: tenantId //所属机构
            }).then(res => {
                this.lines = res.records
            })
        },
        handleDeptChange({ value, title }) {
            this.getLines(value)
            this.lineSubId_equal = undefined
        },
        onQuery() {
            if (this.queryParam.lineSubId_equal) {
                this.isLoading = true
                lineLargeStationAnalysis(this.queryParam).then(res => {
                    this.data = { xdata: [], inNum: [], leaveNum: [] }
                    if (res.length > 0) {
                        res.forEach((item, index) => {
                            if (item.stationName != '合计') {
                                this.data.xdata.push(item.stationName)
                                this.data.inNum.push(item.inNum)
                                this.data.leaveNum.push(item.leaveNum)
                            }
                        })
                    } else {
                        this.data = { xdata: [], inNum: [], leaveNum: [] }
                    }
                    this.lineData = res
                    this.isLoading = false
                    console.log('cout', this.lineData)
                })
            } else {
                this.$message.warning('请选择线路')
            }
        },
        onChange(page, pageSize) {
            this.pagination.current = page
            this.pagination.pageSize = pageSize
            this.handleQuery()
        },
        onPageSizeChnage(current, size) {
            this.pagination.current = current
            this.pagination.pageSize = size
            this.handleQuery()
        },
        dateChange(date, dateString) {
            this.queryParam.uploadYearMonthDay_ge = dateString[0]
            this.queryParam.uploadYearMonthDay_le = dateString[1]
        },
        /** 监控统计导出 */
        exportMonitoringStatisticsInfo() {
            exportMonitoringStatisticsInfo({
                ...this.queryParam,
                statisticsType: this.watchTimeType
            })
        }
    }
}
</script>

<style lang="less" scoped>
.chart-container {
    overflow: auto;
    height: calc(100vh - 254px);
}
.chart-link {
    cursor: pointer;
    text-decoration: underline;
    color: @primary-color;
}
.chart-title {
    font-size: 18px;
    font-weight: 600;
    padding-right: 10px;
}
.chart-download {
    padding-left: 10px;
}
</style>
