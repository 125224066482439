<!--
 * @Autor: dingxiaolin
 * @Date: 2021-09-29 10:04:33
 * @LastEditors: dingxiaolin
 * @LastEditTime: 2021-12-24 13:31:37
-->
<template>
    <a-config-provider :locale="locale">
        <div id="app">
            <router-view />
        </div>
    </a-config-provider>
</template>

<script>
import zhCN from 'ant-design-vue/lib/locale-provider/zh_CN'
import { AppDeviceEnquire } from '@/utils/mixin'
export default {
    mixins: [AppDeviceEnquire],
    data() {
        return {
            locale: zhCN
        }
    },
    mounted() {
        // console.log('ddd', process.env.VUE_APP_MQTT_URL, process.env.VUE_APP_MQTT_USERNAME, process.env.NODE_ENV)
    },
    destroyed() {},
    methods: {}
}
</script>
