/*
 * @Autor: dingxiaolin
 * @Date: 2021-09-23 07:31:51
 * @LastEditors: dingxiaolin
 * @LastEditTime: 2021-09-23 17:13:18
 */
import { cloneDeep } from 'lodash'

export function convertRoutes(nodes) {
    if (!nodes) return null

    nodes = cloneDeep(nodes)

    let queue = Array.isArray(nodes) ? nodes.concat() : [nodes]

    while (queue.length) {
        const levelSize = queue.length

        for (let i = 0; i < levelSize; i++) {
            const node = queue.shift()

            if (!node.children || !node.children.length) continue

            node.children.forEach(child => {
                // 转化相对路径
                if (child.path[0] !== '/' && !child.path.startsWith('http')) {
                    child.path = node.path.replace(/(\w*)[/]*$/, `$1/${child.path}`)
                }
            })
            // console.log('node.children', node.children);
            queue = queue.concat(node.children)
        }
    }

    return nodes
}
