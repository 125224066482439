<!--
 * @Author: gsy 1443717855@qq.com
 * @LastEditors: gsy 1443717855@qq.com
 * @Date: 2024-02-07 11:47:11
 * @LastEditTime: 2024-02-28 14:33:44
 * @Description: 
-->
<template>
    <a-modal title="站点" :visible="visible" :confirm-loading="confirmLoading" destroyOnClose @ok="handleConfirm" @cancel="handleCancel">
        <a-form-model ref="form" :model="form" :rules="formRules" :label-col="labelCol" :wrapper-col="wrapperCol">
            <a-form-model-item label="线路类型" prop="stationType" :rules="formRules.blur">
                <a-select style="width: 100%" placeholder="请选择是否环线" v-model="form.stationType">
                    <a-select-option v-if="!isRoundLine" value="0">上行</a-select-option>
                    <a-select-option v-if="!isRoundLine" value="1">下行</a-select-option>
                    <a-select-option v-if="isRoundLine" value="2">环线</a-select-option>
                </a-select>
            </a-form-model-item>
            <a-form-model-item label="序号" prop="sort" :rules="formRules.blur">
                <a-input-number v-model="form.sort" :min="1" :precision="0" :max="999" style="width:293px"></a-input-number>
            </a-form-model-item>
            <a-form-model-item label="停靠站点" prop="stationId" :rules="formRules.blur">
                <a-input v-model="stationName" placeholder="请选择停靠站点" @click="handleStationsShow"></a-input>
            </a-form-model-item>
        </a-form-model>
        <station-modal ref="stationModal" @ok="handleStationOk" />
    </a-modal>
</template>
<script>
import { formRules } from '@/utils/validator'
import { getStationsList, lineStationsAdd, lineStationsUpdate } from '@/api/alarm/index'
import StationModal from './stationModal.vue'
export default {
    components: {
        StationModal
    },
    data() {
        return {
            formRules: formRules,
            labelCol: {
                xs: { span: 24 },
                sm: { span: 5 }
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 15 }
            },
            visible: false,
            confirmLoading: false,
            form: {
                stationId: '',
                lineId: '',
                stationType: '',
                sort: '',
                id: ''
            },
            stationName: '',
            globalCol: [
                {
                    title: '序号',
                    dataIndex: 'index',
                    customRender: (text, record, index) => `${index + 1}`
                },
                {
                    title: '站点编号',
                    dataIndex: 'stationCode',
                    ellipsis: true
                },
                {
                    title: '站点名称',
                    dataIndex: 'stationName',
                    ellipsis: true
                },
                {
                    title: '操作',
                    dataIndex: 'action',
                    scopedSlots: { customRender: 'action' }
                }
            ],
            stationsList: [],
            /** 是否环线 */
            isRoundLine: false,
            detail: {},
            type: ''
        }
    },
    methods: {
        handleStationOk(row) {
            this.form.stationId = row.id
            this.stationName = row.stationName
        },
        /** 展示站点组件 */
        handleStationsShow() {
            this.$refs.stationModal.show()
        },
        showStationName(item) {
            if (!item) return
            return this.stationsList.find(f => f.id == item).stationName
        },
        show(record, type) {
            console.log(record, 111111111111111111111)
            this.form = this.$options.data.call(this).form
            // 是否为环线
            this.isRoundLine = record.isLoopLine === 1
            this.form.lineId = record.lineId
            this.form.id = record.linkId
            if (type == 'edit') {
                this.form.stationId = record.id
                this.form.stationType = record.stationType
                this.form.sort = record.sort
            }
            this.type = type
            this.getStationsList()
            this.visible = true
            this.detail = record
        },
        getStationsList() {
            getStationsList({ tenantId: '', stationName: '', size: -1 }).then(res => {
                this.stationsList = res.records
                console.log(
                    this.form,
                    res.records.find(item => item.id == this.form.stationId)
                )
                this.stationName = res.records.find(item => item.id == this.form.stationId).stationName
            })
        },

        handleConfirm() {
            this.$refs.form.validate(valid => {
                if (valid) {
                    this.confirmLoading = true
                    let requestApi = this.type === 'add' ? lineStationsAdd : lineStationsUpdate
                    requestApi(this.form)
                        .then(res => {
                            this.$message.success('操作成功')
                            this.confirmLoading = false
                            this.visible = false
                            this.stationName = ''
                            this.$emit('ok')
                        })
                        .catch(err => {
                            this.confirmLoading = false
                        })
                }
            })
        },
        handleCancel() {
            this.visible = false
            this.stationName = ''
        }
    }
}
</script>
<style lang="less" scoped>
.box {
    .title {
        font-size: 14px;
        font-weight: bold;
    }
    .station-item {
        font-size: 12px;
        padding: 5px 0px;
        margin: 0;
        line-height: 12px;
        .label {
            font-weight: 600;
        }
    }
}
</style>
