<template>
    <a-modal :title="title" centered :width="1500" :visible="visible" :confirmLoading="confirmLoading" @ok="handleOk" @cancel="handleCancel">
        <a-form-model ref="form" :model="form" :rules="formRules">
            <a-row :gutter="16">
                <a-col :span="12">
                    <a-row>
                        <a-col :span="24">
                            <a-form-model-item label="任务编号" prop="taskName" :rules="formRules.blur">
                                <a-input v-model="form.taskName" allow-clear />
                            </a-form-model-item>
                        </a-col>
                        <a-col :span="24">
                            <a-form-model-item label="视频输入" prop="mediaName">
                                <a-select v-model="form.mediaName">
                                    <a-select-option v-for="(item, index) in mediaList" :key="index" :value="item.mediaName">
                                        {{ item.mediaName }}-{{ item.mediaUrl }}
                                    </a-select-option>
                                </a-select>
                            </a-form-model-item>
                        </a-col>
                    </a-row>
                </a-col>
                <a-col :span="12">
                    <a-form-model-item label="任务描述" prop="taskDesc">
                        <a-textarea :rows="6" v-model="form.taskDesc" />
                    </a-form-model-item>
                </a-col>
            </a-row>
            <a-row :gutter="16">
                <a-col :md="12" :sm="24">
                    <a-form-model-item label="上报地址" :rules="formRules.blur" prop="alarmUploadUrl">
                        <a-input v-model="form.alarmUploadUrl" allow-clear />
                    </a-form-model-item>
                </a-col>
            </a-row>
            <a-row :gutter="[16, 16]">
                <a-col :md="24" :sm="24">
                    <a-form-model-item label="算法设置" :rules="formRules.blur" prop="modelId">
                        <a-checkbox-group v-model="checkValues" @change="checkChange" style="width: 100%">
                            <a-row>
                                <a-col :span="3" v-for="(item, index) in deviceTagsList" :key="index">
                                    <a-checkbox :value="item['item']">{{ item.name }}</a-checkbox>
                                </a-col>
                            </a-row>
                        </a-checkbox-group>
                    </a-form-model-item>
                </a-col>
            </a-row>
            <a-row :gutter="[16, 16]" v-if="workDone">
                <a-col :span="14">
                    <div>离岗配置</div>
                    <a-row>
                        <a-col :span="12">
                            <a-form-model-item label="超时时间" :labelCol="{ span: 6 }" :wrapperCol="{ span: 18 }">
                                <a-input-number v-model="form.modelInfo.staff_sec" allow-clear :min="0" />
                            </a-form-model-item>
                        </a-col>
                        <a-col :span="12">
                            <a-form-model-item label="要求在岗人数" :labelCol="{ span: 8 }" :wrapperCol="{ span: 16 }">
                                <a-input-number
                                    v-model="form.modelInfo.staff_number"
                                    allow-clear
                                    :min="0"
                                    :precision="0"
                                    :formatter="limitNumber"
                                    :parser="limitNumber"
                                />
                            </a-form-model-item>
                        </a-col>
                    </a-row>
                </a-col>
            </a-row>
            <a-row :gutter="[16, 16]" :span="12" v-if="faceDone">
                <a-col :span="24">
                    <div>脸部抓拍配置</div>
                    <a-row>
                        <a-col :span="6">
                            <a-form-model-item label="抓拍阈值" :labelCol="{ span: 8 }" :wrapperCol="{ span: 16 }">
                                <a-input-number v-model="form.modelInfo.front_face_threshold" allow-clear />
                            </a-form-model-item>
                        </a-col>
                        <a-col :span="6">
                            <a-form-model-item label="最小人脸像素" :labelCol="{ span: 8 }" :wrapperCol="{ span: 16 }">
                                <a-input-number v-model="form.modelInfo.front_face_min_pixel" allow-clear :min="0" :precision="0" />
                            </a-form-model-item>
                        </a-col>
                        <a-col :span="6">
                            <a-form-model-item label="更新间隔(秒)" :labelCol="{ span: 8 }" :wrapperCol="{ span: 16 }">
                                <a-input-number v-model="form.modelInfo.face_repeat_upload_ms" allow-clear :min="0" :precision="0" />
                            </a-form-model-item>
                        </a-col>
                        <a-col :span="6">
                            <a-form-model-item :labelCol="{ span: 2 }" :wrapperCol="{ span: 22 }" style="display:flex">
                                <a-checkbox @change="onChange1" v-model="form.modelInfo.expand_face_area">
                                    扩大脸部区域
                                </a-checkbox>
                                <a-checkbox @change="onChange2" v-model="form.modelInfo.upload_cropped_face_image">
                                    上传裁剪的人脸图
                                </a-checkbox>
                            </a-form-model-item>
                        </a-col>
                    </a-row>
                </a-col>
            </a-row>
            <a-row :gutter="[16, 16]" v-if="flowDone">
                <a-col :span="14">
                    <div>客流计数配置</div>
                    <a-row>
                        <a-col :span="12">
                            <a-form-model-item label="跟踪阈值" :labelCol="{ span: 6 }" :wrapperCol="{ span: 18 }">
                                <a-input-number v-model="form.modelInfo.head_track_distance_pixel" allow-clear :min="0" />
                            </a-form-model-item>
                        </a-col>
                        <a-col :span="12">
                            <a-form-model-item :labelCol="{ span: 2 }" :wrapperCol="{ span: 22 }" style="display:flex">
                                <a-checkbox @change="onChange3" v-model="form.modelInfo.upload_image_on_count_people">
                                    上报时是否上传图片
                                </a-checkbox>
                            </a-form-model-item>
                        </a-col>
                    </a-row>
                </a-col>
            </a-row>
        </a-form-model>
    </a-modal>
</template>
<script>
import { aiDeviceMediaList, aiDeviceAlgorithmSave, aiDeviceAlgorithmDetail, aiDeviceAlgorithmUpdate } from '@/api/alarm'
import { formRules } from '@/utils/validator'
import { mapState } from 'vuex'
export default {
    computed: {
        ...mapState({
            deviceTagsList: state => state.app.deviceTagsList
        })
    },
    data() {
        return {
            isAdd: true,
            title: '',
            formRules: formRules,
            form: {
                id: '',
                deviceId: '',
                taskName: '',
                taskDesc: '',
                mediaName: '',
                alarmUploadUrl: '',
                /** 父id */
                modelId: [],

                modelInfo: {
                    /** 子id集合 */
                    MethodConfig: [],
                    /**离岗检测配置 在岗人数 */
                    staff_number: '',
                    /**离岗检测配置 超时时间 */
                    staff_sec: '',
                    /**脸部抓拍配置 最小人脸像素 */
                    front_face_min_pixel: '',
                    /**脸部抓拍配置 抓拍阈值  */
                    front_face_threshold: '',
                    /**脸部抓拍配置 更新间隔(秒) */
                    face_repeat_upload_ms: '',
                    /**客流计数配置 跟踪阈值 */
                    head_track_distance_pixel: '',
                    /**扩大脸部区域 脸部抓拍 */
                    expand_face_area: false,
                    /**上传裁剪的人脸图 脸部抓拍 */
                    upload_cropped_face_image: false,
                    /**上报是否上传图片 客流计数 */
                    upload_image_on_count_people: false
                },
                /** 算法名称 */
                disposeInfo: ''
            },
            /** 多选checkbox选中值 */
            checkValues: [],
            visible: false,
            confirmLoading: false,
            /** 媒体列表 */
            mediaList: [],
            /** 离岗检测配置 */
            workDone: false,
            /** 脸部抓拍配置 */
            faceDone: false,
            /** 客流计数配置 */
            flowDone: false,
            timer: null,
            key: 'task-edit',
            tableCell: null
        }
    },
    mounted() {},
    methods: {
        limitNumber(value) {
            if (typeof value === 'string') {
                return !isNaN(Number(value)) ? value.replace(/\./g, '') : 0
            } else if (typeof value === 'number') {
                return !isNaN(value) ? String(value).replace(/\./g, '') : 0
            } else {
                return 0
            }
        },
        /** 获取媒体列表 */
        getMediaList() {
            aiDeviceMediaList({
                current: -1,
                size: -1,
                deviceId_equal: this.$route.query.id
            }).then(res => {
                this.mediaList = res.records
            })
        },
        checkChange(v) {
            v.includes(7) ? (this.workDone = true) : (this.workDone = false)
            v.includes(248) ? (this.faceDone = true) : (this.faceDone = false)
            v.includes(15) ? (this.flowDone = true) : (this.flowDone = false)
            let form = { ...this.form }
            form.modelId = []
            form.modelInfo.MethodConfig = []
            let disposeInfoNames = []

            v.forEach((item, index) => {
                // 父级id插入
                form.modelId.push(this.deviceTagsList.find(f => f.item == item).code)
                disposeInfoNames.push(this.deviceTagsList.find(f => f.item == item).name)
            })
            let modelId = new Set(form.modelId)
            this.form.modelInfo.MethodConfig = this.form = {
                ...this.form,
                modelId: [...modelId],
                modelInfo: {
                    ...this.form.modelInfo,
                    MethodConfig: v.filter(f => !modelId.has(f))
                }
            }
            this.$message.warning({
                key: this.key,
                content: '由于增加算法需要消耗硬件资源，请酌情设置任务路数及算法数量'
            })
        },

        async show(record) {
            this.getMediaList()
            if (record) {
                this.visible = true
                this.isAdd = false
                this.title = '修改算法任务'
                let res = await this.getDetail(record.id)
                this.form = res
                this.form.modelId = JSON.parse(res.modelId)
                this.form.modelInfo = JSON.parse(res.modelInfo)
                // 过滤父级id是1的
                let fids = [...new Set(this.form.modelId)]
                // 子级id
                let cids = this.form.modelInfo.MethodConfig || []
                this.checkValues = cids.concat(fids).map(item => item)
                // 显示离岗配置
                this.checkValues.includes(7) ? (this.workDone = true) : (this.workDone = false)
                this.checkValues.includes(248) ? (this.faceDone = true) : (this.faceDone = false)
                this.checkValues.includes(15) ? (this.flowDone = true) : (this.flowDone = false)
                this.tableCell = record
                // 修改
            } else {
                // 新增
                this.visible = true
                this.isAdd = true
                this.form = this.$options.data().form
                this.form.deviceId = this.$route.query.id
                this.checkValues = []
                this.workDone = false
                this.faceDone = false
                this.flowDone = false
                this.title = '新增算法任务'
            }
        },
        /** 获取详情 */
        getDetail(id) {
            return aiDeviceAlgorithmDetail({ id })
        },
        handleOk() {
            this.$refs.form.validate(res => {
                /* res为true表示验证通过 */
                if (!res) {
                    return
                }
                this.$parent.getRealTaskStatus(() => {
                    this.confirmLoading = true
                    let reqMethod = this.isAdd ? aiDeviceAlgorithmSave : aiDeviceAlgorithmUpdate
                    if (!this.isAdd) {
                        this.tableCell.editText = '修改中...'
                        this.tableCell.editStatus = true
                    }
                    reqMethod({
                        ...this.form,
                        modelId: JSON.stringify(this.form.modelId),
                        modelInfo: JSON.stringify(this.form.modelInfo)
                    })
                    this.$parent.setError()
                })
            })
        },
        handleCancel() {
            this.visible = false
            this.confirmLoading = false
        },
        onChange1(v) {
            console.log('first', v)
        },
        onChange2() {},
        onChange3() {}
    }
}
</script>
