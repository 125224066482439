/*
 * @Autor: dingxiaolin
 * @Date: 2021-09-27 10:08:25
 * @LastEditors: dingxiaolin
 * @LastEditTime: 2022-10-22 09:11:01
 */
/**
 * 公共接口
 */
import {
    Post,
    Get,
    Patch,
    Put,
    Delete,
    Download,
    ax
} from '@/utils/newRequest'
import config from '@/config/config.js'

/** 获取行政区划 */
export function getRegion(body) {
    return Get(config.namespace + '/region/select', body)
}
/**
 * 获取字典类型下所有字典，举例，返回格式为：[{code:"M",value:"男"},{code:"F",value:"女"}]
 */
export function sysDictTypeDropDown(body) {
    return Get(config.namespace + '/dict/dropDown', body)

}
/**
 * 地址解析
 */
export function getAddrByGPS(body) {
    return Post(config.namespace + '/shipTravelReal/getAddrByGPS', body)

}