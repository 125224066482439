<template>
    <div class="header-search">
        <auto-complete
            ref="input"
            :getPopupContainer="
                (e) => {
                    return e.parentNode || document.body
                }
            "
            :dataSource="dataSource"
            :class="['search-input', searchMode ? 'enter' : 'leave']"
            placeholder="站内搜索"
            @blur="leaveSearchMode"
        >
            <!-- <a-icon slot="suffix" type="search" class="certain-category-icon" /> -->
            <a-input v-show="searchMode">
                <a-icon class="certain-category-icon" />
            </a-input>
        </auto-complete>
        <a-icon
            type="search"
            v-show="!searchMode"
            :class="theme !== 'dark' ? 'search-icon msy-black' : 'search-icon msy-white'"
            slot="suffix"
            @click="enterSearchMode"
        />
    </div>
</template>

<script>
import { AutoComplete } from 'ant-design-vue/es'
export default {
    name: 'HeaderSearch',
    components: {
        AutoComplete,
    },
    props: {
        theme: {
            type: String,
            required: false,
            default: 'dark',
        },
    },
    data() {
        return {
            dataSource: ['选项一', '选项二'],
            searchMode: false,
        }
    },
    methods: {
        enterSearchMode() {
            this.searchMode = true
            this.$emit('active', true)
            setTimeout(() => this.$refs.input.focus(), 300)
        },
        leaveSearchMode() {
            this.searchMode = false
            setTimeout(() => this.$emit('active', false), 300)
        },
    },
}
</script>

<style lang="less" >
.msy-white {
    color: #ffffff !important;
}
.msy-black {
    color: #001529 !important;
}
.ant-select-selection {
    // border: 0px!important;
}
.header-search {
    .ant-select-selection__rendered {
        margin-left: 0px;
        margin-right: 0px;
    }
    .certain-category-icon {
        color: #303030;
    }
    .search-icon {
        font-size: 16px;
        color: #ffffff;
        cursor: pointer;
    }
    .search-input {
        border: 0;
        border-bottom: 1px solid #303030;
        transition: width 0.3s ease-in-out;
        input {
            border: 0;
            box-shadow: 0 0 0 0;
        }
        &.leave {
            width: 0px;
            .ant-select-selection {
                border: 0px !important;
            }
            input {
                display: none;
            }
        }
        &.enter {
            width: 180px;
            input:focus {
                box-shadow: 0 0 0 0;
            }
        }
    }
}
</style>