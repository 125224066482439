<template>
    <div class="content" v-scrollBar>
        <keep-alive>
            <router-view ref="content" v-if="$route.meta.cache"></router-view>
        </keep-alive>
        <router-view ref="content" v-if="!$route.meta.cache"></router-view>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import { mixin } from '@/utils/mixin'

export default {
    name: 'PageView',
    mixins: [mixin],
    components: {},
    props: {},
    data() {
        return {
            pageTitle: null,
            description: null,
            linkList: [],
            search: false,
            tabs: {},
            collapsed: false,
            router: ''
        }
    },
    computed: {
        ...mapState({
            multiTab: state => state.app.multiTab,
            sidebar: state => state.app.sidebar,
            layout: state => state.app.layout,
            device: state => state.app.device
        })
    },
    watch: {
        // sidebar变化
        sidebar(val) {
            // console.log('sidebar', val);
            this.collapsed = val
        },
        device(val) {
            // console.log('device', val);
        }
    },
    mounted() {
        this.tabs = this.directTabs
        this.getPageMeta()
    },
    updated() {
        this.getPageMeta()
    },
    methods: {
        getPageMeta() {
            // eslint-disable-next-line
            //文字样式//(typeof(this.title) === 'string' || !this.title) ? this.title : this.$route.meta.title
            // 为了简洁改为无
            this.pageTitle = ''
            const content = this.$refs.content
            if (content) {
                if (content.pageMeta) {
                    Object.assign(this, content.pageMeta)
                } else {
                    this.description = content.description
                    this.linkList = content.linkList
                    this.search = content.search === true
                    this.tabs = content.tabs
                    // this.router =
                }
            }
        }
    }
}
</script>

<style lang="less" scoped>
.content {
    position: relative;
    padding: 6px;
    flex: auto;
    // & > .ant-card {
    //     height: 100%;
    //     .ant-card-body {
    //         height: 100%;
    //     }
    // }
}
.page-menu-search {
    text-align: center;
    margin-bottom: 16px;
}
.page-menu-tabs {
    margin-top: 48px;
}

.extra-img {
    margin-top: -60px;
    text-align: center;
    width: 195px;

    img {
        width: 100%;
    }
}
</style>
