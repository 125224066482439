<!--
 * @Autor: dingxiaolin
 * @Date: 2022-09-19 17:59:48
 * @LastEditors: dingxiaolin
 * @LastEditTime: 2022-09-20 10:04:29
-->
<template>
    <div class="box">
        <!-- <video loop muted autoplay :src="info.alarmVideo" style="width:150px;height:80px"></video> -->
        <div class="videobox">
            <div style="position:relative">
                <!-- {{info.relativeBox}} -->
                <img :src="info.alarmPic" style="width:120px;height:90px;" />
                <div
                    v-if="info.relativeType === '0' && info.relativeBox"
                    ref="facebox"
                    :style="{
                        position: 'absolute',
                        top: `${Math.abs(info.relativeBox[1]) * 100}%`,
                        left: `${Math.abs(info.relativeBox[0]) * 100}%`,
                        width: `${info.relativeBox[2] * 100}%`,
                        height: `${info.relativeBox[3] * 100}%`,
                        border: '1px solid #e4393c',
                        zIndex: 1499
                    }"
                ></div>
            </div>
            <!-- <video-com
            class="video_img"
            ref="videoCom"
            :vWidth="160"
            :vHeight="80"
            :controller="false"
            :url="info.alarmVideo"
            v-if="info.alarmVideo"
        >
        </video-com> -->
        </div>
        <div class="content">
            <div class="font1">{{ info.alarmTypeName }}</div>
            <div class="font2">{{ info.areaName }}</div>
            <div class="font2">{{ info.alarmTime }}</div>
            <div>
                <span :class="'level' + info.alarmLevel"> {{ info.alarmLevelName }} </span>
                <a class="rt" :class="get_status(info.confirmStatus, info.isDeal).color">{{ get_status(info.confirmStatus, info.isDeal).text }}</a>
            </div>
        </div>
    </div>
</template>

<script>
import { get_status } from '@/utils/returnStatus'

import VideoCom from '@/components/VideoCom'
export default {
    name: 'alertInfo',
    components: { VideoCom },
    props: ['info', 'that'],
    computed: {},
    data() {
        return {
            videoUrl: ''
        }
    },
    mounted() {},
    methods: {
        get_status: get_status
    }
}
</script>

<style lang="less" scoped>
.box {
    display: flex;
    justify-content: space-between;
    .videobox {
        position: relative;
        margin-left: 10px;
    }
    .content {
        margin-left: 20px;
        .font1 {
            font-size: 14px;
            color: #00acef;
            letter-spacing: 0;
            margin-bottom: 10px;
        }
        .font2 {
            font-size: 12px;
            color: #fff;
            margin-bottom: 3px;
        }
        .level1 {
            color: rgba(255, 21, 65, 1);
            border: 1px solid rgba(255, 21, 65, 1);
            font-size: 10px;
        }
        .level2 {
            color: rgba(220, 124, 56, 1);
            border: 1px solid rgba(220, 124, 56, 1);
            font-size: 10px;
        }
        .level3 {
            color: rgba(60, 184, 120, 1);
            border: 1px solid rgba(60, 184, 120, 1);
            font-size: 10px;
        }
        .level4 {
            color: rgb(60, 114, 184);
            border: 1px solid rgba(60, 114, 184, 1);
            font-size: 10px;
        }
        .rt {
            font-size: 10px;
        }
    }
}
</style>
