/*
 * @Autor: 程少波
 * @Date: 2021-11-25 10:08:25
 * @LastEditors: dingxiaolin
 * @LastEditTime: 2022-10-22 09:09:51
 */
/**
 * 船舶预警
 */
import { Post, Get, Patch, Put, Delete, Download, ax } from '@/utils/newRequest'
import config from '@/config/config.js'
/**
 * 获取摄像头分页列表
 */
export function alarmDeviceList(body) {

    return Post(config.namespace + '/alarmDevice/list', body)
}
/**
 * 获取摄像头未绑定AI终端列表
 */
export function alarmDeviceUnLinkList(body) {
    return Post(config.namespace + '/alarmDevice/unLinkList', body)
}
/**
 * 摄像头新增
 */
export function alarmDeviceAdd(body) {
    return Post(config.namespace + '/alarmDevice/save', body)
}
/**
 * 摄像头修改
 */
export function alarmDeviceEdit(body) {
    return Post(config.namespace + '/alarmDevice/update', body)
}
/**
 * 删除摄像头
 */
export function removeCamera(body) {
    return Post(config.namespace + '/alarmDevice/remove', body)
}
