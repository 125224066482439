/*
 * @Autor: dingxiaolin
 * @Date: 2021-09-23 07:31:51
 * @LastEditors: dingxiaolin
 * @LastEditTime: 2021-12-09 11:59:52
 */
/**
 * 向后端请求用户的菜单，动态生成路由
 */
import { constantRouterMap } from '@/router/router.config'
import { generatorDynamicRouter } from '@/router/generator-routers'

const route = {
    state: {
        routers: constantRouterMap,
        addRouters: [],
        firstRoute: ''
    },
    mutations: {
        SET_ROUTERS: (state, routers) => {
            state.addRouters = routers
            state.firstRoute = routers[0]['children'][0]['children'] ? routers[0]['children'][0]['children'][0].path : routers[0]['children'][0].path;
            state.routers = constantRouterMap.concat(routers)
        }
    },
    actions: {
        GenerateRoutes({ commit }, data) {
            return new Promise(resolve => {
                generatorDynamicRouter(data).then(routers => {
                    commit('SET_ROUTERS', routers)
                    resolve()
                })
            }).catch(err => {
                // eslint-disable-next-line no-undef
                reject(err)
            })
        }
    }
}

export default route
