<template>
    <div class="user-wrapper">
        <div class="content-box">
            <!-- <a href="http://www.jinhx.cn" class="jump" target="_blank">官网</a> -->
            <!-- <a-dropdown
                :trigger="['click']"
                class="qrcode"
                :getPopupContainer="
                    triggerNode => {
                        return triggerNode.parentNode || document.body
                    }
                "
            >
                <span class="download"> APP </span>
                <a-menu slot="overlay">
                    <a-menu-item>
                        <div class="all_center column">
                            <div style="background: #ddd; width: 100%; height: 35px" class="all_center">告警急速处理</div>
                            <img src="@/assets/app.png" alt="" style="width: 150px; height: 150px; margin: 20px 30px" />
                            <div>下载客流管理平台APP</div>
                        </div>
                    </a-menu-item>
                </a-menu>
            </a-dropdown> -->
            <!-- <span class="action">
                <global-search :theme="theme" @active="(val) => (searchActive = val)"></global-search>
            </span> -->
            <!-- <notice-icon :class="theme === 'dark' ? 'msyLight' : 'msyDark'" :theme="theme" class="action" /> -->
            <a-dropdown>
                <span
                    :theme="theme"
                    :title="nickname"
                    :class="
                        theme === 'dark'
                            ? 'msy-avatar msyLight action ant-dropdown-link user-dropdown-menu'
                            : 'msyDark action ant-dropdown-link user-dropdown-menu msy-avatar'
                    "
                >
                    <a-avatar class="avatar" size="small" :src="avatar ? avatar : require('@/assets/avatar/1.png')" />
                    <span class="">{{ nickname.length > 7 ? nickname.substring(0, 6) + '...' : nickname }}</span>
                </span>
                <a-menu :theme="theme" slot="overlay" class="user-dropdown-menu-wrapper">
                    <!-- <a-menu-item key="4" v-if="mode === 'sidemenu'">
                        <a @click="appToggled()">
                            <a-icon type="swap" />
                            <span>切换应用</span>
                        </a>
                    </a-menu-item>  -->
                    <a-menu-item key="1">
                        <router-link :to="{ path: '/account' }">
                            <a-icon type="setting" />
                            <span>账户设置</span>
                        </router-link>
                    </a-menu-item>
                    <a-menu-divider />
                    <a-menu-item key="3">
                        <a href="javascript:;" @click="handleLogout">
                            <a-icon type="logout" />
                            <span>退出登录</span>
                        </a>
                    </a-menu-item>
                </a-menu>
            </a-dropdown>
        </div>
        <!-- <a-modal title="切换应用" :visible="visible" :footer="null" :confirm-loading="confirmLoading" @cancel="handleCancel">
            <a-form :form="form1">
                <a-form-item :labelCol="labelCol" :wrapperCol="wrapperCol" label="选择应用">
                    <a-menu mode="inline" :selectedKeys="defApp" :default-selected-keys="defApp" style="border-bottom: 0px; lineheight: 62px">
                        <a-menu-item v-for="item in userInfo.apps" :key="item.code" style="top: 0px" @click="switchApp(item.code)">
                            {{ item.name }}
                        </a-menu-item>
                    </a-menu>
                </a-form-item>
            </a-form>
        </a-modal> -->
    </div>
</template>

<script>
import NoticeIcon from '@/components/NoticeIcon'
import screenfull from 'screenfull'
import GlobalSearch from '@/components/HeaderSearch'
import { mapActions, mapState } from 'vuex'
import { ALL_APPS_MENU } from '@/store/mutation-types'
import Vue from 'vue'
import { message } from 'ant-design-vue/es'

export default {
    name: 'UserMenu',
    components: {
        NoticeIcon,
        GlobalSearch
    },
    props: {
        mode: {
            type: String,
            // sidemenu, topmenu
            default: 'sidemenu'
        },
        theme: {
            type: String,
            required: false,
            default: 'dark'
        }
    },
    data() {
        return {
            searchActive: false,
            labelCol: {
                xs: { span: 24 },
                sm: { span: 5 }
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 16 }
            },
            visible: false,
            confirmLoading: false,
            form1: this.$form.createForm(this),
            defApp: [],
            isFullScreen: false
        }
    },
    watch: {
        appsmenu(val) {
            if (val.length < 1) {
                return false
            }
            let code = val[0].code
            this.$set(this.defApp, 0, code)
            console.log('UserMenu,defApp', this.defApp, val)
        }
    },
    computed: {
        ...mapState({
            nickname: state => state.user.name,
            avatar: state => state.user.avatar,
            userInfo: state => state.user.info,
            appsmenu: state => state.user.appsmenu
        })
    },
    methods: {
        ...mapActions(['Logout', 'MenuChange']),
        // togglefull() {
        //     this.isFullScreen = !this.isFullScreen
        //     if (!screenfull.isEnabled) {
        //         this.$message({
        //             message: '浏览器不支持全屏，请切换浏览器！',
        //             type: 'warning'
        //         })
        //         return false
        //     }
        //     screenfull.toggle()
        // },
        handleLogout() {
            this.$confirm({
                title: '提示',
                content: '真的要注销登录吗 ?',
                onOk: () => {
                    return this.Logout({})
                        .then(() => {
                            setTimeout(() => {
                                // window.location.reload()
                            }, 16)
                        })
                        .catch(err => {
                            this.$message.error({
                                title: '错误',
                                description: err.msg
                            })
                        })
                },
                onCancel() {}
            })
        },

        /**
         * 打开切换应用框
         */
        appToggled() {
            this.visible = true
            console.log('dd', this.$store.state.user.appsmenu)
            let code = this.appsmenu[0].code
            // this.defApp=[];
            // this.defApp.push(code);
            this.$set(this.defApp, 0, code)
            console.log('appToggled.defApp', this.defApp, this.appsmenu)
        },

        switchApp(appCode) {
            this.visible = false
            // this.defApp = []
            const applicationData = this.userInfo.apps.filter(item => item.code === appCode)
            const hideMessage = message.loading('正在切换应用！', 0)
            this.MenuChange(applicationData[0])
                .then(res => {
                    hideMessage()
                    // eslint-disable-next-line handle-callback-err
                })
                .catch(err => {
                    message.error('应用切换异常')
                })
        },
        handleCancel() {
            this.form1.resetFields()
            this.visible = false
        }
    }
}
</script>

<style lang="less" scoped>
@import '../../utils/utils.less';
.msy-white {
    color: #ffffff;
}
.msy-black {
    color: #001529;
}
.msy-avatar {
    overflow-x: hidden !important;
    max-width: 130px;
    white-space: nowrap;
    text-overflow: ellipsis;
    word-break: break-all;
}
.appRedio {
    border: 1px solid #91d5ff;
    padding: 10px 20px;
    background: #e6f7ff;
    border-radius: 2px;
    margin-bottom: 10px;
    color: #91d5ff;
    /*display: inline;
    margin-bottom:10px;*/
}
.msyDark {
    color: #333333 !important;
}
.msyLight {
    color: #ffffff !important;
}
.download {
    cursor: pointer;
    color: #fff;
}
/deep/ .content-box {
    height: 100%;
    display: flex;
}
/deep/ .content-box .ant-dropdown-menu-item,
.content-box .ant-dropdown-menu {
    padding: 0;
}
a.jump {
    margin-right: 20px;
    color: #fff;
}
</style>
