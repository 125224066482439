/*
 * @Autor: dingxiaolin
 * @Date: 2021-09-23 07:31:50
 * @LastEditors: dingxiaolin
 * @LastEditTime: 2022-08-25 08:26:14
 */
// with polyfills
import 'regenerator-runtime/runtime'
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueKonva from 'vue-konva';
// import 'amfe-flexible';
import './core/lazy_use'//antd懒加载组件、全局组件、自定义指令
import './router/permission' // 路由权限控制
import './utils/filter' // 全局过滤器
import './styles/global.less' //全局样式
import VueCookies from 'vue-cookies';
Vue.use(VueCookies)
Vue.config.productionTip = false
Vue.use(VueKonva);
new Vue({
    router,
    store,
    VueKonva,
    render: h => h(App)
}).$mount('#app')
