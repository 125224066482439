<template>
    <a-card :bordered="false">
        <div class="table-page-search-wrapper">
            <a-form-model :label-col="labelCol" :wrapper-col="wrapperCol">
                <a-row :gutter="48">
                    <a-col :md="5" :sm="24">
                        <a-form-model-item label="所属机构">
                            <dept-tree v-model="queryParam.tenantId_equal" />
                        </a-form-model-item>
                    </a-col>
                    <a-col :md="5" :sm="24">
                        <a-form-model-item label="车牌号">
                            <a-input v-model="queryParam.areaName_like" allow-clear placeholder="请输入车牌号" />
                        </a-form-model-item>
                    </a-col>
                    <a-col :md="5" :sm="24">
                        <a-form-model-item label="图片状态">
                            <a-select style="width: 100%" placeholder="请选择图片上传状态" v-model="queryParam.uploadStatus_equal">
                                <a-select-option v-for="(item, index) in isDealList" :key="index" :value="item.code">{{
                                    item.value
                                }}</a-select-option>
                            </a-select>
                        </a-form-model-item>
                    </a-col>
                    <a-col :md="5" :sm="24">
                        <a-form-model-item label="抓拍时间">
                            <a-range-picker
                                :show-time="{ defaultValue: [$moment('00:00:00', 'HH:mm:ss'), $moment('23:59:59', 'HH:mm:ss')] }"
                                v-model="queryDate"
                                format="YYYY-MM-DD HH:mm:ss"
                                @change="dateChange"
                            />
                        </a-form-model-item>
                    </a-col>
                    <a-col :md="4" :sm="24">
                        <span class="table-page-search-submitButtons">
                            <a-button type="primary" @click="$refs.table.refresh(true)">查询</a-button>
                            <a-button style="margin-left: 8px" @click="resetForm">重置</a-button>
                            <a-button style="margin-left: 8px" @click="handelExportFaceInfo()">导出</a-button>
                        </span>
                    </a-col>
                </a-row>
            </a-form-model>
        </div>

        <n-table
            ref="table"
            :columns="columns"
            size="middle"
            :data="loadData"
            :rowKey="record => record.id"
            :scrollToFirstRowOnChange="true"
            :showPagination="false"
            :scrollX="1600"
        >
            <div slot="pic" slot-scope="text, record, index" class="facePic">
                <a-popover placement="leftTop" overlayClassName="alarmPicBox" v-if="record.pic">
                    <template slot="content">
                        <div class="centerDiv">
                            <img :src="record.waterMark ? record.pic + record.waterMark : record.pic" class="alarmPicBig" alt="" />
                            <div
                                v-if="record.relativeBox && record.relativeBox.length > 0"
                                :style="{
                                    position: 'absolute',
                                    top: `${Math.abs(record.relativeBox[1]) * 100}%`,
                                    left: `${Math.abs(record.relativeBox[0]) * 100}%`,
                                    width: `${record.relativeBox[2] * 100}%`,
                                    height: `${record.relativeBox[3] * 100}%`,
                                    border: '3px solid #e4393c'
                                }"
                            ></div>
                        </div>
                    </template>
                    <img :src="record.pic" class="alarmPic" />
                </a-popover>
            </div>
            <div slot="picFace" slot-scope="text, record, index" class="facePic">
                <a-popover placement="leftTop">
                    <template slot="content">
                        <div class="centerDiv">
                            <img :src="text" />
                        </div>
                    </template>
                    <img :src="text" />
                </a-popover>
            </div>
            <span slot="doorType" slot-scope="text">
                <span v-if="text">{{ getDoorName(text) }}</span>
            </span>
            <span slot="uploadStatus" slot-scope="text">
                <span v-if="text === '0'" style="color:green">成功</span>
                <span v-if="text === '1'" style="color:red">失败</span>
                <span v-if="text === '2'" style="color:grey">无需上传</span>
            </span>
            <div slot="autoNaviLng" slot-scope="text, record, index" class="showAddress">
                <div v-if="!record.showAddress">
                    <span v-if="record.autoNaviLng && record.autoNaviLat" @click="getAddressByGPS(record, index)">显示位置</span>
                    <div>{{ record.autoNaviLng }}<label v-if="record.autoNaviLng && record.autoNaviLat">，</label>{{ record.autoNaviLat }}</div>
                </div>
                <div v-if="record.showAddress">
                    <span @click="showNavi(record, index)">显示经纬度</span>
                    <div class="address" :title="record.address">{{ record.address }}</div>
                </div>
            </div>
        </n-table>
    </a-card>
</template>

<script>
import { NTable, DeptTree } from '@/components'
import { faceInfoList, exportFaceInfo } from '@/api/alarm/index'
import { getAddrByGPS } from '@/api/comm'
import { fileToUrl } from '@/utils/util.js'
import { getDoorName } from '@/utils/returnStatus.js'
import Vue from 'vue'
export default {
    components: {
        NTable,
        DeptTree
    },

    data() {
        return {
            queryDate: [this.$moment().format('YYYY-MM-DD') + ' 00:00:00', this.$moment().format('YYYY-MM-DD') + ' 23:59:59'],
            getDoorName: getDoorName,
            fileToUrl: fileToUrl,
            showAddress: false,
            layout: {
                labelCol: { span: 5 },
                wrapperCol: { span: 17 }
            },
            labelCol: {
                sm: { span: 6 },
                xs: { span: 24 }
            },
            wrapperCol: {
                sm: { span: 18 },
                xs: { span: 24 }
            },
            // 查询参数
            queryParam: {
                descs: 'upload_time', //排序字段
                areaName_like: '', //车牌号
                uploadStatus_equal: '', //图片状态 0-成功 1-失败
                tenantId_equal: '',
                uploadTime_ge: this.$moment().format('YYYY-MM-DD') + ' 00:00:00',
                uploadTime_le: this.$moment().format('YYYY-MM-DD') + ' 23:59:59'
            },
            // 弹窗处理方式
            dealType: 0, // 0 忽略 1 处理完成
            // 表头
            columns: [
                {
                    title: '车牌号',
                    dataIndex: 'areaName',
                    ellipsis: true,
                    width: '150px'
                },
                {
                    title: '所属机构',
                    dataIndex: 'tenantName',
                    ellipsis: true,
                    width: '300px'
                },
                {
                    title: '抓拍地点',
                    dataIndex: 'autoNaviLng',
                    scopedSlots: { customRender: 'autoNaviLng' },
                    ellipsis: true,
                    width: '300px'
                },
                // {
                //     title: '抓拍位置',
                //     dataIndex: 'alarmTime',
                //     ellipsis: true,
                //     width: '160px'
                // },
                {
                    title: '抓拍时间',
                    dataIndex: 'uploadTime',
                    ellipsis: true,
                    width: '150px'
                },
                {
                    title: '设备编号',
                    dataIndex: 'deviceCode',
                    ellipsis: true,
                    width: '100px'
                },
                {
                    title: '门型',
                    dataIndex: 'doorType',
                    scopedSlots: { customRender: 'doorType' },
                    ellipsis: true,
                    width: '100px'
                },
                {
                    title: '图片大',
                    dataIndex: 'pic',
                    scopedSlots: { customRender: 'pic' },
                    width: '80px'
                },
                {
                    title: '图片小',
                    dataIndex: 'picFace',
                    scopedSlots: { customRender: 'picFace' },
                    width: '80px'
                },
                {
                    title: '图片上传状态',
                    dataIndex: 'uploadStatus',
                    scopedSlots: { customRender: 'uploadStatus' },
                    ellipsis: true,
                    width: '120px'
                }
            ],
            data: [],
            loadData: async parameter => {
                // return await faceInfoList(Object.assign(parameter, this.queryParam))
                this.data = await faceInfoList(Object.assign(parameter, this.queryParam))
                console.log(this.data)
                this.data.records.length &&
                    this.data.records.map(v => {
                        // 当record.relativeType == 1的时候，不是正确的坐标位置2.有些列表没有返回 record.relativeBox
                        if (v.relativeType === '0' && v.relativeBox) {
                            v.relativeBox = JSON.parse(v.relativeBox)
                        }
                    })
                return Promise.resolve(this.data)
            },
            isDealList: [
                { code: '', value: '全部' },
                { code: '0', value: '成功' },
                { code: '1', value: '失败' },
                { code: '2', value: '无需上传' }
            ],
            levelList: [],
            sceneList: [],
            selectedRowKeys: [],
            selectedRows: [],
            form: {
                dealType: '',
                dealTypeName: '',
                dealDesc: ''
            }
        }
    },

    mounted() {
        // this.$refs.table.refresh(false)
    },
    methods: {
        handlePic(data) {
            // base64转图片url
            let url = fileToUrl('data:image/png;base64,' + data)
            return url
        },
        handleOk() {
            this.$refs.table.refresh()
        },
        dateChange(date, dateString) {
            this.queryParam.uploadTime_ge = dateString[0]
            this.queryParam.uploadTime_le = dateString[1]
        },
        resetForm() {
            this.queryDate = null
            console.log(this.$options.data())
            this.queryParam = this.$options.data().queryParam
        },
        onSelectChange(selectedRowKeys, selectedRows) {
            this.selectedRowKeys = selectedRowKeys
        },
        getCheckboxProps(record) {
            return {
                props: { disabled: record.isDeal == 2 || record.confirmStatus == 2 } // Column configuration not to be checked
            }
        },
        handelExportFaceInfo() {
            exportFaceInfo({
                ...this.queryParam,
                size: -1
            })
        },
        getAddressByGPS(record, index) {
            console.log(record, index, 'dddddd', this.data)
            let address = ''
            if (record.autoNaviLat == '0.0' || record.autoNaviLng == '0.0') {
                this.$message.warning('定位异常')
                return
            }
            if (record.autoNaviLat && record.autoNaviLng) {
                getAddrByGPS({
                    shipAutoNaviLat: record.autoNaviLat,
                    shipAutoNaviLng: record.autoNaviLng
                }).then(res => {
                    address = res
                    this.$set(record, 'address', address)
                    this.$set(record, 'showAddress', true)
                    console.log(record)
                })
            }
        },
        showNavi(record, index) {
            console.log(record, index, '****')
            // this.data.records[index].showAddress = false
            this.$set(record, 'showAddress', false)
            // console.log(record,index,'*');
            // this.loadData = async parameter => {
            //   return Promise.resolve(this.data)
            // }
        }
    }
}
</script>

<style lang="less" scoped>
.showAddress {
    span {
        color: #1890ff;
        cursor: pointer;
    }
}
.facePic {
    img {
        width: 30px;
        height: 17px;
    }
}
.centerDiv img {
    width: 300px;
}
.address {
    overflow: hidden;
    text-overflow: ellipsis; // text-overflow css3的属性，当文本溢出时，显示省略号
    // display: -webkit-box;
    -webkit-line-clamp: 2; // 设置两行文字溢出
    -webkit-box-orient: vertical;
}
</style>
