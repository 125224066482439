/*
 * @Autor: dingxiaolin
 * @Date: 2022-10-05 09:22:06
 * @LastEditors: dingxiaolin
 * @LastEditTime: 2022-10-22 09:13:33
 */
import { axios } from '@/utils/request'
import config from '@/config/config.js'

/**
 * 查询系统职位
 */
export function sysPosPage(parameter) {
    return axios({
        url: config.namespace+'/pos/page',
        method: 'get',
        params: parameter
    })
}

/**
 * 系统职位列表
 */
export function sysPosList(parameter) {
    return axios({
        url: config.namespace+'/pos/list',
        method: 'get',
        params: parameter
    })
}

/**
 * 添加系统职位
 */
export function sysPosAdd(parameter) {
    return axios({
        url: config.namespace+'/pos/add',
        method: 'post',
        data: parameter
    })
}

/**
 * 编辑系统职位
 */
export function sysPosEdit(parameter) {
    return axios({
        url: config.namespace+'/pos/edit',
        method: 'post',
        data: parameter
    })
}

/**
 * 删除系统职位
 */
export function sysPosDelete(parameter) {
    return axios({
        url: config.namespace+'/pos/delete',
        method: 'post',
        data: parameter
    })
}
