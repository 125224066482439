<template>
    <a-card :bordered="false">
        <div class="table-page-search-wrapper">
            <a-form layout="inline">
                <a-row :gutter="48">
                    <a-col :md="6" :sm="24">
                        <a-form-item label="所属机构">
                            <DeptTree v-model="queryParam.tenantId_equal" @change="handleTanentChange" />
                        </a-form-item>
                    </a-col>
                    <a-col :md="6" :sm="24">
                        <a-form-item label="所属线路">
                            <!-- queryParam.lineSubName_equal -->
                            <a-select
                                showSearch
                                option-filter-prop="children"
                                :filter-option="filterOption"
                                v-model="line_id"
                                placeholder="请选择所属线路"
                                @change="lineChange"
                            >
                                <a-select-option value="">全部</a-select-option>
                                <a-select-option v-for="(item, index) in lineList" :key="index" :value="item.groupLineId">{{
                                    item.lineSubName
                                }}</a-select-option>
                            </a-select>
                        </a-form-item>
                    </a-col>
                    <a-col :md="6" :sm="24">
                        <a-form-item label="车牌号">
                            <a-input :maxLength="35" v-model="queryParam.busNum_like" allow-clear placeholder="请输入车牌号" />
                        </a-form-item>
                    </a-col>
                    <a-col :md="6" :sm="24">
                        <a-form-item label="门数">
                            <a-select v-model="queryParam.busDoorNum_equal">
                                <a-select-option value="">全部</a-select-option>
                                <a-select-option v-for="(item, index) in doorNumList" :key="index" :value="item">{{ item }}</a-select-option>
                            </a-select>
                        </a-form-item>
                    </a-col>
                    <a-col :md="6" :sm="24">
                        <a-form-item label="车型">
                            <a-select v-model="queryParam.busModelId_equal">
                                <a-select-option value="">全部</a-select-option>
                                <a-select-option v-for="(item, index) in busTypeList" :key="index" :value="item.id">{{
                                    item.busModelName
                                }}</a-select-option>
                            </a-select>
                        </a-form-item>
                    </a-col>
                    <a-col :md="6" :sm="24">
                        <a-form-item label="创建时间">
                            <a-range-picker
                                :show-time="{ defaultValue: [$moment('00:00:00', 'HH:mm:ss'), $moment('23:59:59', 'HH:mm:ss')] }"
                                v-model="queryDate"
                                format="YYYY-MM-DD HH:mm:ss"
                                @change="dateChange"
                            />
                        </a-form-item>
                    </a-col>
                    <a-col :md="6" :sm="24" align="right" :offset="6">
                        <span class="table-page-search-submitButtons">
                            <a-button type="primary" @click="search">查询</a-button>
                            <a-button style="margin-left: 8px" @click="resetForm()">重置</a-button>
                        </span>
                    </a-col>
                </a-row>
            </a-form>
        </div>
        <div class="table-operator">
            <a-button type="primary" @click="$refs.editForm.show()">添加车辆</a-button>
            <a-button type="primary" @click="importDialog">导入</a-button>
            <a-button @click="exportExcel" style="float:right">导出</a-button>
        </div>
        <n-table ref="table" :columns="columns" :data="loadData"  :rowKey="record => record.id" :scrollToFirstRowOnChange="true">
            <span slot="action" slot-scope="text, record">
                <a @click="$refs.editForm.show(record)">编辑</a>
                <a-divider type="vertical" />
                <a-popconfirm
                    placement="topRight"
                    :title="record.title"
                    @confirm="() => del(record)"
                    :visible="record.show"
                    @cancel="record.show = false"
                >
                    <a class="red" @click="getDelDetail(record)">删除</a>
                </a-popconfirm>
            </span>
        </n-table>
        <edit-form ref="editForm" @ok="editOk" />
        <a-modal title="上传车辆信息Excel" :width="500" :visible="dialogVisible" @ok="handleOk" @cancel="handleCancel" destroyOnClose ok-text="提交">
            <div class="resultContent" v-if="loading"><a-spin tip="正在导入..."> </a-spin></div>
            <div class="step1" v-else>
                <p><b>注意事项</b></p>
                <p>1. 导入文件格式必须为.xlsx;</p>
                <!-- <p>1. 文件名称规则{年月日公司名称}，如{2022年08年25日镇江车辆};</p> -->
                <p>2. <span @click="downloadTemplate()">模板下载</span></p>
                <!-- <p>3. <a href="../../../../../public/car-upload.xlsx">模板下载</a></p> -->
                <b>文件导入：</b>
                <a-upload
                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel"
                    name="file"
                    :multiple="false"
                    v-model="fileList"
                    :remove="removeFile"
                    :customRequest="customRequest"
                    @change="handleChange"
                    :showUploadList="{ showPreviewIcon: false, showRemoveIcon: true }"
                >
                    <a-button :disabled="fileList.length > 0"> <a-icon type="upload" />选择文件 </a-button>
                </a-upload>
            </div>
        </a-modal>
        <a-modal title="导入完成" :visible="resultVisible" :footer="null" @cancel="resultVisible = false">
            <div class="resultContent">
                <p>
                    {{ resultText }}<span style="color:red">{{ errorNum }}</span
                    >条
                </p>
                <a-button
                    type="primary"
                    v-if="errorNum > 0"
                    @click="
                        resultVisible = false
                        tableVisible = true
                    "
                    >查看失败数据</a-button
                >
            </div>
        </a-modal>
        <a-modal title="导入失败" :visible="tableVisible" :footer="null" @cancel="tableVisible = false" :width="1000">
            <div class="resultContent">
                <a-table :columns="columns1" :data-source="resultData" bordered :pagination="false"> </a-table>
                <a-button type="primary" v-if="errorNum > 0" @click="saveErrInfo" style="margin-top:10px;">保存到本地</a-button>
            </div>
        </a-modal>
    </a-card>
</template>

<script>
import { NTable, DeptTree } from '@/components'
import { busInfoList, busTypeInfoList, removeBus, exportBus } from '@/api/cars/index'
import editForm from './editForm'
import { aiDeviceList, getLineList } from '@/api/alarm/index'
import { importExcel } from '@/api/cars/index'
import ExportJsonExcel from 'js-export-excel'
export default {
    components: {
        NTable,
        DeptTree,
        editForm
    },

    data() {
        return {
            line_id: '',
            loading: false,
            /**导入模态框 */
            dialogVisible: false,
            /**结果模态框 */
            resultVisible: false,
            /**结果列表模态框 */
            tableVisible: false,
            /**门数 */
            doorNumList: [1, 2, 3, 4, 5, 6],
            /**车型 */
            busTypeList: [],
            /**线路列表 */
            lineList: [],
            labelCol: {
                sm: { span: 8 },
                xs: { span: 24 }
            },
            wrapperCol: {
                sm: { span: 16 },
                xs: { span: 24 }
            },
            // 查询参数
            queryParam: {
                descs: 'create_time', //排序字段
                busNum_like: '', //车牌号
                lineSubName_equal: '', //线路
                busDoorNum_equal: '', //门数
                busModelId_equal: '', //车辆品牌
                createTime_ge: '', //创建开始时间
                createTime_le: '', //创建结束时间
                tenantId_equal: '' //所属机构
            },
            // 表头
            columns: [
                {
                    title: '车牌号',
                    dataIndex: 'busNum',
                    ellipsis: true,
                    width: '120px',
                },
                {
                    title: '车型',
                    dataIndex: 'busModelName',
                    ellipsis: true,
                    width: '200px',
                },
                {
                    title: '所属机构',
                    dataIndex: 'tenantName',
                    ellipsis: true,
                    width: '120px',
                },
                {
                    title: '所属线路',
                    dataIndex: 'lineSubName',
                    ellipsis: true,
                    width: '120px',
                },
                {
                    title: '车架号',
                    dataIndex: 'busTag',
                    ellipsis: true,
                    width: '120px',
                },
                {
                    title: '门型',
                    dataIndex: 'busDoorInfo',
                    ellipsis: true,
                    width: '120px',
                },
                {
                    title: '门数',
                    dataIndex: 'busDoorNum',
                    ellipsis: true,
                    width: '120px',

                },
                {
                    title: '创建时间',
                    dataIndex: 'createTime',
                    ellipsis: true
                },
                {
                    title: '操作',
                    dataIndex: 'action',
                    scopedSlots: { customRender: 'action' }
                }
            ],
            columns1: [
                {
                    title: '序号',
                    dataIndex: 'index',
                    width: '80px',
                    customRender: function(text, record, index) {
                        return index + 1
                    }
                },
                {
                    title: '车牌号',
                    dataIndex: 'busNum',
                    width: '100px',
                    ellipsis: true
                },
                {
                    title: '车架号',
                    dataIndex: 'busTag',
                    width: '100px',
                    ellipsis: true
                },
                {
                    title: '所属部门ID',
                    dataIndex: 'tenantId',
                    width: '180px',
                    ellipsis: true
                },
                {
                    title: '所属线路',
                    dataIndex: 'lineSubName',
                    width: '120px',
                    ellipsis: true
                },
                {
                    title: '车型',
                    dataIndex: 'busModelName',
                    width: '120px',
                    ellipsis: true
                },
                {
                    title: '错误详情',
                    dataIndex: 'memo',
                    width: '200px',
                    ellipsis: true
                }
            ],
            // 加载数据方法 必须为 Promise 对象
            loadData: async parameter => {
                return await busInfoList(Object.assign(parameter, this.queryParam))
            },
            queryDate: null,
            fileList: [],
            resultData: [],
            resultText: '',
            errorNum: 0
        }
    },
    mounted() {
        busTypeInfoList({
            size: '-1', //每页数量
            descs: 'bus_model_name' //排序字段
        }).then(res => {
            this.busTypeList = res.records
        })
        // 获取全部线路
        getLineList({
            descs: 'create_time',
            size: -1,
            tenantId_equal: this.$store.state.user.tenant.tenantId
        }).then(res => {
            this.lineList = res.records
        })
    },
    methods: {
        filterOption(input, option) {
            return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
        },
        editOk() {
            this.$refs.table.refresh()
        },
        handleTanentChange() {
            this.line_id = ''
            this.queryParam.lineSubName_equal = ''
            getLineList({ descs: 'create_time', size: -1, tenantId_equal: this.queryParam.tenantId_equal }).then(res => {
                this.lineList = res.records
            })
        },
        handleOk() {
            if (this.file && this.fileList.length) {
                this.loading = true
                importExcel(this.file).then(res => {
                    this.loading = false
                    console.log('file', res)
                    if (res.resultCode === '0') {
                        let { totalNum, succfNum, errorNum, errorInfo } = res.resultPojo
                        this.errorNum = errorNum
                        this.dialogVisible = false
                        this.resultVisible = true
                        this.resultText = `共上传${totalNum}条数据，成功导入${succfNum}条，失败`
                        if (errorNum > 0) {
                            errorInfo.forEach((v, i) => {
                                v.index = i + 1
                            })
                            this.resultData = errorInfo
                        }
                        this.$refs.table.refresh()
                    }
                    if (res.resultCode === '1') {
                        console.log(this.$message)
                        this.$message.error(res.resultMsg)
                    }
                })
            } else {
                this.$message.error('请选择上传的模板')
            }
        },
        resetForm() {
            this.queryParam = this.$options.data().queryParam
            this.queryParam.descs = 'create_time'
            this.queryDate = null
            this.line_id = ''
        },
        saveErrInfo() {
            var option = {
                fileName: '导入失败车辆' + this.$moment().format('YYYY-MM-DD HH:mm:ss'),
                datas: [
                    {
                        //   excel文件的数据源
                        sheetData: this.resultData,
                        //   excel文件sheet的表名
                        sheetName: 'sheet',
                        //   excel文件表头名
                        sheetHeader: ['序号', '车牌号', '车架号', '所属部门ID', '所属线路', '车型', '错误详情'],
                        //   excel文件列名
                        sheetFilter: ['index', 'busNum', 'busTag', 'tenantId', 'lineSubName', 'busModelName', 'memo'],
                        // 列宽
                        columnWidths: ['4', '8', '8', '8', '8', '8', '12']
                    }
                ]
            }
            //   创建ExportJsonExcel实例对象
            let toExcel = new ExportJsonExcel(option)
            //   调用保存方法
            toExcel.saveExcel()
        },
        importDialog() {
            this.file = null
            this.fileList = []
            this.loading = false
            this.dialogVisible = true
            this.resultVisible = false
            this.tableVisible = false
        },
        handleChange(info) {
            info.file.status = 'done'
            console.log('info', info)
            this.fileList = info.fileList
        },
        removeFile(val) {},
        customRequest(event) {
            this.file = event.file
        },
        handleCancel() {
            this.file = null
            this.dialogVisible = false
        },
        downloadTemplate() {
            // 模板下载
            let dom = document.createElement('a')
            dom.href = '/car-upload.xlsx'
            dom.download = 'carTemplate.xlsx'
            document.body.appendChild(dom)
            dom.click()
            document.body.removeChild(dom)
        },
        /**查询 */
        search() {
            // this.queryParam.tenantSearchType = this.queryParam.tenantId_equal ? '0' : ''
            this.$refs.table.localDataSource.map(item => {
                this.$set(item, 'show', false)
            })
            this.$refs.table.refresh(true)
        },
        onSelectChange(selectedRowKeys, selectedRows) {
            this.selectedRowKeys = selectedRowKeys
            this.selectedRows = selectedRows
        },
        dateChange(v, string) {
            this.queryParam.createTime_ge = string[0]
            this.queryParam.createTime_le = string[1]
        },
        /**获取删除提示详情 */
        async getDelDetail(record) {
            const data = await aiDeviceList({
                areaId_equal: record.id, //告警地点车辆ID
                areaType_equal: '0',
                size: -1
            })
            if (data.records.length > 0) {
                const deviceName = data.records.map(v => v.deviceName).join('、')
                var title = '删除后，将自动与终端' + deviceName + '解绑，确定删除吗？'
            } else {
                title = '你确定要删除该车辆吗？'
            }
            this.$refs.table.localDataSource.map(item => {
                if (item.id == record.id) {
                    this.$set(item, 'show', true)
                } else {
                    this.$set(item, 'show', false)
                }
            })
            this.$set(record, 'title', title)
        },
        /**删除 */
        del(record) {
            removeBus({ ids: record.id }).then(res => {
                this.$message.success('删除车辆成功')
                this.$refs.table.refresh()
                record.show = false
            })
        },
        /**导出列表 */
        exportExcel() {
            exportBus(this.queryParam)
        },
        lineChange(id) {
            let data = this.lineList.filter(v => v.groupLineId == id)
            if (data.length > 0) {
                this.queryParam.lineSubName_equal = data[0].lineSubName
            } else {
                console.log('trest')
                this.line_id = ''
                this.queryParam.lineSubName_equal = ''
            }
        }
    }
}
</script>

<style lang="less" scoped>
.align .ant-form-item-children {
    display: flex;
    .ant-input-affix-wrapper {
        width: 45%;
    }
    .line {
        width: 10%;
        display: inline-block;
        text-align: center;
    }
}
.step1 {
    p span {
        color: red;
        cursor: pointer;
        text-decoration: underline;
    }
}
.resultContent {
    display: flex;
    flex-direction: column;
    align-items: center;
}
</style>
