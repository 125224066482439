<template>
    <a-modal
        title="确警通知"
        centered
        :width="980"
        :maskClosable="false"
        :destroyOnClose="true"
        :visible="visible"
        :confirmLoading="confirmLoading"
        @ok="handleSubmit"
        @cancel="handleCancel"
    >
        <a-form-model ref="form" :model="form" :label-col="labelCol" :wrapper-col="wrapperCol">
            <a-form-model-item label="通知状态" prop="isSendAlarmConfirmNotice">
                <a-switch @change="onAlarmNoticeSwitchChange" v-model="form.isSendAlarmConfirmNotice" />
            </a-form-model-item>
            <a-form-model-item label="手机号" prop="alarmConfirmNoticePhone" :rules="[{ validator: validatePhone, trigger: 'change' }]">
                <a-textarea placeholder="请输入手机号，多个以英文逗号隔开" v-model="form.alarmConfirmNoticePhone"></a-textarea>
            </a-form-model-item>
        </a-form-model>
    </a-modal>
</template>

<script>
import { sysOrgAlarmNoticeEdit, getOrgDetail } from '@/api/system/org'
export default {
    data() {
        return {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 5 }
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 15 }
            },
            visible: false,
            confirmLoading: false,
            form: {
                id: '',
                isSendAlarmConfirmNotice: false,
                alarmConfirmNoticePhone: ''
            },
            validatePhone: (rule, value, callback) => {
                let phone = value.split(',')
                console.log(phone)
                for (let i = 0; i < phone.length; i++) {
                    if (phone[i] && !/^1[3|4|5|6|7|8|9][0-9]\d{8}$/.test(phone[i])) {
                        callback(new Error('手机号格式不正确,多个手机号用英文逗号隔开'))
                    }
                }
                callback()
            }
        }
    },
    methods: {
        async show(id) {
            this.visible = true
            let res = await this.getDetail(id)
            this.form.id = id
            this.form.isSendAlarmConfirmNotice = Boolean(res.data.isSendAlarmConfirmNotice)
            this.form.alarmConfirmNoticePhone = res.data.alarmConfirmNoticePhone
        },

        getDetail(id) {
            return getOrgDetail({ id })
        },
        handleSubmit() {
            this.$refs.form.validate(valid => {
                if (!valid) {
                    return
                }
                sysOrgAlarmNoticeEdit({
                    ...this.form,
                    isSendAlarmConfirmNotice: Number(this.form.isSendAlarmConfirmNotice)
                })
                    .then(res => {
                        this.$message.success('修改成功')
                        this.handleCancel()
                    })
                    .catch(res => {
                        this.$message.error('修改失败', res.msg)
                    })
            })
        },
        handleCancel() {
            this.visible = false
            this.confirmLoading = false
            this.form = this.$options.data().form
        },
        onAlarmNoticeSwitchChange() {}
    }
}
</script>

<style></style>
