<template>
    <div class="detail_box">
        <a-card :bordered="false">
            <a-row :gutter="10">
                <a-col :span="2">
                    <a-button style="margin-bottom:5px" @click="$router.back()">返回</a-button>
                    <a-upload
                        action=""
                        list-type="picture-card"
                        :multiple="false"
                        :customRequest="customRequest"
                        :file-list="fileList"
                        accept=".jpg, .jpeg, .png, .gif, .bmp"
                        @preview="handlePreview"
                        @change="uploadChange"
                        v-model="form.fileUrl"
                    >
                        <div v-if="fileList.length < 1">
                            <a-icon type="plus" />
                            <div class="ant-upload-text">船舶图片</div>
                        </div>
                    </a-upload>
                    <a-modal :visible="previewVisible" :footer="null" @cancel="previewVisible = false">
                        <img alt="example" style="width: 100%" :src="previewImage" />
                    </a-modal>
                </a-col>
                <a-col :span="22">
                    <a-form :label-col="labelCol" :wrapper-col="wrapperCol">
                        <a-row>
                            <a-col :span="6">
                                <a-form-item label="船舶名称"> {{ detailData.shipName || '-' }}</a-form-item>
                            </a-col>
                            <a-col :span="6">
                                <a-form-item label="船型">{{ detailData.shipType || '-' }}</a-form-item>
                            </a-col>
                            <a-col :span="6">
                                <a-form-item label="所属机构">{{ detailData.tenantName || '-' }}</a-form-item>
                            </a-col>
                            <a-col :span="6">
                                <a-form-item label="关联设备编号">
                                    <a
                                        href="javascript:;"
                                        v-if="deviceDetail.deviceCode"
                                        style="text-decoration:underline"
                                        @click="$router.push('/aiDevices/detail?id=' + deviceDetail.id)"
                                        >{{ deviceDetail.deviceCode }}</a
                                    >
                                    <span v-else>-</span>
                                </a-form-item>
                            </a-col>
                        </a-row>
                        <a-row>
                            <a-col :span="6">
                                <a-form-item label="MMSI">{{ detailData.shipMmsi || '-' }}</a-form-item>
                            </a-col>
                            <a-col :span="6">
                                <a-form-item label="船舶品牌">{{ detailData.shipBrand || '-' }}</a-form-item>
                            </a-col>
                            <a-col :span="6">
                                <a-form-item label="联系人">{{ detailData.linkPeople || '-' }}</a-form-item>
                            </a-col>
                            <a-col :span="6">
                                <a-form-item label="关联设备状态">
                                    <!-- {{ $filterEnum(isOnlineList, deviceDetail.deviceStatus) }} -->
                                    <a-tag v-if="deviceDetail.deviceStatus == 0">
                                        <a-badge status="default" text="未激活" />
                                    </a-tag>
                                    <a-tag v-if="deviceDetail.deviceStatus == 1" color="green">
                                        <a-badge status="success" text="在线" />
                                    </a-tag>
                                    <a-tag v-if="deviceDetail.deviceStatus == 2" color="red">
                                        <a-badge status="error" text="离线" />
                                    </a-tag>
                                    <span v-else>-</span>
                                </a-form-item>
                            </a-col>
                        </a-row>
                        <a-row>
                            <a-col :span="6">
                                <a-form-item label="船载货吨位">{{ detailData.shipTon || '-' }}</a-form-item>
                            </a-col>
                            <a-col :span="6">
                                <a-form-item label="联系电话">
                                    {{
                                        (detailData.linkPhone &&
                                            detailData.linkPhone.substring(0, 3) + '****' + detailData.linkPhone.substring(7, 11)) ||
                                            '-'
                                    }}</a-form-item
                                >
                            </a-col>
                            <a-col :span="6">
                                <a-form-item label="最后在线时间">
                                    {{ deviceDetail.deviceOnlineTime || '-' }}
                                </a-form-item>
                            </a-col>
                            <a-col :span="6">
                                <a-form-item label="创建时间">{{ detailData.createTime || '-' }}</a-form-item>
                            </a-col>
                        </a-row>
                        <a-row>
                            <a-col :span="2">
                                <p style="text-align:right">运输货物：</p>
                            </a-col>
                            <a-col :span="22">
                                <a-tag v-if="detailData.goodsType == 0 || 1">{{ detailData.goodsType == 0 ? '危化品' : '其他' }}</a-tag
                                >- {{ detailData.goodsNames }}
                            </a-col>
                        </a-row>
                        <a-row>
                            <a-col :span="2">
                                <p style="text-align:right">备注：</p>
                            </a-col>
                            <a-col :span="22">
                                {{ detailData.memo || '-' }}
                            </a-col>
                        </a-row>
                        <a-row>
                            <a-col :span="2">
                                <p style="text-align:right">当前位置：</p>
                            </a-col>
                            <a-col :span="22">
                                <span>{{ shipLocation || '-' }}</span>
                            </a-col>
                        </a-row>
                    </a-form>
                </a-col>
            </a-row>
        </a-card>
        <a-card style="flex: 1">
            <a-tabs type="card" @change="checkTab">
                <a-tab-pane key="1" tab="船舶档案">
                    <div class="tableBox" v-if="true">
                        <a-empty />
                    </div>
                    <div class="tableBox1" v-else>
                        <div>
                            <a-button class="rt">
                                同步
                            </a-button>
                            <p class="rt">最新同步时间：2022-08-23 14:16:31</p>
                        </div>
                    </div>
                </a-tab-pane>
                <a-tab-pane key="2" tab="关联人员">
                    <div class="tableBox1" v-if="shipUserList.length > 0">
                        <div class="operate">
                            <span>该艘船舶的告警数据仅以下关联人员可见</span>
                            <div><a-button @click="showModal" type="primary">添加人员</a-button> <a-button @click="delAll">全部移除</a-button></div>
                        </div>
                        <a-table
                            ref="table"
                            :columns="columns"
                            size="small"
                            :pagination="false"
                            :data-source="shipUserList"
                            :rowKey="record => record.id"
                            :rowClassName="(record, index) => (index % 2 === 1 ? 'odd' : 'even')"
                            :scroll="{ y: scrollHeight }"
                        >
                            <span slot="action" slot-scope="text, record">
                                <a-popconfirm placement="topRight" title="确定移除吗？" @confirm="() => del(record)">
                                    <a class="red">移除</a>
                                </a-popconfirm>
                            </span>
                        </a-table>
                    </div>
                    <div class="tableBox" v-else>
                        <p>添加关联人员后，该艘船舶的告警数据仅关联人员可见</p>
                        <a-button type="primary" @click="showModal">
                            添加人员
                        </a-button>
                    </div>
                </a-tab-pane>
            </a-tabs>
        </a-card>
        <modal ref="modal" @ok="search"></modal>
    </div>
</template>

<script>
import { shipInfoDetail, aiDeviceList, shipTravelReal, getShipUserList, removeShipUser } from '@/api/alarm/index'
import { getAddrByGPS } from '@/api/comm'
import { uploadFile } from '@/utils/util'
import modal from './modal.vue'

export default {
    components: { modal },
    watch: {},
    data() {
        return {
            form: {
                fileUrl: require('@/assets/shipIcon.png')
            },
            fileList: [
                {
                    uid: '-1',
                    status: 'done',
                    name: 'ss',
                    response: require('@/assets/shipIcon.png'), //冗余一个response字段用来预览
                    url: require('@/assets/shipIcon.png')
                }
            ],
            videoUrl: '',
            labelCol: {
                span: 8
            },
            wrapperCol: {
                span: 16
            },
            activeTab: '1',
            previewImage: '',
            previewVisible: false,
            scrollHeight: 0,
            shipLocation: '',
            isOnlineList: [],
            shipUserList: [],
            detailData: {},
            deviceDetail: {},
            columns: [
                {
                    title: '单位名称',
                    dataIndex: 'tenantName',
                    ellipsis: true
                },
                {
                    title: '人员',
                    dataIndex: 'userName',
                    ellipsis: true
                },
                {
                    title: '手机号',
                    dataIndex: 'phone',
                    ellipsis: true
                },
                {
                    title: '邮箱',
                    dataIndex: 'email',
                    ellipsis: true
                },
                {
                    title: '操作',
                    dataIndex: 'action',
                    width: '80px',
                    scopedSlots: { customRender: 'action' }
                }
            ]
        }
    },

    mounted() {
        this.getDetail()
        if (this.$store.state.app.multiTab) {
            this.scrollHeight = `calc(100vh - 600px)`
        } else {
            this.scrollHeight = `calc(100vh - 550px) `
        }
    },

    methods: {
        refresh() {
            this.search()
        },
        /**查询 */
        async search() {
            let queryParam = {
                shipId_equal: this.$route.query.ship_id,
                size: -1,
                current: 0,
                descs: 'user_id'
            }
            let data = await getShipUserList(queryParam)
            this.shipUserList = data.records
        },
        async uploadChange({ file, fileList, event }) {
            this.fileList = fileList
        },
        /**预览图片 */
        handlePreview(file) {
            this.previewImage = file.response
            this.previewVisible = true
        },
        async customRequest(options) {
            const url = await uploadFile(options.file.name, options.file)
            options.onSuccess(url, options.file) //url即为response的冗余字段
            this.form.fileUrl = url
        },
        // 初始化方法
        async getDetail() {
            // 船舶详情
            shipInfoDetail({ id: this.$route.query.ship_id }).then(res => {
                this.detailData = res
            })
            // 设备详情
            let queryParam = {
                deviceCode_equal: '',
                deviceName_like: '',
                deviceStatus_equal: '',
                areaType_equal: this.$route.query.areaType,
                firmwareVersion_equal: '',
                areaName_like: this.$route.query.areaName,
                tenantId_equal: this.$route.query.tenantId,
                areaId_equal: this.$route.query.ship_id,
                deviceTag_like: '',
                deviceType_equal: '',
                descs: 'create_time',
                registerType_equal: ''
            }
            aiDeviceList(queryParam).then(res => {
                if (res.records && res.records.length == 1) {
                    this.deviceDetail = res.records[0]
                    console.log('this.deviceDetail', this.deviceDetail)
                }
            })
            // 船舶位置
            let query = {
                /**船名/MMSI */
                shipkeyWords_like: this.$route.query.areaName,
                current: 0,
                size: 10,
                /**所属机构 */
                tenantId_equal: this.$store.state.user.tenant.tenantId || ''
            }
            shipTravelReal(query).then(res => {
                if (res.records && res.records.length == 1) {
                    getAddrByGPS({
                        shipAutoNaviLat: res.records[0]['shipAutoNaviLat'],
                        shipAutoNaviLng: res.records[0]['shipAutoNaviLng']
                    }).then(res => {
                        this.shipLocation = res
                    })
                }
            })
        },
        checkTab(v) {
            this.activeTab = v
            if (v == '2') {
                this.search()
            }
        },
        showModal() {
            this.$refs.modal.visible = true
            this.$refs.modal.name = this.detailData.tenantName
            this.$refs.modal.queryParam.name = ''
            this.$refs.modal.queryParam.venIdB = this.detailData.tenantId
            this.$refs.modal.form.shipId = this.$route.query.ship_id
        },
        /**删除 */
        del(record) {
            removeShipUser({ ids: record.id, shipId: this.$route.query.ship_id }).then(res => {
                this.$message.success('删除关联人员成功')
                this.search()
            })
        },
        delAll() {
            this.$confirm({
                title: '提示',
                content: '确定要全部移除吗 ?',
                centered: true,
                onOk: () => {
                    let ids = this.shipUserList.map(v => v.id)
                    if (ids.length > 0) {
                        removeShipUser({ ids: ids.join(','), shipId: this.$route.query.ship_id }).then(res => {
                            this.$message.success('删除关联人员成功')
                            this.search()
                        })
                    } else {
                        this.$message.info('暂无可删除人员')
                    }
                },
                onCancel() {}
            })
        }
    }
}
</script>

<style scoped lang="less">
/deep/ .anticon-delete {
    display: none;
}
/deep/ .ant-upload-list-picture-card .ant-upload-list-item-thumbnail img {
    object-fit: fill;
}
.video_title {
    .ant-btn {
        margin-right: 10px;
    }
}
.speCol .ant-col-6 {
    height: 40px;
}
.detail_box {
    padding: 10px;
    height: 100%;
    display: flex;
    flex-direction: column;
    .right {
        width: 580px;
        height: 350px;
        position: relative;
        margin-bottom: 80px;
    }
}

/deep/ .ant-card-body {
    height: 100%;
    margin: 0;
}
/deep/ .ant-modal-body {
    padding-top: 10px;
    padding-bottom: 0;
}
/deep/ .ant-descriptions-item-label.ant-descriptions-item-colon {
    width: 120px;
}
.alarm_title {
    font-size: 30px;
    font-weight: bold;
    margin-right: 20px;
    margin-left: 10px;
}
/deep/ .ant-upload.ant-upload-select-picture-card {
    width: 180px !important;
    height: 160px !important;
}
/deep/ .ant-upload-list-picture-card .ant-upload-list-item {
    width: 100% !important;
    height: auto !important;
}
.tableBox1 {
    width: 100%;
    height: 100%;
    display: block;
}
.tableBox {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
/deep/ .ant-tabs {
    width: 100%;
    height: 100%;
}
/deep/ .ant-tabs-content {
    width: 100%;
    height: calc(100% - 50px);
}
/deep/ .ant-tabs-tabpane {
    width: 100%;
    height: 100%;
}
/deep/ .ant-form-item {
    margin-bottom: 0;
}

.operate {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}
/deep/ .ant-form label {
    font-size: 13px;
}
</style>
