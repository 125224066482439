/**
 * 系统应用
 */
import { axios } from '@/utils/request'
import config from '@/config/config.js'

/**
 * 获取广告列表
 *
 * @author zhaoj
 * @date 2020/01/04 19:16
 */
export function appAdList(parameter) {
    return axios({
        url: config.namespace+'/appAdvertisement/list',
        method: 'post',
        data: parameter
    })
}

/**
 * 新增编辑广告
 *
 * @author zhaoj
 * @date 2020/01/04 19:16
 */
export function appAdSubmit(parameter) {
    return axios({
        url: config.namespace+'/appAdvertisement/submit',
        method: 'post',
        data: parameter
    })
}

/**
 * 广告详情
 *
 * @author zhaoj
 * @date 2020/01/04 19:16
 */
export function appAdDetail(parameter) {
    return axios({
        url: config.namespace+'/appAdvertisement/detail',
        method: 'post',
        data: parameter
    })
}

/**
 * 广告删除
 *
 * @author zhaoj
 * @date 2020/01/04 19:16
 */
export function appAdDel(parameter) {
    return axios({
        url: config.namespace+'/appAdvertisement/remove',
        method: 'post',
        data: parameter
    })
}

/**
 * 获取banner列表
 *
 * @author zhaoj
 * @date 2020/01/04 19:16
 */
export function appBannerList(parameter) {
    return axios({
        url: config.namespace+'/appBanner/list',
        method: 'post',
        data: parameter
    })
}

/**
 * 新增编辑banner
 *
 * @author zhaoj
 * @date 2020/01/04 19:16
 */
export function appBannerSubmit(parameter) {
    return axios({
        url: config.namespace+'/appBanner/submit',
        method: 'post',
        data: parameter
    })
}

/**
 * banner详情
 *
 * @author zhaoj
 * @date 2020/01/04 19:16
 */
export function appBannerDetail(parameter) {
    return axios({
        url: config.namespace+'/appBanner/detail',
        method: 'post',
        data: parameter
    })
}

/**
 * banner删除
 *
 * @author zhaoj
 * @date 2020/01/04 19:16
 */
export function appBannerDel(parameter) {
    return axios({
        url: config.namespace+'/appBanner/remove',
        method: 'post',
        data: parameter
    })
}

/**
 * 获取apptab列表
 *
 * @author zhaoj
 * @date 2020/01/04 19:16
 */
export function appTabList(parameter) {
    return axios({
        url: config.namespace+'/appTab/allList',
        method: 'post',
        data: parameter
    })
}

/**
 * 新增/修改apptab
 *
 * @author zhaoj
 * @date 2020/01/04 19:16
 */
export function appTabSubmit(parameter) {
    return axios({
        url: config.namespace+'/appTab/batchSubmit',
        method: 'post',
        data: parameter
    })
}

/**
 * 系统应用列表
 */
export function getAppPage(parameter) {
    return axios({
        url: config.namespace+'/app/page',
        method: 'get',
        params: parameter
    })
}

/**
 * 系统应用列表
 */
export function getAppList(parameter) {
    return axios({
        url: config.namespace+'/app/list',
        method: 'get',
        params: parameter
    })
}

/**
 * 新增系统应用
 */
export function sysAppAdd(parameter) {
    return axios({
        url: config.namespace+'/app/add',
        method: 'post',
        data: parameter
    })
}

/**
 * 编辑系统应用
 * @returns {*}
 */
export function sysAppEdit(parameter) {
    return axios({
        url: config.namespace+'/app/edit',
        method: 'post',
        data: parameter
    })
}

/**
 * 删除系统应用
 */
export function sysAppDelete(parameter) {
    return axios({
        url: config.namespace+'/app/delete',
        method: 'post',
        data: parameter
    })
}

/**
 * 设为默认应用
 *
 * @author Antonio
 * @date 2020年7月9日15:05:01
 */
export function sysAppSetAsDefault(parameter) {
    return axios({
        url: config.namespace+'/app/setAsDefault',
        method: 'post',
        data: parameter
    })
}
