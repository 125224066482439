<template>
    <div :id="player_container" class="player_container">
        <div :id="glplayer" class="glplayer"></div>
        <div id="controller" class="controller"  v-show="showController">
            

            <div id="operate-container" class="operate-container">
                <li id="playBar" class="playBtn playBar">
                    <a href="javascript:void(0)" title="start">Start</a>
                </li>

                <span id="ptsLabel" class="ptsLabel">00:00:00/00:00:00</span>
                <div class="voice-div" style="display:none"> <!--音量div-->
                    <span>
                        <a id="muteBtn" class="muteBtn" href="javascript:void(0)">
                            <svg
                                class="icon"
                                style="width: 1em; height: 1em; vertical-align: middle; fill: currentColor; overflow: hidden"
                                viewBox="0 0 1024 1024"
                                version="1.1"
                                xmlns="http://www.w3.org/2000/svg"
                                p-id="488"
                            >
                                <path
                                    d="M153.6 665.6V358.4h204.8V256H153.6c-56.32 0-102.4 46.08-102.4 102.4v307.2c0 56.32 46.08 102.4 102.4 102.4h204.8v-102.4H153.6zM358.4 256v102.4l204.8-128v563.2L358.4 665.6v102.4l307.2 204.8V51.2zM768 261.12v107.52c61.44 20.48 102.4 76.8 102.4 143.36s-40.96 122.88-102.4 143.36v107.52c117.76-25.6 204.8-128 204.8-250.88s-87.04-225.28-204.8-250.88z"
                                    p-id="489"
                                ></path>
                            </svg>
                        </a>
                    </span>
                    <progress id="progressVoice" class="progressVoice" value="50" max="100" ></progress>
                </div>

                <a id="fullScreenBtn" class="fullScreenBtn" href="javascript:void(0)">
                    <svg
                        class="icon"
                        style="width: 1em; height: 1em; vertical-align: middle; fill: currentColor; overflow: hidden"
                        viewBox="0 0 1024 1024"
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                        p-id="403"
                    >
                        <path
                            d="M167.8 903.1c-11.5 0-22.9-4.4-31.7-13.1-17.5-17.5-17.5-45.8 0-63.3l221.1-221.1c17.5-17.5 45.9-17.5 63.3 0 17.5 17.5 17.5 45.8 0 63.3L199.4 890c-8.7 8.7-20.2 13.1-31.6 13.1zM638.5 432.4c-11.5 0-22.9-4.4-31.7-13.1-17.5-17.5-17.5-45.8 0-63.3l221.7-221.7c17.5-17.5 45.8-17.5 63.3 0s17.5 45.8 0 63.3L670.1 419.3c-8.7 8.7-20.2 13.1-31.6 13.1zM859.7 903.8c-11.5 0-23-4.4-31.7-13.1L606.7 668.8c-17.5-17.5-17.4-45.9 0.1-63.4s45.9-17.4 63.3 0.1l221.4 221.9c17.5 17.5 17.4 45.9-0.1 63.4-8.8 8.7-20.2 13-31.7 13zM389 432.1c-11.5 0-23-4.4-31.7-13.1L136.1 197.2c-17.5-17.5-17.4-45.9 0.1-63.4s45.9-17.4 63.3 0.1l221.2 221.7c17.5 17.5 17.4 45.9-0.1 63.4-8.7 8.7-20.2 13.1-31.6 13.1z"
                            fill="#ffffff"
                            p-id="404"
                        ></path>
                        <path
                            d="M145 370c-24.7 0-44.8-20.1-44.8-44.8V221.8C100.2 153.5 155.7 98 224 98h103.4c24.7 0 44.8 20.1 44.8 44.8s-20.1 44.8-44.8 44.8H224c-18.9 0-34.2 15.3-34.2 34.2v103.4c0 24.7-20.1 44.8-44.8 44.8zM883.3 370c-24.7 0-44.8-20.1-44.8-44.8V221.8c0-18.9-15.3-34.2-34.2-34.2H700.8c-24.7 0-44.8-20.1-44.8-44.8S676.1 98 700.8 98h103.5c68.2 0 123.8 55.5 123.8 123.8v103.5c0 24.7-20.1 44.7-44.8 44.7zM327.5 926.6H224c-68.2 0-123.8-55.5-123.8-123.8V699.4c0-24.7 20.1-44.8 44.8-44.8s44.8 20.1 44.8 44.8v103.5c0 18.9 15.3 34.2 34.2 34.2h103.5c24.7 0 44.8 20.1 44.8 44.8s-20.1 44.7-44.8 44.7zM804.3 926.6H700.8c-24.7 0-44.8-20.1-44.8-44.8s20.1-44.8 44.8-44.8h103.5c18.9 0 34.2-15.4 34.2-34.2V699.4c0-24.7 20.1-44.8 44.8-44.8 24.7 0 44.8 20.1 44.8 44.8v103.5c0 68.2-55.6 123.7-123.8 123.7z"
                            fill="#ffffff"
                            p-id="405"
                        ></path>
                    </svg>
                </a>
                <span id="showLabel" class="showLabel" style="display:none"></span> <!--加载状态div-->
            </div>
            <div id="progress-contaniner" class="progress-common progress-contaniner" >
                <div id="cachePts" class="progress-common cachePts" style="width: 500px"></div>
                <div id="progressPts" class="progress-common progressPts" ></div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'VideoCom',
    components: {},
    computed: {},
    props: {
        url: {
            type: String,
            default: ''
        },
        vWidth: {
            type: Number,
            default: 500
        },
        vHeight: {
            type: Number,
            default: 500
        },
        glplayer: {
            type: String,
            default: ''
        },
        player_container:{
            type: String,
            default: ''
        },
        index:{
            type: Number,
            default: ''
        },
        showController:{
            type: Boolean,
            default: true
        }
    },
    watch: {
        url: {
            handler(value) {
                this.$nextTick(() => {
                    let url= process.env.NODE_ENV=="development"?value.replace('https','http'):value
                    this.init(url)
                })
            },
            deep: true,
            immediate: true
        },
        vWidth:{
            handler(value) {
              this.$nextTick(() => {
                let url= process.env.NODE_ENV=="development"?this.url.replace('https','http'):this.url
                this.init(url,value)
                // return value
              })
            },
            deep: true,
            immediate: true
        },
        vHeight:{
            handler(value) {
              this.$nextTick(() => {
                return value
              })
            },
            deep: true,
            immediate: true
        },
        showController:{
            handler(value) {
              this.$nextTick(() => {
                return value
              })
            },
            deep: true,
            immediate: true
        },
        
    },
    data() {
        return {
            timelineRef: null,
            playerObject: null,
            playerObj:null,
        }
    },
    mounted() {
      //  console.log(this.url)
      //  this.$nextTick(() => {
      //   this.init()
      //  })
    },
    unmounted() {},
    methods: {
        releasePlay(){
          console.log(this.playerObj,'this.playerObj2');
          this.playerObj.release();
        },
        init(value,vWidth) {
          let that = this
            const SHOW_LOADING = 'loading...'
            const SHOW_DONE = 'done.'

            function durationFormatSubVal(val) {
                let valStr = val.toString()
                if (valStr.length < 2) {
                    return '0' + valStr
                }
                return valStr
            }

            function durationText(duration) {
                if (duration < 0) {
                    return 'Play'
                }
                let durationSecInt = Math.round(duration)
                return (
                    durationFormatSubVal(Math.floor(durationSecInt / 3600)) +
                    ':' +
                    durationFormatSubVal(Math.floor((durationSecInt % 3600) / 60)) +
                    ':' +
                    durationFormatSubVal(Math.floor(durationSecInt % 60))
                )
            }

            const getMsTime = () => {
                return new Date().getTime()
            }

            /***************************************************
             *
             *
             *
             * 1. H.265/HEVC MP4/FLV/HLS/TS
             * Demo for create player(MP4/FLV/HLS/TS)
             * 点播/直播播放器创建Demo(MP4/FLV/HLS/TS)
             *
             *
             *
             ***************************************************/
            // clear cache count
            // function clear() {
            //     window.STATICE_MEM_playerCount = -1
            //     window.STATICE_MEM_playerIndexPtr = 0
            // }
            // clear()
            var token =
                'base64:QXV0aG9yOmNoYW5neWFubG9uZ3xudW1iZXJ3b2xmLEdpdGh1YjpodHRwczovL2dpdGh1Yi5jb20vbnVtYmVyd29sZixFbWFpbDpwb3JzY2hlZ3QyM0Bmb3htYWlsLmNvbSxRUTo1MzEzNjU4NzIsSG9tZVBhZ2U6aHR0cDovL3h2aWRlby52aWRlbyxEaXNjb3JkOm51bWJlcndvbGYjODY5NCx3ZWNoYXI6bnVtYmVyd29sZjExLEJlaWppbmcsV29ya0luOkJhaWR1'
            /******** Test Cases *******/
            // var url = 'http://oss-jhx-maritime.oss-cn-shanghai.aliyuncs.com/2022/05/05/204793b063a64a3497abe67f1170280b.mp4'
            // var url = 'https://jhxoss.oss-cn-beijing.aliyuncs.com/output.mp4'
            var config = {
                player: this.glplayer,
                width: vWidth,
                height: that.vHeight,
                token: token,
                extInfo: {
                    coreProbePart: 1,
                    probeSize: 8192,
                    ignoreAudio: 1,
                    autoPlay:true
                }
            } // config
            let playerId = config.player
            let playerObj = window.new265webjs(value, config)
          console.log(playerObj,'playerObjplayerObj');
            let playerDom = document.getElementById(playerId)
            // let playerDom = document.querySelector('#' + playerId)
            let playerCont = document.querySelectorAll('.player_container')[this.index] 
            let controllerCont = document.querySelectorAll('.controller')[this.index]
            let progressCont = document.querySelectorAll('.progress-contaniner')[this.index]
            let progressContW = progressCont.offsetWidth
            let cachePts = progressCont.querySelector('.cachePts')
            let progressPts = progressCont.querySelector('.progressPts')
            let progressVoice = document.querySelectorAll('.progressVoice')[this.index]
            let playBar = document.querySelectorAll('.playBar')[this.index]
            let playBtn = playBar.getElementsByTagName('a')[0]
            let showLabel = document.querySelectorAll('.showLabel')[this.index]
            let ptsLabel = document.querySelectorAll('.ptsLabel')[this.index]
            let coverToast = document.querySelectorAll('.coverLayer')[this.index]
            let coverBtn = document.querySelectorAll('.coverLayerBtn')[this.index]
            let muteBtn = document.querySelectorAll('.muteBtn')[this.index]
            // let debugYUVBtn     = document.querySelector('#debugYUVBtn');
            // let debugYUVATag    = document.querySelector('#debugYUVUrl');
            let fullScreenBtn = document.querySelectorAll('.fullScreenBtn')[this.index] // document.querySelector 取class返回的是类数组
            let mediaInfo = null

            playBtn.disabled = true
            // playBar.textContent = '>';
            showLabel.textContent = SHOW_LOADING
            playerCont.style.width = config.width + 'px'
            playerCont.style.height = config.height + 'px'
            controllerCont.style.width = config.width + 'px'

            let muteState = false

            // controllerCont.style.left = playerContainer.clientLeft;
            // controllerCont.style.bottom = playerContainer.clientBottom;
            // alert(playerContainer.clientLeft);
            let playAction = () => {
                console.log('is playing:', playerObj.isPlaying())
                if (playerObj.isPlaying()) {
                    console.log('bar pause============>')
                    // playBar.textContent = '>';
                    playBar.setAttribute('class', 'playBtn')
                    playerObj.pause()
                } else {
                    // playBar.textContent = '||';
                    playBar.setAttribute('class', 'pauseBtn')
                    playerObj.play()
                }
            }

            playerCont.onmouseover = function () {
                controllerCont.hidden = false
            }

            playerCont.onmouseout = function () {
                controllerCont.hidden = false
            }

            playerDom.onmouseup = function () {
                playAction()
            }

            playBtn.onclick = () => {
                playAction()
            }

            muteBtn.onclick = () => {
                console.log(playerObj.getVolume())
                if (muteState === true) {
                    playerObj.setVoice(1.0)
                    progressVoice.value = 100
                } else {
                    playerObj.setVoice(0.0)
                    progressVoice.value = 0
                }
                muteState = !muteState
            }

            fullScreenBtn.onclick = () => {
                playerObj.fullScreen()
                // setTimeout(() => {
                //     playerObj.closeFullScreen();
                // }, 2000);
            }

            progressCont.addEventListener('click', (e) => {
                showLabel.textContent = SHOW_LOADING
                let x = e.pageX - progressCont.getBoundingClientRect().left // or e.offsetX (less support, though)
                let y = e.pageY - progressCont.getBoundingClientRect().top // or e.offsetY
                let clickedValue = (x * progressCont.max) / progressCont.offsetWidth
                // alert(clickedValue);
                playerObj.seek(clickedValue)
            })

            progressVoice.addEventListener('click', (e) => {
                let x = e.pageX - progressVoice.getBoundingClientRect().left // or e.offsetX (less support, though)
                let y = e.pageY - progressVoice.getBoundingClientRect().top // or e.offsetY
                let clickedValue = (x * progressVoice.max) / progressVoice.offsetWidth
                progressVoice.value = clickedValue
                let volume = clickedValue / 100
                // alert(volume);
                // console.log(
                //     progressVoice.offsetLeft, // 209
                //     x, y, // 324 584
                //     progressVoice.max, progressVoice.offsetWidth);
                playerObj.setVoice(volume)
            })

            playerObj.onSeekStart = (pts) => {
                showLabel.textContent = SHOW_LOADING + ' seek to:' + parseInt(pts)
            }

            playerObj.onSeekFinish = () => {
                showLabel.textContent = SHOW_DONE
            }

            playerObj.onPlayFinish = () => {
                console.log('============= FINISHED ===============')
                playerObj.play()
                // playBar.setAttribute('class', 'playBtn')
            }

            playerObj.onRender = (width, height, imageBufferY, imageBufferB, imageBufferR) => {
                console.log('on render')
            }

            playerObj.onOpenFullScreen = () => {
                console.log('onOpenFullScreen')
            }

            playerObj.onCloseFullScreen = () => {
                console.log('onCloseFullScreen')
            }

            playerObj.onSeekFinish = () => {
                showLabel.textContent = SHOW_DONE
            }

            playerObj.onLoadCache = () => {
                showLabel.textContent = 'Caching...'
            }

            playerObj.onLoadCacheFinshed = () => {
                showLabel.textContent = SHOW_DONE
            }

            playerObj.onReadyShowDone = () => {
                console.log('onReadyShowDone')
                showLabel.textContent = 'Cover Img OK'
            }

            playerObj.onLoadFinish = () => {
                playerObj.setVoice(1.0)
                // playerObj.play()
                // playBar.setAttribute('class', 'pauseBtn')
                mediaInfo = playerObj.mediaInfo()
                console.log('mediaInfo===========>', mediaInfo)
                /*
                meta:
                    durationMs: 144400
                    fps: 25
                    sampleRate: 44100
                    size: {
                        width: 864,
                        height: 480
                    },
                    audioNone : false
                videoType: "vod"
                */
                if (mediaInfo.meta.isHEVC === false) {
                    console.log('is not HEVC/H.265 media!')
                    //coverToast.removeAttribute('hidden');
                    //coverBtn.style.width = '100%';
                    //coverBtn.style.fontSize = '50px';
                    //coverBtn.innerHTML = 'is not HEVC/H.265 media!';
                    //return;
                }
                //console.log("is HEVC/H.265 media.");

                playBtn.disabled = false

                if (mediaInfo.meta.audioNone) {
                    progressVoice.value = 0
                    progressVoice.style.display = 'none'
                } else {
                    playerObj.setVoice(0.5)
                }

                if (mediaInfo.videoType == 'vod') {
                    cachePts.max = mediaInfo.meta.durationMs / 1000
                    progressCont.max = mediaInfo.meta.durationMs / 1000
                    ptsLabel.textContent = durationText(0) + '/' + durationText(progressCont.max)
                } else {
                    cachePts.hidden = true
                    progressCont.hidden = true
                    ptsLabel.textContent = 'LIVE'

                    if (mediaInfo.meta.audioNone === true) {
                        // playBar.textContent = '||';
                        playerObj.play()
                    } else {
                        coverToast.removeAttribute('hidden')
                        coverBtn.onclick = () => {
                            // playBar.textContent = '||';
                            playAction()
                            coverToast.setAttribute('hidden', 'hidden')
                        }
                    }
                }

                showLabel.textContent = SHOW_DONE
            }

            playerObj.onCacheProcess = (cPts) => {
                // console.log("onCacheProcess => ", cPts);
                try {
                    // cachePts.value = cPts;
                    let precent = cPts / progressCont.max
                    let cacheWidth = precent * 500
                    // console.log(precent, precent * progressCont.offsetWidth);
                    cachePts.style.width = cacheWidth + 'px'
                } catch (err) {
                    console.log(err)
                }
            }

            playerObj.onPlayTime = (videoPTS) => {
                if (mediaInfo.videoType == 'vod') {
                    // progressPts.value = videoPTS;
                    let precent = videoPTS / progressCont.max
                    let progWidth = precent * vWidth
                    // console.log(precent, precent * progressCont.offsetWidth);
                    progressPts.style.width = progWidth + 'px'

                    ptsLabel.textContent = durationText(videoPTS) + '/' + durationText(progressCont.max)
                } else {
                    // ptsLabel.textContent = durationText(videoPTS) + '/LIVE';
                    ptsLabel.textContent = '/LIVE'
                }
            }
            console.log(playerObj,'playerObj1');
            this.playerObj = playerObj
            playerObj.do()
            return playerObj
        }
    }
}
</script>

<style lang="less" scoped>
#player-container {
    position: absolute;
    margin: auto;
    left: -10px;
    right: 0;
    /*width: 960px;
	height: 540px;*/
    /*overflow: hidden;*/
}

.bottom-container {
    margin-top: 600px;
}

.controller {
    background-color: rgba(0, 0, 0, 0.5);
    /*background-image: linear-gradient(
        to right, red 50px, yellow, blue, green);*/
    /*background-position: 100% 50%;*/
    width: 960px;
    height: 39px;
    position: absolute;
    /*float: bottom;*/
    z-index: 99999;
    left: 0;
    bottom: 0;
}

/*.controller:hover {
	background-color: white;
}*/

.operate-container {
    min-height: 35px;
    padding: 3px;
    /*position: relative;
    top: 50%;
    transform: translateY(-50%);*/
}

.muteBtn {
    color: white;
}

.progressVoice {
    float: left;
    width: 50%;
    margin-top: 0.2rem;
    /*border: 10px solid rgba(255,255,255,0);*/
    color: #d9d9d9;
    height: 10px;

    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
    border-radius: 5px;
    border: none;
}
.progressVoice::-moz-progress-bar {
    background-color: #d9d9d9;
}

.progressVoice::-webkit-progress-value {
    background-color: #d9d9d9;
}

#coverLayer {
    width: 100%;
    height: 100%;
    padding-top: 300px;
    z-index: 10000;
    position: absolute;
    top: 0px;
    left: 0px;
    background-color: rgba(0, 0, 0, 0.5);
}
#coverLayerBtn {
    width: 20%;
    height: 200px;
    border-radius: 50px;
}

.ptsLabel {
    font-size: 15px;
    color: white;
    /*background: rgb(0, 0, 0);
    border: 1px solid white;*/
    float: left;
    /*border-radius: 7px;*/
    padding: 1px;
    margin-top: 4px;
    margin-left: 5px;
}

.voice-div {
    color: white;
    /*background: rgb(0, 0, 0);
    border: 2px solid white;
    border-radius: 7px;
    padding-left: 20px;*/
    width: 18%;
    float: left;
    margin-top: 3px;
    margin-left: 10px;

    /*position: relative;*/
    /*margin-top: 50%;*/
    /*transform: translateY(-50%);*/
}
.voice-div > span {
    /*font-size: 15px;*/
    float: left;
    color: white;
    margin-right: 5px;
}
.voice-div > progress {
    margin-top: 8px;
}

.fullScreenBtn {
    /*font-size: 10px;*/
    float: right;
    margin-top: 3px;
    margin-right: 5px;
    color: white;
    /*background: #ffffff;*/
    /*border: 2px solid white;*/
    /*text-align: center;*/
    /*line-height: 20px;*/
    height: 24px;
    /*font-weight: bold;*/
    /*border-radius: 7px;*/
}
.showLabel {
    height: 18px;
    font-size: 8px;
    color: white;
    background: rgb(0, 0, 0);
    border-bottom: 1px solid #666666;
    /*border-radius: 7px;*/
    padding-top: 1px;
    padding-left: 5px;
    padding-right: 5px;
    float: right;
    margin-top: 5px;
    margin-right: 5px;
}
.controller li {
    position: relative;
    float: left;
    border: 15px solid #404040;
    color: #404040;
    height: 0;
    width: 0;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    -o-border-radius: 100%;
    border-radius: 100%;
    margin: 0 5px;
}
.controller li a {
    border-style: solid;
    text-indent: -9999px;
    position: absolute;
    top: -8px;
    left: -5px;
}

.playBtn a {
    border-color: transparent transparent transparent #fff;
    border-width: 8px 0 8px 12px;
    width: 0;
    height: 0;
}
.pauseBtn a {
    border-color: transparent white;
    border-width: 0 3px;
    height: 15px;
    width: 10px;
    left: -6px;
}
.stopBtn a {
    border: 7px solid #fff;
    height: 0;
    width: 0;
    left: -7px;
    top: -7px;
}
.forwardBtn a {
    border-left-width: 8px;
    left: 1px;
}
.forwardBtn a:first-child {
    margin-left: -7px;
}

.rewindBtn a {
    border-width: 8px 8px 8px 0;
    border-color: transparent #fff transparent transparent;
    width: 0;
    height: 0;
}
.rewindBtn a:first-child {
    margin-left: -7px;
}

.ejectBtn a.arrow {
    border-width: 0 8px 8px 8px;
    border-color: transparent transparent #fff transparent;
    top: -26px;
    left: -8px;
}
.ejectBtn a.dash {
    border-width: 0 0 4px;
    border-color: transparent transparent #fff;
    height: 0;
    width: 16px;
    left: -8px;
    top: 4px;
}
.progress-common {
    height: 3px; /** same as progress-contaniner **/
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
    border-radius: 5px;
    border: none;
    margin-bottom: 2px;
}

.progress-contaniner {
    z-index: 1000;
    width: 100%;
    background-color: #666666;
}

.cachePts {
    width: 100% !important;
    position: absolute;
    z-index: 1001;
    /*margin-top: 0.125rem;*/
    background-color: rgb(229,229,229,0.5);
}
/*.cachePts::-moz-progress-bar {
    background-color: #e9e9e9;
}
.cachePts::-webkit-progress-value {
    background-color: #e9e9e9;
}*/

.progressPts {
    position: absolute;
    z-index: 1002;
    width: 0px;
    /*margin-top: 0.125rem;*/
    background-color: rgba(24,144,255, 0.7);
}
/*.progressPts::-moz-progress-bar {
    background-color: yellow;
}
.progressPts::-webkit-progress-value {
    background-color: yellow;
}*/
</style>