/*
 * @Autor: dingxiaolin
 * @Date: 2022-10-05 09:22:06
 * @LastEditors: dingxiaolin
 * @LastEditTime: 2022-10-22 09:15:16
 */
import { axios } from '@/utils/request'
import config from '@/config/config.js'

/**
 * 查询访问日志
 *
 * @author Antonio
 * @date 2020/5/19 11:57
 */
export function sysVisLogPage(parameter) {
    return axios({
        url: config.namespace + '/sysVisLog/page',
        method: 'get',
        params: parameter
    })
}

/**
 * 查询操作日志
 *
 * @author Antonio
 * @date 2020/5/19 11:57
 */
export function sysOpLogPage(parameter) {
    return axios({
        url: config.namespace + '/sysOpLog/page',
        method: 'post',
        data: parameter
    })
}

/**
 * 清空访问日志
 *
 * @author Antonio
 * @date 2020/6/23 23:09
 */
export function sysVisLogDelete(parameter) {
    return axios({
        url: config.namespace + '/sysVisLog/delete',
        method: 'post',
        data: parameter
    })
}

/**
 * 清空登录日志
 *
 * @author Antonio
 * @date 2020/6/23 23:09
 */
export function sysOpLogDelete(parameter) {
    return axios({
        url: config.namespace + '/sysOpLog/delete',
        method: 'post',
        data: parameter
    })
}
