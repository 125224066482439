import { render, staticRenderFns } from "./ExceptionPage.vue?vue&type=template&id=f8f79976&scoped=true"
import script from "./ExceptionPage.vue?vue&type=script&lang=js"
export * from "./ExceptionPage.vue?vue&type=script&lang=js"
import style0 from "./ExceptionPage.vue?vue&type=style&index=0&id=f8f79976&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f8f79976",
  null
  
)

export default component.exports