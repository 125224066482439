<template>
    <a-modal
        :title="`${isAdd ? '新增' : '编辑'}通道`"
        centered
        :width="700"
        :visible="visible"
        :confirmLoading="confirmLoading"
        @ok="handleOk"
        @cancel="handleCancel"
        destroyOnClose
    >
        <a-form-model ref="form" :model="form"  :label-col="labelCol" :wrapper-col="wrapperCol">
            <a-form-model-item label="参数标识" prop="paramKey">
                <a-input :disabled="!isAdd" v-model="form.paramKey" placeholder="请输入参数标识" />
            </a-form-model-item>
            <a-form-model-item label="参数值" prop="paramValue">
                <a-input v-model="form.paramValue" placeholder="请输入参数值" />
            </a-form-model-item>
            <a-form-model-item label="描述" prop="paramDesc">
                <a-textarea v-model="form.paramDesc" placeholder="请输入描述" :auto-size="{ minRows: 3, maxRows: 5 }" />
            </a-form-model-item>
        </a-form-model>
    </a-modal>
</template>
<script>
import { aiDeviceSetConfigAdd, aiDeviceSetConfigDetail,aiDeviceSetConfigUpdate } from '@/api/alarm'
export default {
    data() {
        return {
            isAdd: true,
            sceneList: [],
            form: {
                deviceId: '', //设备ID
                paramKey: '', //参数标识
                paramValue: '', //参数值
                paramDesc: '' //描述
            },

            labelCol: {
                xs: { span: 24 },
                sm: { span: 5 }
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 15 }
            },
            visible: false,
            confirmLoading: false
        }
    },
    created() {},
    methods: {
        /*   init方法是是初始化 用户打开该组件 */
        async init(record) {
            console.log(record)
            this.visible = true /* visiable的值为true 打开弹窗 */
            /* 如果为null表示新增接着初始化表单 */
            if (record === null) {
                /*  isAdd 记录用户行为 新增or编辑 */
                this.isAdd = true
                this.form = this.$options.data().form
                this.form.deviceId = this.$route.query.id
            } else {
                this.isAdd = false //否则就不记录
                let res = await this.getDetail(record.id)
                this.form = {
                    id: res.id,
                    deviceId: res.deviceId, //设备ID
                    paramKey: res.paramKey, //参数标识
                    paramValue: res.paramValue, //参数值
                    paramDesc: res.paramDesc //描述
                }
            }
        },

        getDetail(id) {
            return aiDeviceSetConfigDetail({ id })
        },
        handleOk() {
            this.form.paramValue = this.form.paramValue.trim()
            this.form.paramDesc = this.form.paramDesc.trim()
            this.form.paramKey = this.form.paramKey.trim()
            /* 通过form的validate方法验证表单 */
            this.$refs.form.validate(res => {
                /* res为true表示验证通过 */
                if (!res) {
                    return
                }
                /*  新增调用新增aiDeviceMediaSave 否则调用编辑aiDeviceMediaUpdate */
                const reqList = this.isAdd? aiDeviceSetConfigAdd:aiDeviceSetConfigUpdate
                this.$parent.getRealTaskStatus(() => {
                    this.confirmLoading = true
                    reqList(this.form)
                    this.$parent.setError()
                })
                this.confirmLoading = false
                this.visible = false
            })
        },
        handleCancel() {
            this.confirmLoading = false
            this.visible = false // 点击确定关闭弹窗
        }
    }
}
</script>
