<!--
 * @Autor: dingxiaolin
 * @Date: 2021-09-23 07:31:51
 * @LastEditors: dingxiaolin
 * @LastEditTime: 2021-09-28 11:11:04
-->
<template>
    <router-view />
</template>

<script>
export default {
    name: 'BlankLayout',
}
</script>

<style scoped>
</style>
