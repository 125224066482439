<template>
    <a-card :bordered="false">
        <div class="table-page-search-wrapper">
            <a-form-model :label-col="labelCol" :wrapper-col="wrapperCol">
                <a-row :gutter="48">
                    <a-col :md="6" :sm="24">
                        <a-form-model-item label="短信编号">
                            <a-input :maxLength="35" v-model="queryParam.smsModelCode_like" allow-clear placeholder="请输入短信编号" />
                        </a-form-model-item>
                    </a-col>
                    <a-col :md="6" :sm="24">
                        <a-form-model-item label="短信模板">
                            <a-input :maxLength="35" v-model="queryParam.smsTitle_like" allow-clear placeholder="请输入短信模板" />
                        </a-form-model-item>
                    </a-col>
                    <a-col :md="6" :sm="24">
                        <a-form-model-item label="短信内容">
                            <a-input :maxLength="35" v-model="queryParam.smsDesc_like" allow-clear placeholder="请输入短信内容" />
                        </a-form-model-item>
                    </a-col>
                    <a-col :md="6" :sm="24">
                        <a-form-model-item label="发送时间">
                            <a-range-picker
                                :show-time="{ defaultValue: [$moment('00:00', 'HH:mm:ss'), $moment('00:00:00', 'HH:mm:ss')] }"
                                v-model="queryDate"
                                format="YYYY-MM-DD HH:mm"
                                @change="dateChange"
                            />
                        </a-form-model-item>
                    </a-col>
                    <a-col :md="6" :sm="24" :offset="18" align="right">
                        <span class="table-page-search-submitButtons">
                            <a-button type="primary" @click="$refs.table.refresh(true)">查询</a-button>
                            <a-button style="margin-left: 8px" @click="() => ((queryParam = {}), (queryDate = null))">重置</a-button>
                        </span>
                    </a-col>
                </a-row>
            </a-form-model>
        </div>
        <n-table ref="table" :columns="columns" :data="loadData" :rowKey="(record) => record.id" :scrollToFirstRowOnChange="true"> </n-table>
    </a-card>
</template>

<script>
import { NTable } from '@/components'
import { smsList } from '@/api/alarm/index'

export default {
    components: {
        NTable
    },

    data() {
        return {
            labelCol: {
                sm: { span: 6 },
                xs: { span: 24 }
            },
            wrapperCol: {
                sm: { span: 18 },
                xs: { span: 24 }
            },
            // 查询参数
            queryParam: {
                //短信模板编号
                smsModelCode_like: '',
                //短信模板
                smsTitle_like: '',
                //短信内容
                smsDesc_like: '',
                //短信发送开始时间 yyyy-MM-dd HH:mm:ss
                smsTime_ge: '',
                //短信发送 结束时间 yyyy-MM-dd HH:mm:ss
                smsTime_le: '',
                descs: 'create_time'
            },
            queryDate: null,
            // 表头
            columns: [
                {
                    title: '短信编号',
                    dataIndex: 'smsModelCode',
                    width: 150
                },
                {
                    title: '短信模板',
                    dataIndex: 'smsTitle',
                    width: 250,
                    ellipsis: true
                },
                {
                    title: '短信内容',
                    dataIndex: 'smsDesc',
                    ellipsis: true
                },
                {
                    title: '发送对象',
                    dataIndex: 'smsPhone',
                    ellipsis: true,
                    width: 250
                },
                {
                    title: '发送时间',
                    dataIndex: 'smsTime',
                    width: 250
                }
            ],
            // 加载数据方法 必须为 Promise 对象
            loadData: async (parameter) => {
                return await smsList(Object.assign(parameter, this.queryParam))
            }
        }
    },

    mounted() {},
    methods: {
        dateChange(date, dateString) {
            this.queryParam.smsTime_ge = dateString[0]
            this.queryParam.smsTime_le = dateString[1]
        },
        handleOk() {
            this.$refs.table.refresh()
        }
    }
}
</script>

<style lang="less" scoped></style>
