<template>
    <div class="setting-drawer">
        <a-drawer
            width="275"
            placement="right"
            @close="onClose"
            :closable="false"
            :visible="visible"
            :drawer-style="{ position: 'fixed' }"
            style="position: fixed"
        >
            <div class="setting-drawer-index-content">
                <div :style="{ marginBottom: '12px' }">
                    <h3 class="setting-drawer-index-title">整体风格设置</h3>
                    <div class="setting-drawer-index-blockChecbox">
                        <a-tooltip>
                            <template slot="title"> 暗色菜单风格 </template>
                            <div class="setting-drawer-index-item" @click="handleMenuTheme('dark')">
                                <img src="https://gw.alipayobjects.com/zos/rmsportal/LCkqqYNmvBEbokSDscrm.svg" alt="dark" />
                                <div class="setting-drawer-index-selectIcon" v-if="navTheme === 'dark' && navTheme !== 'night'">
                                    <a-icon type="check" />
                                </div>
                            </div>
                        </a-tooltip>
                        <a-tooltip>
                            <template slot="title"> 亮色菜单风格 </template>
                            <div class="setting-drawer-index-item" @click="handleMenuTheme('light')">
                                <img src="https://gw.alipayobjects.com/zos/rmsportal/jpRkZQMyYRryryPNtyIC.svg" alt="light" />
                                <div class="setting-drawer-index-selectIcon" v-if="navTheme !== 'dark' && navTheme !== 'night'">
                                    <a-icon type="check" />
                                </div>
                            </div>
                        </a-tooltip>
                    </div>
                </div>

                <div :style="{ marginBottom: '12px' }">
                    <h3 class="setting-drawer-index-title">主题色</h3>

                    <div style="height: 20px">
                        <a-tooltip class="setting-drawer-theme-color-colorBlock" v-for="(item, index) in colorList" :key="index">
                            <template slot="title">
                                {{ item.key }}
                            </template>
                            <a-tag :color="item.color" @click="changeColor(item.color)">
                                <a-icon type="check" v-if="item.color === primaryColor"></a-icon>
                            </a-tag>
                        </a-tooltip>
                    </div>
                </div>
                <a-divider />
                <div :style="{ marginBottom: '12px' }">
                    <h3 class="setting-drawer-index-title">其他设置</h3>
                    <div>
                        <a-list :split="false">
                            <a-list-item>
                                <a-switch slot="actions" size="small" :defaultChecked="colorWeak" @change="onColorWeak" />
                                <a-list-item-meta>
                                    <div slot="title">色弱模式</div>
                                </a-list-item-meta>
                            </a-list-item>
                            <a-list-item>
                                <a-switch slot="actions" size="small" :defaultChecked="multiTab" @change="onMultiTab" />
                                <a-list-item-meta>
                                    <div slot="title">多页签模式</div>
                                </a-list-item-meta>
                            </a-list-item>
                        </a-list>
                    </div>
                </div>
            </div>
            <div class="setting-drawer-index-handle" @click="toggle" slot="handle">
                <a-icon type="setting" v-if="!visible" />
                <a-icon type="close" v-else />
            </div>
        </a-drawer>
    </div>
</template>

<script>
import config from '@/config/defaultSettings'
import { updateTheme, updateColorWeak, colorList } from './settingConfig'
import { mixin, mixinDevice } from '@/utils/mixin'
import { lStorage } from '@/utils/lStorage'

export default {
    components: {},
    mixins: [mixin, mixinDevice],
    data() {
        return {
            visible: false,
            colorList
        }
    },
    watch: {},
    mounted() {
        this.primaryColor && updateTheme(this.primaryColor)
        if (this.colorWeak !== config.colorWeak) {
            updateColorWeak(this.colorWeak)
        }
    },
    methods: {
        showDrawer() {
            this.visible = true
        },
        onClose() {
            this.visible = false
        },
        toggle() {
            this.visible = !this.visible
        },
        onColorWeak(checked) {
            this.$store.commit('TOGGLE_WEAK', checked)
            updateColorWeak(checked)
        },
        onMultiTab(checked) {
            this.$store.commit('TOGGLE_MULTI_TAB', checked)
            window.location.reload()
        },
        handleMenuTheme(theme) {
            this.$store.commit('TOGGLE_THEME', theme)
        },
        changeColor(color) {
            if (this.primaryColor !== color) {
                this.$store.commit('TOGGLE_COLOR', color)
                updateTheme(color)
            }
        }
    }
}
</script>

<style lang="less" scoped>
.setting-drawer-index-content {
    .setting-drawer-index-blockChecbox {
        display: flex;

        .setting-drawer-index-item {
            margin-right: 16px;
            position: relative;
            border-radius: 4px;
            cursor: pointer;

            img {
                width: 48px;
            }

            .setting-drawer-index-selectIcon {
                position: absolute;
                top: 0;
                right: 0;
                width: 100%;
                padding-top: 15px;
                padding-left: 24px;
                height: 100%;
                color: #1890ff;
                font-size: 14px;
                font-weight: 700;
            }
        }
    }
    .setting-drawer-theme-color-colorBlock {
        width: 20px;
        height: 20px;
        border-radius: 2px;
        float: left;
        cursor: pointer;
        margin-right: 8px;
        padding-left: 0px;
        padding-right: 0px;
        text-align: center;
        color: #fff;
        font-weight: 700;

        i {
            font-size: 14px;
        }
    }
}

.setting-drawer-index-handle {
    position: absolute;
    top: 240px;
    background: #1890ff;
    width: 40px;
    height: 40px;
    right: 275px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    pointer-events: auto;
    z-index: 1001;
    text-align: center;
    font-size: 16px;
    border-radius: 40px 0 0 40px;

    i {
        color: rgb(255, 255, 255);
        font-size: 20px; //20
    }
}
</style>
