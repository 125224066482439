/*
 * @Autor: dingxiaolin
 * @Date: 2021-09-23 07:31:51
 * @LastEditors: dingxiaolin
 * @LastEditTime: 2022-07-07 16:40:13
 */
/**
 * 枚举--设备类型列表
 */
export const enumDeviceType = [
    { code: 0, value: '客流管理平台1代' },
    { code: 1, value: '客流管理平台2代(4G版)' },
    { code: 2, value: '客流管理平台2代(非4G版)' }
]

export const enumAreaType = [
    { code: 0, value: '船舶' },
    { code: 1, value: '码头' },
    { code: 2, value: '加油站' }
]
/**根据监控对象类型查询名称 */
export const getAreaTypeName = (areaType) => {
    return enumAreaType.find(item => item.code == areaType).value
}
/**过滤原因 */
export const ignoreType = [
    { code: '0', value: '告警关闭' },
    { code: '1', value: '停船' },
    { code: '2', value: '同种误报' }
]
