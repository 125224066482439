/*
 * @Autor: dingxiaolin
 * @Date: 2021-09-29 10:04:34
 * @LastEditors: dingxiaolin
 * @LastEditTime: 2022-06-01 09:50:27
 */
const alarm = {
    state: {
        alarmList: [],
    },
    mutations: {
        updateList: (state, payload) => {
            state.alarmList = payload
        },
    },
    actions: {}
}

export default alarm
