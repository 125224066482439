/*
 * @Autor: dingxiaolin
 * @Date: 2021-09-29 10:04:34
 * @LastEditors: dingxiaolin
 * @LastEditTime: 2021-10-22 13:39:40
 */
export const ACCESS_TOKEN = 'Access-Token'
export const USER_INFO = 'USER_INFO'
export const PW_LEVEL = 'PW_LEVEL'
export const ALL_APPS_MENU = 'ALL_APPS_MENU'
export const DICT_TYPE_TREE_DATA = 'DICT_TYPE_TREE_DATA'
export const COLLAPSED = 'COLLAPSED'

export const CONTENT_WIDTH_TYPE = {
    Fluid: 'Fluid',
    Fixed: 'Fixed'
}
