<template>
    <a-modal
        :title="title"
        centered
        :width="700"
        :visible="visible"
        :confirmLoading="confirmLoading"
        @ok="handleOk"
        @cancel="handleCancel"
        destroyOnClose
    >
        <a-form-model ref="form" :model="form" :rules="formRules" :label-col="labelCol" :wrapper-col="wrapperCol">
            <a-form-model-item label="所属机构" prop="tenantId" :rules="formRules.change">
                <DeptTree :all="false" v-model="form.tenantId" @change="deptChange" :disabled="title == '编辑车辆'"></DeptTree>
            </a-form-model-item>
            <a-form-model-item label="所属线路" prop="lineSubId" :rules="formRules.blur">
                <a-select
                    showSearch
                    option-filter-prop="children"
                    :filter-option="filterOption"
                    v-model="form.groupLineId"
                    @change="lineChange"
                    placeholder="请选择所属线路"
                >
                    <a-select-option v-for="(item, index) in lineList" :key="index" :value="item.groupLineId">{{ item.lineSubName }}</a-select-option>
                </a-select>
            </a-form-model-item>
            <a-form-model-item label="车牌号">
                <a-input v-model="form.busNum" placeholder="请输入车牌号" :maxLength="20" />
            </a-form-model-item>
            <a-form-model-item label="车架号" prop="busTag" :rules="formRules.blur">
                <a-input v-model="form.busTag" placeholder="请输入车架号" :maxLength="20" />
            </a-form-model-item>
            <a-form-model-item label="车型" prop="busModelId" :rules="formRules.change">
                <a-select v-model="form.busModelId" @change="busModelChange" placeholder="请选择车型">
                    <a-select-option v-for="(item, index) in busTypeList" :key="index" :value="item.id">{{ item.busModelName }}</a-select-option>
                </a-select>
            </a-form-model-item>
            <a-form-model-item label="门型" v-if="form.busDoorInfo">
                <span>{{ form.busDoorInfo }}</span>
            </a-form-model-item>
            <a-form-model-item label="备注">
                <a-textarea v-model="form.memo" placeholder="请输入备注" :auto-size="{ minRows: 3, maxRows: 5 }" :maxLength="100" />
            </a-form-model-item>
        </a-form-model>
    </a-modal>
</template>

<script>
import { formRules } from '@/utils/validator'
import { DeptTree } from '@/components'
import { busTypeInfoList, saveBus, updateBus, busModelDoorLink } from '@/api/cars/index'
import { getLineList } from '@/api/alarm/index'

export default {
    data() {
        return {
            shipBrandList: [],
            lineList: [],
            title: '添加车辆',
            formRules: formRules,
            labelCol: {
                xs: { span: 24 },
                sm: { span: 5 }
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 15 }
            },
            fetching: false,
            visible: false,
            confirmLoading: false,
            detailData: {},
            busTypeList: [],
            /**门型 */
            busDoorTypeName: '',
            form: {
                id: '',
                tenantId: '', //所属机构ID
                tenantName: '', //所属机构名称
                busNum: '', //车牌号
                busTag: '', //车架
                busModelId: '', //车型ID
                busDoorInfo: '', //门型,
                lineMainId: '', //主线路ID
                groupLineId: undefined, //lineSubId+tenantId
                lineMainName: '', //主线路名称
                lineSubId: '', //子线路ID
                lineSubName: undefined, //子线路名称
                memo: ''
            }
        }
    },
    props: {},
    components: {
        DeptTree
    },
    mounted() {
        busTypeInfoList({
            size: '-1', //每页数量
            descs: 'bus_model_name' //排序字段
        }).then(res => {
            this.busTypeList = res.records
        })
    },
    methods: {
        filterOption(input, option) {
            return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
        },
        lineChange(id) {
            let data = this.lineList.filter(v => v.groupLineId == id)
            if (data.length > 0) {
                this.form.lineMainId = data[0].lineMainId
                this.form.lineSubId = data[0].lineSubId
                this.form.lineMainName = data[0].lineMainName
                this.form.lineSubName = data[0].lineSubName
                this.form.tenantId = data[0].tenantId
                this.form.tenantName = data[0].tenantName
            }
        },
        // 初始化方法
        async show(record) {
            this.visible = true

            if (record) {
                this.form = Object.assign(this.form, record)
                // 车型下拉
                busTypeInfoList({
                    size: '-1', //每页数量
                    descs: 'bus_model_name' //排序字段
                }).then(res => {
                    this.busTypeList = res.records
                })
                // 线路下拉
                getLineList({
                    descs: 'create_time',
                    size: -1,
                    tenantId_equal: this.form.tenantId
                }).then(res => {
                    this.lineList = res.records
                })
                this.title = '编辑车辆'
                this.form.groupLineId = this.form.lineSubId && this.form.tenantId ? this.form.lineSubId + this.form.tenantId : ''
            } else {
                this.title = '添加车辆'
                this.form = {
                    id: '',
                    tenantId: '', //所属机构ID
                    tenantName: '', //所属机构名称
                    busNum: '', //车牌号
                    busTag: '', //车架
                    busModelId: '', //车型ID
                    busDoorInfo: '', //门型,
                    lineMainId: '', //主线路ID
                    lineMainName: '', //主线路名称
                    groupLineId: undefined, //lineSubId+tenantId
                    lineSubId: '', //子线路ID
                    lineSubName: undefined, //子线路名称
                    memo: ''
                }
            }
        },
        handleOk() {
            this.confirmLoading = true
            this.$refs.form.validate(valid => {
                if (valid) {
                    // this.form.goodsIds = this.form.goodsIdsArray.join(',')
                    if (this.title == '添加车辆') {
                        saveBus(this.form)
                            .then(res => {
                                this.$message.success('新增车辆成功')
                                this.visible = false
                                this.confirmLoading = false
                                this.$emit('ok')
                            })
                            .catch(() => {
                                this.confirmLoading = false
                            })
                    } else {
                        updateBus(this.form)
                            .then(res => {
                                this.$message.success('编辑车辆成功')
                                this.visible = false
                                this.confirmLoading = false
                                this.$emit('ok')
                            })
                            .catch(() => {
                                this.confirmLoading = false
                            })
                    }
                } else {
                    this.confirmLoading = false
                }
            })
        },
        handleCancel() {
            this.visible = false
        },
        deptChange(v) {
            this.form.tenantId = v.value
            this.form.tenantName = v.title
            this.form.lineMainId = ''
            this.form.lineMainName = ''
            this.form.lineSubId = ''
            this.form.lineSubName = undefined
            getLineList({ descs: 'create_time', size: -1, tenantId_equal: this.form.tenantId }).then(res => {
                this.lineList = res.records
            })
        },
        busModelChange(value) {
            busModelDoorLink({
                size: '-1', //每页数量
                ascs: 'bus_door_tag', //排序字段
                busModelId_equal: value //车型ID
            }).then(res => {
                this.form.busDoorInfo = res.records ? res.records.map(v => v.busDoorTypeName).join(',') : ''
                console.log('test', this.form.busDoorInfo)
            })
        }
    }
}
</script>
<style scoped lang="less">
.swiper-container {
    height: 280px;
}
.handle .ant-form-item {
    margin-bottom: 10px;
}
.swiper-pagination-fraction {
    top: 10px;
    bottom: unset;
    color: #007aff;
}
.swiper-container-horizontal > .swiper-pagination-bullets {
    top: 10px;
    bottom: unset;
}
</style>
