<!--
 * @Autor: dingxiaolin
 * @Date: 2021-10-13 16:10:53
 * @LastEditors: dingxiaolin
 * @LastEditTime: 2021-12-22 13:53:17
-->
<template>
    <div id="axisChart" ref="axisChart" class="all_center">
        <a-empty v-if="empty" :image="simpleImage" style="color: #fff" />
    </div>
</template>

<script>
import * as echarts from 'echarts'
import { Empty } from 'ant-design-vue'
export default {
    name: 'axisChart',
    props: {
        /** 图表数据 */
        data: {
            type: Array,
            default: () => {
                return []
            }
        },
        category: {
            type: Array,
            default: () => {
                return []
            }
        }
    },
    data() {
        return {}
    },
    computed: {
        empty() {
            return this.data.length > 0 ? false : true
        }
    },
    watch: {
        data: {
            handler() {
                this.drawChart(this.data, this.category)
            },
            deep: true
        }
    },

    created() {
        this.simpleImage = Empty.PRESENTED_IMAGE_SIMPLE
    },
    methods: {
        /** 绘制图表 */
        drawChart(data, category) {
            var axisChart = echarts.init(this.$refs.axisChart)
            window.addEventListener('resize', () => {
                setTimeout(() => {
                    axisChart.resize()
                }, 200)
            })
            var option = {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                    { offset: 1, color: '#0cb2b7' },
                    { offset: 0.4, color: '#0e79fe' },
                    { offset: 0, color: '#3f3c8b' }
                ]), // 渐变色
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'shadow'
                    }
                },
                grid: {
                    top: '12%',
                    left: '3%',
                    right: '4%',
                    bottom: '3%',
                    containLabel: true,
                    borderColor: '#f00'
                },
                xAxis: [
                    {
                        type: 'category',
                        data: category, // 接口获取的类型
                        axisTick: {
                            alignWithLabel: true
                        },
                        axisLabel: {
                            textStyle: {
                                color: '#fff'
                            },
                           
                        }
                    }
                ],
                yAxis: [
                    {
                        type: 'value',
                        splitLine: {
                            show: true,
                            lineStyle: {
                                color: ['#315070'],
                                width: 1,
                                type: 'solid'
                            }
                        },
                        axisLabel: {
                            textStyle: {
                                color: '#fff'
                            },
                           
                        }
                    }
                ],
                series: [
                    {
                        name: '告警次数',
                        type: 'bar',
                        showBackground: true,
                        barWidth: '20%',
                        data: data, // 接口获取的数据
                        itemStyle: {
                            normal: {
                                barBorderRadius: [6, 6, 0, 0]
                            }
                        }
                    }
                ]
            }
            axisChart.setOption(option)
            setTimeout(() => {
                axisChart.resize()
            }, 0)
        }
    }
}
</script>

<style lang="less" scoped>
#axisChart {
    width: 100%;
    height: 100%;
}
</style>
