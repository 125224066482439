<template>
    <a-modal
        :title="title"
        centered
        :width="700"
        :visible="visible"
        :confirmLoading="confirmLoading"
        @ok="handleOk"
        @cancel="handleCancel"
        destroyOnClose
    >
        <a-form-model ref="form" :model="form" :rules="formRules" :label-col="labelCol" :wrapper-col="wrapperCol">
            <a-form-model-item label="区域名称" prop="areaName" :rules="formRules.blur">
                <a-input v-model="form.areaName" placeholder="请输入区域名称" />
            </a-form-model-item>
            <a-form-model-item label="区域类型" prop="areaType" :rules="formRules.blur">
              {{form.areaType}}
                <a-select v-model="form.areaType" @change="handleAreaTypeChange" placeholder="请选择区域类型" show-search option-filter-prop="children">
                    <a-select-option v-for="item in areaTypeList" :key="item.code" :value="item.value"> {{ item.value }} </a-select-option>
                </a-select>
            </a-form-model-item>
            <a-form-model-item label="区域地址" style="margin-bottom: 0" required>
                <a-row>
                    <a-col :span="8">
                        <a-form-model-item style="margin-bottom: 0" prop="province" :rules="formRules.change">
                            <a-select
                                v-model="form.province"
                                @change="handleProvinceChange"
                                placeholder="请选择省"
                                show-search
                                option-filter-prop="children"
                            >
                                <a-select-option v-for="item in province" :key="item.code" :value="item.code">
                                    {{ item.name }}
                                </a-select-option>
                            </a-select>
                        </a-form-model-item>
                    </a-col>
                    <a-col :span="8">
                        <a-form-model-item style="margin-bottom: 0" prop="city" :rules="formRules.change">
                            <a-select v-model="form.city" @change="handleCityChange" placeholder="请选择市" show-search option-filter-prop="children">
                                <a-select-option v-for="item in city" :key="item.code" :value="item.code"> {{ item.name }} </a-select-option>
                            </a-select>
                        </a-form-model-item>
                    </a-col>
                    <a-col :span="8">
                        <a-form-model-item style="margin-bottom: 0" prop="county" :rules="formRules.change">
                            <a-select
                                v-model="form.county"
                                @change="handlecountyChange"
                                placeholder="请选择区"
                                show-search
                                option-filter-prop="children"
                            >
                                <a-select-option v-for="item in county" :key="item.code" :value="item.code"> {{ item.name }} </a-select-option>
                            </a-select>
                        </a-form-model-item>
                    </a-col>
                </a-row>
            </a-form-model-item>
            <a-form-model-item :wrapperCol="{ span: 15, offset: 5 }" prop="areaAddress" :rules="formRules.blur">
                <a-input v-model="form.areaAddress" placeholder="请输入详细地址" />
                <div style="width: 100%; height: 250px; background: #ddd">
                    <AMap ref="child" style="width: 100%; height: 100%" @onSelect="onSelect" @onClick="onClick" />
                </div>
            </a-form-model-item>
            <a-form-model-item label="所属机构" prop="tenantId" :rules="formRules.blur">
                <DeptTree :all="false" v-model="form.tenantId" @change="handleTanentChange" :disabled="!!this.form.id" />
            </a-form-model-item>
            <a-form-model-item label="联系人" prop="linkPeople" :rules="formRules.blur">
                <a-input v-model="form.linkPeople" placeholder="请输入联系人" />
            </a-form-model-item>
            <a-form-model-item label="联系电话" prop="linkPhone" :rules="formRules.phoneFormate">
                <a-input v-model="form.linkPhone" placeholder="请输入联系电话" />
            </a-form-model-item>
            <a-form-model-item label="备注" prop="memo">
                <a-textarea v-model="form.memo" placeholder="请输入备注" :auto-size="{ minRows: 3, maxRows: 5 }" />
            </a-form-model-item>
        </a-form-model>
    </a-modal>
</template>

<script>
import { saveAreaInfo, getAreaInfoDetail, updateAreaInfo } from '@/api/alarm/index'
import { getRegion, wharfBaseInfoList } from '@/api/alarm'
import { formRules } from '@/utils/validator'
import AMap from '@/components/AMap'
import DeptTree from '@/components/DeptTree'
import { enumAreaType } from '@/utils/enum'
import { sysDictTypeDropDown } from '@/api/comm/index'
export default {
    data() {
        return {
            title: '添加区域',
            formRules: formRules,
            index: 0,
            totalURL: 0,
            labelCol: {
                xs: { span: 24 },
                sm: { span: 5 }
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 15 }
            },

            visible: false,
            confirmLoading: false,
            detailData: {},
            form: {
                /** 码头id */
                id: '',
                /** 机构名称 */
                tenantName: '',
                /**码头ID(高德基础码头id) */
                id: undefined,
                /**区域名称 */
                areaName: '',
                /**省 */
                province: undefined,
                /** 省份name */
                provinceName: '',
                /** 市 */
                city: undefined,
                /** 市name*/
                cityName: '',
                /** 区 */
                county: undefined,
                /** 区name */
                countyName: '',
                /** 详细地址 */
                areaAddress: '',
                /** 经度 */
                areaAutoNaviLat: '',
                /** 纬度 */
                areaAutoNaviLng: '',
                /** 机构id */
                tenantId: '',
                /** 联系人 */
                linkPeople: '',
                /** 联系电话 */
                linkPhone: '',
                /** 备注 */
                memo: '',
                /** 版本号 */
                version: '',
                /**区域类型名称 */
                areaTypeName: ''
            },
            /** 省 */
            province: [],
            /** 市 */
            city: [],
            /** 区 */
            county: [],
            areaTypeList: []
        }
    },
    props: {
        sceneList: {
            type: Array,
            default: []
        },
        levelList: {
            type: Array,
            default: []
        }
    },
    components: {
        AMap,
        DeptTree,
        VNodes: {
            functional: true,
            render: (h, ctx) => ctx.props.vnodes
        }
    },
    async mounted() {
        await this.getRegionList().then(res => {
            this.province = res
        })
        this.getDict()
    },

    methods: {
        getDict(){
          sysDictTypeDropDown({ code: 'areaType',areaType_notequal:'2' }).then(res => {
              this.areaTypeList = res
          })
        },
         
        // 初始化方法
        show(record) {
            this.visible = true
            if (record) {
                this.title = '修改区域'
                this.getDetail(record)
            } else {
                this.title = '添加区域'
                this.form.id = ''
                this.form.warfId = undefined
                this.$forceUpdate()
            }
        },
        /** 获取区域 */
        async getRegionList(code) {
            return getRegion({ code: code })
        },
        async getDetail(record) {
            this.detailData = await getAreaInfoDetail({ id: record.id })
            // 渲染地区下拉框
            this.detailData.province && this.getRegionList(this.detailData.province).then(res => (this.city = res))
            this.detailData.city && this.getRegionList(this.detailData.city).then(res => (this.county = res))
            this.detailData.areaType = Number(this.detailData.areaType)
            // 渲染地图中的marker
            this.detailData.areaAutoNaviLng &&
                this.detailData.areaAutoNaviLat &&
                this.$refs.child.initMarker(this.detailData.areaAutoNaviLng, this.detailData.areaAutoNaviLat)
            this.form = this.detailData
        },
        handleOk() {
            this.confirmLoading = true
            this.$refs.form.validate(valid => {
                if (valid) {
                    if (this.form.areaAutoNaviLat && this.form.areaAutoNaviLng) {
                        // 有经纬度直接提交
                        this.sendAddForm(this.form)
                    } else {
                        // 提交时没有经纬度
                        // 地区code转中文
                        this.form.provinceName = this.province.find(item => item.code === this.form.province).name
                        this.form.cityName = this.city.find(item => item.code === this.form.city).name
                        this.form.countyName = this.county.find(item => item.code === this.form.county).name
                        this.$refs.child
                            .onSearch({
                                address: this.form.areaAddress,
                                provinceName: this.form.provinceName,
                                cityName: this.form.cityName,
                                countyName: this.form.countyName,
                                county: this.form.county
                            })
                            .then(res => {
                                console.log(res)
                                this.sendAddForm({
                                    ...this.form,
                                    areaAutoNaviLat: res[1],
                                    areaAutoNaviLng: res[0]
                                })
                            })
                        this.confirmLoading = false
                    }
                } else {
                    this.confirmLoading = false
                }
            })
        },
        /** 提交事件 */
        sendAddForm(payload) {
            if (payload.id) {
                // 修改
                updateAreaInfo(payload)
                    .then(res => {
                        // 修改
                        this.$message.success('修改区域成功')
                        this.handleCancel()
                        this.$emit('ok')
                    })
                    .finally(() => {
                        this.confirmLoading = false
                    })
            } else {
                // 新增
                saveAreaInfo(payload)
                    .then(res => {
                        // 新增
                        this.$message.success('添加区域成功')
                        this.handleCancel()
                        this.$emit('ok')
                    })
                    .finally(() => {
                        this.confirmLoading = false
                    })
            }
        },
        handleCancel() {
            this.visible = false
            this.city = this.county = []
            this.resetFrom()
        },

        /** 重置表单 */
        resetFrom() {
            this.$refs.form.resetFields()
        },

        /** 区域类型选择 */
        handleAreaTypeChange(v) {
            this.form.areaTypeName = this.areaTypeList.find(item => item.code == v).value
        },

        /** 省级级切换 */
        handleProvinceChange(value, option) {
            console.log('province', value, option)
            this.city = this.county = []
            this.form.city = this.form.county = this.form.areaAddress = ''
            option && this.clearAddress()
            this.getRegionList(value).then(res => {
                this.city = res
            })
        },
        /** 切换市级 */
        handleCityChange(value, option) {
            console.log('city', value)
            this.county = []
            this.form.county = this.form.areaAddress = ''
            option && this.clearAddress()
            this.getRegionList(value).then(res => {
                this.county = res
            })
        },
        /** 切换县 */
        handlecountyChange(value, option) {
            option && this.clearAddress()
        },
        /** 地图子组件回调 */
        onSelect({ address, city, county, lat, lng, province }) {
            this.handleProvinceChange(province)
            this.handleCityChange(city)
            this.form = {
                ...this.form,
                province,
                city,
                county,
                areaAddress: address,
                areaAutoNaviLat: lat,
                areaAutoNaviLng: lng
            }
        },
        /** 地图点击事件 */
        onClick({ address, lng, lat, province, city, county }) {
            console.log(address, city, county, lat, lng, province)
            this.handleProvinceChange(province)
            this.handleCityChange(city)
            this.form = {
                ...this.form,
                areaAddress: address,
                /** 经度 */
                areaAutoNaviLat: lat,
                /** 纬度 */
                areaAutoNaviLng: lng,
                province,
                city,
                county
            }
        },
        /** 地图中点击下拉 */
        handleTanentChange(option) {
            if (option.value) {
                this.form.tenantName = option.title
            }
        },
        /** 更改省市区清空地址和地图中的选择 */
        clearAddress() {
            this.$refs.child.clear()
            this.form.areaAddress = ''
            this.form.areaAutoNaviLat = ''
            this.form.areaAutoNaviLng = ''
        }
    }
}
</script>
<style scoped lang="less">
.ant-input-group .ant-select {
    width: 33.3%;
}
</style>
