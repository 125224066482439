<template>
<div>
    <a-modal
        :title="title"
        centered
        :width="700"
        :visible="visible"
        :confirmLoading="confirmLoading"
        @ok="handleOk"
        @cancel="handleCancel"
        destroyOnClose
    >
        <a-form-model ref="form" :model="form" :rules="formRules" :label-col="labelCol" :wrapper-col="wrapperCol" >
           
            <a-form-model-item label="通知类型" prop="noticeType" :rules="formRules.blur" >
                <a-select v-model="form.noticeType" style="width:100px" :disabled="formDisabled">
                    <a-select-option value="">请选择</a-select-option>
                    <a-select-option v-for="(item, index) in noticeTypeList" :key="index" :value="item.code">{{ item.value }}</a-select-option>
                </a-select>
            </a-form-model-item>
            <a-form-model-item label="通知标题" prop="noticeTitle" :rules="formRules.blur">
                <a-input v-model="form.noticeTitle"  placeholder="请输入通知标题" :disabled="formDisabled"></a-input>
            </a-form-model-item>
            <a-form-model-item label="通知内容" prop="noticeContent" :rules="formRules.blur">
                <a-textarea placeholder="请输入通知内容" :rows="2"  v-model="form.noticeContent" :disabled="formDisabled"/>
            </a-form-model-item>
            <a-form-model-item label="通知对象" prop="noticePeopleRule" :rules="formRules.change">
                <a-radio-group v-model="form.noticePeopleRule" :disabled="formDisabled">
                  <a-radio  value="0"> 所有单位</a-radio>
                  <a-radio  value="1"> 指定单位</a-radio>
                </a-radio-group>
                <a v-if="form.noticePeopleRule === '1' || formDisabled" 
                @click="$refs.orgList2.edit(choosedOrgList),getOrgList()">请选择指定单位</a>
                <div v-if="choosedOrgList.length" class="choosedOrgList">
                    <a-tag v-for="item in choosedOrgList" :key="item.tenantId">{{item.name}}</a-tag>
                </div>
            </a-form-model-item>
            <a-form-model-item label="发布时间"  >
                <a-radio-group v-model="form.noticeTimeRule"  @change="noticeTimeRuleChange" :disabled="formDisabled">
                  <a-radio  value="0"> 立即发布</a-radio>
                  <a-radio  value="1"> 定时发布</a-radio>
                </a-radio-group>
            </a-form-model-item>
            <a-form-model-item label="选择发布时间"  v-if="form.noticeTimeRule === '1'">
              <a-date-picker 
              format="YYYY-MM-DD HH:mm:ss" 
              v-model="form.noticeTime"
              :disabled-date="disabledDate"
              :show-time="{ defaultValue: moment('00:00:00', 'HH:mm:ss') }"
              placeholder="选择时间" 
              @change="dateChange" :disabled="formDisabled" />
            </a-form-model-item>
             <a-form-model-item label="通知状态"  v-if="record.id">
               <a-tag color="green" v-if="form.noticeStatus == 1">发送成功</a-tag>
               <a-tag color="orange" v-if="form.noticeStatus == 0">未发送</a-tag>
            </a-form-model-item>
        </a-form-model>
    </a-modal>
    <orgList ref="orgList2"  @ok="handleChoose" :orgLists="orgLists" @getOrgList="getOrgList"></orgList>
  </div>
</template>

<script>
import {saveNoticeConfig, updateNoticeConfig,noticeConfigdetail} from '@/api/system/notice.js'
import { getOrgPage } from '@/api/system/org'
import { formRules } from '@/utils/validator'
import orgList from "@/components/orgList";
import moment from 'moment';
export default {
    data() {
        return {
            moment,
            choosedOrgList:[],
            orgLists:[],
            smsArea:'',
            emailArea:'',
            timeUnit:[{name:'分',code:'0'},{name:'小时',code:'1'}],
            title: '新增告警模型',
            smsList:[],
            emailList:[],
            formRules: formRules,
            emailUserId:'',
            index: 0,
            totalURL: 0,
            labelCol: {
                xs: { span: 24 },
                sm: { span: 5 }
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 15 }
            },
            visible: false,
            popShow: false,
            confirmLoading: false,
            detailData: {},
            form: {
               noticeType:'',
               noticeTitle:'',
               noticeContent:'',
               noticePeopleRule:'',
               linkIds: '',
               noticeTimeRule:'',
               noticeTime:null,
            },
            isAdd: false,
            record:{},
            formDisabled:false
        }
    },
    props: {
        noticeTypeList: {
            type: Array,
            default: []
        },
    },
    // watch:{
    //   'form.noticeTimeRule'(val){
    //     if(val === '0'){
    //       form.noticeTime = ''
    //     }
    //   }
    // },
    components: {
      orgList
    },
    mounted(){
      
    },
    methods: {
        noticeTimeRuleChange(val){
          if(val === '0'){
            this.form.noticeTime = ''
          }
        },
        dateChange(val,time){
          this.form.noticeTime = time
        },
        disabledDate(current) {
          
          // let end = moment().format('YYYY-MM-DD HH:mm:ss')
          // let start = moment(end).subtract(5, "minute").format("YYYY-MM-DD HH:mm:ss")
          // return current && current >  moment().format('YYYY-MM-DD HH:mm')
          return current && current <= moment().add(1, 'minutes')
        },
        async edit(record) {
            this.record = {...record}
            this.form = this.$options.data().form // 重置form表单
            this.choosedOrgList = []
            console.log(record,'record.id');
            this.visible = true
            if(record && !record.noticeStatus){
              this.title = '编辑通知'
              this.getNoticedetail(record.id)
              this.form = {...this.form,...record}
            }else if(record && record.noticeStatus){
              this.title = '查看通知'
              this.getNoticedetail(record.id)
              this.form = {...this.form,...record}
            }else{
              this.title = '添加通知'
            }
            if(this.record && this.record.noticeStatus === '1'){
              this.formDisabled = true
            }else{
               this.formDisabled = false
            }
            
        },
        transferList(str){
          let ids = []
          let newArr = []
          if(str){
            ids = str.split( ',' )
          }
          console.log(ids,'ids',this.orgLists);
          // debugger
          this.orgLists.map(item=>{
             ids.map(i=>{
                if(i == item.id){
                    newArr.push(item)
                }
             })
          })
          return newArr
        },
        async getNoticedetail(id) {
            noticeConfigdetail({ id:id }).then(res=>{
                let linkIds = res.data.noticeConfigLinkVOList
                linkIds.map(item=>{
                  this.choosedOrgList.push({
                    'tenantId':item.linkId,
                    'name':item.linkName
                  })
                })
            })
        },
        /** 获取机构列表 */
        getOrgList(key) {
          return new Promise((resolve, reject) => {
            getOrgPage({
                pageNo: 1,
                pageSize: 5000,
                descs: 'create_time',
                name: key,
                // venIdA:this.$store.state.user.tenant.tenantId
            }).then(res => {
                this.orgLists = res.data.rows
                resolve(this.orgLists)
            })
          })
        },
        choosePeople(val){
          let obj = {
            fromEmail: val,
            smsList: this.smsList,
            emailList: this.emailList
          }
          this.$refs.userlist.edit(obj)
        },
        handleChoose(list){ // 选择的机构列表
          this.choosedOrgList = list
        },
        handleClose(name){
          let list = this.smsList.filter(tag => tag.name !== name);
          this.smsList = [...list]
        },
        handleOk() {
            this.confirmLoading = true
            this.choosedOrgList.map(item=>{
              
              if(this.form.linkIds){
                this.form.linkIds = `${this.form.linkIds},${item.tenantId}`
              }else{
                this.form.linkIds = item.tenantId
              }
            })
            console.log(this.form.linkIds,this.choosedOrgList);
            this.$refs.form.validate(valid => {
                if (valid) {
                     if(this.record?.id){ //编辑
                        updateNoticeConfig( {...this.form} )
                          .then(res => {
                              if(res.success){
                                  this.$message.success(res.msg)
                                  this.visible = false
                                  this.confirmLoading = false
                                  this.$emit('ok')
                                  this.$refs.form.resetFields()
                              }else{
                                this.$message.error(res.msg)
                              }
                          })
                          .catch(() => {
                              this.confirmLoading = false
                          })
                     }else{ // 新增
                        saveNoticeConfig( {...this.form} )
                          .then(res => {
                              if(res.success){
                                  this.$message.success(res.msg)
                                  this.visible = false
                                  this.confirmLoading = false
                                  this.$emit('ok')
                                  this.$refs.form.resetFields()
                              }else{
                                this.$message.error(res.msg)
                              }
                          })
                          .catch(() => {
                              this.confirmLoading = false
                          })
                     }
                      
                    
                } else {
                    this.confirmLoading = false
                }
            })
        },
        handleCancel() {
            this.visible = false
        }
    }
}
</script>
<style scoped lang="less">
.choosedOrgList{
    border: dashed 1px #ddd;
    padding: 5px 10px;
}
</style>
