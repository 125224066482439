/*
 * @Autor: 程少波
 * @Date: 2021-11-25 10:08:25
 * @LastEditors: dingxiaolin
 * @LastEditTime: 2022-10-22 09:11:08
 */
/**
 * 仪表盘接口
 */
import { Post, Get, Patch, Put, Delete, Download, ax } from '@/utils/newRequest'
import config from '@/config/config.js'

/**
 * 获取数据概览
 */
export function alarmDataOverviewStatistics(body) {
    return Post(config.namespace + '/statisticsEnterprise/alarmDataOverviewStatistics', body)
}
/**
 * 获取实时告警通知
 */
export function alarmRealtimeNoticesStatistics(body) {
    return Post(config.namespace + '/statisticsEnterprise/alarmRealtimeNoticesStatistics', body)
}
/**
 * 获取重要待处理告警
 */
export function alarmUndisposedImportanceStatistics(body) {
    return Post(config.namespace + '/statisticsEnterprise/alarmUndisposedImportanceStatistics', body)
}
/**
 * 获取告警处理状态
 */
export function alarmHandleStatusStatistics(body) {
    return Post(config.namespace + '/statisticsEnterprise/alarmHandleStatusStatistics', body)
}
/**
 * 获取告警场景分布
 */
export function alarmDistributionScenarioStatistics(body) {
    return Post(config.namespace + '/statisticsEnterprise/alarmDistributionScenarioStatistics', body)
}
/**
 * 获取告警类型排行
 */
export function alarmTypRankingStatistics(body) {
    return Post(config.namespace + '/statisticsEnterprise/alarmTypRankingStatistics', body)
}
/**
 * 获取告警次数统计
 */
export function alarmTimesStatistics(body) {
    return Post(config.namespace + '/statisticsEnterprise/alarmTimesStatistics', body)
}
/**
 * 获取告警时段分布
 */
export function alarmHourStatistics(body) {
    return Post(config.namespace + '/statisticsEnterprise/alarmHourStatistics', body)
}
/**
 * 获取告警时段分布
 */
export function alarmConfirmTimeStatistics(body) {
    return Post(config.namespace + '/statisticsEnterprise/alarmConfirmTimeStatistics', body)
}
/**
 * 获取告警时段分布
 */
export function alarmDealTimeStatistics(body) {
    return Post(config.namespace + '/statisticsEnterprise/alarmDealTimeStatistics', body)
}
