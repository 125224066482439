<template>
    <div ref="chart" :style="{ height: '300px', overflow: 'hidden', position: 'relative' }"></div>
</template>

<script>
import * as echarts from 'echarts'
export default {
    props: {
        data: {
            type: Object,
            require: true
        },
        title: {
            type: String,
            default: ''
        },
        legend: {
            type: Array,
            default: () => {
                return []
            }
        },
        percent: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            myChart: null,
            option: null
        }
    },
    mounted() {
        setTimeout(() => {
            this.init()
            this.setOptions()
        }, 10)
    },
    methods: {
        init() {
            this.myChart = echarts.init(this.$refs.chart)
        },
        setOptions() {
            // let data = this.splitData(this.data)
            let data = this.data
            let xSeries =
                data &&
                (this.legend || []).map((item, index) => {
                    return {
                        name: item,
                        type: 'line',
                        data: this.calculateMA(index, this.data),
                        smooth: true,
                        lineStyle: {
                            normal: { opacity: 0.5 }
                        }
                    }
                })
            this.myChart.setOption(
                (this.option = {
                    title: {
                        show: true,
                        text: this.title,
                        textStyle: {
                            fontWeight: 'lighter'
                        }
                    },
                    animation: false,
                    legend: {
                        bottom: 0,
                        left: 'center',
                        data: this.legend
                    },
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                            type: 'cross'
                        },
                        backgroundColor: 'rgba(255, 255, 255, 0.8)',
                        position: function(pos, params, el, elRect, size) {
                            var obj = { top: 10 }
                            obj['left'] = pos[0] + 30
                            if (size.viewSize[0] - pos[0] < 300) {
                                obj['left'] = pos[0] - 300
                            }
                            return obj
                        },
                        extraCssText: 'width: 240px'
                    },
                    axisPointer: {
                        link: { xAxisIndex: 'all' },
                        label: {
                            backgroundColor: '#777'
                        }
                    },

                    grid: [
                        {
                            left: '4.2%',
                            right: '4.2%',
                            top: this.title ? 60 : 20,
                            bottom: '20%'
                        }
                    ],
                    xAxis: [
                        {
                            type: 'category',
                            data: data ? data.categoryData : [],
                            scale: true,
                            boundaryGap: false,
                            axisLine: { onZero: false },
                            splitLine: { show: false },
                            splitNumber: 5,
                            min: 'dataMin',
                            max: 'dataMax',
                            axisPointer: {
                                z: 100
                            }
                        }
                    ],
                    yAxis: [
                        {
                            type: 'value',
                            scale: true,
                            splitArea: {
                                show: true
                            }
                        }
                    ],
                    // dataZoom: [
                    //     {
                    //         type: 'inside',
                    //         xAxisIndex: 0,
                    //         start: 0,
                    //         end: 100
                    //     },
                    //     {
                    //         show: true,
                    //         xAxisIndex: 0,
                    //         type: 'slider',
                    //         bottom: '40',
                    //         start: 0,
                    //         end: 100
                    //     }
                    // ],
                    series: xSeries
                }),
                true
            )
            // 添加百分比
            if (this.percent) {
                this.option.yAxis[0].axisLabel = {
                    formatter: function(v) {
                        return (v * 100).toFixed(2) + '%'
                    } //纵坐标百分比
                }
                this.option.tooltip.formatter = function(params) {
                    let value = `<div> <div>${params[0].axisValueLabel}</div>`
                    for (let i = 0; i < params.length; i++) {
                        value += `<div>
                    ${params[i].marker}
                    ${params[i].seriesName}:${(params[i].value * 100).toFixed(2) + '%'}
                    </div>`
                    }
                    value += `</div>`
                    return value
                }
            }
            this.myChart.setOption(this.option)
        },

        calculateMA(dayCount, data) {
            var result = []
            for (var i = 0, len = data.values.length; i < len; i++) {
                result.push(data.values[i][dayCount])
            }
            return result
        }
    }
}
</script>

<style></style>
