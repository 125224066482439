<template>
    <a-card :bordered="false">
        <div class="table-page-search-wrapper">
            <a-form-model :label-col="labelCol" :wrapper-col="wrapperCol">
                <a-row :gutter="48">
                    <a-col :md="6" :sm="24">
                        <a-form-model-item label="所属机构">
                            <dept-tree v-model="queryParam.tenantId_equal" :all="false" @change="handleDeptChange" />
                        </a-form-model-item>
                    </a-col>
                    <a-col :md="6" :sm="24">
                        <a-form-model-item label="所属线路">
                            <line-select placeholder="请选择所属线路" mode="multiple" :lines="lines" :maxTagCount="2" v-model="lineSubId_in" @change="handleLine" />
                        </a-form-model-item>
                    </a-col>

                    <a-col :md="6" :sm="24">
                        <a-form-model-item label="选择时间">
                            <a-range-picker
                                v-model="queryDate"
                                format="YYYY-MM-DD"
                                @change="dateChange"
                                :disabled-date="disabledDate"
                                v-if="countType === 0"
                            />
                            <a-week-picker v-model="queryDate" @change="dateChange" :disabled-date="disabledDate" v-if="countType === 1" />
                            <a-month-picker v-model="queryDate" @change="dateChange" :disabled-date="disabledDate" v-if="countType === 2" />
                        </a-form-model-item>
                    </a-col>
                    <a-col :md="6" :sm="24">
                        <a-form-model-item label="统计方式">
                            <a-radio-group :options="options" v-model="countType" @change="queryParam.startType = countType" />
                        </a-form-model-item>
                    </a-col>
                    <a-col :md="6" :sm="24">
                        <a-form-model-item label="上下行">
                            <a-select
                                style="width: 100%"
                                placeholder="请选择上下行"
                                :filter-option="filterOption"
                                v-model="queryParam.lineType_equal"
                            >
                                <a-select-option v-for="(item, index) in upAndDown" :key="index" :value="item.code">{{ item.value }}</a-select-option>
                            </a-select>
                        </a-form-model-item>
                    </a-col>
                    <a-col :md="18" :sm="24" align="right">
                        <span class="table-page-search-submitButtons">
                            <a-button type="primary" @click="$refs.table.refresh()">查询</a-button>
                            <a-button style="margin-left: 8px" @click="handleExport">导出</a-button>
                        </span>
                    </a-col>
                </a-row>
            </a-form-model>
        </div>
        <n-table ref="table" :columns="columns" :data="loadData" :rowKey="record => record.uniqueNumber" :scrollToFirstRowOnChange="true">
            <template slot="footer">
                <div style="text-align:right">
                    <span>合计：上客人数{{ totalInNum }}</span> <span>下客人数{{ totalLeaveNum }}</span>
                </div>
            </template>
        </n-table>
    </a-card>
</template>

<script>
import { NTable, DeptTree, LineSelect } from '@/components'
import { linePassengerFlowTrends, getLineList, linePassengerFlowTrendsExport } from '@/api/alarm/index'
export default {
    components: {
        NTable,
        DeptTree,
        LineSelect
    },

    data() {
        return {
            labelCol: {
                sm: { span: 6 },
                xs: { span: 24 }
            },
            wrapperCol: {
                sm: { span: 18 },
                xs: { span: 24 }
            },
            queryDate: [this.$moment().subtract(1, 'days'), this.$moment().subtract(1, 'days')],
            // 查询参数
            queryParam: {
                uploadYearMonthDay_ge: this.$moment().subtract(1, 'days').format('YYYY-MM-DD'),
                uploadYearMonthDay_le: this.$moment().subtract(1, 'days').format('YYYY-MM-DD'),
                tenantId_equal: this.$store.state.user.tenant.tenantId == 0 ? '' : this.$store.state.user.tenant.tenantId, //部门名称
                lineSubId_in: [], //线路名称
                lineType_equal: '',
                startType: 0
            },
            lineSubId_in: [],
            totalInNum: '',
            totalLeaveNum: '',
            countType: 0,
            loadData: async p => {
                if (this.queryParam.tenantId_equal === '') {
                    this.$message.warning('请选择机构')
                    return
                }
                if (this.queryParam.uploadYearMonthDay_ge === '' || this.queryParam.uploadYearMonthDay_le === '') {
                    this.$message.warning('请选择时间')
                    return
                }
                return await linePassengerFlowTrends(
                    Object.assign(p, this.queryParam, { lineSubId_in: this.queryParam.lineSubId_in?.join(',') || '' })
                ).then(res => {
                    if (res.records.length > 0) {
                        const { totalInNum, totalLeaveNum } = res.records[0]
                        this.totalInNum = totalInNum ?? ''
                        this.totalLeaveNum = totalLeaveNum ?? ''
                    } else {
                        this.totalInNum = 0
                        this.totalLeaveNum = 0
                    }
                    return res
                })
            },
            // 表头
            columns: [
                {
                    title: '序号',
                    dataIndex: 'id',
                    key: 'id',
                    customRender: (text, record, index) => index + 1,
                    align: 'center',
                    width: '80px'
                },
                {
                    title: '所属机构',
                    dataIndex: 'tenantName',
                    key: 'tenantName'
                },
                {
                    title: '线路名称',
                    dataIndex: 'lineSubName',
                    key: 'lineSubName'
                },
                {
                    title: '车辆数',
                    dataIndex: 'carNum',
                    key: 'carNum'
                },

                {
                    title: '统计日期',
                    dataIndex: 'uploadYearMonthDay',
                    key: 'uploadYearMonthDay',
                    ellipsis: true
                },
                {
                    title: '上下行',
                    dataIndex: 'lineType',
                    key: 'lineType'
                },
                {
                    title: '上客人数',
                    dataIndex: 'inNum',
                    key: 'inNum'
                },
                {
                    title: '下客人数',
                    dataIndex: 'leaveNum',
                    key: 'leaveNum'
                }
            ],
            options: [
                { label: '日统计', value: 0 },
                { label: '周统计', value: 1 },
                { label: '月统计', value: 2 }
            ],
            upAndDown: [
                { code: '', value: '全部' },
                { code: 0, value: '上行' },
                { code: 1, value: '下行' },
                { code: 2, value: '环线' }
            ],
            /** 线路列表 */
            lines: [],
            tenantName: this.$store.state.user.tenant.tenantName
        }
    },
    watch: {
        countType(v) {
            switch (v) {
                case 0:
                    this.queryDate = [this.$moment().subtract(1, 'days'), this.$moment().subtract(1, 'days')]
                    this.queryParam.uploadYearMonthDay_ge = this.$moment()
                        .subtract(1, 'days')
                        .format('YYYY-MM-DD')
                    this.queryParam.uploadYearMonthDay_le = this.$moment()
                        .subtract(1, 'days')
                        .format('YYYY-MM-DD')
                    break
                case 1:
                    this.queryDate = this.$moment().subtract(1, 'W')
                    this.queryParam.uploadYearMonthDay_ge = this.$moment()
                        .subtract(1, 'W')
                        .startOf('W')
                        .format('YYYY-MM-DD')
                    this.queryParam.uploadYearMonthDay_le = this.$moment()
                        .subtract(1, 'W')
                        .endOf('W')
                        .format('YYYY-MM-DD')
                    break
                case 2:
                    this.queryDate = this.$moment().subtract(1, 'M')
                    this.queryParam.uploadYearMonthDay_ge = this.$moment()
                        .subtract(1, 'M')
                        .startOf('M')
                        .format('YYYY-MM-DD')
                    this.queryParam.uploadYearMonthDay_le = this.$moment()
                        .subtract(1, 'M')
                        .endOf('M')
                        .format('YYYY-MM-DD')
                    break
                default:
                    break
            }
            this.$refs.table.refresh()
        }
    },
    computed: {
        disabledDate() {
            switch (this.countType) {
                case 0:
                    return current => {
                        return current && current >= this.$moment().startOf('day')
                    }
                case 1:
                    return current => {
                        return (
                            current &&
                            current >
                                this.$moment()
                                    .subtract(1, 'W')
                                    .endOf('W')
                        )
                    }
                case 2:
                    return current => {
                        return current && current > this.$moment().startOf('M')
                    }
                default:
                    break
            }
        }
    },
    activated() {
        this.init()
    },

    methods: {
        init() {
            this.getLines()
        },
        handleLine(v, lineSubId_in) {
            this.queryParam.lineSubId_in = lineSubId_in
        },
        handleDeptChange({ value, title }) {
            this.getLines(value)
            this.queryParam.lineSubId_in = []
            this.tenantName = title
            this.lineSubId_in = []
        },
        filterOption(input, option) {
            return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
        },
        getLines(tenantId = this.$store.state.user.tenant.tenantId) {
            getLineList({
                size: '-1',
                descs: 'create_time', //排序字段
                tenantId_equal: tenantId //所属机构
            }).then(res => {
                this.lines = res.records
            })
        },
        dateChange(date, dateString) {
            switch (this.countType) {
                case 0:
                    this.queryParam.uploadYearMonthDay_ge = dateString[0] ? this.$moment(dateString[0]).format('YYYY-MM-DD') : ''
                    this.queryParam.uploadYearMonthDay_le = dateString[1] ? this.$moment(dateString[1]).format('YYYY-MM-DD') : ''
                    break
                case 1:
                    this.queryParam.uploadYearMonthDay_ge = date.startOf('W').format('YYYY-MM-DD')
                    this.queryParam.uploadYearMonthDay_le = date.endOf('W').format('YYYY-MM-DD')
                    break
                case 2:
                    this.queryParam.uploadYearMonthDay_ge = date.startOf('M').format('YYYY-MM-DD')
                    this.queryParam.uploadYearMonthDay_le = date.endOf('M').format('YYYY-MM-DD')
                    break

                default:
                    break
            }
        },

        /** 导出 */
        handleExport() {
            if (this.queryParam.tenantId_equal === '') {
                this.$message.warning('请选择机构')
                return
            }
            if (this.queryParam.uploadYearMonthDay_ge === '' || this.queryParam.uploadYearMonthDay_le === '') {
                this.$message.warning('请选择时间')
                return
            }
            const lineSubId_in = this.queryParam.lineSubId_in ? this.queryParam.lineSubId_in?.join(',') : ''
            linePassengerFlowTrendsExport(Object.assign({}, this.queryParam, { lineSubId_in, tenantName: this.tenantName }))
        }
    }
}
</script>

<style lang="less" scoped>
.alarmTypeName {
    border-radius: 20px;
    padding: 2px 8px;
    width: fit-content;
    background: rgba(250, 152, 164, 0.996078431372549);
    color: #fff;
    font-size: 12px;
}
/deep/ .ant-card {
    height: 100%;
}
/deep/ .ant-card-body {
    height: 100%;
}
/deep/ .ant-table-body {
    max-height: calc(((100vh - 331px) - 94px) - 0px) !important;
}
</style>
