<!--
 * @Autor: dingxiaolin
 * @Date: 2021-12-15 16:03:47
 * @LastEditors: dingxiaolin
 * @LastEditTime: 2021-12-17 08:59:24
-->
<script>
export default {
    name: 'RouteView',
    props: {
        keepAlive: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {}
    },
    render() {
        const {
            $route: { meta },
            $store: { state }
        } = this
        console.log(meta)
        const inKeep = (
            <keep-alive>
                <router-view />
            </keep-alive>
        )
        const notKeep = <router-view />
        return this.keepAlive || meta.cache ? inKeep : notKeep
    }
}
</script>
