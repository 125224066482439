<template>
  <a-modal
    title="对应关系编辑"
    centered
    :width="680"
    :maskClosable="false"
    :destroyOnClose="true"
    :visible="visible"
    :confirmLoading="confirmLoading"
    @ok="handleSubmit"
    @cancel="handleCancel"
  >
    <a-spin :spinning="formLoading">
      <a-form :form="form">
        <a-form-item style="display: none;" :labelCol="labelCol" :wrapperCol="wrapperCol" has-feedback>
          <a-input v-decorator="['id']" />
          <a-input v-decorator="['version']" />
          <a-input v-decorator="['poiType']" />
          <a-input v-decorator="['dataSource']" />
          <a-input v-decorator="['dataType']" />
          <a-input v-decorator="['dataCode']" />
          <a-input v-decorator="['dataName']" />
        </a-form-item>

        <a-form-item label="选择" :labelCol="labelCol" :wrapperCol="wrapperCol" has-feedback>
          <a-radio-group button-style="solid" v-model="selectedItems">
            <a-radio :style="radioStyle" v-for="(item, index) in groupCodeList" :key="index" :value="item.id">
              {{ item.value }}
            </a-radio>
          </a-radio-group>
          <!-- <a-select
            style="width: 100%"
            :value="selectedItems"
            @change="handleChange"
            placeholder="请选择"
            v-decorator="['groupCode', { rules: [{ required: true, message: '请选！' }] }]"
          >
            <a-select-option v-for="(item, index) in filteredOptions" :key="index" :value="item.code">{{
              item.value
            }}</a-select-option>
          </a-select> -->
        </a-form-item>
      </a-form>
    </a-spin>
  </a-modal>
</template>

<script>
import { dictLinkEdit } from '@/api/system/config'
import { sysDictTypeDropDown } from '@/api/system/dict'
export default {
  data() {
    return {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 15 }
      },
      radioStyle: {
        display: 'block',
        height: '30px',
        lineHeight: '30px'
      },
      visible: false,
      confirmLoading: false,
      formLoading: true,
      groupCodeList: [],
      selectedItems: '',
      editDisabled: false,
      form: this.$form.createForm(this)
    }
  },
  computed: {
    filteredOptions() {
      return this.groupCodeList.filter(o => !this.selectedItems.includes(o.code))
    }
  },
  methods: {
    // 初始化方法
    edit(record) {
      this.visible = true
      this.groupCodeList = []
      this.selectedItems = record.dictId ? record.dictId : ''
      setTimeout(() => {
        this.form.setFieldsValue({
          id: record.id,
          version: record.version,
          poiType: record.poiType,
          dataSource: record.dataSource,
          dataType: record.dataType,
          dataCode: record.dataCode,
          dataName: record.dataName
        })
      }, 100)
      if (record.poiType === '1' && record.dataType === '1') {
        this.sysDictTypeDropDown('scenic_type')
      }
      // else if (record.poiType === '1' && record.dataType === '2') {
      //   this.sysDictTypeDropDown('scenic_label')
      // }
      else if (record.poiType === '2' && record.dataType === '1') {
        this.sysDictTypeDropDown('room_service')
      } else if (record.poiType === '3' && record.dataType === '1') {
        this.sysDictTypeDropDown('food_type')
      } else if (record.poiType === '4' && record.dataType === '1') {
        this.sysDictTypeDropDown('pile_type')
      } else if (record.poiType === '5' && record.dataType === '1') {
        this.sysDictTypeDropDown('speed_service')
      }
      // else if (record.poiType === '6' && record.dataType === '1') {
      //   this.sysDictTypeDropDown('food_type')
      // }else if (record.poiType === '7' && record.dataType === '1') {
      //   this.sysDictTypeDropDown('food_type')
      // }else if (record.poiType === '8' && record.dataType === '1') {
      //   this.sysDictTypeDropDown('food_type')
      // }
    },

    handleChange(selectedItems) {
      console.log('selectedItems', selectedItems)
      // this.selectedItems = selectedItems
      this.groupCodeList.filter(o => {
        if (o.code === selectedItems) {
          this.selectedItems = o.value
        }
      })
    },
    /**
     * 获取所属分类
     */
    sysDictTypeDropDown(val) {
      sysDictTypeDropDown({ code: val }).then(res => {
        this.groupCodeList = res.data
        this.formLoading = false
      })
    },

    handleSubmit() {
      const {
        form: { validateFields }
      } = this
      this.confirmLoading = true
      validateFields((errors, values) => {
        if (!errors) {
          values.dictId = this.selectedItems
          this.groupCodeList.filter(o => {
            if (o.id === this.selectedItems) {
              values.dictName = o.value
            }
          })
          // console.log('selectedItems', this.selectedItems, values)
          dictLinkEdit(values)
            .then(res => {
              this.confirmLoading = false
              if (res.success) {
                this.$message.success('编辑成功')
                this.$emit('ok', values)
                this.handleCancel()
              } else {
                this.$message.error('编辑失败：' + res.msg)
              }
            })
            .finally(res => {
              this.confirmLoading = false
            })
        } else {
          this.confirmLoading = false
        }
      })
    },
    handleCancel() {
      this.form.resetFields()
      this.visible = false
      this.editDisabled = false
    }
  }
}
</script>
