/*
 * @Autor: dingxiaolin
 * @Date: 2022-08-25 14:55:07
 * @LastEditors: dingxiaolin
 * @LastEditTime: 2022-10-22 09:13:06
 */
import { axios } from '@/utils/request'
import config from '@/config/config.js'

/**
 * 查询系统通知公告
 */
export function sysNoticePage(parameter) {
    return axios({
        url: config.namespace + '/systemNotice/page',
        method: 'get',
        params: parameter
    })
}

/**
 * 添加系统通知公告
 */
export function sysNoticeAdd(parameter) {
    return axios({
        url: config.namespace + '/systemNotice/add',
        method: 'post',
        data: parameter
    })
}

/**
 * 编辑系统通知公告
 */
export function sysNoticeEdit(parameter) {
    return axios({
        url: config.namespace + '/systemNotice/edit',
        method: 'post',
        data: parameter
    })
}

/**
 * 删除系统通知公告
 */
export function sysNoticeDelete(parameter) {
    return axios({
        url: config.namespace + '/systemNotice/delete',
        method: 'post',
        data: parameter
    })
}

/**
 * 通知公告详情
 */
export function sysNoticeDetail(parameter) {
    return axios({
        url: config.namespace + '/systemNotice/detail',
        method: 'get',
        params: parameter
    })
}

/**
 * 修改状态
 */
export function sysNoticeChangeStatus(parameter) {
    return axios({
        url: config.namespace + '/systemNotice/changeStatus',
        method: 'post',
        data: parameter
    })
}
/**
 * 发布通知
 */
export function saveNoticeConfig(parameter) {
    return axios({
        url: config.namespace + '/noticeConfig/save',
        method: 'post',
        data: parameter
    })
}
/**
 * 修改发布通知
 */
export function updateNoticeConfig(parameter) {
    return axios({
        url: config.namespace + '/noticeConfig/update',
        method: 'post',
        data: parameter
    })
}
/**
 * 通知详情
 */
export function noticeConfigdetail(parameter) {
    return axios({
        url: config.namespace + '/noticeConfig/detail',
        method: 'post',
        data: parameter
    })
}
