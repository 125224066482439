<template>
    <div>
        <div class="flex ac" style="padding: 0 0 10px">
            <a-input v-model="queryParam.filter" style="width:240px" placeholder="请输入过滤关键词"></a-input>
            <a-button type="primary" style="margin-left:20px" @click="handleQuery(true)"> 查询 </a-button>
        </div>
        <div class="log_list">
            <a-table
                bordered
                :loading="isLoading"
                :columns="logColumns"
                :data-source="logData"
                size="small"
                :rowKey="record => record.id"
                :scrollToFirstRowOnChange="true"
                :pagination="pagination"
            >
            </a-table>
        </div>
    </div>
</template>
<script>
import { mapState } from 'vuex'
export default {
    props: ['deviceTag', 'deviceStatus'],
    data() {
        return {
            logColumns: [
                {
                    title: '时间',
                    dataIndex: 'time',
                    width: '120px'
                },
                {
                    title: '内容',
                    dataIndex: 'context',
                    width: '400px'
                }
            ],
            logData: [],
            mediaTime: '',
            queryParam: {
                filter: ''
            },
            /** 定时器实例 */
            timer: null,
            /** 弹窗提示key */
            key: 'logKey',
            pagination: {
                current: 1,
                pageSize: 31,
                total: 0,
                // showSizeChanger: true,
                showTotal: (total, range) => {
                    return range[0] + '-' + range[1] + '共' + total + '条'
                },
                onChange: this.onChange,
                onShowSizeChange: this.onPageSizeChnage
            },
            isLoading: false
        }
    },
    computed: {
        ...mapState({
            mqtt: state => state.app.mqtt
        })
    },
    mounted() {
        if (this.deviceStatus == 1) {
            this.handleQuery()
        }
        this.$root.$on('task', res => {
            //获取日志信息
            if (res.Event == '/app_log_fetch' && res.Result.Code == 0) {
                this.cancelTimer()
                this.logData = res.Result.Log.Content.map((item, i) => {
                    let index = item.split('').findIndex(f => f == '(')
                    return {
                        time: item.slice(0, index),
                        context: item.slice(index, item.length),
                        id: i
                    }
                })
                this.pagination.total = res.Result.Log.Total
            }
        })
    },
    destroyed() {
        this.$root.$off('task')
    },
    methods: {
        handleQuery(FirstPage = false) {
            if (FirstPage) {
                this.pagination.current = 1
            }
            this.isLoading = true
            this.setError()
            let params = {
                BoardId: this.deviceTag,
                Event: '/app_log_fetch',
                page: this.pagination.current,
                filter: this.queryParam.filter
            }
            // 订阅截图
            this.mqtt.publish('/edge_app_controller', JSON.stringify(params))
        },
        setError() {
            // 自定义错误处理
            this.timer = setTimeout(() => {
                this.isLoading = false
            }, 1000 * 60)
        },
        cancelTimer() {
            clearTimeout(this.timer)
            this.isLoading = false
        },
        onChange(page, pageSize) {
            this.pagination.current = page
            this.pagination.pageSize = pageSize
            this.handleQuery()
        },
        onPageSizeChnage(current, size) {
            this.pagination.current = current
            this.pagination.pageSize = size
            this.handleQuery()
        }
    }
}
</script>

<style lang="less" scoped>
.deepGreen {
    color: #67c23a;
}
.lightGreen {
    color: rgb(225, 243, 216);
}
.deepRed {
    color: #f56c6c;
}
.lightRed {
    color: rgb(253, 226, 226);
}
</style>
