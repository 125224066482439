<!--
 * @Autor: dingxiaolin
 * @Date: 2021-10-13 16:10:53
 * @LastEditors: dingxiaolin
 * @LastEditTime: 2021-12-20 17:13:26
-->
<template>
    <div style="width: 100%; height: 100%" class="all_center">
        <a-empty v-show="empty" :image="simpleImage" style="color: #fff" />
        <div id="pieRadius" ref="pieRadius" v-show="!empty"></div>
    </div>
</template>

<script>
import * as echarts from 'echarts'
import { Empty } from 'ant-design-vue'
export default {
    name: 'pieRadius',
    props: {
        /** 环形图表数据 */
        data: {
            type: Array,
            default: () => {
                return []
            }
        }
    },

    data() {
        return {}
    },
    computed: {
        empty() {
            return this.data.length > 0 ? false : true
        }
    },
    watch: {
        data: {
            handler() {
                this.drawChart(this.data)
            },
            deep: true
        }
    },
    created() {
        this.simpleImage = Empty.PRESENTED_IMAGE_SIMPLE
    },
    mounted() {},
    methods: {
        /** 绘制图表 */
        drawChart(data) {
            var pieRadius = echarts.init(this.$refs.pieRadius)
            window.addEventListener('resize', () => {
                setTimeout(() => {
                    pieRadius.resize()
                }, 200)
            })
            var option = {
                tooltip: {
                    trigger: 'item'
                },
                label: {
                    color: '#fff',
                    formatter: '{b}:{c}' + '\n\r' + '({d}%)'
                },
                legend: {
                    itemWidth: 10,
                    itemHeight: 10,
                    bottom: 0,
                    left: 'center',
                    textStyle: {
                        color: '#fff'
                    }
                },
                emphasis: {
                    itemStyle: {
                        shadowBlur: 10,
                        shadowOffsetX: 0,
                        shadowColor: 'rgba(0, 0, 0, 0.5)'
                    }
                },
                series: [
                    {
                        type: 'pie',
                        center: ['50%', '40%'],
                        top:5,
                        radius: ['40%', '50%'],
                        itemStyle: {
                            borderRadius: 3
                        },
                        data: data
                    }
                ]
            }
            pieRadius.setOption(option)
            setTimeout(() => {
                pieRadius.resize()
            }, 200)
        }
    }
}
</script>

<style lang="less" scoped>
#pieRadius {
    // width: 400px;
    // height: 190px;
    width: 100%;
    height: 100%;
}
</style>
