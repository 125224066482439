import { axios } from '@/utils/request'
import { Post } from '@/utils/newRequest'
import config from '@/config/config.js'

/**
 * 获取角色列表
 */
export function getRolePage(parameter) {
    return axios({
        url: config.namespace+'/role/page',
        method: 'get',
        params: parameter
    })
}

/**
 * 增加角色
 */
export function sysRoleAdd(parameter) {
    return axios({
        url: config.namespace+'/role/add',
        method: 'post',
        data: parameter
    })
}

/**
 * 编辑角色
 */
// export function sysRoleEdit(parameter) {
//     return axios({
//         url: config.namespace+'/role/edit',
//         method: 'post',
//         data: parameter
//     })
// }
export function sysRoleEdit(body) {
  return Post(config.namespace + '/role/edit', body)
}

/**
 * 删除角色
 */
export function sysRoleDelete(parameter) {
    return axios({
        url: config.namespace+'/role/delete',
        method: 'post',
        data: parameter
    })
}

/**
 * 删除角色
 */
export function sysRoleDeteil(parameter) {
    return axios({
        url: config.namespace+'/role/detail',
        method: 'get',
        params: parameter
    })
}

/**
 * 获取授权角色列表
 */
export function sysRoleDropDown(parameter) {
    return axios({
        url: config.namespace+'/role/dropDown',
        method: 'get',
        params: parameter
    })
}

/**
 * 拥有菜单
 */
export function sysRoleOwnMenu(parameter) {
    return axios({
        url: config.namespace+'/role/ownMenu',
        method: 'get',
        params: parameter
    })
}

/**
 * 授权菜单
 */
export function sysRoleGrantMenu(parameter) {
    return axios({
        url: config.namespace+'/role/grantMenu',
        method: 'post',
        data: parameter
    })
}

/**
 * 拥有数据
 */
export function sysRoleOwnData(parameter) {
    return axios({
        url: config.namespace+'/role/ownData',
        method: 'get',
        params: parameter
    })
}

/**
 * 授权数据
 */
export function sysRoleGrantData(parameter) {
    return axios({
        url: config.namespace+'/role/grantData',
        method: 'post',
        data: parameter
    })
}
