<template>
    <div>
        <div class="btn-tb">
            最新同步时间：{{ syncTime }}
            <a-button type="default" class="btn-add" @click="sync_network"> {{ syncBtnText }} </a-button>
        </div>
        <a-form-model ref="form" layout="vertical" :model="form">
            <a-row :gutter="24">
                <a-col :md="8" :sm="24">
                    <a-form-item label="网卡名称" prop="name">
                        <a-input
                            v-model="form.name"
                            :disabled="Boolean(form.id) || !hasPerm('aiDevice:detail:network')"
                            allow-clear
                            placeholder="请输入网卡名称"
                        />
                    </a-form-item>
                </a-col>
                <a-col :md="8" :sm="24">
                    <a-form-item label="网络地址" prop="ipAddr">
                        <a-input v-model="form.ipAddr" :disabled="!hasPerm('aiDevice:detail:network')" allow-clear placeholder="请输入网络地址" />
                    </a-form-item>
                </a-col>
                <a-col :md="8" :sm="24">
                    <a-form-item label="子网掩码" prop="mask">
                        <a-input v-model="form.mask" :disabled="!hasPerm('aiDevice:detail:network')" allow-clear placeholder="请输入子网掩码" />
                    </a-form-item>
                </a-col>
                <a-col :md="8" :sm="24">
                    <a-form-item label="网关地址" prop="gateway">
                        <a-input v-model="form.gateway" :disabled="!hasPerm('aiDevice:detail:network')" allow-clear placeholder="请输入网关地址" />
                    </a-form-item>
                </a-col>
                <a-col :md="8" :sm="24">
                    <a-form-item label="物理地址" prop="mac">
                        <a-input
                            v-model="form.mac"
                            :disabled="Boolean(form.id) || !hasPerm('aiDevice:detail:network')"
                            allow-clear
                            placeholder="请输入物理地址"
                        />
                    </a-form-item>
                </a-col>
                <a-col :md="8" :sm="24">
                    <a-form-item label="DNS" prop="dns">
                        <a-input v-model="form.dns" :disabled="!hasPerm('aiDevice:detail:network')" allow-clear placeholder="请输入DNS" />
                    </a-form-item>
                </a-col>
                <a-col :md="8" :sm="24" v-if="hasPerm('aiDevice:detail:network')">
                    <a-button type="primary" @click="onSave" :loading="isLoading">{{ saveText }}</a-button>
                </a-col>
            </a-row>
        </a-form-model>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import { aiDeviceNetworkInfoSynInfo, aiDeviceNetworkInfoDetail, aiDeviceNetworkInfoUpdate, aiDeviceTasklist } from '@/api/alarm'
export default {
    data() {
        return {
            form: {},
            timer: null,
            key: 'netword',
            saveText: '保存',
            isLoading: false,
            syncTime: '',
            syncBtnText: '同步'
        }
    },
    props: ['deviceTag'],
    mounted() {
        this.getNetWork()
    },
    methods: {
        /** 查找当前任务队列  */
        getRealTaskStatus(fn) {
            return aiDeviceTasklist({
                current: -1,
                size: -1,
                deviceTag_like: this.deviceTag,
                taskStatus_equal: 0
            }).then(({ records }) => {
                if (records.length > 0) {
                    this.$message.error('当前有任务在执行')
                    return
                }
                fn && fn()
            })
        },
        sync_network() {
            this.getRealTaskStatus(() => {
                aiDeviceNetworkInfoSynInfo({
                    deviceId: this.$route.query.id
                })
                this.syncBtnText = '同步中...'
                this.syncTime = this.$moment().format('YYYY-MM-DD HH:mm:ss')
                this.cancelTimer()
                this.setError()
            })
        },
        setError() {
            // 自定义错误处理
            this.timer = setTimeout(() => {
                // this.$message.error({
                //     key: this.key,
                //     content: '操作超时'
                // })
                this.isLoading = false
                this.saveText = '保存'
                this.syncBtnText = '同步'
                this.getNetWork()
            }, 1000 * 60)
        },
        cancelTimer() {
            clearTimeout(this.timer)
            this.isLoading = false
            this.saveText = '保存'
        },
        getNetWork() {
            aiDeviceNetworkInfoDetail({ deviceId: this.$route.query.id }).then(res => {
                this.form = { ...res }
                this.syncTime = res.updateTime
                this.syncBtnText = '同步'
            })
        },
        onSave() {
            this.getRealTaskStatus(() => {
                this.isLoading = true
                this.saveText = '保存中'
                this.setError()
                aiDeviceNetworkInfoUpdate(this.form)
            })
        }
    }
}
</script>

<style></style>
