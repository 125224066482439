<template>
    <div
        v-if="mode === 'sidemenu'"
        :class="theme === 'dark' ? 'footer msybgDark' : 'footer msybgLight'"
        :style="collapsed ? 'width: calc(100% - 80px)' : 'width: calc(100% - 202px)'"
    >
        <div :class="theme === 'dark' ? 'copyright msyLight' : 'copyright msyDark'">
            Copyright &copy; {{ new Date().getFullYear() }} &nbsp;
            <strong>
                {{ project.company_name }}
                &nbsp;
                <a key="" title="" target="_blank" :href="project.company_web">
                    {{ project.company_ename }}
                </a>
            </strong>
            <span>All Rights Reserved.</span>
        </div>
    </div>

    <div v-else :class="theme === 'dark' ? 'footer msybgDark' : 'footer msybgLight'" :style="collapsed ? 'width: calc(100%)' : 'width: calc(100%)'">
        <div :class="theme === 'dark' ? 'copyright msyLight' : 'copyright msyDark'">
            Copyright &copy; {{ new Date().getFullYear() }} &nbsp;
            <strong>
                {{ project.company_name }}
                &nbsp;
                <a key="" title="" target="_blank" :href="project.company_web">
                    {{ project.company_ename }}
                </a>
            </strong>
            <span>All Rights Reserved. </span>
        </div>
    </div>
</template>

<script>
import { mixin } from '@/utils/mixin'
const Project = require('@/config/product.json')

export default {
    name: 'GlobalFooter',
    data() {
        return {
            project: Project,
        }
    },
    mixins: [mixin],
    props: {
        mode: {
            type: String,
            // sidemenu, topmenu, mixmenu
            default: 'sidemenu',
        },
        collapsed: {
            type: Boolean,
            required: false,
            default: false,
        },
        theme: {
            type: String,
            required: false,
            default: 'dark',
        },
    },
    mounted() {
        // console.log('theme', this.theme, this.mode)
    },
    watch: {},
}
</script>

<style lang="less" scoped>
.msybgDark {
    background-color: #001529;
}
.msybgLight {
    background-color: #ffffff;
}
.footer {
    height: 35px;
    line-height: 35px;
    text-align: center;
    position: fixed;
    bottom: 0px;
    z-index: 5;
    .links {
        margin-bottom: 8px;
        a {
            color: rgba(0, 0, 0, 0.45);

            &:hover {
                color: rgba(0, 0, 0, 0.65);
            }
            &:not(:last-child) {
                margin-right: 40px;
            }
        }
    }
    .msyDark {
        color: #001529 !important;
    }
    .msyLight {
        color: #ffffff !important;
    }
    .copyright {
        color: rgba(0, 0, 0, 0.45);
        font-size: 14px;
    }
}
</style>
