<template>
    <a-card :bordered="false">
        <div class="table-page-search-wrapper">
            <a-form-model :label-col="labelCol" :wrapper-col="wrapperCol">
                <a-row :gutter="48">
                    <a-col :md="6" :sm="24">
                        <a-form-item label="所属机构">
                            <dept-tree v-model="queryParam.tenantId_equal" />
                        </a-form-item>
                    </a-col>
                    <a-col :md="6" :sm="24">
                        <a-form-model-item label="AI终端编号">
                            <a-input v-model="queryParam.deviceCode_like" allow-clear placeholder="请输入AI终端编号" />
                        </a-form-model-item>
                    </a-col>
                    <a-col :md="6" :sm="24">
                        <a-form-model-item label="盒子唯一标识">
                            <a-input v-model="queryParam.deviceTag_like" allow-clear placeholder="请输入盒子唯一标识" />
                        </a-form-model-item>
                    </a-col>
                    <a-col :md="6" :sm="24">
                        <a-form-model-item label="功能标识">
                            <a-input v-model="queryParam.taskDesc_like" allow-clear placeholder="请输入功能标识" />
                        </a-form-model-item>
                    </a-col>
                    <a-col :md="6" :sm="24">
                        <a-form-model-item label="版本号">
                            <a-input v-model="queryParam.levelFileVersion_like" allow-clear placeholder="请输入版本号" />
                        </a-form-model-item>
                    </a-col>
                    <a-col :md="6" :sm="24">
                        <a-form-model-item label="任务时间">
                            <a-range-picker
                                :show-time="{ defaultValue: [$moment('00:00:00', 'HH:mm:ss'), $moment('23:59:59', 'HH:mm:ss')] }"
                                v-model="queryDate"
                                format="YYYY-MM-DD HH:mm:ss"
                                @change="dateChange"
                            />
                        </a-form-model-item>
                    </a-col>
                    <a-col :md="6" :sm="24">
                        <a-form-model-item label="任务状态">
                            <a-select style="width: 100%" placeholder="请选择状态" v-model="queryParam.taskStatus_equal">
                                <a-select-option v-for="(item, index) in isDealList" :key="index" :value="item.code">{{
                                    item.value
                                }}</a-select-option>
                            </a-select>
                        </a-form-model-item>
                    </a-col>
                    <a-col :md="6" :sm="24" align="right">
                        <span class="table-page-search-submitButtons">
                            <a-button type="primary" @click="$refs.table.refresh(true)">查询</a-button>
                            <a-button style="margin-left: 8px" @click="resetForm">重置</a-button>
                        </span>
                    </a-col>
                </a-row>
            </a-form-model>
        </div>
        <n-table ref="table" :columns="columns" :data="loadData" :rowKey="record => record.id" :scrollToFirstRowOnChange="true">
            <span slot="taskState" slot-scope="text">
                <span v-if="text == 0" class="blue">执行中</span>
                <span v-if="text == 1" class="green">成功</span>
                <span v-if="text == 2" class="red">失败</span>
            </span>
        </n-table>
    </a-card>
</template>

<script>
import { NTable, DeptTree } from '@/components'
import { aiDeviceTasklist } from '@/api/alarm/index'
export default {
    components: {
        NTable,
        DeptTree
    },

    data() {
        return {
            visible: false,
            ignore_visible: false,
            layout: {
                labelCol: { span: 5 },
                wrapperCol: { span: 17 }
            },
            labelCol: {
                sm: { span: 6 },
                xs: { span: 24 }
            },
            wrapperCol: {
                sm: { span: 18 },
                xs: { span: 24 }
            },
            queryParam: {
                descs: 'create_time', //排序字段
                tenantId_equal: '', //所属机构
                deviceCode_like: '', //AI终端编号
                deviceTag_like: '', //盒子唯一标识
                taskDesc_like: '', //功能标识
                levelFileVersion_like: '', //版本号
                taskStatus_equal: '', //任务状态
                createTime_ge: '',
                createTime_le: ''
            },
            queryDate: null,
            columns: [
                {
                    title: '所属机构',
                    dataIndex: 'tenantName',
                    ellipsis: true
                },
                {
                    title: 'AI终端编号',
                    dataIndex: 'deviceCode',
                    ellipsis: true
                },
                {
                    title: '盒子唯一标识',
                    dataIndex: 'deviceTag',
                    ellipsis: true,
                    width: '350px'
                },
                {
                    title: '功能标识',
                    dataIndex: 'taskDesc',
                    ellipsis: true
                },
                {
                    title: '任务编号',
                    dataIndex: 'taskCode',
                    ellipsis: true
                },
                {
                    title: '保存文件名',
                    dataIndex: 'levelFileName',
                    ellipsis: true
                },
                {
                    title: '版本号',
                    dataIndex: 'levelFileVersion',
                    ellipsis: true
                },
                {
                    title: '任务时间',
                    dataIndex: 'createTime',
                    ellipsis: true,
                    width: '200px'
                },
                {
                    title: '操作人',
                    dataIndex: 'createUserName',
                    ellipsis: true
                },
                {
                    title: '任务状态',
                    dataIndex: 'taskStatus',
                    scopedSlots: { customRender: 'taskState' },
                    ellipsis: true
                },
                {
                    title: '错误描述信息',
                    dataIndex: 'errorDesc',
                    ellipsis: true,
                    width: '150px'
                }
            ],
            loadData: async parameter => {
                return await aiDeviceTasklist(Object.assign(parameter, this.queryParam))
            },
            isDealList: [
                { code: '', value: '全部' },
                { code: '0', value: '执行中' },
                { code: '1', value: '成功' },
                { code: '2', value: '失败' }
            ],
            isOnlineList: []
        }
    },
    mounted() {},
    methods: {
        search() {
            this.queryParam.tenantSearchType = this.queryParam.tenantId_equal ? '0' : ''
            this.$refs.table.localDataSource.map(item => {
                this.$set(item, 'show', false)
            })
            this.$refs.table.refresh(true)
        },
        handleOk() {
            this.$refs.table.refresh()
        },
        resetForm() {
            this.queryDate = null
            this.queryParam = {
                tenantId_equal: '', //所属机构
                deviceCode_like: '', //AI终端编号
                deviceTag_like: '', //盒子唯一标识
                taskDesc_like: '', //功能标识
                levelFileVersion_like: '', //版本号
                taskStatus_equal: null
            }
        },
        onSelectChange(selectedRowKeys, selectedRows) {
            this.selectedRowKeys = selectedRowKeys
            this.selectedRows = selectedRows
        },
        dateChange(date, dateString) {
            this.queryParam.createTime_ge = dateString[0]
            this.queryParam.createTime_le = dateString[1]
        }
    }
}
</script>
<style lang="less" scoped></style>
