<template>
  <a-modal
    title="日志详情"
    centered
    :width="980"
    :maskClosable="false"
    :destroyOnClose="true"
    :visible="visible"
    :confirmLoading="confirmLoading"
    :footer="null"
    @cancel="handleCancel"
  >
    <a-spin :spinning="confirmLoading">
      <a-form :form="form">
        <a-row :gutter="24">
          <a-col :md="12" :sm="24">
            <a-form-item label="日志名称" :labelCol="labelCol" :wrapperCol="wrapperCol">
              <a-input v-decorator="['name']" :disabled="true" />
            </a-form-item>
          </a-col>
          <a-col :md="12" :sm="24">
            <a-form-item label="操作类型" :labelCol="labelCol" :wrapperCol="wrapperCol">
              <a-input v-decorator="['opType']" :disabled="true" />
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="24">
          <a-col :md="12" :sm="24">
            <a-form-item label="执行结果" :labelCol="labelCol" :wrapperCol="wrapperCol">
              <a-input v-decorator="['success']" :disabled="true" />
            </a-form-item>
          </a-col>
          <a-col :md="12" :sm="24">
            <a-form-item label="请求类型" :labelCol="labelCol" :wrapperCol="wrapperCol">
              <a-input v-decorator="['reqMethod']" :disabled="true" />
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="24">
          <a-col :md="12" :sm="24">
            <a-form-item label="操作时间" :labelCol="labelCol" :wrapperCol="wrapperCol">
              <a-input :rows="4" v-decorator="['opTime']" :disabled="true" />
            </a-form-item>
          </a-col>
          <a-col :md="12" :sm="24">
            <a-form-item label="操作人" :labelCol="labelCol" :wrapperCol="wrapperCol">
              <a-input :rows="4" v-decorator="['accountName']" :disabled="true" />
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="24">
          <a-col :md="12" :sm="24">
            <a-form-item label="请求参数" :labelCol="labelCol" :wrapperCol="wrapperCol">
              <a-textarea :rows="5" v-decorator="['param']" :disabled="true" />
            </a-form-item>
          </a-col>
          <a-col :md="12" :sm="24">
            <a-form-item label="返回结果" :labelCol="labelCol" :wrapperCol="wrapperCol">
              <a-textarea :rows="5" v-decorator="['result']" :disabled="true" />
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </a-spin>
  </a-modal>
</template>
<script>
import { sysDictTypeDropDown } from '@/api/system/dict'
export default {
  data() {
    return {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 15 }
      },
      visible: false,
      confirmLoading: false,
      form: this.$form.createForm(this),
      opTypeDict: [],
      successDict: []
    }
  },
  methods: {
    opTypeFilter(opType) {
      // eslint-disable-next-line eqeqeq
      const values = this.opTypeDict.filter(item => item.code == opType)
      if (values.length > 0) {
        return values[0].value
      }
    },
    successFilter(success) {
      // eslint-disable-next-line eqeqeq
      const values = this.successDict.filter(item => item.code == success)
      if (values.length > 0) {
        return values[0].value
      }
    },
    /**
     * 获取字典数据
     */
    sysDictTypeDropDown() {
      sysDictTypeDropDown({ code: 'op_type' }).then(res => {
        this.opTypeDict = res.data
      })
      sysDictTypeDropDown({ code: 'yes_or_no' }).then(res => {
        this.successDict = res.data
      })
    },
    // 初始化方法
    details(record) {
      this.visible = true
      this.sysDictTypeDropDown()
      setTimeout(() => {
        this.form.setFieldsValue({
          opType: this.opTypeFilter(record.opType),
          success: this.successFilter(record.success),
          reqMethod: record.reqMethod,
          opTime: record.opTime,
          name: record.name,
          param: record.param,
          result: record.result,
          accountName: record.accountName
        })
      }, 100)
    },
    handleCancel() {
      this.form.resetFields()
      this.visible = false
    }
  }
}
</script>
