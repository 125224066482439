<template>
    <a-card :bordered="false">
        <div class="table-page-search-wrapper">
            <a-form layout="inline">
                <a-row :gutter="48">
                    <a-col :md="6" :sm="24">
                        <a-form-item label="区域名称">
                            <a-input v-model="queryParam.areaName_like" allow-clear placeholder="请输入码头名称" />
                        </a-form-item>
                    </a-col>
                    <a-col :md="6" :sm="24">
                        <a-form-item label="区域地址">
                            <a-input v-model="queryParam.areaAddress_like" allow-clear placeholder="请输入码头地址" />
                        </a-form-item>
                    </a-col>
                    <a-col :md="6" :sm="24">
                        <a-form-item label="所属机构">
                            <DeptTree v-model="queryParam.tenantId_equal" />
                        </a-form-item>
                    </a-col>
                    <a-col :md="6" :sm="24">
                        <a-form-item label="创建时间">
                            <a-range-picker
                                :show-time="{ defaultValue: [$moment('00:00:00', 'HH:mm:ss'), $moment('00:00:00', 'HH:mm:ss')] }"
                                v-model="queryDate"
                                format="YYYY-MM-DD HH:mm"
                                @change="dateChange"
                            />
                        </a-form-item>
                    </a-col>
                    <a-col :md="6" :sm="24" align="right" :offset="18">
                        <span class="table-page-search-submitButtons">
                            <a-button type="primary" @click="search">查询</a-button>
                            <a-button style="margin-left: 8px" @click="() => ((queryParam = {}), (queryDate = null))">重置</a-button>
                        </span>
                    </a-col>
                </a-row>
            </a-form>
        </div>
        <div class="table-operator">
            <a-button type="primary" @click="$refs.editForm.show()">添加区域</a-button>
        </div>
        <n-table ref="table" :columns="columns" :data="loadData" :rowKey="record => record.id" :scrollToFirstRowOnChange="true">
            <span slot="action" slot-scope="text, record">
                <a @click="$refs.editForm.show(record)">编辑</a>
                <a-divider type="vertical" />
                <a-popconfirm
                    placement="topRight"
                    :title="record.title"
                    @confirm="() => del(record)"
                    :visible="record.show"
                    @cancel="record.show = false"
                >
                    <a class="red" @click="getDelDetail(record)">删除</a>
                </a-popconfirm>
            </span>
        </n-table>
        <edit-form ref="editForm" @ok="handleOk" :sceneList="sceneList" :levelList="levelList" />
    </a-card>
</template>

<script>
import { NTable } from '@/components'
import { getAreaInfoList } from '@/api/alarm/index'
import editForm from './editForm'
import { sysDictTypeDropDown } from '@/api/comm/index'
import DeptTree from '@/components/DeptTree'
import { aiDeviceList, deleteAreaInfo } from '@/api/alarm/index'

export default {
    components: {
        NTable,
        editForm,
        DeptTree
    },

    data() {
        return {
            labelCol: {
                sm: { span: 8 },
                xs: { span: 24 }
            },
            wrapperCol: {
                sm: { span: 16 },
                xs: { span: 24 }
            },
            // 查询参数
            queryParam: {
                /**码头名称 */
                areaName_like: '',
                /**地址 */
                areaAddress_like: '',
                /**所属机构 */
                tenantId_equal: '',
                /**创建开始时间 yyyy-MM-dd HH:mm:ss */
                createTime_ge: '',
                /**创建结束时间 yyyy-MM-dd HH:mm:ss */
                createTime_le: '',
                descs: 'create_time',
                tenantSearchType: ''
            },
            // 表头
            columns: [
                {
                    title: '区域名称',
                    dataIndex: 'areaName',
                    ellipsis: true
                },
                {
                    title: '区域地址',
                    dataIndex: 'areaAddress',
                    ellipsis: true
                },
                {
                    title: '所属机构',
                    dataIndex: 'tenantName',
                    ellipsis: true
                },
                {
                    title: '联系人',
                    dataIndex: 'linkPeople',
                    width: '150px'
                },
                {
                    title: '联系电话',
                    dataIndex: 'linkPhone',
                    width: '150px'
                },
                {
                    title: '备注',
                    dataIndex: 'memo',
                    ellipsis: true
                },
                {
                    title: '创建时间',
                    dataIndex: 'createTime',
                    width: '150px'
                },
                {
                    title: '操作',
                    dataIndex: 'action',
                    width: '150px',
                    scopedSlots: { customRender: 'action' }
                }
            ],
            // 加载数据方法 必须为 Promise 对象
            loadData: async parameter => {
                return await getAreaInfoList(Object.assign(parameter, this.queryParam))
            },
            warningList: [
                { code: '', value: '全部' },
                { code: '1', value: '未戴安全帽' }
            ],
            levelList: [],
            sceneList: [],
            selectedRowKeys: [],
            selectedRows: [],
            queryDate: null
        }
    },

    mounted() {
        this.getDict()
        document.addEventListener('click', this.hideTip, false)
    },
    unmounted() {
        document.removeEventListener('click', this.hideTip, false)
    },
    methods: {
        hideTip() {
            this.$refs.table.localDataSource.map(item => {
                this.$set(item, 'show', false)
            })
        },
        /**查询 */
        search() {
            this.queryParam.tenantSearchType = this.queryParam.tenantId_equal ? '0' : ''
            this.$refs.table.localDataSource.map(item => {
                this.$set(item, 'show', false)
            })
            this.$refs.table.refresh(true)
        },
        /**
         * 获取字典数据
         */
        getDict() {
            sysDictTypeDropDown({ code: 'alarm_level' }).then(res => {
                this.levelList = res
            })
            sysDictTypeDropDown({ code: 'scene' }).then(res => {
                this.sceneList = res
            })
        },
        
        sysPosDelete(record) {
            sysPosDelete(record)
                .then(res => {
                    if (res.success) {
                        this.$message.success('删除成功')
                        this.$refs.table.refresh()
                    } else {
                        this.$message.error('删除失败：' + res.msg)
                    }
                })
                .catch(err => {
                    this.$message.error('删除错误：' + err.msg)
                })
        },

        toggleAdvanced() {
            this.advanced = !this.advanced
        },
        handleOk() {
            this.$refs.table.refresh()
        },
        onSelectChange(selectedRowKeys, selectedRows) {
            this.selectedRowKeys = selectedRowKeys
            this.selectedRows = selectedRows
        },
        /** 时间切换事件 */
        dateChange(v, string) {
            this.queryParam.createTime_ge = string[0]
            this.queryParam.createTime_le = string[1]
        },
        /**获取删除提示详情 */
        async getDelDetail(record) {
            const data = await aiDeviceList({
                areaId_equal: record.id, //告警地点码头ID
                areaType_equal:'2',
                size: -1
            })
            if (data.records.length > 0) {
                const deviceName = data.records.map(v => v.deviceName).join('、')
                var title = '删除后，将自动与终端' + deviceName + '解绑，确定删除吗？'
            } else {
                title = '你确定要删除该区域吗？'
            }
            this.$refs.table.localDataSource.map(item => {
                if (item.id == record.id) {
                    this.$set(item, 'show', true)
                } else {
                    this.$set(item, 'show', false)
                }
            })
            this.$set(record, 'title', title)
        },
        /**删除 */
        del(record) {
            deleteAreaInfo({ ids: record.id }).then(res => {
                this.$message.success('删除区域成功')
                this.$refs.table.refresh()
                record.show = false
            })
        }
    }
}
</script>

<style lang="less" scoped>
.align .ant-form-item-children {
    display: flex;
    .ant-input-affix-wrapper {
        width: 45%;
    }
    .line {
        width: 10%;
        display: inline-block;
        text-align: center;
    }
}
</style>
