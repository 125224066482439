const Validator = {
    /**
     * @param str 手机号
     */
    isPhone(str) {
        const reg = /^1[3|4|5|6|7|8][0-9]\d{8}$/
        return reg.test(str)
    },
    isArray() {
        let hasValue = (rule, value, callback) => {
            console.log('vv', value)
            if (value.length > 0) {
                callback()
            } else {
                callback(new Error('此项为必选项'))
            }
        }
        return hasValue
    },
    getPhoneValidator(isRequire) {
        let validPhone = (rule, value, callback) => {
            if (!value) {
                if (isRequire) {
                    callback(new Error('请输入手机号码'))
                } else {
                    callback()
                }
            } else if (!Validator.isPhone(value)) {
                callback(new Error('请输入正确的11位手机号码'))
            } else {
                callback()
            }
        }
        return validPhone
    }
}

export var formRules = {
    // phone:{validator : Validator.getPhoneValidator(true),trigger:'change' }  /* 自定义校验 */
    // 金额
    money: [
        {
            required: true,
            pattern: /^\d{1,9}(\.\d{1,2})?$/,
            message: '最多输入9位和2位小数'
        }
    ],
    // 中英文数字
    newName: [{ required: true, pattern: /^[\u4E00-\u9FA5A-Za-z0-9\（\）\/]+$/, message: '请输入中英文数字名称', trigger: 'change' }],
    // 中文名称
    cnName: [{ required: true, pattern: /^[\u4E00-\u9FA5]+$/, message: '请输入中文名称', trigger: 'change' }],
    // 非空 无空格
    nospace: [
        {
            required: true,
            pattern: /\S/,
            message: '请输入用户名',
            trigger: 'change'
        }
    ],
    /* input框 */
    blur: [{ required: true, pattern: /\S/, message: '此项为必填项', trigger: 'blur' }],
    array: { validator: Validator.isArray(), trigger: 'blur' },
    /* select框 */
    change: [{ required: true, message: '此项为必填项', trigger: 'change' }],
    /* 密码校验 */
    password: [
        {
            required: true,
            pattern: /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,}$/,
            message: '请输入字母数字组合密码',
            trigger: 'change'
        }
    ],
    /* 手机号验证 */
    phone: [
        { required: true, message: '请输入手机号' },
        {
            pattern: /^1[3|4|5|6|7|8|9][0-9]\d{8}$/,
            message: '请输入正确的手机号',
            trigger: 'change'
        }
    ],
    /* 基础手机号验证 */
    phoneFormate: [
        { required: true, message: '请输入手机号' },
        {
            pattern: /^1\d{10}$/,
            message: '请输入正确的手机号',
            trigger: 'change'
        }
    ],
    /* 短信验证码校验 */
    messageCode: [
        {
            required: true,
            pattern: /^\d{6}$/,
            message: '请输入6位数字验证码',
            trigger: 'blur'
        }
    ],
    /* 0到1区间保留两位小数*/
    percent: [
        {
            required: true,
            pattern: /^(0(\.\d{1,2})?|1(\.0{1,2})?)$/,
            message: '请输入0到1区间的数字'
        }
    ],
    // 保留小数点后两位
    twoDecimalPoint: [{ required: true, pattern: /^[0-9]+(.[0-9]{1,2})?$/, message: '请输入正确的数字' }],
    number: [
        {
            required: true,
            pattern: /^[0-9]+(.[0-9]{1,4})?$/,
            message: '请输入正确的数字'
        }
    ],
    /**吨 */
    ton: [
        {
            required: false,
            pattern: /^\d+$|^\d*\.\d+$/,
            message: '请输入正确的数字'
        }
    ],
    //邮箱
    email: [
        {
            pattern: /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z0-9]{2,6}$/,
            message: '请输入正确的电子邮箱',
            trigger: 'change'
        },
        {
            max: 45,
            message: '邮箱不得超过45字符',
            trigger: 'change'
        }
    ],
    // 身份证号
    idNumber: [
        {
            required: true,
            pattern: /^[1-9]\d{5}(18|19|20|(3\d))\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/,
            message: '请输入正确的身份证号',
            trigger: 'change'
        }
    ],
    adminpassword: [
        {
            required: true,
            pattern: /(?!.*[\u4E00-\u9FA5\s])(?!^[a-zA-Z]+$)(?!^[\d]+$)(?!^[^a-zA-Z\d]+$)^.{6,20}$/,
            message: '6-20位字符,数字/字母/符号（除空格）至少包括2种'
        }
    ],
    // 3-12位字符，字母/数字/下划线，区分大小写
    adminname: [
        {
            required: true,
            pattern: /^[a-zA-Z0-9_]{3,12}$/,
            message: '3-12位字符，字母/数字/下划线，区分大小写'
        }
    ],
    codeValue: [{ required: true, pattern: /^[0-9]{6}?$/, message: '验证码格式错误' }],
    /**0-100% */
    diskSize: [
        {
            required: true,
            pattern: /^(?:[1-9]?\d|100)$/,
            message: '只能输入0-100%',
            trigger: 'change'
        }
    ],
    /**0-9数字 */
    diskTotal: [{ required: true, pattern: /^[0-9]*$/, message: '只能输入0-9数字' }],
    roundNum: [{ required: true, pattern: /^[+]{0,1}(\d+)$/g, message: '只能输入正整数' }]
}
