<template>
    <a-modal
        :title="title"
        centered
        :width="680"
        :maskClosable="false"
        :destroyOnClose="true"
        :visible="visible"
        :confirmLoading="confirmLoading"
        @ok="handleSubmit"
        @cancel="handleCancel"
    >
        <a-spin :spinning="formLoading">
            <a-form-model ref="form" :model="form" :rules="formRules" :label-col="labelCol" :wrapper-col="wrapperCol">
                <a-row>
                    <a-col :span="24">
                        <a-form-model-item label="机构名称" prop="name" :rules="formRules.blur">
                            <a-input :maxLength="35" placeholder="请输入机构名称" v-model="form.name" />
                        </a-form-model-item>
                        <a-form-model-item label="上级机构" prop="pid" :rules="formRules.change">
                            <a-tree-select
                                show-search
                                treeNodeFilterProp="title"
                                style="width: 100%"
                                :dropdownStyle="{ maxHeight: '300px', overflow: 'auto' }"
                                :treeData="orgTree"
                                placeholder="请选择上级机构"
                                treeDefaultExpandAll
                                v-model="form.pid"
                                :replace-fields="{ value: 'id' }"
                            >
                                <!-- <span slot="title" slot-scope="{ id }">{{ id }} </span> -->
                            </a-tree-select>
                        </a-form-model-item>

                        <a-form-model-item label="地址" style="margin-bottom: 0">
                            <a-row>
                                <a-col :span="8">
                                    <a-form-model-item style="margin-bottom: 0">
                                        <a-select
                                            v-model="form.province"
                                            @change="handleProvinceChange"
                                            placeholder="请选择省"
                                            show-search
                                            option-filter-prop="children"
                                        >
                                            <a-select-option v-for="item in province" :key="item.code" :value="item.code">
                                                {{ item.name }}
                                            </a-select-option>
                                        </a-select>
                                    </a-form-model-item>
                                </a-col>
                                <a-col :span="8">
                                    <a-form-model-item style="margin-bottom: 0">
                                        <a-select
                                            v-model="form.city"
                                            @change="handleCityChange"
                                            placeholder="请选择市"
                                            show-search
                                            option-filter-prop="children"
                                        >
                                            <a-select-option v-for="item in city" :key="item.code" :value="item.code">
                                                {{ item.name }}
                                            </a-select-option>
                                        </a-select>
                                    </a-form-model-item>
                                </a-col>
                                <a-col :span="8">
                                    <a-form-model-item style="margin-bottom: 0">
                                        <a-select
                                            v-model="form.county"
                                            @change="handlecountyChange"
                                            placeholder="请选择区"
                                            show-search
                                            option-filter-prop="children"
                                        >
                                            <a-select-option v-for="item in county" :key="item.code" :value="item.code">
                                                {{ item.name }}
                                            </a-select-option>
                                        </a-select>
                                    </a-form-model-item>
                                </a-col>
                            </a-row>
                        </a-form-model-item>
                        <a-form-model-item :wrapperCol="{ span: 15, offset: 5 }">
                            <a-input v-model="form.orgAddress" placeholder="请输入详细地址" />
                            <div style="width: 100%; height: 250px; background: #ddd">
                                <AMap ref="child" style="width: 100%; height: 100%" @onSelect="onSelect" @onClick="onClick" />
                            </div>
                        </a-form-model-item>
                        <a-form-model-item label="排序" prop="sort">
                            <a-input-number placeholder="请输入排序" style="width: 100%" v-model="form.sort" :min="1" :max="1000" />
                        </a-form-model-item>
                        <a-form-model-item label="备注" prop="remark">
                            <a-textarea :maxLength="254" :rows="4" placeholder="请输入备注" v-model="form.remark"></a-textarea>
                        </a-form-model-item>
                    </a-col>
                </a-row>
            </a-form-model>
        </a-spin>
    </a-modal>
</template>

<script>
import { sysOrgAdd, sysOrgEdit, getOrgDetail } from '@/api/system/org'
import { getUserPage } from '@/api/system/user'
import { getDeptTree } from '@/api/alarm'
import { formRules } from '@/utils/validator'
import AMap from '@/components/AMap'
import { getRegion } from '@/api/alarm'
import { mapState } from 'vuex'
import { sysDictTypeDropDown } from '@/api/comm/index'

export default {
    components: {
        AMap
    },
    data() {
        return {
            title: '新增机构',
            labelCol: {
                xs: { span: 24 },
                sm: { span: 5 }
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 15 }
            },
            formRules: formRules,
            orgTree: [],
            visible: false,
            confirmLoading: false,
            formLoading: true,
            form: {
                name: '',
                sort: 100,
                pid: '',
                province: '',
                city: '',
                county: '',
                orgAddress: '',
                orgLat: '',
                orgLng: '',
                orgAutoNaviLat: '',
                orgAutoNaviLng: '',
                geoHash: '',
                linkPhone: '',
                remark: '',
                industryCode: '',
                industryName: ''
            },
            province: [],
            city: [],
            county: [],
            /** 用户列表 */
            userList: [],
            isAdd: true
        }
    },
    computed: {
        ...mapState({
            userInfo: state => state.user.info
        })
    },
    mounted() {
        this.getRegionList().then(res => {
            this.province = res
        })
        this.getUserList()
    },
    methods: {
        async getRegionList(code) {
            return getRegion({ code: code })
        },
        // 初始化方法
        add(record) {
            this.visible = true
            this.getOrgTree()
            if (record) {
                console.log(record)
                this.title = '修改机构'
                this.isAdd = false
                getOrgDetail({
                    id: record.id
                }).then(res => {
                    let result = res.data
                    // 渲染地区下拉框
                    result.province && this.getRegionList(result.province).then(res => (this.city = res))
                    result.city && this.getRegionList(result.city).then(res => (this.county = res))
                    // 渲染地图中的marker
                    result.orgAutoNaviLng &&
                        result.orgAutoNaviLat &&
                        setTimeout(() => {
                            this.$refs.child.initMarker(result.orgAutoNaviLng, result.orgAutoNaviLat)
                        }, 10)
                    Object.assign(this.form, result)
                    console.log('this.form', this.form)
                })
            } else {
                this.title = '新增机构'
                this.isAdd = true
            }
        },
        /** 获取用户列表 */
        getUserList() {
            getUserPage({
                pageNo: -1,
                pageSize: -1,
                descs: 'create_time'
            }).then(res => {
                this.userList = res.data.rows
            })
        },

        /**
         * 获取机构树，并加载于表单中
         */
        getOrgTree() {
            getDeptTree()
                .then(res => {
                    this.formLoading = false
                    this.orgTree =
                        this.userInfo.adminType == 1
                            ? [
                                  {
                                      id: '0',
                                      parentId: '0',
                                      title: '顶级',
                                      value: '0',
                                      pid: '0',
                                      children: res
                                  }
                              ]
                            : res
                })
                .catch(() => {
                    this.orgTree = []
                    this.formLoading = false
                })
        },

        handleSubmit() {
            this.confirmLoading = true
            this.$refs.form.validate(valid => {
                if (!valid) {
                    this.confirmLoading = false
                    return
                }
                console.log(this.form)
                let method = this.isAdd ? sysOrgAdd : sysOrgEdit
                method(this.form)
                    .then(res => {
                        if (res.success) {
                            this.isAdd ? this.$message.success('新增成功') : this.$message.success('修改成功')
                            this.confirmLoading = false
                            this.$emit('ok')
                            this.handleCancel()
                        } else {
                            this.isAdd ? this.$message.error('新增失败：' + res.msg) : this.$message.error('修改失败：' + res.msg)
                        }
                    })
                    .finally(res => {
                        this.confirmLoading = false
                    })
            })
        },
        handleCancel() {
            this.form = this.$options.data().form
            this.visible = false
        },
        /** 省级级切换 */
        handleProvinceChange(value, option) {
            console.log('province', value, option)
            this.city = this.county = []
            this.form.city = this.form.county = this.form.orgAddress = ''
            option && this.clearAddress()
            this.getRegionList(value).then(res => {
                this.city = res
            })
        },
        /** 切换市级 */
        handleCityChange(value, option) {
            console.log('city', value)
            this.county = []
            this.form.county = this.form.orgAddress = ''
            option && this.clearAddress()
            this.getRegionList(value).then(res => {
                this.county = res
            })
        },
        /** 更改省市区清空地址和地图中的选择 */
        clearAddress() {
            this.$refs.child.clear()
            this.form.orgAddress = ''
            this.form.orgAutoNaviLat = ''
            this.form.orgAutoNaviLng = ''
        },
        /** 切换县 */
        handlecountyChange(value, option) {
            option && this.clearAddress()
        },
        /** 地图子组件回调 */
        onSelect({ address, city, county, lat, lng, province }) {
            this.handleProvinceChange(province)
            this.handleCityChange(city)
            this.form = {
                ...this.form,
                province,
                city,
                county,
                orgAddress: address,
                orgAutoNaviLat: lat,
                orgAutoNaviLng: lng
            }
        },
        /** 地图点击事件 */
        onClick({ address, lng, lat, province, city, county }) {
            console.log(address)
            this.handleProvinceChange(province)
            this.handleCityChange(city)
            this.form = {
                ...this.form,
                orgAddress: address,
                /** 经度 */
                orgAutoNaviLat: lat,
                /** 纬度 */
                orgAutoNaviLng: lng,
                province,
                city,
                county
            }
        }
    }
}
</script>
