/*
 * @Autor: dingxiaolin
 * @Date: 2021-09-29 10:04:34
 * @LastEditors: dingxiaolin
 * @LastEditTime: 2021-10-22 13:55:31
 */
import store from '@/store'

export function actionToObject(json) {
    try {
        return JSON.parse(json)
    } catch (e) {
        console.log('err', e.msg)
    }
    return []
}

/**
 * 控制按钮是否显示
 *
 * @author Antonio
 * @date 2020/06/27 02:34
 */
export function hasBtnPermission(permission) {
    const myBtns = store.state.user.buttons
    const admintype = store.state.user.admintype
    // eslint-disable-next-line eqeqeq
    if (admintype == '1') { // 超级管理员
        return true
    }
    return myBtns.indexOf(permission) > -1
}
