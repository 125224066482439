<!--
 * @Autor: dingxiaolin
 * @Date: 2021-09-23 07:31:51
 * @LastEditors: dingxiaolin
 * @LastEditTime: 2021-10-14 10:34:28
-->
<template>
    <a-layout-sider
        :class="['sider', isDesktop() ? null : 'shadow', theme, fixSiderbar ? 'ant-fixed-sidemenu' : null]"
        width="202px"
        :collapsible="collapsible"
        v-model="collapsed"
        :trigger="null"
    >
        <logo :showTitle="collapsed" />
        <s-menu
            v-scrollBar
            :collapsed="collapsed"
            :menu="menus"
            :default-open-keys="menus[0].name"
            :theme="theme"
            :mode="mode"
            @select="onSelect"
            style="padding: 16px 0px; margin-top: 60px; position: relative; height: 86vh"
        ></s-menu>
    </a-layout-sider>
</template>

<script>
import Logo from '@/components/tools/Logo'
import SMenu from './index'
import { mixin, mixinDevice } from '@/utils/mixin'

export default {
    name: 'SideMenu',
    components: { Logo, SMenu },
    mixins: [mixin, mixinDevice],
    props: {
        mode: {
            type: String,
            required: false,
            default: 'inline',
        },
        theme: {
            type: String,
            required: false,
            default: 'dark',
        },
        collapsible: {
            type: Boolean,
            required: false,
            default: false,
        },
        collapsed: {
            type: Boolean,
            required: false,
            default: false,
        },
        menus: {
            type: Array,
            required: true,
        },
    },
    mounted() {},
    methods: {
        onSelect(obj) {
            // console.log('menus', obj);
            this.$emit('menuSelect', obj)
            // this.$emit('onclick', obj)
        },
    },
}
</script>
