<!--
 * @Autor: dingxiaolin
 * @Date: 2021-09-23 07:31:51
 * @LastEditors: dingxiaolin
 * @LastEditTime: 2021-10-12 13:19:21
-->
<template>
    <div class="logo" :style="!showTitle ? 'width:202px' : 'width:80px'">
        <!-- <router-link :to="{ name: 'welcome' }"> -->
        <img src="@/assets/logo.png" style="border-radius: 3px" />
        <h1 v-if="!showTitle">{{ project.sproduct_name }}</h1>
        <!-- </router-link> -->
    </div>
</template>

<script>
import { mixin, mixinDevice } from '@/utils/mixin'
const Project = require('@/config/product.json')

export default {
    name: 'Logo',
    components: {},
    mixins: [mixin, mixinDevice],
    data() {
        return {
            titles: '',
            project: Project
        }
    },
    props: {
        title: {
            type: String,
            default: '管理后台',
            required: false
        },
        showTitle: {
            type: Boolean,
            default: true,
            required: false
        }
    },
    created() {
        if (this.title.length > 10) {
            this.titles = this.title.substring(0, 9) + '...'
        } else {
            this.titles = this.title
        }
    }
}
</script>
