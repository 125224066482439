<!--
 * @Autor: dingxiaolin
 * @Date: 2021-12-10 17:37:43
 * @LastEditors: dingxiaolin
 * @LastEditTime: 2022-12-07 13:37:33
-->
<template>
    <a-tree-select
        show-search
        v-model="tenantId"
        dropdownClassName="d1"
        style="width: 100%"
        :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
        :tree-data="treeData"
        tree-default-expand-all
        @select="onSelect"
        @click="changeScrollTop"
        :disabled="disabled"
        treeNodeFilterProp="title"
        :size="size"
        :getPopupContainer="
            triggerNode => {
                return triggerNode.parentNode || document.body
            }
        "
    >
    </a-tree-select>
</template>

<script>
import { getDeptTree } from '@/api/alarm'

export default {
    name: 'DeptTree',
    props: {
        all: {
            type: Boolean,
            default: true
        },
        size: {
            type: String,
            default: 'default'
        },
        value: {
            type: String,
            default: ''
        },
        disabled: {
            type: Boolean,
            default: false
        },
        noAll: {
            type: Boolean,
            default: false
        }
    },
    watch: {
        value: {
            handler(v) {
                this.tenantId = v
            },
            immediate: true,
            deep: true
        }
    },
    data() {
        return {
            tenantId: '',
            treeData: []
        }
    },
    created() {
        this.getDeptTree()
    },
    methods: {
        getDeptTree() {
            getDeptTree().then(res => {
                this.treeData = this.noAll
                    ? res
                    : [
                          {
                              title: this.all ? '全部' : '请选择',
                              value: '',
                              children: res
                          }
                      ]
            })
        },
        onSelect(value, node, extra) {
            this.$emit('input', value)
            this.$emit('change', node.$options.propsData)
        },
        changeScrollTop(val) {
            // 修复点击tree，滚动条位置来回滑到底部和顶部的问题
            setTimeout(() => {
                document.getElementsByClassName('d1')[0].scrollTop = 0
            }, 50)
        }
    }
}
</script>

<style></style>
