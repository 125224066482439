/*
 * @Autor: dingxiaolin
 * @Date: 2021-09-23 07:31:51
 * @LastEditors: dingxiaolin
 * @LastEditTime: 2023-01-12 10:11:36
 */
import {
    BasicLayout,
    RouteView,
    BlankLayout,
    PageView
} from '@/layouts'

/**
 * 基础路由
 * @type { *[] }
 */
export const constantRouterMap = [{
    path: '/',
    hidden: true,
    redirect: {
        name: 'login'
    }
},
{
    path: '/login',
    name: 'login',
    component: () => import( /* webpackChunkName: "user" */ '@/views/staticViews/login/index'),
    meta: {
        title: '登录'
    }
},
{
    path: '/',
    component: BasicLayout,
    hidden: true,
    children: [
        // {
        //     path: '/welcome',
        //     name: 'welcome',
        //     component: () => import(/* webpackChunkName: "dashboard" */ '@/views/staticViews/welcome/index'),
        //     meta: { title: '欢迎页', hiddenHeader: true, hiddenMultiTab: true }
        // },
        {
            path: '/account',
            name: 'account',
            component: () => import( /* webpackChunkName: "user" */ '@/views/staticViews/account/Index'),
            meta: {
                title: '个人设置'
            },
            redirect: '/account/base',
            children: [{
                path: '/account/base',
                name: 'base',
                component: () => import( /* webpackChunkName: "user" */ '@/views/staticViews/account/BaseSetting'),
                meta: {
                    title: '基本设置'
                }
            },
            {
                path: '/account/security',
                name: 'security',
                component: () => import( /* webpackChunkName: "user" */ '@/views/staticViews/account/Security'),
                meta: {
                    title: '安全设置'
                }
            },
            {
                path: '/account/custom',
                name: 'custom',
                component: () => import( /* webpackChunkName: "user" */ '@/views/staticViews/account/Custom'),
                meta: {
                    title: '个性化设置'
                }
            },
            {
                path: '/account/notification',
                name: 'notification',
                component: () => import( /* webpackChunkName: "user" */ '@/views/staticViews/account/Notification'),
                meta: {
                    title: '新消息通知'
                }
            },
            {
                path: '/account/binding',
                name: 'binding',
                component: () => import( /* webpackChunkName: "user" */ '@/views/staticViews/account/Binding'),
                meta: {
                    title: '账户绑定'
                }
            }
            ]
        },
        {
            path: '/dd',
            name: 'dd',
            component: () => import( /* webpackChunkName: "h5" */ '@/views/dynamicViews/statistics'),
            meta: {
                title: '数据统计'
            }
        },
        {
            path: '/vehicleTrends',
            name: 'vehicleTrends',
            component: () => import( /* webpackChunkName: "h5" */ '@/views/dynamicViews/flow/vehicleTrends/index'),
            meta: {
                title: '车辆客流分析趋势'
            }
        },
        {
            path: '/vehicleDetails',
            name: 'vehicleDetails',
            component: () => import( /* webpackChunkName: "h5" */ '@/views/dynamicViews/flow/vehicleDetails/index'),
            meta: {
                title: '车辆客流分析详情'
            }
        },
        // Exception
        {
            path: '/404',
            name: 'Exception404',
            component: () => import( /* webpackChunkName: "fail" */ '@/views/staticViews/exception/404'),
            meta: {
                title: '未找到页面',
                hiddenHeader: true,
                hiddenMultiTab: true
            }
        }
    ]
},

{
    path: '/export',
    name: 'export',
    component: () => import( /* webpackChunkName: "h5" */ '@/views/staticViews/export/index'),
    meta: {
        title: '导出'
    }
}
]