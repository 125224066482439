<template>
    <a-card :bordered="false">
        <div class="table-page-search-wrapper" v-if="hasPerm('sysDictlink:page')">
            <a-form layout="inline">
                <a-row :gutter="48">
                    <a-col :md="8" :sm="24">
                        <a-form-item label="POI类型">
                            <a-select v-model="queryParam.poiType" placeholder="请选择POI类型" allow-clear @change="onSearch()">
                                <a-select-option v-for="(item, index) in poiTypeArr" :key="index" :value="item.code">{{
                                    item.value
                                }}</a-select-option>
                            </a-select>
                        </a-form-item>
                    </a-col>
                    <a-col :md="8" :sm="24">
                        <a-form-item label="数据类型">
                            <a-select v-model="queryParam.dataType" placeholder="请选择数据类型" allow-clear @change="onSearch()">
                                <a-select-option v-for="(item, index) in dataTypeArr" :key="index" :value="item.code">{{
                                    item.value
                                }}</a-select-option>
                            </a-select>
                        </a-form-item>
                    </a-col>
                    <template v-if="advanced">
                        <a-col :md="8" :sm="24">
                            <a-form-item label="字典对应">
                                <a-input v-model="queryParam.dictName" allow-clear placeholder="请输入字典对应名称" />
                            </a-form-item>
                        </a-col>
                        <a-col :md="8" :sm="24">
                            <a-form-item label="数据来源">
                                <a-select v-model="queryParam.data_source" placeholder="请选择数据来源" allow-clear @change="onSearch()">
                                    <a-select-option v-for="(item, index) in dataSourceArr" :key="index" :value="item.code">{{
                                        item.value
                                    }}</a-select-option>
                                </a-select>
                            </a-form-item>
                        </a-col>
                        <a-col :md="8" :sm="24">
                            <a-form-item label="名称" class="msy-78">
                                <a-input v-model="queryParam.dataName" allow-clear placeholder="请输入名称" />
                            </a-form-item>
                        </a-col>
                        <a-col :md="8" :sm="24">
                            <a-form-item label="是否绑定">
                                <a-radio-group default-value="" button-style="solid" v-model="queryParam.bindFlag" @change="onSearch">
                                    <a-radio value=""> 全部 </a-radio>
                                    <a-radio v-for="(item, index) in bindFlagArr" :key="index" :value="item.code">
                                        {{ item.value }}
                                    </a-radio>
                                </a-radio-group>
                            </a-form-item>
                        </a-col>
                    </template>
                    <a-col :md="(!advanced && 8) || 24" :sm="24">
                        <span class="table-page-search-submitButtons" :style="(advanced && { float: 'right', overflow: 'hidden' }) || {}">
                            <a-button type="primary" @click="onSearch()">查询</a-button>
                            <a-button style="margin-left: 8px" @click="onReset()">重置</a-button>
                            <a @click="toggleAdvanced" style="margin: 0 8px">
                                {{ advanced ? '收起' : '展开' }}
                                <a-icon :type="advanced ? 'up' : 'down'" />
                            </a>
                        </span>
                    </a-col>
                </a-row>
            </a-form>
        </div>
        <!-- <div class="table-operator" v-if="hasPerm('sysDictlink:edit')">
      <a-button type="primary" v-if="hasPerm('sysDictlink:edit')" icon="plus" @click="$refs.addForm.add()"
        >新增配置</a-button
      >
    </div> -->
        <s-table ref="table" :columns="columns" :data="loadData" :alert="true" :rowKey="(record) => record.code">
            <span slot="name" slot-scope="text">
                <ellipsis :length="20" tooltip>{{ text }}</ellipsis>
            </span>

            <span slot="dataSource" slot-scope="text">
                {{ dataSourceFilter(text) }}
            </span>
            <span slot="dataType" slot-scope="text">
                {{ dataTypeFilter(text) }}
            </span>
            <span slot="poiType" slot-scope="text">
                {{ poiCodeFilter(text) }}
            </span>
            <span slot="action" slot-scope="text, record">
                <a v-if="hasPerm('sysDictlink:edit')" @click="$refs.editForm.edit(record)">编辑</a>
                <!-- <a-divider type="vertical" v-if="hasPerm('sysDictlink:edit') & hasPerm('sysDictlink:delete')" />
        <a-popconfirm
          v-if="hasPerm('sysDictlink:delete')"
          placement="topRight"
          title="确认删除？"
          @confirm="() => sysDictlinkDelete(record)"
        >
          <a>删除</a>
        </a-popconfirm> -->
            </span>
        </s-table>
        <edit-form ref="editForm" @ok="handleOk" v-if="hasPerm('sysDictlink:edit')" />
    </a-card>
</template>
<script>
import { STable, Ellipsis } from '@/components'
import { dictLinkPage, dictLinkEdit } from '@/api/system/config'
import { sysDictTypeDropDown } from '@/api/system/dict'
import editForm from './editForm'
export default {
    components: {
        STable,
        Ellipsis,
        editForm,
    },
    data() {
        return {
            // 高级搜索 展开/关闭
            advanced: false,
            // 查询参数
            queryParam: {},
            // 表头
            columns: [
                {
                    title: 'POI类型',
                    dataIndex: 'poiType',
                    scopedSlots: { customRender: 'poiType' },
                },
                {
                    title: '数据来源',
                    dataIndex: 'dataSource',
                    scopedSlots: { customRender: 'dataSource' },
                },
                {
                    title: '数据类型',
                    dataIndex: 'dataType',
                    scopedSlots: { customRender: 'dataType' },
                },
                {
                    title: '名称',
                    dataIndex: 'dataName',
                },
                {
                    title: '字典对应',
                    dataIndex: 'dictName',
                },
            ],
            // 加载数据方法 必须为 Promise 对象
            loadData: (parameter) => {
                return dictLinkPage(Object.assign({ current: parameter.pageNo, size: parameter.pageSize }, this.queryParam)).then((res) => {
                    let data = {}
                    data.pageNo = res.data.current
                    data.pageSize = res.data.size
                    data.rows = res.data.records
                    this.invoinceList = res.data.records
                    data.totalPage = res.data.pages
                    data.totalRows = res.data.total
                    return data
                })
            },
            selectedRowKeys: [],
            selectedRows: [],
            groupCodeDictTypeDropDown: [],
            poiTypeArr: [],
            dataTypeArr: [
                { code: '', value: '全部' },
                { code: '1', value: '分类' },
                { code: '2', value: '标签' },
            ],
            dataSourceArr: [
                { code: '', value: '全部' },
                { code: '1', value: '高德' },
            ],
            bindFlagArr: [
                { code: '1', value: '是' },
                { code: '2', value: '否' },
            ],
        }
    },
    /**
     * 初始化判断按钮权限是否拥有，没有则不现实列
     */
    created() {
        this.sysDictTypeDropDown()
        if (this.hasPerm('sysDictlink:edit')) {
            this.columns.push({
                title: '操作',
                width: '150px',
                dataIndex: 'action',
                scopedSlots: { customRender: 'action' },
            })
        }
    },
    methods: {
        onReset() {
            this.queryParam = {}
            this.queryParam = {
                bindFlag: '',
            }
            this.$refs.table.refresh(true)
        },
        onSearch() {
            this.$refs.table.refresh(true)
        },
        /**
         * 获取字典数据
         */
        sysDictTypeDropDown() {
            sysDictTypeDropDown({ code: 'poi_type' }).then((res) => {
                this.poiTypeArr = res.data
                this.poiTypeArr.unshift({ code: '', value: '全部' })
            })
        },
        /** POI类型 */
        poiCodeFilter(groupCode) {
            const values = this.poiTypeArr.filter((item) => item.code == groupCode)
            if (values.length > 0) {
                return values[0].value
            }
        },
        /** 数据类型 */
        dataTypeFilter(val) {
            if (val === '1') {
                return '分类'
            } else if (val === '2') {
                return '标签'
            } else {
                return ''
            }
        },
        /** 数据来源 */
        dataSourceFilter(val) {
            if (val === '1') {
                return '高德'
            } else {
                return ''
            }
        },
        toggleAdvanced() {
            this.advanced = !this.advanced
        },
        handleOk() {
            this.$refs.table.refresh()
        },
        onSelectChange(selectedRowKeys, selectedRows) {
            this.selectedRowKeys = selectedRowKeys
            this.selectedRows = selectedRows
        },
    },
}
</script>

<style lang="less" scoped>
.msy-78 {
    .ant-form-item-label {
        width: 78px !important;
    }
}
</style>
