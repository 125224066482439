<template>
    <div>
        <a-card :bordered="false">
            <div class="title flex-start">
                <a-button type="default" icon="arrow-left" @click="$router.push('/aiDevices')"></a-button>
                <div class="titleC pd20"><a-icon type="reddit" class="titleC icon" />{{ detail.deviceName }}</div>
                <div>
                    <a-tag v-if="detail.deviceStatus == 0">
                        <a-badge status="default" text="未激活" />
                    </a-tag>
                    <a-tag v-if="detail.deviceStatus == 1" color="green">
                        <a-badge status="success" text="在线" />
                    </a-tag>
                    <a-tag v-if="detail.deviceStatus == 2" color="red">
                        <a-badge status="error" text="离线" />
                    </a-tag>
                </div>
                <div class="time pd20">
                    最后在线时间：
                    <span v-if="detail.deviceStatus == 1">{{ $moment(new Date()).format('YYYY-MM-DD HH:mm:ss') }}</span>
                    <span v-else>{{ detail.deviceOnlineTime }}</span>
                </div>
                <div class="btnGroup" v-if="hasPerm('aiDevice:detail:reset')">
                    <a-button-group>
                        <a-popconfirm
                            placement="right"
                            title="确定将设备重启吗?"
                            ok-text="继续"
                            cancel-text="取消"
                            @confirm="handleRestart"
                            :disabled="detail.deviceStatus != 1"
                        >
                            <a-button type="default" :disabled="detail.deviceStatus != 1" :loading="reStart.loading">{{ reStart.text }} </a-button>
                        </a-popconfirm>
                    </a-button-group>
                </div>

                <div class="btn-yc" v-if="hasPerm('aiDevice:detail:upgrade')">
                    <a-button type="primary " @click="$refs.upgrade.show('', detail)" :disabled="isUpgrade"> 远程升级 </a-button>
                </div>
            </div>
            <div>
                <a-descriptions bordered :column="12">
                    <a-descriptions-item label="所属机构" :span="3"> {{ detail.tenantName || '-' }} </a-descriptions-item>
                    <a-descriptions-item label="AI终端编号" :span="3"> {{ detail.deviceCode || '-' }} </a-descriptions-item>
                    <a-descriptions-item label="车牌号" :span="3"> {{ detail.areaName || '-' }} </a-descriptions-item>
                    <a-descriptions-item label="盒子唯一标识" :span="3"> {{ detail.deviceTag || '-' }} </a-descriptions-item>
                    <!-- <a-descriptions-item label="设备型号" :span="3">
                        {{ $filterEnum(deviceTypeList, detail.deviceModel) || '-' }}
                    </a-descriptions-item> -->
                    <a-descriptions-item label="设备型号" :span="3">
                        {{ detail.deviceModelName }}
                    </a-descriptions-item>
                    <a-descriptions-item label="备注" :span="3"> {{ detail.memo || '-' }} </a-descriptions-item>
                </a-descriptions>
            </div>
            <a-tabs default-active-key="0" v-model="activeKey">
                <a-tab-pane key="0" tab="心跳记录">
                    <heart />
                </a-tab-pane>
                <a-tab-pane key="1" tab="媒体通道">
                    <media ref="media" :detail="detail" :busInfo="busInfo" :deviceTag="detail.deviceTag" :addBtndiabled="addBtndiabled" />
                </a-tab-pane>
                <a-tab-pane key="2" tab="设备任务" v-if="jhxDevice">
                    <task :detail="detail" ref="task" :deviceTag="detail.deviceTag" />
                </a-tab-pane>
                <a-tab-pane key="3" tab="网络配置" v-if="jhxDevice">
                    <net-work ref="network" :deviceTag="detail.deviceTag" />
                </a-tab-pane>
                <a-tab-pane key="4" tab="参数配置" v-if="hasPerm('aiDevice:detail:config') && jhxDevice">
                    <set-config ref="setconfig" :deviceTag="detail.deviceTag" />
                </a-tab-pane>
                <a-tab-pane key="5" tab="日志" v-if="hasPerm('aiDevice:detail:log') && jhxDevice">
                    <log ref="log" :deviceTag="detail.deviceTag" :deviceStatus="detail.deviceStatus" />
                </a-tab-pane>
                <a-tab-pane key="6" tab="异常日志" v-if="hasPerm('aiDevice:detail:errlog') && jhxDevice">
                    <errorLog ref="errorLog" :deviceId="detail.id" :deviceStatus="detail.deviceStatus" />
                </a-tab-pane>
            </a-tabs>
        </a-card>
        <upgrade ref="upgrade" @ok="handleOk(detail)" />
    </div>
</template>

<script>
import Media from './components/media'
import Task from './components/task'
import NetWork from './components/network'
import SetConfig from './components/setConfig'
import Heart from './components/heart'
import Log from './components/log'
import errorLog from './components/errorLog'
import Clothes from './components/clothes'
import { aiDeviceLogList, aiDeviceDetail, aiDeviceReStart, busInfoDetail } from '@/api/alarm/index'
import Upgrade from './upgrade'
import { enumDeviceType } from '@/utils/enum'
import { mapState } from 'vuex'
import { sysDictTypeDropDown } from '@/api/comm/index'

export default {
    components: {
        Upgrade,
        Media,
        Task,
        NetWork,
        Heart,
        SetConfig,
        Log,
        errorLog,
        Clothes
    },
    computed: {
        ...mapState({
            mqtt: state => state.app.mqtt
        }),
        jhxDevice(){
          if (this.detail.deviceModel == '0' || this.detail.deviceModel == '1') {
                return true
            }
            return false
        }
    },
    data() {
        return {
            /**设备类型列表 */
            deviceTypeList: enumDeviceType,
            labelCol: {
                sm: { span: 6 },
                xs: { span: 24 }
            },
            wrapperCol: {
                sm: { span: 18 },
                xs: { span: 24 }
            },
            /** 选中tab下标 */
            activeKey: '0',
            // 查询参数
            queryParam: {
                descs: 'create_time', //排序字段
                aiDeviceId_equal: this.$route.query.id, //终端ID
                status_equal: '', //在线离线
                logType_equal: '0', //日志类型 0终端心跳包 1告警信息上行
                createTime_ge: this.$moment().format('YYYY-MM-DD') + ' 00:00:00', //日志开始时间 yyyy-MM-dd HH:mm:ss
                createTime_le: this.$moment().format('YYYY-MM-DD') + ' 23:59:59' //日志结束时间 yyyy-MM-dd HH:mm:ss
            },
            queryDate: [this.$moment(this.$moment().format('YYYY-MM-DD 00:00:00')), this.$moment(this.$moment().format('YYYY-MM-DD 23:59:59'))],
            // 表头
            areaTypeList: [],
            heartColumns: [
                {
                    title: '上报时间',
                    dataIndex: 'createTime',
                    width: '80px',
                    ellipsis: true
                },
                {
                    title: '盒子网络地址',
                    dataIndex: 'BoardIp',
                    width: '100px',
                    ellipsis: true
                },
                {
                    title: '盒子芯片温度',
                    dataIndex: 'BoardTemp',
                    width: '60px',
                    ellipsis: true
                },
                {
                    title: '软件版本',
                    dataIndex: 'Version',
                    width: '60px',
                    ellipsis: true
                },
                {
                    title: '位置经度',
                    dataIndex: 'Latitude',
                    width: '60px',
                    ellipsis: true
                },
                {
                    title: '位置纬度',
                    dataIndex: 'Longitude',
                    width: '60px',
                    ellipsis: true
                },
                {
                    title: '网络接入方式',
                    dataIndex: 'BoardType',
                    scopedSlots: { customRender: 'status6' },
                    width: '60px',
                    ellipsis: true
                },
                {
                    title: '空闲内存',
                    dataIndex: 'HostMemory',
                    width: '80px',
                    ellipsis: true
                },
                {
                    title: '主进程内存占用',
                    dataIndex: 'Malloc',
                    width: '80px',
                    ellipsis: true
                },
                {
                    title: '系统镜像版本',
                    dataIndex: 'System',
                    width: '100px',
                    ellipsis: true
                },
                {
                    title: '状态描述',
                    dataIndex: 'Status',
                    width: '80px',
                    ellipsis: true
                },
                {
                    title: '运行状态',
                    dataIndex: 'Key',
                    width: '80px',
                    ellipsis: true
                },
                {
                    title: '剩余空间(kb)',
                    dataIndex: 'Available',
                    width: '80px',
                    ellipsis: true
                },
                {
                    title: '总空间',
                    dataIndex: 'Total',
                    width: '80px',
                    ellipsis: true
                },
                {
                    title: '已用空间',
                    dataIndex: 'Used',
                    width: '80px',
                    ellipsis: true
                },
                {
                    title: '透传参数',
                    dataIndex: 'status12',
                    width: '100px',
                    ellipsis: true
                }
            ],
            // 加载数据方法 必须为 Promise 对象
            loadData: async parameter => {
                const data = await aiDeviceLogList(Object.assign(parameter, this.queryParam))
                data.records.map(v => {
                    if (v.content != '') {
                        Object.assign(v, JSON.parse(v.content))
                        v.HostMemory = JSON.parse(v.content).HostMemory[0]
                        v.Key = JSON.parse(v.content).Key
                        v.Available = JSON.parse(v.content).Available
                        v.Total = JSON.parse(v.content).Total
                        v.Used = JSON.parse(v.content).Used
                    }
                    if (v.status == 2) {
                        v.Status = '离线'
                    }
                })

                return Promise.resolve(data)
            },
            detail: {},
            today: true,
            isUpgrade: false,
            reStart: {
                text: '重启',
                loading: false
            },
            areaId: this.$route.query.areaId,
            busInfo: {},
            addBtndiabled: false
        }
    },
    created() {
        this.getDetail()
    },
    mounted() {
        sysDictTypeDropDown({ code: 'areaType' }).then(res => {
            this.areaTypeList = res
        })
        if (this.$route.query.areaId && this.$route.query.areaId != 'null') {
            this.getBusInfoDetail(this.$route.query.areaId)
        } else {
            this.addBtndiabled = true
            this.$message.warning('请先返回，绑定车辆')
        }
        this.activeKey = this.$route.query.activeKey || '0'
        this.$root.$on('device', res => {
            const { controllerType, status, deviceId } = res
            console.log(res)
            const routeDeviceId = this.$route.query.id
            switch (controllerType) {
                // 算法任务启动
                case 1:
                // 算法任务停止
                case 2:
                // 算法任务删除
                case 3:
                    if (this.activeKey != '2' || deviceId != routeDeviceId) {
                        return
                    }
                    // 移出超时处理
                    this.$refs.task.cancelTimer()
                    if (status === '0') {
                        this.alertMes('success', res.message)
                        this.$refs.task.sync_task('device sync 操作')
                    } else {
                        this.alertMes('error', res.message)
                        this.$refs.task.getTaskList()
                    }
                    break
                // 算法列表
                case 4:
                    if (this.activeKey != '2' || deviceId != routeDeviceId) {
                        return
                    }
                    // 移出超时处理
                    this.$refs.task.cancelTimer()
                    if (status === '0') {
                        // 列表成功
                        this.alertMes('success', res.message)
                        this.$refs.task.getTaskList()
                    } else {
                        this.alertMes('error', res.message)
                    }

                    break
                // 算法任务配置 增/改
                case 5:
                    if (this.activeKey != '2' || deviceId != routeDeviceId) {
                        return
                    }
                    this.$refs.task.cancelTimer()
                    if (status === '0') {
                        this.alertMes('success', res.message)
                        this.$refs.task.sync_task('device sync 增改')
                        this.$refs.task.$refs.edit.handleCancel()
                    } else {
                        this.alertMes('error', res.message)
                        this.$refs.task.getTaskList()
                    }
                    this.$root.$emit('clostTab', 'detail/zone')
                    break
                // 媒体列表
                case 10:
                    if (this.activeKey != '1' || deviceId != routeDeviceId) {
                        return
                    }
                    this.$refs.media.cancelTimer()
                    if (status === '0') {
                        // 列表成功
                        this.alertMes('success', res.message)
                        this.$refs.media.getMediaList()
                    } else {
                        this.alertMes('error', res.message)
                    }
                    break
                // 媒体操作
                case 11:
                    if (this.activeKey != '1' || deviceId != routeDeviceId) {
                        return
                    }
                    // 移除超时处理
                    this.$refs.media.cancelTimer()
                    if (status === '0') {
                        this.alertMes('success', res.message)
                        this.$refs.media.sync_media()
                        // 关闭媒体通道子弹窗
                        this.$refs.media.$refs.editForm.handleCancel()
                    } else {
                        this.alertMes('error', res.message)
                        this.$refs.media.getMediaList()
                    }
                    break
                // 媒体删除
                case 12:
                    if (this.activeKey != '1' || deviceId != routeDeviceId) {
                        return
                    }
                    // 移除超时处理
                    this.$refs.media.cancelTimer()
                    if (status === '0') {
                        this.$refs.media.sync_media()
                        this.alertMes('success', res.message)
                    } else {
                        this.alertMes('error', res.message)
                        this.$refs.media.getMediaList()
                    }
                    break
                // 网络列表
                case 20:
                    if (this.activeKey != '3' || deviceId != routeDeviceId) {
                        return
                    }
                    // 移出超时处理
                    this.$refs.network.cancelTimer()
                    if (status === '0') {
                        // 列表成功
                        this.alertMes('success', res.message)
                        this.$refs.network.getNetWork()
                    } else {
                        this.alertMes('error', res.message)
                    }
                    break
                // 网络配置
                case 21:
                    if (this.activeKey != '3' || deviceId != routeDeviceId) {
                        return
                    }
                    // 移出超时处理
                    this.$refs.network.cancelTimer()
                    if (status === '0') {
                        this.$refs.network.sync_network()
                        this.alertMes('success', res.message)
                    } else {
                        this.alertMes('error', res.message)
                    }
                    break
                // 参数列表
                case 22:
                    if (this.activeKey != '4' || deviceId != routeDeviceId) {
                        return
                    }
                    this.$refs.setconfig.cancelTimer()
                    if (status === '0') {
                        // 列表成功
                        this.alertMes('success', res.message)
                        this.$refs.setconfig.getConfigList()
                    } else {
                        this.alertMes('error', res.message)
                    }
                    break
                // 参数设置
                case 23:
                    if (this.activeKey != '4' || deviceId != routeDeviceId) {
                        return
                    }
                    // 移出超时处理
                    this.$refs.setconfig.cancelTimer()
                    if (status === '0') {
                        this.$refs.setconfig.sync_config()
                        this.$refs.setconfig.$refs.editForm.handleCancel()
                        this.alertMes('success', res.message)
                    } else {
                        this.alertMes('error', res.message)
                    }
                    break
                // 设备重启
                case 25:
                    if (status === '0') {
                        this.getDetail()
                        this.alertMes('success', res.message)
                    } else {
                        this.alertMes('error', res.message)
                    }
                    this.reStart = {
                        text: '重启',
                        loading: false
                    }
                    break
                // 工装信息同步
                case 26:
                    if (this.activeKey != '6' || deviceId != routeDeviceId) {
                        return
                    }
                    this.$refs.suitConfig.cancelTimer()
                    if (status === '0') {
                        this.$refs.suitConfig.suitList()
                        this.alertMes('success', res.message)
                    } else {
                        this.alertMes('error', res.message)
                        this.$refs.suitConfig.setSync()
                    }
                    break
                // 工装添加类别
                case 27:
                    if (this.activeKey != '6' || deviceId != routeDeviceId) {
                        return
                    }
                    this.$refs.suitConfig.cancelTimer()
                    if (status === '0') {
                        this.$refs.suitConfig.suitList()
                        this.alertMes('success', res.message)
                    } else {
                        this.alertMes('error', res.message)
                    }
                    break
                // 工装删除模板
                case 28:
                    if (this.activeKey != '6' || deviceId != routeDeviceId) {
                        return
                    }
                    this.$refs.suitConfig.cancelTimer()
                    if (status === '0') {
                        this.$refs.suitConfig.suitList()
                        this.alertMes('success', res.message)
                    } else {
                        this.alertMes('error', res.message)
                    }
                    break
                // 工装添加模板
                case 29:
                    if (this.activeKey != '7' || deviceId != routeDeviceId) {
                        return
                    }
                    this.$refs.suitConfig.cancelTimer()
                    if (status === '0') {
                        this.$refs.suitConfig.suitList()
                        this.alertMes('success', res.message)
                    } else {
                        this.alertMes('error', res.message)
                    }
                    break
                default:
                    break
            }
            clearTimeout(this.timer)
        })
    },
    methods: {
        getBusInfoDetail(areaId) {
            //获取终端绑定的车辆详情
            busInfoDetail({
                id: areaId // areaId
            }).then(res => {
                this.busInfo = res
                console.log(this.busInfo, 'this.busInfo')
            })
        },
        /** 设备重启 */
        handleRestart() {
            this.reStart = {
                text: '重启中...',
                loading: true
            }
            aiDeviceReStart({
                id: this.$route.query.id
            })
        },
        async getDetail() {
            await aiDeviceDetail({ id: this.$route.query.id }).then(res => {
                this.detail = res
                console.log('detail', this.detail)
                // this.connectMqtt()
            })
        },

        alertMes(type, msg) {
            if (type == 'success') {
                this.$message.success({
                    key: this.key,
                    content: msg
                })
            } else {
                this.$message.error({
                    key: this.key,
                    content: msg
                })
            }
        }
    },
    beforeDestroy() {
        this.$root.$off('device')
        this.$root.$off('task')
    },
    destroyed() {
        console.log('销毁')
        // this.unsubscribe()
    }
}
</script>

<style lang="less" scoped>
.flex.title {
    justify-content: space-around;
    align-items: center;
    margin-bottom: 10px;
}
.titleC {
    font-size: 26px;
    color: #003366;
    font-weight: bold;
    white-space: nowrap;
}
.flex-start {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.pd20 {
    padding: 0 20px;
}
.titleC.icon {
    margin-right: 10px;
}
.btn-yc {
    width: 46%;
    text-align: right;
}
.time {
    white-space: nowrap;
}
.btn-tb {
    text-align: right;
    position: relative;
    top: -55px;
    height: 0;
    margin-right: 40px;
}
.copy {
    color: #02a7f0;
    cursor: pointer;
}

/deep/ .ant-descriptions-item-label {
    width: 150px;
}
/deep/ .ant-descriptions-row > th {
    padding-bottom: 8px !important;
    padding: 7px 24px !important;
    width: 10% !important;
}
/deep/ .ant-descriptions-row > td {
    padding-bottom: 8px !important;
    padding: 7px 24px !important;
    width: 15% !important;
}
/deep/ .ant-table-tbody > tr > td {
    white-space: normal;
    word-wrap: break-word;
    word-break: break-all;
}
</style>
