<template>
    <a-card :bordered="false">
        <div class="table-page-search-wrapper">
            <a-form-model :label-col="labelCol" :wrapper-col="wrapperCol">
                <a-row :gutter="48">
                    <a-col :md="8" :sm="24">
                        <a-form-model-item label="所属机构">
                            <dept-tree v-model="queryParam.tenantId_equal" :all="false" @change="handleDeptChange" />
                        </a-form-model-item>
                    </a-col>
                    <a-col :md="8" :sm="24">
                        <a-form-model-item label="所属线路">
                            <line-select placeholder="请选择所属线路" v-model="lineSubId_equal" :lines="lines" @change="handleLine"/>
                        </a-form-model-item>
                    </a-col>

                    <a-col :md="8" :sm="24">
                        <a-form-model-item label="车牌号">
                            <a-select
                                style="width: 100%"
                                showSearch
                                placeholder="请选择车牌号"
                                :filter-option="filterOption"
                                v-model="queryParam.areaName_equal"
                                allowClear
                            >
                                <a-select-option value="">请选择车牌号</a-select-option>
                                <a-select-option v-for="item in carNumbers.filter(item => !!item.busNum)" :key="item.id" :value="item.busNum">{{
                                    item.busNum
                                }}</a-select-option>
                            </a-select>
                        </a-form-model-item>
                    </a-col>
                    <a-col :md="8" :sm="24">
                        <a-form-model-item label="上下行">
                            <a-select
                                style="width: 100%"
                                placeholder="请选择上下行"
                                :filter-option="filterOption"
                                v-model="queryParam.lineType_equal"
                            >
                                <a-select-option v-for="(item, index) in upAndDown" :key="index" :value="item.code">{{ item.value }}</a-select-option>
                            </a-select>
                        </a-form-model-item>
                    </a-col>
                    <a-col :md="8" :sm="24">
                        <a-form-model-item label="选择时间">
                            <a-range-picker
                                :show-time="{ defaultValue: [$moment('00:00:00', 'HH:mm:ss'), $moment('23:59:59', 'HH:mm:ss')] }"
                                v-model="queryDate"
                                format="YYYY-MM-DD HH:mm:ss"
                                @change="dateChange"
                            />
                        </a-form-model-item>
                    </a-col>
                    <a-col :md="8" :sm="24" align="right">
                        <span class="table-page-search-submitButtons">
                            <a-button type="primary" @click="$refs.table.refresh()">查询</a-button>
                            <a-button style="margin-left: 8px" @click="handleExport">导出</a-button>
                        </span>
                    </a-col>
                </a-row>
            </a-form-model>
        </div>
        <n-table ref="table" :columns="columns" :data="loadData" :rowKey="record => record.id" :scrollX="1200" :scrollToFirstRowOnChange="true">
            <a slot="lngAndLat" slot-scope="text, record" @click="handleLngLat(record)">
                <a-tooltip>
                    <template slot="title">
                        {{ record.isShow ? record.address : text }}
                    </template>
                    {{ record.isShow ? record.address : text }}
                </a-tooltip>
            </a>
            <template slot="footer">
                <div style="text-align:right">
                    <span>合计：上客人数{{ totalInNum }}</span> <span>下客人数{{ totalLeaveNum }}</span>
                </div>
            </template>
        </n-table>
    </a-card>
</template>

<script>
import { NTable, DeptTree, LineSelect } from '@/components'
import { passengerFlowDetails, getLineList, getBusList, exportPassengerFlowDetails } from '@/api/alarm/index'
export default {
    components: {
        NTable,
        DeptTree,
        LineSelect
    },

    data() {
        return {
            labelCol: {
                sm: { span: 6 },
                xs: { span: 24 }
            },
            wrapperCol: {
                sm: { span: 18 },
                xs: { span: 24 }
            },
            queryDate: [this.$moment().format('YYYY-MM-DD 00:00:00'), this.$moment().format('YYYY-MM-DD 23:59:59')], //告警时间范围
            // 查询参数
            queryParam: {
                uploadTime_ge: this.$moment().format('YYYY-MM-DD 00:00:00'), //查询开始时间yyyy-MM-dd HH:mm:ss
                uploadTime_le: this.$moment().format('YYYY-MM-DD 23:59:59'), //查询开始时间yyyy-MM-dd HH:mm:ss
                tenantId_equal: this.$store.state.user.tenant.tenantId == 0 ? '' : this.$store.state.user.tenant.tenantId, //部门
                areaName_equal: '', //车牌号
                lineType_equal: '', //线路类型
                lineSubId_equal: '', //线路名称
                descs: 'upload_time' //排序，默认传upload_time倒序
            },
            lineSubId_equal:'',
            // 表头
            columns: [
                {
                    title: '序号',
                    dataIndex: 'id',
                    key: 'id',
                    customRender: (text, record, index) => index + 1,
                    width: '80px',
                    align: 'center'
                },
                {
                    title: '车牌号',
                    dataIndex: 'areaName',
                    key: 'areaName',
                    width: '100px'
                },
                {
                    title: '所属机构',
                    dataIndex: 'tenantName',
                    key: 'tenantName',
                    ellipsis: true,
                    width: '120px'
                },
                {
                    title: '线路名称',
                    dataIndex: 'lineSubName',
                    key: 'lineSubName',
                    ellipsis: true,
                    width: '180px'
                },
                {
                    title: '上下行',
                    dataIndex: 'lineType',
                    key: 'lineType',
                    width: '70px',
                    ellipsis: true
                },
                {
                    title: 'AI终端编号',
                    dataIndex: 'deviceCode',
                    key: 'deviceCode',
                    width: '120px',
                    ellipsis: true
                },
                {
                    title: '统计时间',
                    dataIndex: 'uploadTime',
                    key: 'uploadTime',
                    ellipsis: true,
                    width: '200px'
                },
                {
                    title: '上客人数',
                    dataIndex: 'inNum',
                    key: 'inNum',
                    width: '100px',
                    ellipsis: true
                },
                {
                    title: '下客人数',
                    dataIndex: 'leaveNum',
                    key: 'leaveNum',
                    width: '100px',
                    ellipsis: true
                },
                {
                    title: '站点',
                    dataIndex: 'stationName',
                    key: 'stationName',
                    width: '180px',
                    ellipsis: true
                },
                {
                    title: '经纬度',
                    dataIndex: 'lngAndLat',
                    key: 'lngAndLat',
                    scopedSlots: { customRender: 'lngAndLat' },
                    width: '280px',
                    ellipsis: true
                }
            ],
            upAndDown: [
                { code: '', value: '全部' },
                { code: 0, value: '上行' },
                { code: 1, value: '下行' },
                { code: 2, value: '环线' }
            ],
            totalInNum: '',
            totalLeaveNum: '',
            /** 线路列表 */
            lines: [],
            /** 车牌列表 */
            carNumbers: [],
            loadData: async p => {
                if (this.queryParam.tenantId_equal === '') {
                    this.$message.warning('请选择机构')
                    return
                }
                if (!this.queryParam.areaName_equal) {
                    this.$message.warning('请选择车牌号')
                    return
                }
                if (this.$moment(this.queryParam.uploadTime_le).diff(this.$moment(this.queryParam.uploadTime_ge), 'hours') > 48) {
                    this.$message.warning('时间长度不能超过48小时')
                    return
                }
                return await passengerFlowDetails(Object.assign(p, this.queryParam)).then(res => {
                    if (res.records.length > 0) {
                        const { totalInNum, totalLeaveNum } = res.records[0]
                        this.totalInNum = totalInNum
                        this.totalLeaveNum = totalLeaveNum
                        res.records.forEach(item => (item.isShow = false))
                    } else {
                        this.totalInNum = 0
                        this.totalLeaveNum = 0
                    }
                    return res
                })
            },
            geocoder: null,
            tenantName: this.$store.state.user.tenant.tenantName
        }
    },

    activated() {
        this.init()
    },
    methods: {
        handleLine(v, lineSubId_equal) {
            this.queryParam.lineSubId_equal = lineSubId_equal
        },
        handleLngLat(record) {
            let lnglat = record.lngAndLat.split(',')
            this.getAddress(lnglat, record)
        },
        getAddress(lnglat, item) {
            this.geocoder = new AMap.Geocoder()
            console.log(lnglat)
            this.geocoder.getAddress(lnglat, (status, result) => {
                console.log(status, result)
                if (status === 'complete' && result.regeocode) {
                    item.address = result.regeocode.formattedAddress
                    item.isShow = !item.isShow
                } else {
                    this.$message.error('根据经纬度查询地址失败')
                    throw new Error('根据经纬度查询地址失败')
                }
            })
        },
        init() {
            this.getLines()
            this.getBus()
        },
        filterOption(input, option) {
            return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
        },
        handleDeptChange({ value, title }) {
            this.getLines(value)
            this.getBus(value)
            this.queryParam.lineSubId_equal = ''
            this.lineSubId_equal = undefined
            this.queryParam.areaName_equal = undefined
            this.tenantName = title
        },
        getLines(tenantId = this.$store.state.user.tenant.tenantId) {
            getLineList({
                size: '-1',
                descs: 'create_time', //排序字段
                tenantId_equal: tenantId //所属机构
            }).then(res => {
                this.lines = res.records
            })
        },
        getBus(tenantId = this.$store.state.user.tenant.tenantId) {
            getBusList({
                size: '-1',
                descs: 'create_time', //排序字段
                tenantId_equal: tenantId
            }).then(res => {
                this.carNumbers = res.records
            })
        },
        dateChange(date, dateString) {
            if (date[1] && date[0] && date[1].diff(date[0], 'hours') > 48) {
                this.$message.warning('时间长度不能超过48小时')
            }
            this.queryParam.uploadTime_ge = dateString[0] ? this.$moment(dateString[0]).format('YYYY-MM-DD HH:mm:ss') : ''
            this.queryParam.uploadTime_le = dateString[1] ? this.$moment(dateString[1]).format('YYYY-MM-DD HH:mm:ss') : ''
        },
        /** 导出 */
        handleExport() {
            if (this.queryParam.tenantId_equal === '') {
                this.$message.warning('请选择机构')
                return
            }
            if (!this.queryParam.areaName_equal) {
                this.$message.warning('请选择车牌号')
                return
            }
            if (this.$moment(this.queryParam.uploadTime_le).diff(this.$moment(this.queryParam.uploadTime_ge), 'hours') > 48) {
                this.$message.warning('时间长度不能超过48小时')
                return
            }

            exportPassengerFlowDetails({ ...this.queryParam, tenantName: this.tenantName })
        }
    }
}
</script>

<style lang="less" scoped>
.alarmTypeName {
    border-radius: 20px;
    padding: 2px 8px;
    width: fit-content;
    background: rgba(250, 152, 164, 0.996078431372549);
    color: #fff;
    font-size: 12px;
}
/deep/ .ant-card {
    height: 100%;
}
/deep/ .ant-card-body {
    height: 100%;
}
/deep/ .ant-table-body {
    max-height: calc(((100vh - 331px) - 94px) - 0px) !important;
}
</style>
