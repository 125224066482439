/*
 * @Autor: dingxiaolin
 * @Date: 2022-10-05 09:22:06
 * @LastEditors: dingxiaolin
 * @LastEditTime: 2022-10-22 09:12:41
 */
import { axios } from '@/utils/request'
import config from '@/config/config.js'

/**
 * 查询系统字典值
 */
export function sysDictDataPage(parameter) {
    return axios({
        url: config.namespace + '/dict/data/page',
        method: 'get',
        params: parameter
    })
}

/**
 * 添加系统字典值
 */
export function sysDictDataAdd(parameter) {
    return axios({
        url: config.namespace + '/dict/data/add',
        method: 'post',
        data: parameter
    })
}

/**
 * 编辑系统字典值
 */
export function sysDictDataEdit(parameter) {
    return axios({
        url: config.namespace + '/dict/data/edit',
        method: 'post',
        data: parameter
    })
}

/**
 * 删除系统字典值
 */
export function sysDictDataDelete(parameter) {
    return axios({
        url: config.namespace + '/dict/data/delete',
        method: 'post',
        data: parameter
    })
}
